/*
Author         : G-axon
Template Name  : Jumbo React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
    B. Base
        01. Base Styles
        02. Typography Styles
    C. Layouts
        01. App Layout Styles
        02. Sidebar Styles
        03. Header Styles
        04. Main Content Styles
        05. Footer Styles
        06. Right Sidebar Styles
    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. Badges Styles
        04. Blockquotes Styles
        05. Bottom Navigation Styles
        06. Breadcrumb Styles
        07. Button Styles
        08. Button Group Styles
        09. Callout Styles
        10. Cards Styles
        11. Charts Styles
        12. Chips Styles
        13. Carousel Styles
        14. Color Picker Styles
        15. Drawer Styles
        16. E-commerce Styles
        17. Grids List Styles
        18. Icons Styles
        19. Label Styles
        20. List Styles
        21. Nav Style
        22. Pricing Styles
        23. Progressbar Styles
        24. Popover Styles
        25. React Joyride Styles
        26. Tables Styles
        27. Tabs Styles
        28. Testimonial Styles
        29. Time Lines Style
        30. Joyride Tour Styles
        31. Form Styles
    E. Pages
        01. Login Styles
        02. Error Styles
        03. Editor Styles
        04. Contact Page Styles
        05. Portfolio Styles
        06. Faqs Styles
        07. Dashboard Styles
    F. Slider
    G. Modules
        01. App Module Style
        02. Chat Module Styles
        03. Mails Module Styles
        04. Contact Module Styles
        05. Calendar Module Style
  =============================================================*/
/* Globals
   ========================================================================== */
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i");
.jr-menu-icon .menu-icon {
  position: relative;
  width: 70%;
  height: 1px;
  min-height: 1px;
  color: inherit;
  background-color: #808080;
  display: block;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  margin: 6px 0;
  cursor: pointer; }
  .jr-menu-icon .menu-icon:after, .jr-menu-icon .menu-icon:before {
    position: absolute;
    display: block;
    color: inherit;
    background-color: inherit;
    left: 0;
    height: 1px;
    min-height: 1px;
    content: "";
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .jr-menu-icon .menu-icon:after {
    width: 120%;
    bottom: -6px; }
  .jr-menu-icon .menu-icon:before {
    width: 130%;
    top: -6px; }

.jr-menu-icon:hover .menu-icon {
  width: 100%; }
  .jr-menu-icon:hover .menu-icon:after, .jr-menu-icon:hover .menu-icon:before {
    width: 100%; }

.size-50, .user-avatar, .jr-fab-btn, .user-list .user-avatar {
  height: 50px !important;
  width: 50px !important;
  line-height: 50px; }

.size-60, .jr-btn-fab-lg {
  height: 60px !important;
  width: 60px !important;
  line-height: 60px; }

.size-80 {
  height: 80px !important;
  width: 80px !important;
  line-height: 80px; }

.size-100 {
  height: 100px !important;
  width: 100px !important;
  line-height: 100px; }

.size-20 {
  height: 20px !important;
  width: 20px !important;
  line-height: 20px; }

.size-30, .jr-menu-icon, .jr-btn-fab-xs, .icon {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px; }

.size-40, .jr-btn-fab-sm {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px; }

.size-120, .profile-intro img.avatar-circle {
  height: 120px !important;
  width: 120px !important;
  line-height: 120px; }

.icon-addon {
  display: inline-block;
  text-align: center;
  background-color: inherit; }

.jr-card {
  padding: 24px 30px;
  margin-bottom: 34px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ced4da;
  position: relative; }

/* Ripple magic */
.ripple-effect {
  position: relative;
  overflow: hidden; }
  .ripple-effect:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%; }
  .ripple-effect:hover:after {
    animation: ripple 1s ease-out; }

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5; }
  20% {
    transform: scale(60, 60);
    opacity: 0.3; }
  100% {
    opacity: 0;
    transform: scale(100, 100); } }

@-webkit-keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5; }
  20% {
    transform: scale(60, 60);
    opacity: 0.3; }
  100% {
    opacity: 0;
    transform: scale(100, 100); } }

.quick-menu .icon-alert:after {
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  -moz-box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  -o-box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
  animation: pulse 2s infinite; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
    box-shadow: 0 0 0 10px rgba(244, 67, 54, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0); } }

@-webkit-keyframes online {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); } }

@keyframes online {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
    box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); } }

@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 193, 7, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0); } }

@keyframes away {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 193, 7, 0);
    box-shadow: 0 0 0 10px rgba(255, 193, 7, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0); } }

/*Different size images set in Equal block height and width*/
a.btn-info, a.btn-danger, a.btn-warning, a.btn-success, a.btn-dark, a.badge-success, a.badge-danger, a.badge-warning, a.badge-info, a.badge-dark {
  color: #fff !important; }
  a.btn-info:hover, a.btn-danger:hover, a.btn-warning:hover, a.btn-success:hover, a.btn-dark:hover, a.badge-success:hover, a.badge-danger:hover, a.badge-warning:hover, a.badge-info:hover, a.badge-dark:hover, a.btn-info:focus, a.btn-danger:focus, a.btn-warning:focus, a.btn-success:focus, a.btn-dark:focus, a.badge-success:focus, a.badge-danger:focus, a.badge-warning:focus, a.badge-info:focus, a.badge-dark:focus {
    color: #fff !important; }

.btn:not([disabled]):not(.disabled):active,
.btn-info:not([disabled]):not(.disabled):active,
.btn-success:not([disabled]):not(.disabled):active,
.btn-warning:not([disabled]):not(.disabled):active,
.btn-danger:not([disabled]):not(.disabled):active,
.btn-light:not([disabled]):not(.disabled):active,
.btn-dark:not([disabled]):not(.disabled):active,
.btn-primary:not([disabled]):not(.disabled):active,
.btn-secondary:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active,
.btn-info:not([disabled]):not(.disabled).active,
.btn-success:not([disabled]):not(.disabled).active,
.btn-warning:not([disabled]):not(.disabled).active,
.btn-danger:not([disabled]):not(.disabled).active,
.btn-light:not([disabled]):not(.disabled).active,
.btn-dark:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled).active {
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none; }
  .btn:not([disabled]):not(.disabled):active:focus,
  .btn-info:not([disabled]):not(.disabled):active:focus,
  .btn-success:not([disabled]):not(.disabled):active:focus,
  .btn-warning:not([disabled]):not(.disabled):active:focus,
  .btn-danger:not([disabled]):not(.disabled):active:focus,
  .btn-light:not([disabled]):not(.disabled):active:focus,
  .btn-dark:not([disabled]):not(.disabled):active:focus,
  .btn-primary:not([disabled]):not(.disabled):active:focus,
  .btn-secondary:not([disabled]):not(.disabled):active:focus, .btn:not([disabled]):not(.disabled).active:focus,
  .btn-info:not([disabled]):not(.disabled).active:focus,
  .btn-success:not([disabled]):not(.disabled).active:focus,
  .btn-warning:not([disabled]):not(.disabled).active:focus,
  .btn-danger:not([disabled]):not(.disabled).active:focus,
  .btn-light:not([disabled]):not(.disabled).active:focus,
  .btn-dark:not([disabled]):not(.disabled).active:focus,
  .btn-primary:not([disabled]):not(.disabled).active:focus,
  .btn-secondary:not([disabled]):not(.disabled).active:focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-25 {
  max-width: 25% !important; }

.mw-50 {
  max-width: 50% !important; }

.mw-75 {
  max-width: 75% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-25 {
  max-height: 25% !important; }

.mh-50 {
  max-height: 50% !important; }

.mh-75 {
  max-height: 75% !important; }

.mh-100 {
  max-height: 100% !important; }

.bg-primary {
  background-color: #3f51b5 !important; }

.text-primary {
  color: #3f51b5 !important; }
  .text-primary.icon-outline {
    border-color: #3f51b5 !important; }

.bg-primary.lighten-5 {
  background-color: #e8eaf6 !important; }

.text-primary.text-lighten-5 {
  color: #e8eaf6 !important; }
  .text-primary.text-lighten-5.icon-outline {
    border-color: #e8eaf6 !important; }

.bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.text-primary.text-lighten-4 {
  color: #c5cae9 !important; }
  .text-primary.text-lighten-4.icon-outline {
    border-color: #c5cae9 !important; }

.bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.text-primary.text-lighten-3 {
  color: #9fa8da !important; }
  .text-primary.text-lighten-3.icon-outline {
    border-color: #9fa8da !important; }

.bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.text-primary.text-lighten-2 {
  color: #7986cb !important; }
  .text-primary.text-lighten-2.icon-outline {
    border-color: #7986cb !important; }

.bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.text-primary.text-lighten-1 {
  color: #5c6bc0 !important; }
  .text-primary.text-lighten-1.icon-outline {
    border-color: #5c6bc0 !important; }

.bg-primary.darken-1 {
  background-color: #3949ab !important; }

.text-primary.text-darken-1 {
  color: #3949ab !important; }
  .text-primary.text-darken-1.icon-outline {
    border-color: #3949ab !important; }

.bg-primary.darken-2 {
  background-color: #303f9f !important; }

.text-primary.text-darken-2 {
  color: #303f9f !important; }
  .text-primary.text-darken-2.icon-outline {
    border-color: #303f9f !important; }

.bg-primary.darken-3 {
  background-color: #283593 !important; }

.text-primary.text-darken-3 {
  color: #283593 !important; }
  .text-primary.text-darken-3.icon-outline {
    border-color: #283593 !important; }

.bg-primary.darken-4 {
  background-color: #1a237e !important; }

.text-primary.text-darken-4 {
  color: #1a237e !important; }
  .text-primary.text-darken-4.icon-outline {
    border-color: #1a237e !important; }

.bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.text-primary.text-accent-1 {
  color: #8c9eff !important; }
  .text-primary.text-accent-1.icon-outline {
    border-color: #8c9eff !important; }

.bg-primary.accent-2 {
  background-color: #536dfe !important; }

.text-primary.text-accent-2 {
  color: #536dfe !important; }
  .text-primary.text-accent-2.icon-outline {
    border-color: #536dfe !important; }

.bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.text-primary.text-accent-3 {
  color: #3d5afe !important; }
  .text-primary.text-accent-3.icon-outline {
    border-color: #3d5afe !important; }

.bg-primary.accent-4 {
  background-color: #304ffe !important; }

.text-primary.text-accent-4 {
  color: #304ffe !important; }
  .text-primary.text-accent-4.icon-outline {
    border-color: #304ffe !important; }

.bg-red {
  background-color: #F44336 !important; }

.text-red {
  color: #F44336 !important; }
  .text-red.icon-outline {
    border-color: #F44336 !important; }

.bg-red.lighten-5 {
  background-color: #FFEBEE !important; }

.text-red.text-lighten-5 {
  color: #FFEBEE !important; }
  .text-red.text-lighten-5.icon-outline {
    border-color: #FFEBEE !important; }

.bg-red.lighten-4 {
  background-color: #FFCDD2 !important; }

.text-red.text-lighten-4 {
  color: #FFCDD2 !important; }
  .text-red.text-lighten-4.icon-outline {
    border-color: #FFCDD2 !important; }

.bg-red.lighten-3 {
  background-color: #EF9A9A !important; }

.text-red.text-lighten-3 {
  color: #EF9A9A !important; }
  .text-red.text-lighten-3.icon-outline {
    border-color: #EF9A9A !important; }

.bg-red.lighten-2 {
  background-color: #E57373 !important; }

.text-red.text-lighten-2 {
  color: #E57373 !important; }
  .text-red.text-lighten-2.icon-outline {
    border-color: #E57373 !important; }

.bg-red.lighten-1 {
  background-color: #EF5350 !important; }

.text-red.text-lighten-1 {
  color: #EF5350 !important; }
  .text-red.text-lighten-1.icon-outline {
    border-color: #EF5350 !important; }

.bg-red.darken-1 {
  background-color: #E53935 !important; }

.text-red.text-darken-1 {
  color: #E53935 !important; }
  .text-red.text-darken-1.icon-outline {
    border-color: #E53935 !important; }

.bg-red.darken-2 {
  background-color: #D32F2F !important; }

.text-red.text-darken-2 {
  color: #D32F2F !important; }
  .text-red.text-darken-2.icon-outline {
    border-color: #D32F2F !important; }

.bg-red.darken-3 {
  background-color: #C62828 !important; }

.text-red.text-darken-3 {
  color: #C62828 !important; }
  .text-red.text-darken-3.icon-outline {
    border-color: #C62828 !important; }

.bg-red.darken-4 {
  background-color: #B71C1C !important; }

.text-red.text-darken-4 {
  color: #B71C1C !important; }
  .text-red.text-darken-4.icon-outline {
    border-color: #B71C1C !important; }

.bg-red.accent-1 {
  background-color: #FF8A80 !important; }

.text-red.text-accent-1 {
  color: #FF8A80 !important; }
  .text-red.text-accent-1.icon-outline {
    border-color: #FF8A80 !important; }

.bg-red.accent-2 {
  background-color: #FF5252 !important; }

.text-red.text-accent-2 {
  color: #FF5252 !important; }
  .text-red.text-accent-2.icon-outline {
    border-color: #FF5252 !important; }

.bg-red.accent-3 {
  background-color: #FF1744 !important; }

.text-red.text-accent-3 {
  color: #FF1744 !important; }
  .text-red.text-accent-3.icon-outline {
    border-color: #FF1744 !important; }

.bg-red.accent-4 {
  background-color: #D50000 !important; }

.text-red.text-accent-4 {
  color: #D50000 !important; }
  .text-red.text-accent-4.icon-outline {
    border-color: #D50000 !important; }

.bg-pink {
  background-color: #e91e63 !important; }

.text-pink {
  color: #e91e63 !important; }
  .text-pink.icon-outline {
    border-color: #e91e63 !important; }

.bg-pink.lighten-5 {
  background-color: #fce4ec !important; }

.text-pink.text-lighten-5 {
  color: #fce4ec !important; }
  .text-pink.text-lighten-5.icon-outline {
    border-color: #fce4ec !important; }

.bg-pink.lighten-4 {
  background-color: #f8bbd0 !important; }

.text-pink.text-lighten-4 {
  color: #f8bbd0 !important; }
  .text-pink.text-lighten-4.icon-outline {
    border-color: #f8bbd0 !important; }

.bg-pink.lighten-3 {
  background-color: #f48fb1 !important; }

.text-pink.text-lighten-3 {
  color: #f48fb1 !important; }
  .text-pink.text-lighten-3.icon-outline {
    border-color: #f48fb1 !important; }

.bg-pink.lighten-2 {
  background-color: #f06292 !important; }

.text-pink.text-lighten-2 {
  color: #f06292 !important; }
  .text-pink.text-lighten-2.icon-outline {
    border-color: #f06292 !important; }

.bg-pink.lighten-1 {
  background-color: #ec407a !important; }

.text-pink.text-lighten-1 {
  color: #ec407a !important; }
  .text-pink.text-lighten-1.icon-outline {
    border-color: #ec407a !important; }

.bg-pink.darken-1 {
  background-color: #d81b60 !important; }

.text-pink.text-darken-1 {
  color: #d81b60 !important; }
  .text-pink.text-darken-1.icon-outline {
    border-color: #d81b60 !important; }

.bg-pink.darken-2 {
  background-color: #c2185b !important; }

.text-pink.text-darken-2 {
  color: #c2185b !important; }
  .text-pink.text-darken-2.icon-outline {
    border-color: #c2185b !important; }

.bg-pink.darken-3 {
  background-color: #ad1457 !important; }

.text-pink.text-darken-3 {
  color: #ad1457 !important; }
  .text-pink.text-darken-3.icon-outline {
    border-color: #ad1457 !important; }

.bg-pink.darken-4 {
  background-color: #880e4f !important; }

.text-pink.text-darken-4 {
  color: #880e4f !important; }
  .text-pink.text-darken-4.icon-outline {
    border-color: #880e4f !important; }

.bg-pink.accent-1 {
  background-color: #ff80ab !important; }

.text-pink.text-accent-1 {
  color: #ff80ab !important; }
  .text-pink.text-accent-1.icon-outline {
    border-color: #ff80ab !important; }

.bg-pink.accent-2 {
  background-color: #ff4081 !important; }

.text-pink.text-accent-2 {
  color: #ff4081 !important; }
  .text-pink.text-accent-2.icon-outline {
    border-color: #ff4081 !important; }

.bg-pink.accent-3 {
  background-color: #f50057 !important; }

.text-pink.text-accent-3 {
  color: #f50057 !important; }
  .text-pink.text-accent-3.icon-outline {
    border-color: #f50057 !important; }

.bg-pink.accent-4 {
  background-color: #c51162 !important; }

.text-pink.text-accent-4 {
  color: #c51162 !important; }
  .text-pink.text-accent-4.icon-outline {
    border-color: #c51162 !important; }

.bg-purple {
  background-color: #9C27B0 !important; }

.text-purple {
  color: #9C27B0 !important; }
  .text-purple.icon-outline {
    border-color: #9C27B0 !important; }

.bg-purple.lighten-5 {
  background-color: #f3e5f5 !important; }

.text-purple.text-lighten-5 {
  color: #f3e5f5 !important; }
  .text-purple.text-lighten-5.icon-outline {
    border-color: #f3e5f5 !important; }

.bg-purple.lighten-4 {
  background-color: #e1bee7 !important; }

.text-purple.text-lighten-4 {
  color: #e1bee7 !important; }
  .text-purple.text-lighten-4.icon-outline {
    border-color: #e1bee7 !important; }

.bg-purple.lighten-3 {
  background-color: #ce93d8 !important; }

.text-purple.text-lighten-3 {
  color: #ce93d8 !important; }
  .text-purple.text-lighten-3.icon-outline {
    border-color: #ce93d8 !important; }

.bg-purple.lighten-2 {
  background-color: #ba68c8 !important; }

.text-purple.text-lighten-2 {
  color: #ba68c8 !important; }
  .text-purple.text-lighten-2.icon-outline {
    border-color: #ba68c8 !important; }

.bg-purple.lighten-1 {
  background-color: #ab47bc !important; }

.text-purple.text-lighten-1 {
  color: #ab47bc !important; }
  .text-purple.text-lighten-1.icon-outline {
    border-color: #ab47bc !important; }

.bg-purple.darken-1 {
  background-color: #8e24aa !important; }

.text-purple.text-darken-1 {
  color: #8e24aa !important; }
  .text-purple.text-darken-1.icon-outline {
    border-color: #8e24aa !important; }

.bg-purple.darken-2 {
  background-color: #7b1fa2 !important; }

.text-purple.text-darken-2 {
  color: #7b1fa2 !important; }
  .text-purple.text-darken-2.icon-outline {
    border-color: #7b1fa2 !important; }

.bg-purple.darken-3 {
  background-color: #6a1b9a !important; }

.text-purple.text-darken-3 {
  color: #6a1b9a !important; }
  .text-purple.text-darken-3.icon-outline {
    border-color: #6a1b9a !important; }

.bg-purple.darken-4 {
  background-color: #4a148c !important; }

.text-purple.text-darken-4 {
  color: #4a148c !important; }
  .text-purple.text-darken-4.icon-outline {
    border-color: #4a148c !important; }

.bg-purple.accent-1 {
  background-color: #ea80fc !important; }

.text-purple.text-accent-1 {
  color: #ea80fc !important; }
  .text-purple.text-accent-1.icon-outline {
    border-color: #ea80fc !important; }

.bg-purple.accent-2 {
  background-color: #e040fb !important; }

.text-purple.text-accent-2 {
  color: #e040fb !important; }
  .text-purple.text-accent-2.icon-outline {
    border-color: #e040fb !important; }

.bg-purple.accent-3 {
  background-color: #d500f9 !important; }

.text-purple.text-accent-3 {
  color: #d500f9 !important; }
  .text-purple.text-accent-3.icon-outline {
    border-color: #d500f9 !important; }

.bg-purple.accent-4 {
  background-color: #aa00ff !important; }

.text-purple.text-accent-4 {
  color: #aa00ff !important; }
  .text-purple.text-accent-4.icon-outline {
    border-color: #aa00ff !important; }

.bg-deep-purple {
  background-color: #673ab7 !important; }

.text-deep-purple {
  color: #673ab7 !important; }
  .text-deep-purple.icon-outline {
    border-color: #673ab7 !important; }

.bg-deep-purple.lighten-5 {
  background-color: #ede7f6 !important; }

.text-deep-purple.text-lighten-5 {
  color: #ede7f6 !important; }
  .text-deep-purple.text-lighten-5.icon-outline {
    border-color: #ede7f6 !important; }

.bg-deep-purple.lighten-4 {
  background-color: #d1c4e9 !important; }

.text-deep-purple.text-lighten-4 {
  color: #d1c4e9 !important; }
  .text-deep-purple.text-lighten-4.icon-outline {
    border-color: #d1c4e9 !important; }

.bg-deep-purple.lighten-3 {
  background-color: #b39ddb !important; }

.text-deep-purple.text-lighten-3 {
  color: #b39ddb !important; }
  .text-deep-purple.text-lighten-3.icon-outline {
    border-color: #b39ddb !important; }

.bg-deep-purple.lighten-2 {
  background-color: #9575cd !important; }

.text-deep-purple.text-lighten-2 {
  color: #9575cd !important; }
  .text-deep-purple.text-lighten-2.icon-outline {
    border-color: #9575cd !important; }

.bg-deep-purple.lighten-1 {
  background-color: #7e57c2 !important; }

.text-deep-purple.text-lighten-1 {
  color: #7e57c2 !important; }
  .text-deep-purple.text-lighten-1.icon-outline {
    border-color: #7e57c2 !important; }

.bg-deep-purple.darken-1 {
  background-color: #5e35b1 !important; }

.text-deep-purple.text-darken-1 {
  color: #5e35b1 !important; }
  .text-deep-purple.text-darken-1.icon-outline {
    border-color: #5e35b1 !important; }

.bg-deep-purple.darken-2 {
  background-color: #512da8 !important; }

.text-deep-purple.text-darken-2 {
  color: #512da8 !important; }
  .text-deep-purple.text-darken-2.icon-outline {
    border-color: #512da8 !important; }

.bg-deep-purple.darken-3 {
  background-color: #4527a0 !important; }

.text-deep-purple.text-darken-3 {
  color: #4527a0 !important; }
  .text-deep-purple.text-darken-3.icon-outline {
    border-color: #4527a0 !important; }

.bg-deep-purple.darken-4 {
  background-color: #311b92 !important; }

.text-deep-purple.text-darken-4 {
  color: #311b92 !important; }
  .text-deep-purple.text-darken-4.icon-outline {
    border-color: #311b92 !important; }

.bg-deep-purple.accent-1 {
  background-color: #b388ff !important; }

.text-deep-purple.text-accent-1 {
  color: #b388ff !important; }
  .text-deep-purple.text-accent-1.icon-outline {
    border-color: #b388ff !important; }

.bg-deep-purple.accent-2 {
  background-color: #7c4dff !important; }

.text-deep-purple.text-accent-2 {
  color: #7c4dff !important; }
  .text-deep-purple.text-accent-2.icon-outline {
    border-color: #7c4dff !important; }

.bg-deep-purple.accent-3 {
  background-color: #651fff !important; }

.text-deep-purple.text-accent-3 {
  color: #651fff !important; }
  .text-deep-purple.text-accent-3.icon-outline {
    border-color: #651fff !important; }

.bg-deep-purple.accent-4 {
  background-color: #6200ea !important; }

.text-deep-purple.text-accent-4 {
  color: #6200ea !important; }
  .text-deep-purple.text-accent-4.icon-outline {
    border-color: #6200ea !important; }

.bg-indigo {
  background-color: #3f51b5 !important; }

.text-indigo {
  color: #3f51b5 !important; }
  .text-indigo.icon-outline {
    border-color: #3f51b5 !important; }

.bg-indigo.lighten-5 {
  background-color: #e8eaf6 !important; }

.text-indigo.text-lighten-5 {
  color: #e8eaf6 !important; }
  .text-indigo.text-lighten-5.icon-outline {
    border-color: #e8eaf6 !important; }

.bg-indigo.lighten-4 {
  background-color: #c5cae9 !important; }

.text-indigo.text-lighten-4 {
  color: #c5cae9 !important; }
  .text-indigo.text-lighten-4.icon-outline {
    border-color: #c5cae9 !important; }

.bg-indigo.lighten-3 {
  background-color: #9fa8da !important; }

.text-indigo.text-lighten-3 {
  color: #9fa8da !important; }
  .text-indigo.text-lighten-3.icon-outline {
    border-color: #9fa8da !important; }

.bg-indigo.lighten-2 {
  background-color: #7986cb !important; }

.text-indigo.text-lighten-2 {
  color: #7986cb !important; }
  .text-indigo.text-lighten-2.icon-outline {
    border-color: #7986cb !important; }

.bg-indigo.lighten-1 {
  background-color: #5c6bc0 !important; }

.text-indigo.text-lighten-1 {
  color: #5c6bc0 !important; }
  .text-indigo.text-lighten-1.icon-outline {
    border-color: #5c6bc0 !important; }

.bg-indigo.darken-1 {
  background-color: #3949ab !important; }

.text-indigo.text-darken-1 {
  color: #3949ab !important; }
  .text-indigo.text-darken-1.icon-outline {
    border-color: #3949ab !important; }

.bg-indigo.darken-2 {
  background-color: #303f9f !important; }

.text-indigo.text-darken-2 {
  color: #303f9f !important; }
  .text-indigo.text-darken-2.icon-outline {
    border-color: #303f9f !important; }

.bg-indigo.darken-3 {
  background-color: #283593 !important; }

.text-indigo.text-darken-3 {
  color: #283593 !important; }
  .text-indigo.text-darken-3.icon-outline {
    border-color: #283593 !important; }

.bg-indigo.darken-4 {
  background-color: #1a237e !important; }

.text-indigo.text-darken-4 {
  color: #1a237e !important; }
  .text-indigo.text-darken-4.icon-outline {
    border-color: #1a237e !important; }

.bg-indigo.accent-1 {
  background-color: #8c9eff !important; }

.text-indigo.text-accent-1 {
  color: #8c9eff !important; }
  .text-indigo.text-accent-1.icon-outline {
    border-color: #8c9eff !important; }

.bg-indigo.accent-2 {
  background-color: #536dfe !important; }

.text-indigo.text-accent-2 {
  color: #536dfe !important; }
  .text-indigo.text-accent-2.icon-outline {
    border-color: #536dfe !important; }

.bg-indigo.accent-3 {
  background-color: #3d5afe !important; }

.text-indigo.text-accent-3 {
  color: #3d5afe !important; }
  .text-indigo.text-accent-3.icon-outline {
    border-color: #3d5afe !important; }

.bg-indigo.accent-4 {
  background-color: #304ffe !important; }

.text-indigo.text-accent-4 {
  color: #304ffe !important; }
  .text-indigo.text-accent-4.icon-outline {
    border-color: #304ffe !important; }

.bg-blue {
  background-color: #2196F3 !important; }

.text-blue {
  color: #2196F3 !important; }
  .text-blue.icon-outline {
    border-color: #2196F3 !important; }

.bg-blue.lighten-5 {
  background-color: #E3F2FD !important; }

.text-blue.text-lighten-5 {
  color: #E3F2FD !important; }
  .text-blue.text-lighten-5.icon-outline {
    border-color: #E3F2FD !important; }

.bg-blue.lighten-4 {
  background-color: #BBDEFB !important; }

.text-blue.text-lighten-4 {
  color: #BBDEFB !important; }
  .text-blue.text-lighten-4.icon-outline {
    border-color: #BBDEFB !important; }

.bg-blue.lighten-3 {
  background-color: #90CAF9 !important; }

.text-blue.text-lighten-3 {
  color: #90CAF9 !important; }
  .text-blue.text-lighten-3.icon-outline {
    border-color: #90CAF9 !important; }

.bg-blue.lighten-2 {
  background-color: #64B5F6 !important; }

.text-blue.text-lighten-2 {
  color: #64B5F6 !important; }
  .text-blue.text-lighten-2.icon-outline {
    border-color: #64B5F6 !important; }

.bg-blue.lighten-1 {
  background-color: #42A5F5 !important; }

.text-blue.text-lighten-1 {
  color: #42A5F5 !important; }
  .text-blue.text-lighten-1.icon-outline {
    border-color: #42A5F5 !important; }

.bg-blue.darken-1 {
  background-color: #1E88E5 !important; }

.text-blue.text-darken-1 {
  color: #1E88E5 !important; }
  .text-blue.text-darken-1.icon-outline {
    border-color: #1E88E5 !important; }

.bg-blue.darken-2 {
  background-color: #1976D2 !important; }

.text-blue.text-darken-2 {
  color: #1976D2 !important; }
  .text-blue.text-darken-2.icon-outline {
    border-color: #1976D2 !important; }

.bg-blue.darken-3 {
  background-color: #1565C0 !important; }

.text-blue.text-darken-3 {
  color: #1565C0 !important; }
  .text-blue.text-darken-3.icon-outline {
    border-color: #1565C0 !important; }

.bg-blue.darken-4 {
  background-color: #0D47A1 !important; }

.text-blue.text-darken-4 {
  color: #0D47A1 !important; }
  .text-blue.text-darken-4.icon-outline {
    border-color: #0D47A1 !important; }

.bg-blue.accent-1 {
  background-color: #82B1FF !important; }

.text-blue.text-accent-1 {
  color: #82B1FF !important; }
  .text-blue.text-accent-1.icon-outline {
    border-color: #82B1FF !important; }

.bg-blue.accent-2 {
  background-color: #448AFF !important; }

.text-blue.text-accent-2 {
  color: #448AFF !important; }
  .text-blue.text-accent-2.icon-outline {
    border-color: #448AFF !important; }

.bg-blue.accent-3 {
  background-color: #2979FF !important; }

.text-blue.text-accent-3 {
  color: #2979FF !important; }
  .text-blue.text-accent-3.icon-outline {
    border-color: #2979FF !important; }

.bg-blue.accent-4 {
  background-color: #2962FF !important; }

.text-blue.text-accent-4 {
  color: #2962FF !important; }
  .text-blue.text-accent-4.icon-outline {
    border-color: #2962FF !important; }

.bg-light-blue {
  background-color: #03a9f4 !important; }

.text-light-blue {
  color: #03a9f4 !important; }
  .text-light-blue.icon-outline {
    border-color: #03a9f4 !important; }

.bg-light-blue.lighten-5 {
  background-color: #e1f5fe !important; }

.text-light-blue.text-lighten-5 {
  color: #e1f5fe !important; }
  .text-light-blue.text-lighten-5.icon-outline {
    border-color: #e1f5fe !important; }

.bg-light-blue.lighten-4 {
  background-color: #b3e5fc !important; }

.text-light-blue.text-lighten-4 {
  color: #b3e5fc !important; }
  .text-light-blue.text-lighten-4.icon-outline {
    border-color: #b3e5fc !important; }

.bg-light-blue.lighten-3 {
  background-color: #81d4fa !important; }

.text-light-blue.text-lighten-3 {
  color: #81d4fa !important; }
  .text-light-blue.text-lighten-3.icon-outline {
    border-color: #81d4fa !important; }

.bg-light-blue.lighten-2 {
  background-color: #4fc3f7 !important; }

.text-light-blue.text-lighten-2 {
  color: #4fc3f7 !important; }
  .text-light-blue.text-lighten-2.icon-outline {
    border-color: #4fc3f7 !important; }

.bg-light-blue.lighten-1 {
  background-color: #29b6f6 !important; }

.text-light-blue.text-lighten-1 {
  color: #29b6f6 !important; }
  .text-light-blue.text-lighten-1.icon-outline {
    border-color: #29b6f6 !important; }

.bg-light-blue.darken-1 {
  background-color: #039be5 !important; }

.text-light-blue.text-darken-1 {
  color: #039be5 !important; }
  .text-light-blue.text-darken-1.icon-outline {
    border-color: #039be5 !important; }

.bg-light-blue.darken-2 {
  background-color: #0288d1 !important; }

.text-light-blue.text-darken-2 {
  color: #0288d1 !important; }
  .text-light-blue.text-darken-2.icon-outline {
    border-color: #0288d1 !important; }

.bg-light-blue.darken-3 {
  background-color: #0277bd !important; }

.text-light-blue.text-darken-3 {
  color: #0277bd !important; }
  .text-light-blue.text-darken-3.icon-outline {
    border-color: #0277bd !important; }

.bg-light-blue.darken-4 {
  background-color: #01579b !important; }

.text-light-blue.text-darken-4 {
  color: #01579b !important; }
  .text-light-blue.text-darken-4.icon-outline {
    border-color: #01579b !important; }

.bg-light-blue.accent-1 {
  background-color: #80d8ff !important; }

.text-light-blue.text-accent-1 {
  color: #80d8ff !important; }
  .text-light-blue.text-accent-1.icon-outline {
    border-color: #80d8ff !important; }

.bg-light-blue.accent-2 {
  background-color: #40c4ff !important; }

.text-light-blue.text-accent-2 {
  color: #40c4ff !important; }
  .text-light-blue.text-accent-2.icon-outline {
    border-color: #40c4ff !important; }

.bg-light-blue.accent-3 {
  background-color: #00b0ff !important; }

.text-light-blue.text-accent-3 {
  color: #00b0ff !important; }
  .text-light-blue.text-accent-3.icon-outline {
    border-color: #00b0ff !important; }

.bg-light-blue.accent-4 {
  background-color: #0091ea !important; }

.text-light-blue.text-accent-4 {
  color: #0091ea !important; }
  .text-light-blue.text-accent-4.icon-outline {
    border-color: #0091ea !important; }

.bg-cyan {
  background-color: #00bcd4 !important; }

.text-cyan {
  color: #00bcd4 !important; }
  .text-cyan.icon-outline {
    border-color: #00bcd4 !important; }

.bg-cyan.lighten-5 {
  background-color: #e0f7fa !important; }

.text-cyan.text-lighten-5 {
  color: #e0f7fa !important; }
  .text-cyan.text-lighten-5.icon-outline {
    border-color: #e0f7fa !important; }

.bg-cyan.lighten-4 {
  background-color: #b2ebf2 !important; }

.text-cyan.text-lighten-4 {
  color: #b2ebf2 !important; }
  .text-cyan.text-lighten-4.icon-outline {
    border-color: #b2ebf2 !important; }

.bg-cyan.lighten-3 {
  background-color: #80deea !important; }

.text-cyan.text-lighten-3 {
  color: #80deea !important; }
  .text-cyan.text-lighten-3.icon-outline {
    border-color: #80deea !important; }

.bg-cyan.lighten-2 {
  background-color: #4dd0e1 !important; }

.text-cyan.text-lighten-2 {
  color: #4dd0e1 !important; }
  .text-cyan.text-lighten-2.icon-outline {
    border-color: #4dd0e1 !important; }

.bg-cyan.lighten-1 {
  background-color: #26c6da !important; }

.text-cyan.text-lighten-1 {
  color: #26c6da !important; }
  .text-cyan.text-lighten-1.icon-outline {
    border-color: #26c6da !important; }

.bg-cyan.darken-1 {
  background-color: #00acc1 !important; }

.text-cyan.text-darken-1 {
  color: #00acc1 !important; }
  .text-cyan.text-darken-1.icon-outline {
    border-color: #00acc1 !important; }

.bg-cyan.darken-2 {
  background-color: #0097a7 !important; }

.text-cyan.text-darken-2 {
  color: #0097a7 !important; }
  .text-cyan.text-darken-2.icon-outline {
    border-color: #0097a7 !important; }

.bg-cyan.darken-3 {
  background-color: #00838f !important; }

.text-cyan.text-darken-3 {
  color: #00838f !important; }
  .text-cyan.text-darken-3.icon-outline {
    border-color: #00838f !important; }

.bg-cyan.darken-4 {
  background-color: #006064 !important; }

.text-cyan.text-darken-4 {
  color: #006064 !important; }
  .text-cyan.text-darken-4.icon-outline {
    border-color: #006064 !important; }

.bg-cyan.accent-1 {
  background-color: #84ffff !important; }

.text-cyan.text-accent-1 {
  color: #84ffff !important; }
  .text-cyan.text-accent-1.icon-outline {
    border-color: #84ffff !important; }

.bg-cyan.accent-2 {
  background-color: #18ffff !important; }

.text-cyan.text-accent-2 {
  color: #18ffff !important; }
  .text-cyan.text-accent-2.icon-outline {
    border-color: #18ffff !important; }

.bg-cyan.accent-3 {
  background-color: #00e5ff !important; }

.text-cyan.text-accent-3 {
  color: #00e5ff !important; }
  .text-cyan.text-accent-3.icon-outline {
    border-color: #00e5ff !important; }

.bg-cyan.accent-4 {
  background-color: #00b8d4 !important; }

.text-cyan.text-accent-4 {
  color: #00b8d4 !important; }
  .text-cyan.text-accent-4.icon-outline {
    border-color: #00b8d4 !important; }

.bg-teal {
  background-color: #009688 !important; }

.text-teal {
  color: #009688 !important; }
  .text-teal.icon-outline {
    border-color: #009688 !important; }

.bg-teal.lighten-5 {
  background-color: #e0f2f1 !important; }

.text-teal.text-lighten-5 {
  color: #e0f2f1 !important; }
  .text-teal.text-lighten-5.icon-outline {
    border-color: #e0f2f1 !important; }

.bg-teal.lighten-4 {
  background-color: #b2dfdb !important; }

.text-teal.text-lighten-4 {
  color: #b2dfdb !important; }
  .text-teal.text-lighten-4.icon-outline {
    border-color: #b2dfdb !important; }

.bg-teal.lighten-3 {
  background-color: #80cbc4 !important; }

.text-teal.text-lighten-3 {
  color: #80cbc4 !important; }
  .text-teal.text-lighten-3.icon-outline {
    border-color: #80cbc4 !important; }

.bg-teal.lighten-2 {
  background-color: #4db6ac !important; }

.text-teal.text-lighten-2 {
  color: #4db6ac !important; }
  .text-teal.text-lighten-2.icon-outline {
    border-color: #4db6ac !important; }

.bg-teal.lighten-1 {
  background-color: #26a69a !important; }

.text-teal.text-lighten-1 {
  color: #26a69a !important; }
  .text-teal.text-lighten-1.icon-outline {
    border-color: #26a69a !important; }

.bg-teal.darken-1 {
  background-color: #00897b !important; }

.text-teal.text-darken-1 {
  color: #00897b !important; }
  .text-teal.text-darken-1.icon-outline {
    border-color: #00897b !important; }

.bg-teal.darken-2 {
  background-color: #00796b !important; }

.text-teal.text-darken-2 {
  color: #00796b !important; }
  .text-teal.text-darken-2.icon-outline {
    border-color: #00796b !important; }

.bg-teal.darken-3 {
  background-color: #00695c !important; }

.text-teal.text-darken-3 {
  color: #00695c !important; }
  .text-teal.text-darken-3.icon-outline {
    border-color: #00695c !important; }

.bg-teal.darken-4 {
  background-color: #004d40 !important; }

.text-teal.text-darken-4 {
  color: #004d40 !important; }
  .text-teal.text-darken-4.icon-outline {
    border-color: #004d40 !important; }

.bg-teal.accent-1 {
  background-color: #a7ffeb !important; }

.text-teal.text-accent-1 {
  color: #a7ffeb !important; }
  .text-teal.text-accent-1.icon-outline {
    border-color: #a7ffeb !important; }

.bg-teal.accent-2 {
  background-color: #64ffda !important; }

.text-teal.text-accent-2 {
  color: #64ffda !important; }
  .text-teal.text-accent-2.icon-outline {
    border-color: #64ffda !important; }

.bg-teal.accent-3 {
  background-color: #1de9b6 !important; }

.text-teal.text-accent-3 {
  color: #1de9b6 !important; }
  .text-teal.text-accent-3.icon-outline {
    border-color: #1de9b6 !important; }

.bg-teal.accent-4 {
  background-color: #00bfa5 !important; }

.text-teal.text-accent-4 {
  color: #00bfa5 !important; }
  .text-teal.text-accent-4.icon-outline {
    border-color: #00bfa5 !important; }

.bg-green {
  background-color: #4CAF50 !important; }

.text-green {
  color: #4CAF50 !important; }
  .text-green.icon-outline {
    border-color: #4CAF50 !important; }

.bg-green.lighten-5 {
  background-color: #E8F5E9 !important; }

.text-green.text-lighten-5 {
  color: #E8F5E9 !important; }
  .text-green.text-lighten-5.icon-outline {
    border-color: #E8F5E9 !important; }

.bg-green.lighten-4 {
  background-color: #C8E6C9 !important; }

.text-green.text-lighten-4 {
  color: #C8E6C9 !important; }
  .text-green.text-lighten-4.icon-outline {
    border-color: #C8E6C9 !important; }

.bg-green.lighten-3 {
  background-color: #A5D6A7 !important; }

.text-green.text-lighten-3 {
  color: #A5D6A7 !important; }
  .text-green.text-lighten-3.icon-outline {
    border-color: #A5D6A7 !important; }

.bg-green.lighten-2 {
  background-color: #81C784 !important; }

.text-green.text-lighten-2 {
  color: #81C784 !important; }
  .text-green.text-lighten-2.icon-outline {
    border-color: #81C784 !important; }

.bg-green.lighten-1 {
  background-color: #66BB6A !important; }

.text-green.text-lighten-1 {
  color: #66BB6A !important; }
  .text-green.text-lighten-1.icon-outline {
    border-color: #66BB6A !important; }

.bg-green.darken-1 {
  background-color: #43A047 !important; }

.text-green.text-darken-1 {
  color: #43A047 !important; }
  .text-green.text-darken-1.icon-outline {
    border-color: #43A047 !important; }

.bg-green.darken-2 {
  background-color: #388E3C !important; }

.text-green.text-darken-2 {
  color: #388E3C !important; }
  .text-green.text-darken-2.icon-outline {
    border-color: #388E3C !important; }

.bg-green.darken-3 {
  background-color: #2E7D32 !important; }

.text-green.text-darken-3 {
  color: #2E7D32 !important; }
  .text-green.text-darken-3.icon-outline {
    border-color: #2E7D32 !important; }

.bg-green.darken-4 {
  background-color: #1B5E20 !important; }

.text-green.text-darken-4 {
  color: #1B5E20 !important; }
  .text-green.text-darken-4.icon-outline {
    border-color: #1B5E20 !important; }

.bg-green.accent-1 {
  background-color: #B9F6CA !important; }

.text-green.text-accent-1 {
  color: #B9F6CA !important; }
  .text-green.text-accent-1.icon-outline {
    border-color: #B9F6CA !important; }

.bg-green.accent-2 {
  background-color: #69F0AE !important; }

.text-green.text-accent-2 {
  color: #69F0AE !important; }
  .text-green.text-accent-2.icon-outline {
    border-color: #69F0AE !important; }

.bg-green.accent-3 {
  background-color: #00E676 !important; }

.text-green.text-accent-3 {
  color: #00E676 !important; }
  .text-green.text-accent-3.icon-outline {
    border-color: #00E676 !important; }

.bg-green.accent-4 {
  background-color: #00C853 !important; }

.text-green.text-accent-4 {
  color: #00C853 !important; }
  .text-green.text-accent-4.icon-outline {
    border-color: #00C853 !important; }

.bg-light-green {
  background-color: #8bc34a !important; }

.text-light-green {
  color: #8bc34a !important; }
  .text-light-green.icon-outline {
    border-color: #8bc34a !important; }

.bg-light-green.lighten-5 {
  background-color: #f1f8e9 !important; }

.text-light-green.text-lighten-5 {
  color: #f1f8e9 !important; }
  .text-light-green.text-lighten-5.icon-outline {
    border-color: #f1f8e9 !important; }

.bg-light-green.lighten-4 {
  background-color: #dcedc8 !important; }

.text-light-green.text-lighten-4 {
  color: #dcedc8 !important; }
  .text-light-green.text-lighten-4.icon-outline {
    border-color: #dcedc8 !important; }

.bg-light-green.lighten-3 {
  background-color: #c5e1a5 !important; }

.text-light-green.text-lighten-3 {
  color: #c5e1a5 !important; }
  .text-light-green.text-lighten-3.icon-outline {
    border-color: #c5e1a5 !important; }

.bg-light-green.lighten-2 {
  background-color: #aed581 !important; }

.text-light-green.text-lighten-2 {
  color: #aed581 !important; }
  .text-light-green.text-lighten-2.icon-outline {
    border-color: #aed581 !important; }

.bg-light-green.lighten-1 {
  background-color: #9ccc65 !important; }

.text-light-green.text-lighten-1 {
  color: #9ccc65 !important; }
  .text-light-green.text-lighten-1.icon-outline {
    border-color: #9ccc65 !important; }

.bg-light-green.darken-1 {
  background-color: #7cb342 !important; }

.text-light-green.text-darken-1 {
  color: #7cb342 !important; }
  .text-light-green.text-darken-1.icon-outline {
    border-color: #7cb342 !important; }

.bg-light-green.darken-2 {
  background-color: #689f38 !important; }

.text-light-green.text-darken-2 {
  color: #689f38 !important; }
  .text-light-green.text-darken-2.icon-outline {
    border-color: #689f38 !important; }

.bg-light-green.darken-3 {
  background-color: #558b2f !important; }

.text-light-green.text-darken-3 {
  color: #558b2f !important; }
  .text-light-green.text-darken-3.icon-outline {
    border-color: #558b2f !important; }

.bg-light-green.darken-4 {
  background-color: #33691e !important; }

.text-light-green.text-darken-4 {
  color: #33691e !important; }
  .text-light-green.text-darken-4.icon-outline {
    border-color: #33691e !important; }

.bg-light-green.accent-1 {
  background-color: #ccff90 !important; }

.text-light-green.text-accent-1 {
  color: #ccff90 !important; }
  .text-light-green.text-accent-1.icon-outline {
    border-color: #ccff90 !important; }

.bg-light-green.accent-2 {
  background-color: #b2ff59 !important; }

.text-light-green.text-accent-2 {
  color: #b2ff59 !important; }
  .text-light-green.text-accent-2.icon-outline {
    border-color: #b2ff59 !important; }

.bg-light-green.accent-3 {
  background-color: #76ff03 !important; }

.text-light-green.text-accent-3 {
  color: #76ff03 !important; }
  .text-light-green.text-accent-3.icon-outline {
    border-color: #76ff03 !important; }

.bg-light-green.accent-4 {
  background-color: #64dd17 !important; }

.text-light-green.text-accent-4 {
  color: #64dd17 !important; }
  .text-light-green.text-accent-4.icon-outline {
    border-color: #64dd17 !important; }

.bg-lime {
  background-color: #cddc39 !important; }

.text-lime {
  color: #cddc39 !important; }
  .text-lime.icon-outline {
    border-color: #cddc39 !important; }

.bg-lime.lighten-5 {
  background-color: #f9fbe7 !important; }

.text-lime.text-lighten-5 {
  color: #f9fbe7 !important; }
  .text-lime.text-lighten-5.icon-outline {
    border-color: #f9fbe7 !important; }

.bg-lime.lighten-4 {
  background-color: #f0f4c3 !important; }

.text-lime.text-lighten-4 {
  color: #f0f4c3 !important; }
  .text-lime.text-lighten-4.icon-outline {
    border-color: #f0f4c3 !important; }

.bg-lime.lighten-3 {
  background-color: #e6ee9c !important; }

.text-lime.text-lighten-3 {
  color: #e6ee9c !important; }
  .text-lime.text-lighten-3.icon-outline {
    border-color: #e6ee9c !important; }

.bg-lime.lighten-2 {
  background-color: #dce775 !important; }

.text-lime.text-lighten-2 {
  color: #dce775 !important; }
  .text-lime.text-lighten-2.icon-outline {
    border-color: #dce775 !important; }

.bg-lime.lighten-1 {
  background-color: #d4e157 !important; }

.text-lime.text-lighten-1 {
  color: #d4e157 !important; }
  .text-lime.text-lighten-1.icon-outline {
    border-color: #d4e157 !important; }

.bg-lime.darken-1 {
  background-color: #c0ca33 !important; }

.text-lime.text-darken-1 {
  color: #c0ca33 !important; }
  .text-lime.text-darken-1.icon-outline {
    border-color: #c0ca33 !important; }

.bg-lime.darken-2 {
  background-color: #afb42b !important; }

.text-lime.text-darken-2 {
  color: #afb42b !important; }
  .text-lime.text-darken-2.icon-outline {
    border-color: #afb42b !important; }

.bg-lime.darken-3 {
  background-color: #9e9d24 !important; }

.text-lime.text-darken-3 {
  color: #9e9d24 !important; }
  .text-lime.text-darken-3.icon-outline {
    border-color: #9e9d24 !important; }

.bg-lime.darken-4 {
  background-color: #827717 !important; }

.text-lime.text-darken-4 {
  color: #827717 !important; }
  .text-lime.text-darken-4.icon-outline {
    border-color: #827717 !important; }

.bg-lime.accent-1 {
  background-color: #f4ff81 !important; }

.text-lime.text-accent-1 {
  color: #f4ff81 !important; }
  .text-lime.text-accent-1.icon-outline {
    border-color: #f4ff81 !important; }

.bg-lime.accent-2 {
  background-color: #eeff41 !important; }

.text-lime.text-accent-2 {
  color: #eeff41 !important; }
  .text-lime.text-accent-2.icon-outline {
    border-color: #eeff41 !important; }

.bg-lime.accent-3 {
  background-color: #c6ff00 !important; }

.text-lime.text-accent-3 {
  color: #c6ff00 !important; }
  .text-lime.text-accent-3.icon-outline {
    border-color: #c6ff00 !important; }

.bg-lime.accent-4 {
  background-color: #aeea00 !important; }

.text-lime.text-accent-4 {
  color: #aeea00 !important; }
  .text-lime.text-accent-4.icon-outline {
    border-color: #aeea00 !important; }

.bg-yellow {
  background-color: #ffeb3b !important; }

.text-yellow {
  color: #ffeb3b !important; }
  .text-yellow.icon-outline {
    border-color: #ffeb3b !important; }

.bg-yellow.lighten-5 {
  background-color: #fffde7 !important; }

.text-yellow.text-lighten-5 {
  color: #fffde7 !important; }
  .text-yellow.text-lighten-5.icon-outline {
    border-color: #fffde7 !important; }

.bg-yellow.lighten-4 {
  background-color: #fff9c4 !important; }

.text-yellow.text-lighten-4 {
  color: #fff9c4 !important; }
  .text-yellow.text-lighten-4.icon-outline {
    border-color: #fff9c4 !important; }

.bg-yellow.lighten-3 {
  background-color: #fff59d !important; }

.text-yellow.text-lighten-3 {
  color: #fff59d !important; }
  .text-yellow.text-lighten-3.icon-outline {
    border-color: #fff59d !important; }

.bg-yellow.lighten-2 {
  background-color: #fff176 !important; }

.text-yellow.text-lighten-2 {
  color: #fff176 !important; }
  .text-yellow.text-lighten-2.icon-outline {
    border-color: #fff176 !important; }

.bg-yellow.lighten-1 {
  background-color: #ffee58 !important; }

.text-yellow.text-lighten-1 {
  color: #ffee58 !important; }
  .text-yellow.text-lighten-1.icon-outline {
    border-color: #ffee58 !important; }

.bg-yellow.darken-1 {
  background-color: #fdd835 !important; }

.text-yellow.text-darken-1 {
  color: #fdd835 !important; }
  .text-yellow.text-darken-1.icon-outline {
    border-color: #fdd835 !important; }

.bg-yellow.darken-2 {
  background-color: #fbc02d !important; }

.text-yellow.text-darken-2 {
  color: #fbc02d !important; }
  .text-yellow.text-darken-2.icon-outline {
    border-color: #fbc02d !important; }

.bg-yellow.darken-3 {
  background-color: #f9a825 !important; }

.text-yellow.text-darken-3 {
  color: #f9a825 !important; }
  .text-yellow.text-darken-3.icon-outline {
    border-color: #f9a825 !important; }

.bg-yellow.darken-4 {
  background-color: #f57f17 !important; }

.text-yellow.text-darken-4 {
  color: #f57f17 !important; }
  .text-yellow.text-darken-4.icon-outline {
    border-color: #f57f17 !important; }

.bg-yellow.accent-1 {
  background-color: #ffff8d !important; }

.text-yellow.text-accent-1 {
  color: #ffff8d !important; }
  .text-yellow.text-accent-1.icon-outline {
    border-color: #ffff8d !important; }

.bg-yellow.accent-2 {
  background-color: #ffff00 !important; }

.text-yellow.text-accent-2 {
  color: #ffff00 !important; }
  .text-yellow.text-accent-2.icon-outline {
    border-color: #ffff00 !important; }

.bg-yellow.accent-3 {
  background-color: #ffea00 !important; }

.text-yellow.text-accent-3 {
  color: #ffea00 !important; }
  .text-yellow.text-accent-3.icon-outline {
    border-color: #ffea00 !important; }

.bg-yellow.accent-4 {
  background-color: #ffd600 !important; }

.text-yellow.text-accent-4 {
  color: #ffd600 !important; }
  .text-yellow.text-accent-4.icon-outline {
    border-color: #ffd600 !important; }

.bg-amber {
  background-color: #ffc107 !important; }

.text-amber {
  color: #ffc107 !important; }
  .text-amber.icon-outline {
    border-color: #ffc107 !important; }

.bg-amber.lighten-5 {
  background-color: #fff8e1 !important; }

.text-amber.text-lighten-5 {
  color: #fff8e1 !important; }
  .text-amber.text-lighten-5.icon-outline {
    border-color: #fff8e1 !important; }

.bg-amber.lighten-4 {
  background-color: #ffecb3 !important; }

.text-amber.text-lighten-4 {
  color: #ffecb3 !important; }
  .text-amber.text-lighten-4.icon-outline {
    border-color: #ffecb3 !important; }

.bg-amber.lighten-3 {
  background-color: #ffe082 !important; }

.text-amber.text-lighten-3 {
  color: #ffe082 !important; }
  .text-amber.text-lighten-3.icon-outline {
    border-color: #ffe082 !important; }

.bg-amber.lighten-2 {
  background-color: #ffd54f !important; }

.text-amber.text-lighten-2 {
  color: #ffd54f !important; }
  .text-amber.text-lighten-2.icon-outline {
    border-color: #ffd54f !important; }

.bg-amber.lighten-1 {
  background-color: #ffca28 !important; }

.text-amber.text-lighten-1 {
  color: #ffca28 !important; }
  .text-amber.text-lighten-1.icon-outline {
    border-color: #ffca28 !important; }

.bg-amber.darken-1 {
  background-color: #ffb300 !important; }

.text-amber.text-darken-1 {
  color: #ffb300 !important; }
  .text-amber.text-darken-1.icon-outline {
    border-color: #ffb300 !important; }

.bg-amber.darken-2 {
  background-color: #ffa000 !important; }

.text-amber.text-darken-2 {
  color: #ffa000 !important; }
  .text-amber.text-darken-2.icon-outline {
    border-color: #ffa000 !important; }

.bg-amber.darken-3 {
  background-color: #ff8f00 !important; }

.text-amber.text-darken-3 {
  color: #ff8f00 !important; }
  .text-amber.text-darken-3.icon-outline {
    border-color: #ff8f00 !important; }

.bg-amber.darken-4 {
  background-color: #ff6f00 !important; }

.text-amber.text-darken-4 {
  color: #ff6f00 !important; }
  .text-amber.text-darken-4.icon-outline {
    border-color: #ff6f00 !important; }

.bg-amber.accent-1 {
  background-color: #ffe57f !important; }

.text-amber.text-accent-1 {
  color: #ffe57f !important; }
  .text-amber.text-accent-1.icon-outline {
    border-color: #ffe57f !important; }

.bg-amber.accent-2 {
  background-color: #ffd740 !important; }

.text-amber.text-accent-2 {
  color: #ffd740 !important; }
  .text-amber.text-accent-2.icon-outline {
    border-color: #ffd740 !important; }

.bg-amber.accent-3 {
  background-color: #ffc400 !important; }

.text-amber.text-accent-3 {
  color: #ffc400 !important; }
  .text-amber.text-accent-3.icon-outline {
    border-color: #ffc400 !important; }

.bg-amber.accent-4 {
  background-color: #ffab00 !important; }

.text-amber.text-accent-4 {
  color: #ffab00 !important; }
  .text-amber.text-accent-4.icon-outline {
    border-color: #ffab00 !important; }

.bg-orange {
  background-color: #ff9800 !important; }

.text-orange {
  color: #ff9800 !important; }
  .text-orange.icon-outline {
    border-color: #ff9800 !important; }

.bg-orange.lighten-5 {
  background-color: #fff3e0 !important; }

.text-orange.text-lighten-5 {
  color: #fff3e0 !important; }
  .text-orange.text-lighten-5.icon-outline {
    border-color: #fff3e0 !important; }

.bg-orange.lighten-4 {
  background-color: #ffe0b2 !important; }

.text-orange.text-lighten-4 {
  color: #ffe0b2 !important; }
  .text-orange.text-lighten-4.icon-outline {
    border-color: #ffe0b2 !important; }

.bg-orange.lighten-3 {
  background-color: #ffcc80 !important; }

.text-orange.text-lighten-3 {
  color: #ffcc80 !important; }
  .text-orange.text-lighten-3.icon-outline {
    border-color: #ffcc80 !important; }

.bg-orange.lighten-2 {
  background-color: #ffb74d !important; }

.text-orange.text-lighten-2 {
  color: #ffb74d !important; }
  .text-orange.text-lighten-2.icon-outline {
    border-color: #ffb74d !important; }

.bg-orange.lighten-1 {
  background-color: #ffa726 !important; }

.text-orange.text-lighten-1 {
  color: #ffa726 !important; }
  .text-orange.text-lighten-1.icon-outline {
    border-color: #ffa726 !important; }

.bg-orange.darken-1 {
  background-color: #fb8c00 !important; }

.text-orange.text-darken-1 {
  color: #fb8c00 !important; }
  .text-orange.text-darken-1.icon-outline {
    border-color: #fb8c00 !important; }

.bg-orange.darken-2 {
  background-color: #f57c00 !important; }

.text-orange.text-darken-2 {
  color: #f57c00 !important; }
  .text-orange.text-darken-2.icon-outline {
    border-color: #f57c00 !important; }

.bg-orange.darken-3 {
  background-color: #ef6c00 !important; }

.text-orange.text-darken-3 {
  color: #ef6c00 !important; }
  .text-orange.text-darken-3.icon-outline {
    border-color: #ef6c00 !important; }

.bg-orange.darken-4 {
  background-color: #e65100 !important; }

.text-orange.text-darken-4 {
  color: #e65100 !important; }
  .text-orange.text-darken-4.icon-outline {
    border-color: #e65100 !important; }

.bg-orange.accent-1 {
  background-color: #ffd180 !important; }

.text-orange.text-accent-1 {
  color: #ffd180 !important; }
  .text-orange.text-accent-1.icon-outline {
    border-color: #ffd180 !important; }

.bg-orange.accent-2 {
  background-color: #ffab40 !important; }

.text-orange.text-accent-2 {
  color: #ffab40 !important; }
  .text-orange.text-accent-2.icon-outline {
    border-color: #ffab40 !important; }

.bg-orange.accent-3 {
  background-color: #ff9100 !important; }

.text-orange.text-accent-3 {
  color: #ff9100 !important; }
  .text-orange.text-accent-3.icon-outline {
    border-color: #ff9100 !important; }

.bg-orange.accent-4 {
  background-color: #ff6d00 !important; }

.text-orange.text-accent-4 {
  color: #ff6d00 !important; }
  .text-orange.text-accent-4.icon-outline {
    border-color: #ff6d00 !important; }

.bg-deep-orange {
  background-color: #ff5722 !important; }

.text-deep-orange {
  color: #ff5722 !important; }
  .text-deep-orange.icon-outline {
    border-color: #ff5722 !important; }

.bg-deep-orange.lighten-5 {
  background-color: #fbe9e7 !important; }

.text-deep-orange.text-lighten-5 {
  color: #fbe9e7 !important; }
  .text-deep-orange.text-lighten-5.icon-outline {
    border-color: #fbe9e7 !important; }

.bg-deep-orange.lighten-4 {
  background-color: #ffccbc !important; }

.text-deep-orange.text-lighten-4 {
  color: #ffccbc !important; }
  .text-deep-orange.text-lighten-4.icon-outline {
    border-color: #ffccbc !important; }

.bg-deep-orange.lighten-3 {
  background-color: #ffab91 !important; }

.text-deep-orange.text-lighten-3 {
  color: #ffab91 !important; }
  .text-deep-orange.text-lighten-3.icon-outline {
    border-color: #ffab91 !important; }

.bg-deep-orange.lighten-2 {
  background-color: #ff8a65 !important; }

.text-deep-orange.text-lighten-2 {
  color: #ff8a65 !important; }
  .text-deep-orange.text-lighten-2.icon-outline {
    border-color: #ff8a65 !important; }

.bg-deep-orange.lighten-1 {
  background-color: #ff7043 !important; }

.text-deep-orange.text-lighten-1 {
  color: #ff7043 !important; }
  .text-deep-orange.text-lighten-1.icon-outline {
    border-color: #ff7043 !important; }

.bg-deep-orange.darken-1 {
  background-color: #f4511e !important; }

.text-deep-orange.text-darken-1 {
  color: #f4511e !important; }
  .text-deep-orange.text-darken-1.icon-outline {
    border-color: #f4511e !important; }

.bg-deep-orange.darken-2 {
  background-color: #e64a19 !important; }

.text-deep-orange.text-darken-2 {
  color: #e64a19 !important; }
  .text-deep-orange.text-darken-2.icon-outline {
    border-color: #e64a19 !important; }

.bg-deep-orange.darken-3 {
  background-color: #d84315 !important; }

.text-deep-orange.text-darken-3 {
  color: #d84315 !important; }
  .text-deep-orange.text-darken-3.icon-outline {
    border-color: #d84315 !important; }

.bg-deep-orange.darken-4 {
  background-color: #bf360c !important; }

.text-deep-orange.text-darken-4 {
  color: #bf360c !important; }
  .text-deep-orange.text-darken-4.icon-outline {
    border-color: #bf360c !important; }

.bg-deep-orange.accent-1 {
  background-color: #ff9e80 !important; }

.text-deep-orange.text-accent-1 {
  color: #ff9e80 !important; }
  .text-deep-orange.text-accent-1.icon-outline {
    border-color: #ff9e80 !important; }

.bg-deep-orange.accent-2 {
  background-color: #ff6e40 !important; }

.text-deep-orange.text-accent-2 {
  color: #ff6e40 !important; }
  .text-deep-orange.text-accent-2.icon-outline {
    border-color: #ff6e40 !important; }

.bg-deep-orange.accent-3 {
  background-color: #ff3d00 !important; }

.text-deep-orange.text-accent-3 {
  color: #ff3d00 !important; }
  .text-deep-orange.text-accent-3.icon-outline {
    border-color: #ff3d00 !important; }

.bg-deep-orange.accent-4 {
  background-color: #dd2c00 !important; }

.text-deep-orange.text-accent-4 {
  color: #dd2c00 !important; }
  .text-deep-orange.text-accent-4.icon-outline {
    border-color: #dd2c00 !important; }

.bg-brown {
  background-color: #795548 !important; }

.text-brown {
  color: #795548 !important; }
  .text-brown.icon-outline {
    border-color: #795548 !important; }

.bg-brown.lighten-5 {
  background-color: #efebe9 !important; }

.text-brown.text-lighten-5 {
  color: #efebe9 !important; }
  .text-brown.text-lighten-5.icon-outline {
    border-color: #efebe9 !important; }

.bg-brown.lighten-4 {
  background-color: #d7ccc8 !important; }

.text-brown.text-lighten-4 {
  color: #d7ccc8 !important; }
  .text-brown.text-lighten-4.icon-outline {
    border-color: #d7ccc8 !important; }

.bg-brown.lighten-3 {
  background-color: #bcaaa4 !important; }

.text-brown.text-lighten-3 {
  color: #bcaaa4 !important; }
  .text-brown.text-lighten-3.icon-outline {
    border-color: #bcaaa4 !important; }

.bg-brown.lighten-2 {
  background-color: #a1887f !important; }

.text-brown.text-lighten-2 {
  color: #a1887f !important; }
  .text-brown.text-lighten-2.icon-outline {
    border-color: #a1887f !important; }

.bg-brown.lighten-1 {
  background-color: #8d6e63 !important; }

.text-brown.text-lighten-1 {
  color: #8d6e63 !important; }
  .text-brown.text-lighten-1.icon-outline {
    border-color: #8d6e63 !important; }

.bg-brown.darken-1 {
  background-color: #6d4c41 !important; }

.text-brown.text-darken-1 {
  color: #6d4c41 !important; }
  .text-brown.text-darken-1.icon-outline {
    border-color: #6d4c41 !important; }

.bg-brown.darken-2 {
  background-color: #5d4037 !important; }

.text-brown.text-darken-2 {
  color: #5d4037 !important; }
  .text-brown.text-darken-2.icon-outline {
    border-color: #5d4037 !important; }

.bg-brown.darken-3 {
  background-color: #4e342e !important; }

.text-brown.text-darken-3 {
  color: #4e342e !important; }
  .text-brown.text-darken-3.icon-outline {
    border-color: #4e342e !important; }

.bg-brown.darken-4 {
  background-color: #3e2723 !important; }

.text-brown.text-darken-4 {
  color: #3e2723 !important; }
  .text-brown.text-darken-4.icon-outline {
    border-color: #3e2723 !important; }

.bg-blue-grey {
  background-color: #607d8b !important; }

.text-blue-grey {
  color: #607d8b !important; }
  .text-blue-grey.icon-outline {
    border-color: #607d8b !important; }

.bg-blue-grey.lighten-5 {
  background-color: #eceff1 !important; }

.text-blue-grey.text-lighten-5 {
  color: #eceff1 !important; }
  .text-blue-grey.text-lighten-5.icon-outline {
    border-color: #eceff1 !important; }

.bg-blue-grey.lighten-4 {
  background-color: #cfd8dc !important; }

.text-blue-grey.text-lighten-4 {
  color: #cfd8dc !important; }
  .text-blue-grey.text-lighten-4.icon-outline {
    border-color: #cfd8dc !important; }

.bg-blue-grey.lighten-3 {
  background-color: #b0bec5 !important; }

.text-blue-grey.text-lighten-3 {
  color: #b0bec5 !important; }
  .text-blue-grey.text-lighten-3.icon-outline {
    border-color: #b0bec5 !important; }

.bg-blue-grey.lighten-2 {
  background-color: #90a4ae !important; }

.text-blue-grey.text-lighten-2 {
  color: #90a4ae !important; }
  .text-blue-grey.text-lighten-2.icon-outline {
    border-color: #90a4ae !important; }

.bg-blue-grey.lighten-1 {
  background-color: #78909c !important; }

.text-blue-grey.text-lighten-1 {
  color: #78909c !important; }
  .text-blue-grey.text-lighten-1.icon-outline {
    border-color: #78909c !important; }

.bg-blue-grey.darken-1 {
  background-color: #546e7a !important; }

.text-blue-grey.text-darken-1 {
  color: #546e7a !important; }
  .text-blue-grey.text-darken-1.icon-outline {
    border-color: #546e7a !important; }

.bg-blue-grey.darken-2 {
  background-color: #455a64 !important; }

.text-blue-grey.text-darken-2 {
  color: #455a64 !important; }
  .text-blue-grey.text-darken-2.icon-outline {
    border-color: #455a64 !important; }

.bg-blue-grey.darken-3 {
  background-color: #37474f !important; }

.text-blue-grey.text-darken-3 {
  color: #37474f !important; }
  .text-blue-grey.text-darken-3.icon-outline {
    border-color: #37474f !important; }

.bg-blue-grey.darken-4 {
  background-color: #263238 !important; }

.text-blue-grey.text-darken-4 {
  color: #263238 !important; }
  .text-blue-grey.text-darken-4.icon-outline {
    border-color: #263238 !important; }

.bg-grey {
  background-color: #9e9e9e !important; }

.text-grey {
  color: #9e9e9e !important; }
  .text-grey.icon-outline {
    border-color: #9e9e9e !important; }

.bg-grey.lighten-5 {
  background-color: #fafafa !important; }

.text-grey.text-lighten-5 {
  color: #fafafa !important; }
  .text-grey.text-lighten-5.icon-outline {
    border-color: #fafafa !important; }

.bg-grey.lighten-4 {
  background-color: #f5f5f5 !important; }

.text-grey.text-lighten-4 {
  color: #f5f5f5 !important; }
  .text-grey.text-lighten-4.icon-outline {
    border-color: #f5f5f5 !important; }

.bg-grey.lighten-3 {
  background-color: #eeeeee !important; }

.text-grey.text-lighten-3 {
  color: #eeeeee !important; }
  .text-grey.text-lighten-3.icon-outline {
    border-color: #eeeeee !important; }

.bg-grey.lighten-2 {
  background-color: #e0e0e0 !important; }

.text-grey.text-lighten-2 {
  color: #e0e0e0 !important; }
  .text-grey.text-lighten-2.icon-outline {
    border-color: #e0e0e0 !important; }

.bg-grey.lighten-1 {
  background-color: #bdbdbd !important; }

.text-grey.text-lighten-1 {
  color: #bdbdbd !important; }
  .text-grey.text-lighten-1.icon-outline {
    border-color: #bdbdbd !important; }

.bg-grey.darken-1 {
  background-color: #757575 !important; }

.text-grey.text-darken-1 {
  color: #757575 !important; }
  .text-grey.text-darken-1.icon-outline {
    border-color: #757575 !important; }

.bg-grey.darken-2 {
  background-color: #616161 !important; }

.text-grey.text-darken-2 {
  color: #616161 !important; }
  .text-grey.text-darken-2.icon-outline {
    border-color: #616161 !important; }

.bg-grey.darken-3 {
  background-color: #424242 !important; }

.text-grey.text-darken-3 {
  color: #424242 !important; }
  .text-grey.text-darken-3.icon-outline {
    border-color: #424242 !important; }

.bg-grey.darken-4 {
  background-color: #212121 !important; }

.text-grey.text-darken-4 {
  color: #212121 !important; }
  .text-grey.text-darken-4.icon-outline {
    border-color: #212121 !important; }

.bg-black {
  background-color: #000000 !important; }

.text-black {
  color: #000000 !important; }
  .text-black.icon-outline {
    border-color: #000000 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.text-white {
  color: #FFFFFF !important; }
  .text-white.icon-outline {
    border-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-transparent {
  color: transparent !important; }
  .text-transparent.icon-outline {
    border-color: transparent !important; }

/*Custom Animation*/
@keyframes flipCenter {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: perspective(100000px) rotate3d(1, 0, 0, 90deg); }
  40% {
    animation-timing-function: ease-in;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  60% {
    opacity: 1;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  80% {
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  100% {
    transform: perspective(100000px); } }

@-webkit-keyframes flipCenter {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: perspective(100000px) rotate3d(1, 0, 0, 90deg); }
  40% {
    animation-timing-function: ease-in;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  60% {
    opacity: 1;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  80% {
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  100% {
    transform: perspective(100000px); } }

.flipCenter {
  animation-name: flipCenter;
  backface-visibility: visible; }

@keyframes desployDown {
  0% {
    transform: scaleY(0);
    transform-origin: center top 0; }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center top 0; } }

@-webkit-keyframes desployDown {
  0% {
    transform: scaleY(0);
    transform-origin: center top 0; }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center top 0; } }

.desployDown {
  animation-name: desployDown;
  backface-visibility: visible; }

@keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    transform: translate3d(30%, 0px, 0px); }
  100% {
    opacity: 1;
    transform: none; } }

@-webkit-keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    transform: translate3d(30%, 0px, 0px); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInRightTiny {
  animation-name: fadeInRightTiny; }

@keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0px, 0px); }
  100% {
    opacity: 1;
    transform: none; } }

@-webkit-keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0px, 0px); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInLeftTiny {
  animation-name: fadeInLeftTiny; }

@keyframes slideInRightTiny {
  0% {
    transform: translate3d(30%, 0px, 0px); }
  100% {
    transform: none; } }

@-webkit-keyframes slideInRightTiny {
  0% {
    transform: translate3d(30%, 0px, 0px); }
  100% {
    transform: none; } }

.slideInRightTiny {
  animation-name: slideInRightTiny; }

@keyframes slideInLeftTiny {
  0% {
    transform: translate3d(-30%, 0px, 0px); }
  100% {
    transform: none; } }

@-webkit-keyframes slideInLeftTiny {
  0% {
    transform: translate3d(-30%, 0px, 0px); }
  100% {
    transform: none; } }

.slideInLeftTiny {
  animation-name: slideInLeftTiny; }

@keyframes slideInUpTiny {
  0% {
    transform: translate3d(0px, 5%, 0px); }
  100% {
    transform: none; } }

@-webkit-keyframes slideInUpTiny {
  0% {
    transform: translate3d(0px, 5%, 0px); }
  100% {
    transform: none; } }

.slideInUpTiny {
  animation-name: slideInUpTiny; }

/*Animation Durations*/
.animation-duration-1 {
  animation-duration: 0.1s; }

.animation-duration-2 {
  animation-duration: 0.2s; }

.animation-duration-3 {
  animation-duration: 0.3s; }

.animation-duration-4 {
  animation-duration: 0.4s; }

.animation-duration-5 {
  animation-duration: 0.5s; }

.animation-duration-6 {
  animation-duration: 0.6s; }

.animation-duration-7 {
  animation-duration: 0.7s; }

.animation-duration-8 {
  animation-duration: 0.8s; }

.animation-duration-9 {
  animation-duration: 0.9s; }

.animation-duration-10 {
  animation-duration: 1s; }

.animation-duration-11 {
  animation-duration: 1.1s; }

.animation-duration-12 {
  animation-duration: 1.2s; }

.animation-duration-13 {
  animation-duration: 1.3s; }

.animation-duration-14 {
  animation-duration: 1.4s; }

.animation-duration-15 {
  animation-duration: 1.5s; }

.animation-duration-16 {
  animation-duration: 1.6s; }

.animation-duration-17 {
  animation-duration: 1.7s; }

.animation-duration-18 {
  animation-duration: 1.8s; }

.animation-duration-19 {
  animation-duration: 1.9s; }

.animation-duration-20 {
  animation-duration: 2s; }

.animation-duration-21 {
  animation-duration: 2.1s; }

.animation-duration-22 {
  animation-duration: 2.2s; }

.animation-duration-23 {
  animation-duration: 2.3s; }

.animation-duration-24 {
  animation-duration: 2.4s; }

.animation-duration-25 {
  animation-duration: 2.5s; }

.animation-duration-26 {
  animation-duration: 2.6s; }

.animation-duration-27 {
  animation-duration: 2.7s; }

.animation-duration-28 {
  animation-duration: 2.8s; }

.animation-duration-29 {
  animation-duration: 2.9s; }

.animation-duration-30 {
  animation-duration: 3s; }

.animation-duration-31 {
  animation-duration: 3.1s; }

.animation-duration-32 {
  animation-duration: 3.2s; }

.animation-duration-33 {
  animation-duration: 3.3s; }

.animation-duration-34 {
  animation-duration: 3.4s; }

.animation-duration-35 {
  animation-duration: 3.5s; }

.animation-duration-36 {
  animation-duration: 3.6s; }

.animation-duration-37 {
  animation-duration: 3.7s; }

.animation-duration-38 {
  animation-duration: 3.8s; }

.animation-duration-39 {
  animation-duration: 3.9s; }

.animation-duration-40 {
  animation-duration: 4s; }

.animation-delay-1 {
  animation-delay: 0.1s; }

.animation-delay-2 {
  animation-delay: 0.2s; }

.animation-delay-3 {
  animation-delay: 0.3s; }

.animation-delay-4 {
  animation-delay: 0.4s; }

.animation-delay-5 {
  animation-delay: 0.5s; }

.animation-delay-6 {
  animation-delay: 0.6s; }

.animation-delay-7 {
  animation-delay: 0.7s; }

.animation-delay-8 {
  animation-delay: 0.8s; }

.animation-delay-9 {
  animation-delay: 0.9s; }

.animation-delay-10 {
  animation-delay: 1s; }

.animation-delay-11 {
  animation-delay: 1.1s; }

.animation-delay-12 {
  animation-delay: 1.2s; }

.animation-delay-13 {
  animation-delay: 1.3s; }

.animation-delay-14 {
  animation-delay: 1.4s; }

.animation-delay-15 {
  animation-delay: 1.5s; }

.animation-delay-16 {
  animation-delay: 1.6s; }

.animation-delay-17 {
  animation-delay: 1.7s; }

.animation-delay-18 {
  animation-delay: 1.8s; }

.animation-delay-19 {
  animation-delay: 1.9s; }

.animation-delay-20 {
  animation-delay: 2s; }

.animation-delay-21 {
  animation-delay: 2.1s; }

.animation-delay-22 {
  animation-delay: 2.2s; }

.animation-delay-23 {
  animation-delay: 2.3s; }

.animation-delay-24 {
  animation-delay: 2.4s; }

.animation-delay-25 {
  animation-delay: 2.5s; }

.animation-delay-26 {
  animation-delay: 2.6s; }

.animation-delay-27 {
  animation-delay: 2.6s; }

.animation-delay-28 {
  animation-delay: 2.8s; }

.animation-delay-29 {
  animation-delay: 2.9s; }

.animation-delay-30 {
  animation-delay: 3s; }

.animation-delay-31 {
  animation-delay: 3.1s; }

.animation-delay-32 {
  animation-delay: 3.2s; }

.animation-delay-33 {
  animation-delay: 3.3s; }

.animation-delay-34 {
  animation-delay: 3.4s; }

.animation-delay-35 {
  animation-delay: 3.5s; }

.animation-delay-36 {
  animation-delay: 3.6s; }

.animation-delay-37 {
  animation-delay: 3.7s; }

.animation-delay-38 {
  animation-delay: 3.8s; }

.animation-delay-39 {
  animation-delay: 3.9s; }

.animation-delay-40 {
  animation-delay: 4s; }

.animation-delay-41 {
  animation-delay: 4.1s; }

.animation-delay-42 {
  animation-delay: 4.2s; }

.animation-delay-43 {
  animation-delay: 4.3s; }

.animation-delay-44 {
  animation-delay: 4.4s; }

.animation-delay-45 {
  animation-delay: 4.5s; }

.animation-delay-46 {
  animation-delay: 4.6s; }

.animation-delay-47 {
  animation-delay: 4.7s; }

.animation-delay-48 {
  animation-delay: 4.8s; }

.animation-delay-49 {
  animation-delay: 4.9s; }

.animation-delay-50 {
  animation-delay: 5s; }

.card.card-abierto {
  border-left: 5px solid #ff9800; }

.card.card-cerrado {
  border-left: 5px solid #4caf50; }

/* Base
   ========================================================================== */
/*Base Styles*/
html {
  height: 100%; }

body {
  font-family: "Nunito Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #5c5c5c;
  background-color: #f4f4f4;
  height: 100%;
  overflow: hidden; }

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important; }

#nprogress .bar {
  z-index: 5000 !important;
  background: #E91E63 !important; }

#nprogress .peg {
  -webkit-box-shadow: 0 0 10px #E91E63, 0 0 5px #E91E63 !important;
  -moz-box-shadow: 0 0 10px #E91E63, 0 0 5px #E91E63 !important;
  -o-box-shadow: 0 0 10px #E91E63, 0 0 5px #E91E63 !important;
  box-shadow: 0 0 10px #E91E63, 0 0 5px #E91E63 !important; }

#nprogress .spinner {
  z-index: 5000 !important; }

#nprogress .spinner-icon {
  border-top-color: #E91E63 !important;
  border-left-color: #E91E63 !important; }

.text-transform-none {
  text-transform: none; }

.br-break {
  word-break: break-all !important; }

.font-weight-semibold {
  font-weight: 500 !important; }

.z-index-20 {
  z-index: 20; }

.border-bottom {
  border-bottom: solid 1px #dee2e6; }

.col-with-divider div[class*="col-"] {
  border-left: solid 1px #dee2e6; }
  .col-with-divider div[class*="col-"]:first-child {
    border: 0 none; }

.height-100 {
  height: 100px !important; }

.height-150 {
  height: 150px !important; }

.height-130 {
  height: 130px !important; }

.height-200 {
  height: 200px !important; }

.max-width-100 {
  max-width: 100px !important; }

.max-height-120 {
  max-height: 120px !important; }

.right-arrow {
  display: inline-block;
  color: #3f51b5;
  text-transform: capitalize; }
  .right-arrow:after {
    content: "\f30f";
    font-family: "Material-Design-Iconic-Font";
    color: #3f51b5;
    margin-left: 8px;
    font-size: 20px;
    vertical-align: middle; }
  .right-arrow:hover, .right-arrow:focus {
    text-decoration: none; }

.letter-spacing-base {
  letter-spacing: 3px; }

.letter-spacing-lg {
  letter-spacing: 6px; }

.letter-spacing-xl {
  letter-spacing: 8px; }

.page-heading {
  margin-bottom: 38px; }
  .page-heading .title {
    text-transform: capitalize;
    font-weight: 600; }
  .page-heading .breadcrumb {
    padding: 0;
    background-color: transparent;
    margin-bottom: 0; }
  @media screen and (max-width: 575px) {
    .page-heading {
      margin-bottom: 18px; } }

.sub-heading {
  font-size: 13px;
  color: #adb5bd;
  margin-bottom: 0; }
  .sub-heading:not(:last-child) {
    margin-bottom: 16px !important; }

.manage-margin {
  margin-bottom: 0; }
  .manage-margin > * {
    margin-bottom: 6px; }
    .manage-margin > *:not(:last-child) {
      margin-right: 16px; }

.no-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.shadow-sm {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15); }

.shadow {
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15); }

.shadow-lg {
  -webkit-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.2); }

.shadow-xl {
  -webkit-box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.2); }

.avatar-shadow {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5); }

.remove-table-border td, .remove-table-border th {
  border: 0 none; }

.media-list {
  margin-bottom: 10px; }
  .media-list:not(:last-child) {
    border-bottom: solid 1px #e9ecef;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative; }

.border-2 {
  border: 2px solid #ced4da !important; }

.border-w2 {
  border-width: 2px; }

.border-primary {
  border-color: #3f51b5 !important; }

.border-red {
  border-color: #F44336 !important; }

.border-pink {
  border-color: #e91e63 !important; }

.border-purple {
  border-color: #9C27B0 !important; }

.border-deep-purple {
  border-color: #673ab7 !important; }

.border-indigo {
  border-color: #3f51b5 !important; }

.border-blue {
  border-color: #2196F3 !important; }

.border-light-blue {
  border-color: #03a9f4 !important; }

.border-cyan {
  border-color: #00bcd4 !important; }

.border-teal {
  border-color: #009688 !important; }

.border-green {
  border-color: #4CAF50 !important; }

.border-light-green {
  border-color: #8bc34a !important; }

.border-lime {
  border-color: #cddc39 !important; }

.border-yellow {
  border-color: #ffeb3b !important; }

.border-amber {
  border-color: #ffc107 !important; }

.border-orange {
  border-color: #ff9800 !important; }

.border-deep-orange {
  border-color: #ff5722 !important; }

.border-brown {
  border-color: #795548 !important; }

.border-blue-grey {
  border-color: #607d8b !important; }

.border-grey {
  border-color: #9e9e9e !important; }

.center-crop-img {
  position: relative;
  width: 100%;
  min-height: 120px;
  overflow: hidden; }
  .center-crop-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .center-crop-img img.portrait {
    width: 100%;
    height: auto; }

.pointer {
  cursor: pointer; }

.icon-btn {
  height: 48px;
  display: flex;
  align-items: center;
  width: 48px;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: #6c757d; }
  .header-notifications .icon-btn {
    padding: 0 4px; }

.avatar {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  color: #fafafa; }

.avatar-lg {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: #fafafa; }

.avatar-sm {
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fafafa; }

.animated {
  -webkit-animation-fill-mode: none !important;
  animation-fill-mode: none !important; }

.overflow-hidden {
  overflow: hidden; }

.icons-showcase > div {
  cursor: pointer;
  padding: 7px 15px;
  border-radius: 4px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-decoration {
  text-decoration: none !important; }

.row-5 {
  margin-left: -5px;
  margin-right: -5px; }
  .row-5 > [class*='col-'] {
    padding-left: 5px;
    padding-right: 5px; }

.font-size-16 {
  font-size: 16px !important; }

/*Typography Styles*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  margin: 0 0 12px;
  color: inherit; }

h1, .h1 {
  font-size: 24px; }

h2, .h2 {
  font-size: 18px; }

h3, .h3 {
  font-size: 16px; }

h4, .h4 {
  font-size: 15px; }

h5, .h5 {
  font-size: 13px; }

h6, .h6 {
  font-size: 12px; }

.text-strikethrough {
  text-decoration: line-through !important; }

@media screen and (max-width: 991px) {
  h1, .h1 {
    font-size: 18px; }
  h2, .h2 {
    font-size: 16px; }
  h3, .h3 {
    font-size: 15px; } }

.desc-list {
  margin-bottom: 0; }
  .desc-list li {
    padding: 24px 30px;
    border-bottom: 1px solid #ced4da;
    margin-left: -30px;
    margin-right: -30px; }
    .desc-list li:last-child {
      border-bottom: 0 none; }
    .desc-list li .card-title {
      margin-bottom: 6px; }
  .desc-list.even-odd li {
    border-bottom: 0 none; }
    .desc-list.even-odd li:nth-child(2n) {
      background-color: #dee2e6; }

.padding-0 {
  padding: 0; }

.border-radius-0 {
  border-radius: 0; }

.form-group label.requerido:after {
  content: " *";
  color: red; }

/* Layouts
   ========================================================================== */
/*App Layout Styles*/
#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100%; }

.app-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%; }

.app-sidebar {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 280px;
  min-width: 280px;
  max-width: 280px; }

.app-main-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  position: relative; }

.app-header {
  min-height: 70px;
  z-index: 99;
  position: relative; }

.app-main-content-wrapper {
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto; }

.app-container.fixed-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important; }

.app-container.collapsible-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important; }

.app-container.collapsible-drawer .app-main-container {
  position: relative; }

.app-container.mini-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important; }

.app-container.mini-drawer .app-sidebar {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

/*Sidebar Styles*/
.side-nav {
  width: 280px;
  background-color: #fff !important;
  color: #868e96 !important;
  border-right: solid 1px #e9ecef !important;
  z-index: 1250 !important; }
  .side-nav .user-profile {
    background-color: #f7f7f7;
    padding: 13px 20px !important; }
  .side-nav .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 44px !important;
    width: 44px !important;
    line-height: 44px; }
  .side-nav .user-detail .user-name {
    color: #3f51b5;
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    white-space: nowrap; }

.user-detail .user-name {
  cursor: pointer; }

/*Navbar Styles*/
ul.nav-menu {
  position: relative;
  font-size: 13px;
  list-style: outside none none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; }

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px; }

ul.nav-menu li.menu + .nav-header {
  margin-top: 40px; }

ul.nav-menu li.menu .sub-menu {
  display: none; }

ul.nav-menu li.menu.open > .sub-menu {
  display: block; }

ul.nav-menu li.menu.open .menu.active > .sub-menu {
  display: block; }

ul.nav-menu li a {
  color: #868e96;
  display: block;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 0; }
  ul.nav-menu li a .badge {
    margin-bottom: 0;
    padding: 3px 8px 2px; }

ul.nav-menu .nav-header {
  color: #868e96;
  padding: 4px 22px;
  font-size: 11px;
  text-transform: uppercase; }
  ul.nav-menu .nav-header:first-child {
    margin-top: 30px; }

ul.nav-menu > li.selected > a,
ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
  color: #3f51b5; }

ul.nav-menu li.menu > a {
  padding: 16px 20px;
  border-left: 2px solid transparent; }

ul.nav-menu li.menu.open > a {
  border-color: transparent; }

ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: #3f51b5; }

ul.nav-menu li i {
  margin-right: 12px;
  font-size: 15px; }

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden; }

ul.nav-menu li ul.sub-menu li > a {
  padding: 7px 20px 7px 56px; }

ul.nav-menu li ul.sub-menu > li > ul > li > a {
  padding-left: 76px; }

ul.nav-menu li ul.sub-menu > li > ul > li > ul > li > a {
  padding-left: 96px; }

ul.nav-menu li ul.sub-menu > li > ul > li > ul > li > ul > li > a {
  padding-left: 116px; }

ul.nav-menu li.menu > a:before {
  color: #868e96;
  content: "\f2fb";
  display: inline-block;
  float: right;
  font-family: 'Material-Design-Iconic-Font';
  font-size: 15px;
  margin-left: 10px;
  position: relative;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-weight: normal; }

ul.nav-menu li.menu.no-arrow > a:before {
  display: none; }

ul.nav-menu li.menu.selected > a:before,
ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: #3f51b5; }

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  padding: 3px 7px;
  margin-left: -7px;
  background-color: #3f51b5;
  color: #fff;
  border-radius: 4px;
  display: inline-block; }

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  color: #3f51b5; }

.app-container.mini-drawer .side-nav {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 80px; }
  .app-container.mini-drawer .side-nav .nav-menu li span.nav-text,
  .app-container.mini-drawer .side-nav .customizer {
    display: none; }
  .app-container.mini-drawer .side-nav .user-profile .user-detail {
    display: none !important; }
  .app-container.mini-drawer .side-nav .nav-menu .menu.open > .sub-menu {
    display: none !important; }
  .app-container.mini-drawer .side-nav .nav-menu li {
    text-align: center; }
    .app-container.mini-drawer .side-nav .nav-menu li i {
      margin-right: 0; }
    .app-container.mini-drawer .side-nav .nav-menu li.nav-header {
      padding: 4px 5px; }
  .app-container.mini-drawer .side-nav ul.nav-menu li a {
    padding: 16px 20px; }
  .app-container.mini-drawer .side-nav ul.nav-menu ul.sub-menu li a {
    padding-left: 30px; }
  .app-container.mini-drawer .side-nav ul.nav-menu > li > a:before {
    display: none; }
  .app-container.mini-drawer .side-nav .mini-logo-img-hover {
    display: none; }

.app-container.mini-drawer .side-nav:hover {
  width: 280px; }
  .app-container.mini-drawer .side-nav:hover .nav-menu li span.nav-text {
    display: inline-block !important; }
  .app-container.mini-drawer .side-nav:hover .user-profile .user-detail {
    display: flex !important; }
  .app-container.mini-drawer .side-nav:hover .nav-menu .menu.open > .sub-menu {
    display: block !important; }
  .app-container.mini-drawer .side-nav:hover .customizer {
    display: flex !important; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu li {
    text-align: left; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li i {
      margin-right: 16px; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li a {
      padding: 16px 20px; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li.nav-header {
      padding: 4px 22px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu li a {
    padding: 7px 20px 7px 56px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > a {
    padding-left: 76px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > a {
    padding-left: 96px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > ul > li > a {
    padding-left: 116px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu > li > a:before {
    display: block; }
  .app-container.mini-drawer .side-nav:hover .mini-logo-img {
    display: none; }
  .app-container.mini-drawer .side-nav:hover .mini-logo-img-hover {
    display: block; }

.customizer {
  padding: 20px 35px; }
  .customizer img {
    padding: 5px;
    cursor: pointer; }

.user-info ul li {
  font-size: 15px; }

.rmc-drawer-left .rmc-drawer-sidebar,
.rmc-drawer-right .rmc-drawer-sidebar {
  height: 100vh; }

.rmc-drawer-sidebar {
  position: fixed;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out; }
  .rmc-drawer.rmc-drawer-left .rmc-drawer-sidebar {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    z-index: 903; }
  .rmc-drawer.rmc-drawer-right .rmc-drawer-sidebar {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    z-index: 903; }
  .rmc-drawer.rmc-drawer-top .rmc-drawer-sidebar {
    z-index: 903; }
  .rmc-drawer.rmc-drawer-bottom .rmc-drawer-sidebar {
    z-index: 903; }

.drawer-container {
  position: relative; }
  .drawer-container .main {
    position: relative;
    padding: 10px;
    box-sizing: border-box; }

.rmc-drawer-overlay {
  z-index: 902;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  min-width: 100% !important; }

.app-sidebar.rmc-drawer.rmc-drawer-docked {
  position: relative; }

/*Header Styles*/
.jr-menu-icon {
  outline: none !important;
  -webkit-justify-content: flex-start !important;
  justify-content: flex-start !important;
  padding: 0 5px !important;
  margin-right: 15px;
  display: flex;
  cursor: pointer;
  align-items: center; }

.app-main-header {
  background-color: #fff;
  color: #808080;
  max-height: 70px;
  position: relative; }
  .app-main-header .search-bar input {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: transparent;
    color: #808080; }
    .app-main-header .search-bar input + .search-icon {
      top: 0; }
      .app-main-header .search-bar input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
    .app-main-header .search-bar input:focus {
      background-color: #f4f4f4;
      color: #808080; }
      .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  .app-header .app-main-header {
    border-bottom: 1px solid #dee2e6; }
  .jr-card .app-main-header {
    border: 1px solid #ced4da;
    border-radius: 8px; }

.app-logo {
  margin-right: 12px; }
  .app-logo img {
    height: 35px; }
    @media screen and (max-width: 575px) {
      .app-logo img {
        height: 28px; } }
  .app-header .app-logo {
    margin-right: 20px; }
    @media screen and (max-width: 991px) {
      .app-header .app-logo {
        margin-right: auto; } }

.react-logo-white.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .react-logo-white.d-lg-block {
    display: none !important; } }

.sidebar-header {
  min-height: 70px;
  padding: 10px 22px;
  border-bottom: 1px solid #dee2e6; }

.app-toolbar {
  min-height: 70px;
  padding: 0 40px; }
  .app-toolbar .search-bar {
    margin-right: auto;
    max-width: 320px;
    width: 300px; }
    @media screen and (max-width: 1199px) {
      .app-toolbar .search-bar {
        width: 240px; } }
    @media screen and (max-width: 1099px) {
      .app-toolbar .search-bar {
        width: 200px; } }
    @media screen and (max-width: 767px) {
      .app-toolbar .search-bar {
        margin-left: 40px;
        width: 180px; } }
  @media screen and (max-width: 575px) {
    .app-toolbar {
      padding: 0 20px; } }
  .app-top-nav .app-toolbar {
    min-height: 40px; }

@media screen and (max-width: 1199px) {
  .app-header-horizontal .app-toolbar .search-bar {
    margin-right: 10px; } }

.app-toolbar-special .search-bar {
  width: 200px; }
  @media screen and (max-width: 991px) {
    .app-toolbar-special .search-bar {
      width: 280px; } }
  @media screen and (max-width: 767px) {
    .app-toolbar-special .search-bar {
      width: 200px; } }

.app-toolbar .nav-searchbox .search-bar {
  margin-left: 0;
  width: 100%; }

.app-toolbar .nav-searchbox .search-bar input {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  color: #5c5c5c; }
  .app-toolbar .nav-searchbox .search-bar input + .search-icon i {
    color: rgba(92, 92, 92, 0.8); }
  .app-toolbar .nav-searchbox .search-bar input:focus {
    background-color: transparent;
    color: #5c5c5c; }
    .app-toolbar .nav-searchbox .search-bar input:focus + .search-icon i {
      color: rgba(92, 92, 92, 0.8); }

.header-notifications {
  margin-bottom: 0; }
  .header-notifications li {
    vertical-align: middle; }
  @media screen and (max-width: 449px) {
    .header-notifications .list-inline-item:not(:last-child) {
      margin-right: 0; } }

.dropdown-menu {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none; }

.quick-menu .dropdown-menu {
  padding: 5px 10px;
  width: 350px; }
  .quick-menu .dropdown-menu .jr-card-header {
    margin: -5px -10px 5px;
    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid #dee2e6;
    z-index: 10; }
  .quick-menu .dropdown-menu:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    top: -9px;
    content: "";
    height: 9px;
    position: absolute;
    right: 8px;
    width: 10px; }
  .quick-menu .dropdown-menu:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
    top: -10px;
    content: "";
    height: 10px;
    position: absolute;
    right: 8px;
    width: 10px; }
  @media screen and (max-width: 449px) {
    .quick-menu .dropdown-menu {
      width: 230px; } }
  @media screen and (max-width: 359px) {
    .quick-menu .dropdown-menu {
      width: 180px; } }

.quick-menu .messages-list .user-avatar {
  position: relative; }

.quick-menu .messages-list .badge {
  position: absolute;
  left: -4px;
  top: -4px;
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  padding: 0; }

.app-header .nav-searchbox.quick-menu .dropdown-menu {
  width: 200px;
  left: 0 !important;
  right: auto !important; }

.app-header .nav-searchbox.quick-menu .dropdown-menu:after {
  right: auto;
  left: 8px; }

.app-header .nav-searchbox.quick-menu .dropdown-menu:before {
  right: auto;
  left: 8px; }

@media screen and (max-width: 400px) {
  .app-header .nav-searchbox.quick-menu {
    display: none; } }

@media screen and (max-width: 575px) {
  .app-header .nav-searchbox.quick-menu .dropdown-menu {
    width: 200px; } }

@media screen and (max-width: 399px) {
  .app-header .nav-searchbox.quick-menu .dropdown-menu {
    width: 100px; } }

.language-list li {
  display: block !important; }

.messages-list.language-list ul li {
  padding: 7px 6px 6px; }

.messages-list ul li {
  position: relative;
  padding: 20px 10px 14px;
  border-bottom: 1px solid #dee2e6; }
  .messages-list ul li .user-name a {
    color: #3f51b5; }
  .messages-list ul li .sub-heading {
    font-size: 11px;
    margin-bottom: 6px !important; }
    .messages-list ul li .sub-heading.mb-0 {
      margin-bottom: 0 !important; }
  .messages-list ul li:last-child {
    border-bottom: 0 none; }
  .messages-list ul li .jr-btn {
    margin-right: 0 !important; }
  .messages-list ul li .jr-btn-xs {
    font-size: 11px !important;
    padding: 5px !important; }

.quick-menu .icon-btn {
  color: #808080; }

.quick-menu .icon-alert {
  position: relative; }
  .quick-menu .icon-alert:after {
    display: block;
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    z-index: 10;
    width: 8px;
    height: 8px;
    background-color: #f44336;
    border-radius: 50%; }

.quick-menu i.animated.infinite {
  -moz-animation-iteration-count: 25;
  -webkit-animation-iteration-count: 25;
  -o-animation-iteration-count: 25;
  animation-iteration-count: 25; }

.quick-menu .dropdown-menu {
  display: block;
  visibility: hidden;
  left: auto !important;
  right: 0 !important;
  opacity: 0;
  transform: translate3d(0px, 100px, 0px) !important;
  -webkit-transition: all 200ms linear 0ms;
  -o-transition: all 200ms linear 0ms;
  transition: all 200ms linear 0ms; }

.quick-menu.show .dropdown-menu {
  opacity: 1;
  transform: translate3d(0px, 30px, 0px) !important;
  visibility: visible; }

.user-nav .quick-menu .dropdown-menu {
  width: 200px;
  padding: 0 0 15px; }
  .user-nav .quick-menu .dropdown-menu:after {
    border-bottom-color: #f4f4f4; }

.user-nav .quick-menu .user-profile {
  position: relative;
  padding: 20px 25px;
  background-color: #f4f4f4;
  margin-bottom: 15px;
  border-radius: 0.25rem 0.25rem 0 0; }

.module-box-header-right {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center; }

.app-top-header {
  position: relative;
  z-index: 9999; }

/*Navbar Styles*/
.app-main-menu .navbar-nav {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.navbar-nav {
  position: relative; }
  .navbar-nav li {
    position: relative; }
    .navbar-nav li:hover > ul.sub-menu, .navbar-nav li:focus > ul.sub-menu {
      opacity: 1;
      visibility: visible;
      top: 100%; }
    .navbar-nav li:hover > a, .navbar-nav li:focus > a {
      color: #3f51b5; }
    .navbar-nav li a {
      display: block;
      color: #868e96;
      padding: 10px 0;
      font-size: 14px; }
    .navbar-nav li a:hover,
    .navbar-nav li a:focus {
      text-decoration: none;
      color: #3f51b5; }
    .navbar-nav li.mega-menu {
      position: static; }
    .navbar-nav li.mega-menu ul.sub-menu {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 85%;
      margin: 0 auto;
      left: 0;
      right: 0; }
      .navbar-nav li.mega-menu ul.sub-menu li {
        width: 25%;
        padding: 0 15px; }
  .navbar-nav > li:last-child ul.sub-menu {
    left: auto;
    right: 0; }
  .navbar-nav > li:last-child ul.sub-menu ul.sub-menu {
    left: auto;
    right: 100%;
    top: 50px; }
  .navbar-nav > li:last-child ul.sub-menu li:hover > ul.sub-menu,
  .navbar-nav > li:last-child ul.sub-menu li:focus > ul.sub-menu {
    top: 0; }
  .navbar-nav li.nav-item {
    padding: 0 10px; }
    .navbar-nav li.nav-item > a {
      padding: 23px 0; }
    .navbar-nav li.nav-item.active > a {
      color: #3f51b5; }
  .navbar-nav li.nav-item:hover > a,
  .navbar-nav li.nav-item:focus > a,
  .navbar-nav li.nav-item > a:hover,
  .navbar-nav li.nav-item > a:focus {
    color: #3f51b5; }
  .navbar-nav ul.sub-menu {
    list-style: none;
    border: solid 1px #ced4da;
    position: absolute;
    left: 0;
    top: 100px;
    opacity: 0;
    padding: 10px 0;
    background-color: #fff;
    visibility: hidden;
    border-radius: 4px;
    -webkit-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    .navbar-nav ul.sub-menu li a {
      padding: 5px 15px;
      width: 200px;
      font-size: 14px; }
      .navbar-nav ul.sub-menu li a.active {
        color: #3f51b5; }
    .navbar-nav ul.sub-menu li > ul.sub-menu {
      left: 100%;
      right: auto;
      top: 50px; }
    .navbar-nav ul.sub-menu li:hover > ul.sub-menu,
    .navbar-nav ul.sub-menu li:focus > ul.sub-menu {
      top: 0; }
    .navbar-nav ul.sub-menu li a i {
      margin-right: 10px; }
    .navbar-nav ul.sub-menu li.active > a {
      color: #3f51b5; }
  .navbar-nav .nav-arrow > a {
    position: relative; }
    .navbar-nav .nav-arrow > a:before {
      color: #868e96;
      content: "\f2fb";
      display: inline-block;
      float: right;
      font-family: 'Material-Design-Iconic-Font';
      font-size: 15px;
      margin-left: 10px;
      position: relative;
      -webkit-transition: all 300ms ease-in-out;
      -o-transition: all 300ms ease-in-out;
      transition: all 300ms ease-in-out;
      font-weight: normal; }
  .navbar-nav .nav-arrow a:hover:before,
  .navbar-nav .nav-arrow a:focus:before {
    color: #3f51b5; }
  .navbar-nav .nav-arrow:hover > a:before, .navbar-nav .nav-arrow:focus > a:before, .navbar-nav .nav-arrow.active > a:before {
    color: #3f51b5; }
  .navbar-nav ul.sub-menu-half {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 300px; }
    .navbar-nav ul.sub-menu-half li {
      width: 50%; }
      .navbar-nav ul.sub-menu-half li a {
        width: 100%; }
        @media screen and (max-width: 1099px) {
          .navbar-nav ul.sub-menu-half li a {
            font-size: 13px; } }
    @media screen and (max-width: 1099px) {
      .navbar-nav ul.sub-menu-half {
        width: 262px; } }

.navbar-nav-mega {
  position: static !important; }

.app-top-nav {
  background-color: #3f51b5; }
  .app-top-nav .navbar-nav li.nav-item {
    padding: 0 30px; }
  .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff;
    padding: 13px 0; }
  .app-top-nav .navbar-nav li.nav-item:hover > a,
  .app-top-nav .navbar-nav li.nav-item:focus > a,
  .app-top-nav .navbar-nav li.nav-item > a:hover,
  .app-top-nav .navbar-nav li.nav-item > a:focus,
  .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #E91E63; }
  .app-top-nav .navbar-nav ul.sub-menu {
    margin-top: 0; }

/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%; }

.app-wrapper {
  padding: 36px 40px 0 40px;
  width: 100%; }
  @media screen and (max-width: 575px) {
    .app-wrapper {
      padding: 20px; } }

/*Footer Styles*/
.app-footer {
  padding: 0 40px;
  border-top: solid 1px #dbdbdb;
  background-color: #f4f4f4;
  color: #868e96;
  line-height: 50px;
  min-height: 50px; }
  .app-footer a {
    color: #868e96; }
    .app-footer a:hover {
      color: #3f51b5; }
  @media screen and (max-width: 575px) {
    .app-footer {
      padding: 20px; } }
  @media screen and (max-width: 400px) {
    .app-footer {
      font-size: 12px; } }

.app-wrapper {
  padding-top: 10px; }

/* UI Components
   ========================================================================== */
/*Alert Styles*/
.alert {
  border: 0 none;
  padding: 20px 24px;
  border-radius: 8px;
  position: relative; }
  .alert .close {
    line-height: inherit;
    font-weight: 300; }
  .alert .alert-addon {
    width: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1; }

.alert-dismissible {
  padding-right: 60px; }

.alert-addon-card {
  padding-left: 80px; }

/*Avatar Styles*/
.user-avatar {
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  position: relative;
  overflow: hidden;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  border-radius: 50%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  border: 1px solid #ced4da; }
  .user-avatar.avatar-shadow {
    border: 0 none; }

.user-profile {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start; }
  .user-profile .user-detail {
    -webkit-flex: 1;
    flex: 1;
    -ms-flex-align: center;
    -webkit-align-self: center;
    align-self: center; }

.user-detail .user-name {
  margin-bottom: 2px;
  font-weight: 400;
  text-transform: capitalize; }

.user-detail .user-description {
  font-size: 12px;
  margin-bottom: 0;
  color: #adb5bd; }

.user-thumb {
  position: relative; }
  .messages-list .user-thumb {
    margin-right: 8px; }

/*Badges Styles*/
.badge {
  font-weight: 400;
  padding: 3px 8px;
  line-height: inherit;
  margin-bottom: 6px;
  border-radius: 2px; }
  .badge:not(:last-child) {
    margin-right: 15px; }
  table .badge {
    margin-bottom: 0; }

.badge-pill {
  border-radius: 20px; }

.badge-border {
  border: 2px solid !important; }

.badge-outline {
  background-color: transparent;
  border: 1px solid #ced4da; }

/*Blockquotes Styles*/
blockquote {
  padding: 10px 0 10px 20px;
  font-size: 13px !important; }
  blockquote .blockquote-footer {
    font-size: 13px !important; }

.blockquote {
  border-left: 5px solid #e9ecef; }

.blockquote-reverse {
  border-left: 0 none;
  border-right: 5px solid #e9ecef;
  text-align: right;
  padding-left: 0;
  padding-right: 20px; }

/*Bottom Navigation Styles*/
.bottom-navigation button {
  min-width: 10px !important; }

/*Breadcrumb Styles*/
.breadcrumb {
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item::before {
  content: '\F2FB';
  font-family: 'Material-Design-Iconic-Font';
  font-size: 16px;
  line-height: 1;
  vertical-align: middle; }

/*Button Styles*/
button {
  outline: 0 none !important;
  cursor: pointer;
  border: 1px solid transparent; }

.jr-btn {
  position: relative;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.2;
  border-radius: 4px;
  vertical-align: top;
  cursor: pointer;
  text-align: center; }
  .jr-btn:hover, .jr-btn:focus, .jr-btn:active {
    outline: 0 none;
    text-decoration: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important; }
  .login-content .jr-btn {
    padding: 10px 35px; }

.btn:hover, .btn:focus, .btn:active {
  outline: 0 none;
  text-decoration: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.login-content .btn {
  padding: 10px 35px; }

.btn,
.btn-info,
.btn-success,
.btn-warning,
.btn-danger,
.btn-light,
.btn-dark,
.btn-primary,
.btn-secondary {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.show > .btn.dropdown-toggle:focus,
.show > .btn-info.dropdown-toggle:focus,
.show > .btn-success.dropdown-toggle:focus,
.show > .btn-warning.dropdown-toggle:focus,
.show > .btn-danger.dropdown-toggle:focus,
.show > .btn-light.dropdown-toggle:focus,
.show > .btn-dark.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.jr-btn,
.jr-fab-btn,
.jr-flat-btn,
.btn {
  margin-bottom: 6px;
  text-transform: capitalize; }
  .jr-btn:not(:last-child),
  .jr-fab-btn:not(:last-child),
  .jr-flat-btn:not(:last-child),
  .btn:not(:last-child) {
    margin-right: 15px; }
  .form-inline .jr-btn, .form-inline
  .jr-fab-btn, .form-inline
  .jr-flat-btn, .form-inline
  .btn {
    margin-bottom: 0;
    min-height: 35px; }
  .navbar .jr-btn, .navbar
  .jr-fab-btn, .navbar
  .jr-flat-btn, .navbar
  .btn {
    margin-bottom: 0; }

.btn-primary:focus, .btn-primary.focus {
  box-shadow: none !important; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-append > .btn {
  margin-right: 0;
  margin-bottom: 0;
  line-height: inherit; }

.jr-fab-btn {
  position: relative;
  display: inline-flex;
  border-radius: 50%;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #eeeeee;
  overflow: hidden; }
  .jr-fab-btn:hover, .jr-fab-btn:focus {
    outline: 0 none;
    text-decoration: none; }

.jr-btn,
.jr-flat-btn {
  padding: 12px 20px; }
  .jr-btn i + span,
  .jr-btn span + i,
  .jr-flat-btn i + span,
  .jr-flat-btn span + i {
    margin-left: 6px; }

.jr-flat-btn {
  background-color: transparent;
  color: #5c5c5c; }
  .jr-flat-btn:hover, .jr-flat-btn:focus, .jr-flat-btn:active {
    background-color: #f5f5f5;
    color: #5c5c5c; }

.jr-btn-group {
  margin: 0 -8px; }
  .jr-btn-group:not(:last-child) {
    margin-bottom: 16px; }
  .jr-btn-group .jr-btn,
  .jr-btn-group .jr-fab-btn {
    margin: 0 8px 18px; }

.jr-btn-rounded {
  border-radius: 50px !important; }

.jr-btn-lg, .btn-group-lg > .jr-btn {
  padding: 16px 24px; }

.jr-btn-sm, .btn-group-sm > .jr-btn {
  padding: 8px 15px; }

.jr-btn-xs {
  padding: 6px 10px; }

.jr-btn-fab-lg, .jr-btn-lg, .btn-group-lg > .jr-btn {
  font-size: 17px; }
  .jr-btn-fab-lg i,
  .jr-btn-lg i,
  .btn-group-lg > .jr-btn i {
    font-size: 19px; }

.jr-btn-fab-sm, .jr-btn-sm, .btn-group-sm > .jr-btn {
  font-size: 11px; }
  .jr-btn-fab-sm i,
  .jr-btn-sm i,
  .btn-group-sm > .jr-btn i {
    font-size: 11px; }

.jr-btn-fab-xs,
.jr-btn-xs {
  font-size: 10px; }
  .jr-btn-fab-xs i,
  .jr-btn-xs i {
    font-size: 10px; }

.complex-btn-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: 200px;
  width: 100%; }

.complex-btn {
  position: relative;
  height: 200px;
  overflow: hidden;
  width: 33.333%;
  border: 0 none; }
  @media screen and (max-width: 767px) {
    .complex-btn {
      width: 100%;
      height: 100px; } }
  .complex-btn:hover {
    z-index: 1; }
    .complex-btn:hover .img-btn-overlay {
      opacity: 0.15; }
    .complex-btn:hover .img-marked {
      opacity: 0; }
    .complex-btn:hover .img-title {
      border: 4px solid currentColor; }
  .complex-btn .img-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    color: #fff; }
  .complex-btn .img-src {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center 40%; }
  .complex-btn .img-btn-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.4;
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); }
  .complex-btn .img-title {
    position: relative;
    padding: 16px 32px 14px; }
  .complex-btn .img-marked {
    height: 3px;
    width: 18px;
    background: #fff;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 9px);
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); }

.btn-group-mins {
  margin: 0 -5px; }
  .btn-group-mins .btn,
  .btn-group-mins .jr-btn {
    margin: 0 5px 5px; }

.jr-btn.jr-btn-label i {
  background-color: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 100%;
  line-height: 42px;
  text-align: center; }

.jr-btn.jr-btn-label.left i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1; }

.jr-btn.jr-btn-label.right i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

.jr-btn.jr-btn-label.left i + span {
  margin-left: 30px; }

.jr-btn.jr-btn-label.right i + span {
  margin-right: 30px;
  margin-left: 0; }

.jr-btn.jr-btn-label.jr-btn-lg i, .btn-group-lg > .jr-btn.jr-btn-label i {
  line-height: 54px; }

.jr-btn.jr-btn-label.jr-btn-sm i, .btn-group-sm > .jr-btn.jr-btn-label i {
  line-height: 32px; }

.jr-btn.jr-btn-label.jr-btn-xs i {
  line-height: 24px;
  width: 30px; }

.btn-primary {
  background-color: #3f51b5;
  color: #fff; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #3849a2;
    color: #fff; }

.btn-red {
  background-color: #F44336;
  color: #fff; }
  .btn-red:hover, .btn-red:focus {
    background-color: #f32c1e;
    color: #fff; }

.btn-pink {
  background-color: #e91e63;
  color: #fff; }
  .btn-pink:hover, .btn-pink:focus {
    background-color: #d81558;
    color: #fff; }

.btn-purple {
  background-color: #9C27B0;
  color: #fff; }
  .btn-purple:hover, .btn-purple:focus {
    background-color: #89229b;
    color: #fff; }

.btn-deep-purple {
  background-color: #673ab7;
  color: #fff; }
  .btn-deep-purple:hover, .btn-deep-purple:focus {
    background-color: #5c34a4;
    color: #fff; }

.btn-indigo {
  background-color: #3f51b5;
  color: #fff; }
  .btn-indigo:hover, .btn-indigo:focus {
    background-color: #3849a2;
    color: #fff; }

.btn-blue {
  background-color: #2196F3;
  color: #fff; }
  .btn-blue:hover, .btn-blue:focus {
    background-color: #0d8aee;
    color: #fff; }

.btn-light-blue {
  background-color: #03a9f4;
  color: #fff; }
  .btn-light-blue:hover, .btn-light-blue:focus {
    background-color: #0398db;
    color: #fff; }

.btn-cyan {
  background-color: #00bcd4;
  color: #fff; }
  .btn-cyan:hover, .btn-cyan:focus {
    background-color: #00a5bb;
    color: #fff; }

.btn-teal {
  background-color: #009688;
  color: #fff; }
  .btn-teal:hover, .btn-teal:focus {
    background-color: #007d71;
    color: #fff; }

.btn-green {
  background-color: #4CAF50;
  color: #fff; }
  .btn-green:hover, .btn-green:focus {
    background-color: #449d48;
    color: #fff; }

.btn-light-green {
  background-color: #8bc34a;
  color: #fff; }
  .btn-light-green:hover, .btn-light-green:focus {
    background-color: #7eb73d;
    color: #fff; }

.btn-lime {
  background-color: #cddc39;
  color: #fff; }
  .btn-lime:hover, .btn-lime:focus {
    background-color: #c6d626;
    color: #fff; }

.btn-yellow {
  background-color: #ffeb3b;
  color: #fff; }
  .btn-yellow:hover, .btn-yellow:focus {
    background-color: #ffe822;
    color: #fff; }

.btn-amber {
  background-color: #ffc107;
  color: #fff; }
  .btn-amber:hover, .btn-amber:focus {
    background-color: #edb100;
    color: #fff; }

.btn-orange {
  background-color: #ff9800;
  color: #fff; }
  .btn-orange:hover, .btn-orange:focus {
    background-color: #e68900;
    color: #fff; }

.btn-deep-orange {
  background-color: #ff5722;
  color: #fff; }
  .btn-deep-orange:hover, .btn-deep-orange:focus {
    background-color: #ff4409;
    color: #fff; }

.btn-brown {
  background-color: #795548;
  color: #fff; }
  .btn-brown:hover, .btn-brown:focus {
    background-color: #694a3e;
    color: #fff; }

.btn-blue-grey {
  background-color: #607d8b;
  color: #fff; }
  .btn-blue-grey:hover, .btn-blue-grey:focus {
    background-color: #566f7c;
    color: #fff; }

.btn-grey {
  background-color: #9e9e9e;
  color: #fff; }
  .btn-grey:hover, .btn-grey:focus {
    background-color: #919191;
    color: #fff; }

.jr-btn-shadow {
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  -o-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important; }
  .jr-btn-shadow:hover, .jr-btn-shadow:focus {
    -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    -moz-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    -o-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12) !important; }
  .jr-btn-shadow:active {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important; }

.jr-btn.disabled,
.jr-btn.disabled {
  pointer-events: none;
  opacity: 0.65; }

.jr-btn-default {
  background-color: #fff;
  color: #5c5c5c;
  border: 1px solid #dee2e6; }
  .jr-btn-default:hover, .jr-btn-default:focus {
    background-color: #f2f2f2;
    color: #5c5c5c; }

.jr-btn-secondary {
  background-color: #E91E63;
  color: #fff; }
  .jr-btn-secondary:hover, .jr-btn-secondary:focus {
    background-color: #d81558;
    color: #fff; }

.jr-flat-btn.jr-btn-primary {
  background-color: transparent;
  color: #3f51b5; }
  .jr-flat-btn.jr-btn-primary:hover, .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(63, 81, 181, 0.12);
    color: #3f51b5; }

.bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent;
  color: #fff; }
  .bg-primary .jr-flat-btn.jr-btn-primary:hover, .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff; }

.jr-flat-btn.jr-btn-secondary {
  background-color: transparent;
  color: #E91E63; }
  .jr-flat-btn.jr-btn-secondary:hover, .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(233, 30, 99, 0.12);
    color: #E91E63; }

.bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent;
  color: #fff; }
  .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12);
    color: #fff; }

.jr-btn-hover:hover:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.06); }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }

.action-btn {
  height: 40px;
  width: 40px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.54); }
  .action-btn:hover, .action-btn:focus, .action-btn:active {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.64); }
  .circle-check .action-btn {
    height: 24px;
    width: 24px;
    font-size: 16px; }
  .chat-main-header .action-btn {
    color: #fff; }

.action-replay .jr-btn-default {
  font-size: 14px;
  padding: 5px 10px;
  border-color: #6c757d !important; }

.btn-outline-primary:focus,
.btn-outline-secondary:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

/*Button Group Styles*/
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .jr-btn,
  .btn-group-vertical > .jr-btn {
    position: relative;
    flex: 0 1 auto;
    border-color: #dee2e6; }
    .btn-group > .jr-btn:hover,
    .btn-group-vertical > .jr-btn:hover {
      z-index: 2; }
    .btn-group > .jr-btn:focus, .btn-group > .jr-btn:active, .btn-group > .jr-btn.active,
    .btn-group-vertical > .jr-btn:focus,
    .btn-group-vertical > .jr-btn:active,
    .btn-group-vertical > .jr-btn.active {
      z-index: 2; }
    .btn-group > .jr-btn.active,
    .btn-group-vertical > .jr-btn.active {
      background-color: #3f51b5;
      color: #fff;
      border-color: #3f51b5; }
    .btn-group > .jr-btn.jr-flat-btn,
    .btn-group-vertical > .jr-btn.jr-flat-btn {
      border-color: transparent; }
      .btn-group > .jr-btn.jr-flat-btn.active,
      .btn-group-vertical > .jr-btn.jr-flat-btn.active {
        background-color: transparent;
        color: #3f51b5; }
        .btn-group > .jr-btn.jr-flat-btn.active:hover, .btn-group > .jr-btn.jr-flat-btn.active:focus,
        .btn-group-vertical > .jr-btn.jr-flat-btn.active:hover,
        .btn-group-vertical > .jr-btn.jr-flat-btn.active:focus {
          background-color: rgba(63, 81, 181, 0.12);
          color: #3f51b5; }
  .btn-group .jr-btn + .jr-btn,
  .btn-group .jr-btn + .btn-group,
  .btn-group .btn-group + .jr-btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .jr-btn + .jr-btn,
  .btn-group-vertical .jr-btn + .btn-group,
  .btn-group-vertical .btn-group + .jr-btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-group.no-border > .btn-group > .jr-btn,
.btn-group.no-border > .btn-group-vertical > .jr-btn,
.btn-group.no-border > .jr-btn,
.btn-group-vertical.no-border > .btn-group > .jr-btn,
.btn-group-vertical.no-border > .btn-group-vertical > .jr-btn,
.btn-group-vertical.no-border > .jr-btn {
  border-color: transparent; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .jr-btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0; }

.btn-group > .jr-btn:first-child {
  margin-left: 0; }
  .btn-group > .jr-btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.btn-group > .jr-btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
  border-radius: 0; }

.btn-group > .btn-group:first-child:not(:last-child) > .jr-btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.jr-btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .jr-btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.jr-btn-sm + .dropdown-toggle-split, .btn-group-sm > .jr-btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.jr-btn-lg + .dropdown-toggle-split, .btn-group-lg > .jr-btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .jr-btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .jr-btn + .jr-btn,
  .btn-group-vertical > .jr-btn + .btn-group,
  .btn-group-vertical > .btn-group + .jr-btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .jr-btn:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .btn-group-vertical > .jr-btn:first-child:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .jr-btn:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
    border-radius: 0; }
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .jr-btn:last-child,
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

[data-toggle="buttons"] > .jr-btn input[type="radio"],
[data-toggle="buttons"] > .jr-btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .jr-btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .jr-btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0); }

.btn-group > .jr-btn,
.btn-group > .btn,
.btn-group-vertical > .jr-btn,
.btn-group-vertical > .btn {
  margin-right: 0;
  margin-bottom: 0; }

/*Cards Styles*/
.profile-intro {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden; }
  .profile-intro img.avatar-circle {
    display: inline-block;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    margin: 0 auto;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 1; }
  .profile-intro:hover .avatar-circle {
    -webkit-transition: border-radius 0.4s ease 0.1s;
    -o-transition: border-radius 0.4s ease 0.1s;
    transition: border-radius 0.4s ease 0.1s;
    border-radius: 25%; }

.profile-intro .card-image {
  position: relative;
  width: 100%;
  min-height: 100%;
  background-image: url("http://via.placeholder.com/1920x1072");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.profile-intro .pi-content {
  padding: 70px 40px 24px; }

.profile-intro .pi-footer {
  padding: 0 40px 24px; }

.profile-intro .icon {
  display: inline-block;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  color: #3f51b5; }
  .profile-intro .icon:hover, .profile-intro .icon:focus, .profile-intro .icon:active {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }

.profile-intro .icon:not(:last-child) {
  margin-right: 10px; }

.layer:before {
  display: block;
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0; }

.card.jr-card-full-height,
.jr-card.jr-card-full-height {
  height: calc(100% - 34px); }
  @media screen and (max-width: 575px) {
    .card.jr-card-full-height,
    .jr-card.jr-card-full-height {
      height: auto; } }

.jr-card > .jr-card-body:first-child {
  margin-top: 6px; }

.jr-card.p-0 > .jr-card-body:first-child {
  margin-top: 0; }

.jr-card .jr-card-thumb {
  margin: -24px -30px 0; }
  .jr-card .jr-card-thumb .card-img {
    border-radius: 8px 8px 0 0; }

@media screen and (max-width: 575px) {
  .jr-card {
    margin-bottom: 18px; } }

.jr-card-header {
  margin-bottom: 24px; }
  .jr-card-header .card-heading {
    margin-bottom: 4px; }
  .jr-card-header .badge {
    margin-bottom: 0; }
    .jr-card-header .badge:last-child {
      margin-left: 10px; }
  @media screen and (max-width: 575px) {
    .jr-card-header {
      margin-bottom: 18px; } }

.jr-entry-header {
  margin-bottom: 24px; }
  .jr-entry-header .entry-heading {
    margin-bottom: 4px; }
  .jr-entry-header .entry-description {
    margin-bottom: 0;
    font-size: 13px;
    color: #6c757d; }
  @media screen and (max-width: 575px) {
    .jr-entry-header {
      margin-bottom: 18px; } }

.card {
  margin-bottom: 34px; }
  @media screen and (max-width: 575px) {
    .card {
      margin-bottom: 18px; } }

.card-header {
  font-size: 16px; }
  .card-header .badge {
    margin-bottom: 0; }
    .card-header .badge:last-child {
      margin-left: 10px; }

.card-title {
  font-size: 16px;
  text-transform: capitalize; }

.card-subtitle {
  font-size: 14px;
  color: #495057; }
  .card-subtitle:not(:last-child) {
    margin-bottom: 16px; }

.card-mt-footer {
  padding: 0 15px 20px;
  margin-top: -10px; }

.jr-card-social {
  font-size: 22px;
  padding-top: 26px; }

.social-link {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 -5px 10px; }
  .social-link li {
    padding: 0 5px;
    margin-bottom: 5px; }
  .social-link li a {
    color: #5c5c5c; }
  .social-link li.active a,
  .social-link li a:hover,
  .social-link li a:focus {
    color: #E91E63; }
  .jr-card-social .social-link {
    margin-bottom: 0;
    -webkit-justify-content: space-around;
    justify-content: space-around; }

.jr-cart-ab {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  color: #fff;
  font-size: 16px; }
  .jr-cart-ab i {
    vertical-align: middle;
    font-size: 25px; }

.img-overlay-card {
  border-radius: 8px;
  margin-bottom: 34px; }
  @media screen and (max-width: 1199px) {
    .img-overlay-card .center-crop-img {
      min-height: 135px; } }
  @media screen and (max-width: 767px) {
    .img-overlay-card .center-crop-img {
      min-height: 160px; } }
  @media screen and (max-width: 575px) {
    .img-overlay-card {
      margin-bottom: 18px; } }

/*Chips Styles*/
.chip-outline {
  background-color: transparent !important;
  border: 1px solid #adb5bd !important; }

/*Drawer Styles*/
.drawer {
  width: 250px; }

.full-drawer {
  width: 100%; }

/*Grids List Styles*/
.gl-image,
.gl-list,
.gl-advanced,
.gl-single-line {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  overflow: hidden; }

.gl {
  max-width: 100%;
  max-height: 360px;
  transform: translateZ(0); }
  .gl.row.no-gutters {
    margin: 0 -2px; }
    .gl.row.no-gutters .col,
    .gl.row.no-gutters > [class*="col-"] {
      padding-right: 2px;
      padding-left: 2px;
      margin-bottom: 4px; }

.gl-single-line .slg {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: 100%; }

.title-gradient {
  background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; }

.title-gradient-bottom {
  background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: -o-linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; }

.g-title {
  left: 0;
  right: 0;
  height: 48px;
  display: flex;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif; }

.grid {
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden; }
  .grid > img {
    top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 0;
    flex-wrap: wrap; }

.g-ul {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  overflow-y: auto;
  list-style: none;
  -webkit-overflow-scrolling: touch; }

.gradient-text {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  color: white; }

.title-gradient-bottom {
  padding: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  display: flex;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent) !important;
  background: -o-linear-gradient(to top, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent 100%) !important;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent) !important; }

.title-gradient-top {
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  display: flex;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent) !important;
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent 100%) !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0, rgba(0, 0, 0, 0.3) 70%, transparent) !important; }

.grid-thumb img {
  max-height: 185px; }

.gl-advanced-grid {
  position: relative; }

/*Icons Styles*/
.icon {
  display: inline-block;
  text-align: center; }

.icons-wrapper {
  display: inline-block; }
  .icons-wrapper + .icons-wrapper {
    margin-left: 25px; }

/*Label Styles*/
label {
  margin-bottom: 0; }

/*List Styles*/
.pinned-list {
  position: relative;
  height: 370px;
  overflow: auto;
  width: auto; }

.user-list {
  width: 100%;
  padding: 20px 15px 10px; }
  .user-list.card {
    padding-left: 30px; }
    @media screen and (max-width: 575px) {
      .user-list.card {
        padding-left: 15px; } }
  .user-list .user-avatar {
    margin-right: 20px;
    margin-bottom: 10px; }
  .user-list h3 {
    font-weight: 700;
    margin-bottom: 8px; }
  .user-list a[class*="meta-"] i {
    margin-right: 6px; }
  .user-list a[class*="meta-"]:hover {
    text-decoration: none; }
  .user-list a[class*="meta-"] {
    display: inline-block;
    color: #5c5c5c;
    font-size: 13px;
    font-weight: 700; }

.user-list .img-section {
  width: 230px; }
  @media screen and (max-width: 649px) {
    .user-list .img-section {
      width: 100%; } }

.user-list .description {
  width: calc(100% - 300px); }
  @media screen and (max-width: 649px) {
    .user-list .description {
      width: 100%; } }

.user-list .jr-btn-list li:not(:last-child) {
  margin-right: 10px; }

.user-list .jr-btn-list .btn-light {
  background-color: lightgray;
  border-color: lightgray;
  color: #5c5c5c;
  font-weight: 600; }
  .user-list .jr-btn-list .btn-light:hover, .user-list .jr-btn-list .btn-light:focus, .user-list .jr-btn-list .btn-light:active {
    background-color: #c7c7c7;
    border-color: #c7c7c7; }

.user-list .jr-btn-list.badge-list li:not(:last-child) {
  margin-right: 0; }

.list-inline {
  margin-left: -5px;
  margin-right: -5px; }
  .list-inline li {
    padding: 0 5px;
    display: inline-block; }
    .list-inline li.media {
      display: flex; }

.card-strip {
  border-bottom: solid 1px #dee2e6;
  margin-bottom: 10px; }

.jr-mbtn-list {
  margin-left: -20px; }

.img-res {
  max-width: 100%;
  height: auto; }

.nav-link {
  cursor: pointer;
  padding: 0.5rem; }

.card-header.bg-primary .nav-link {
  color: #fff; }
  .card-header.bg-primary .nav-link.active, .card-header.bg-primary .nav-link:hover, .card-header.bg-primary .nav-link:focus {
    color: #000;
    background-color: #fff; }
  .card-header.bg-primary .nav-link.disabled {
    color: #f8f9fa; }
  .card-header.bg-primary .nav-link.disabled:hover {
    color: #212529; }

/*Progressbar Styles*/
.pb-wrapper {
  display: flex;
  align-items: center; }

.pb-btn-wrapper {
  margin: 8px;
  position: relative; }
  .pb-btn-wrapper .btn-success {
    background-color: #4CAF50; }
    .pb-btn-wrapper .btn-success:hover {
      background-color: #388E3C; }

.fab-progress {
  color: #4CAF50;
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 1; }

.btn-progress {
  color: #4CAF50;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px; }

/*Popover Styles*/
.popover {
  z-index: 1510; }

.popover-header {
  background-color: transparent;
  border-bottom: 0 none;
  color: #000;
  font-size: 18px;
  padding: 15px 20px 0; }

.popover-body {
  padding: 15px 20px; }

/*React Joyride Styles*/
.joyride-beacon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 1500; }
  .joyride-beacon:active, .joyride-beacon:focus, .joyride-beacon:hover {
    outline: none; }
  .joyride-beacon__inner {
    animation: joyride-beacon-inner 1.2s infinite ease-in-out;
    background-color: #f04;
    border-radius: 50%;
    display: block;
    height: 50%;
    left: 50%;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%; }
  .joyride-beacon__outer {
    animation: joyride-beacon-outer 1.2s infinite ease-in-out;
    background-color: rgba(255, 0, 68, 0.2);
    border: 2px solid #f04;
    border-radius: 50%;
    box-sizing: border-box;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    transform-origin: center;
    width: 100%; }

.joyride-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500; }

.joyride-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute; }
  .joyride-hole.safari {
    box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5); }

.joyride-tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #555;
  cursor: default;
  filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  opacity: 0;
  padding: 20px;
  transform: translate3d(0, 0, 0);
  width: 290px;
  z-index: 1510; }
  .joyride-tooltip--animate {
    animation: joyride-tooltip 0.4s forwards;
    animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18); }
  .joyride-tooltip__triangle {
    background-repeat: no-repeat;
    position: absolute; }
  .joyride-tooltip.bottom, .joyride-tooltip.bottom-left, .joyride-tooltip.bottom-right {
    margin-top: 18px; }
    .joyride-tooltip.bottom .joyride-tooltip__triangle, .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.bottom-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28180 8 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 18px;
      left: 50%;
      top: -16px;
      transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.top, .joyride-tooltip.top-left, .joyride-tooltip.top-right {
    margin-bottom: 18px; }
    .joyride-tooltip.top .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%280%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      bottom: -16px;
      height: 18px;
      left: 50%;
      transform: translateX(-50%);
      width: 36px; }
  .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
    left: 3%;
    transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
        left: 2%; } }
  .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
    left: auto;
    right: 3%;
    transform: translateX(0); }
    @media screen and (min-width: 480px) {
      .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
        right: 2%; } }
  .joyride-tooltip.left {
    margin-right: 18px; }
    .joyride-tooltip.left .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%28270 8 8%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      right: -16px;
      width: 18px; }
  .joyride-tooltip.right {
    margin-left: 18px; }
    .joyride-tooltip.right .joyride-tooltip__triangle {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#fff%22%20transform%3D%22scale%282.25%29%20rotate%2890 4 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
      height: 36px;
      left: -16px;
      width: 18px; }
  .joyride-tooltip__close {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22rgba(85, 85, 85, 0.5)%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    height: 12px;
    position: absolute;
    right: 10px;
    text-decoration: none;
    top: 10px;
    width: 12px;
    z-index: 10;
    display: block; }
    .joyride-tooltip__close:active, .joyride-tooltip__close:focus, .joyride-tooltip__close:hover {
      outline: none; }
    .joyride-tooltip__close:hover, .joyride-tooltip__close:focus {
      color: rgba(60, 60, 60, 0.5);
      outline: none; }
    .joyride-tooltip__close--header {
      right: 20px;
      top: 20px; }
  .joyride-tooltip__header {
    border-bottom: 1px solid #f04;
    color: #555;
    font-size: 16px;
    padding-bottom: 6px;
    padding-right: 18px;
    position: relative; }
    .joyride-tooltip__header ~ .joyride-tooltip__main {
      padding: 12px 0 18px; }
  .joyride-tooltip__main {
    font-size: 14px;
    padding-bottom: 18px;
    padding-right: 18px; }
  .joyride-tooltip__footer {
    text-align: right; }
  .joyride-tooltip__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0; }
    .joyride-tooltip__button:active, .joyride-tooltip__button:focus, .joyride-tooltip__button:hover {
      outline: none; }
    .joyride-tooltip__button--primary {
      background-color: #f04;
      border-radius: 4px;
      color: #fff;
      padding: 6px 12px;
      transition: background-color 0.2s ease-in-out; }
      .joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
        background-color: #ff1f5a;
        color: #fff; }
    .joyride-tooltip__button--secondary {
      color: #f04;
      margin-right: 10px; }
    .joyride-tooltip__button--skip {
      color: #ccc;
      float: left;
      margin-right: 10px; }
  .joyride-tooltip--standalone .joyride-tooltip__main {
    padding-bottom: 0; }
  .joyride-tooltip--standalone .joyride-tooltip__footer {
    display: none; }
  @media screen and (min-width: 480px) {
    .joyride-tooltip {
      width: 360px; } }
  @media screen and (min-width: 960px) {
    .joyride-tooltip {
      width: 450px; } }

@keyframes joyride-tooltip {
  0% {
    transform: scale(0.1); }
  100% {
    opacity: 1;
    transform: perspective(1px) scale(1); } }

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@keyframes joyride-beacon-outer {
  0% {
    transform: scale(1); }
  45% {
    opacity: 0.7;
    transform: scale(0.75); }
  100% {
    opacity: 0.9;
    transform: scale(1); } }

/*Tables Styles*/
.table-header {
  padding-right: 2px !important;
  min-height: 64px;
  padding-left: 24px;
  align-items: center; }
  .table-header.highlight-light {
    color: #616161;
    background-color: #e0e0e0; }
  .table-header.highlight-dark {
    color: #fff;
    background-color: #616161; }

.spacer {
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%; }

.actions {
  color: #F44336; }

.title {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.table-hover tbody tr:hover {
  background-color: rgba(63, 81, 181, 0.075); }

.table-material {
  width: 100% !important;
  max-width: 100% !important;
  background-color: transparent !important; }
  .table-material th,
  .table-material td {
    padding: 0.75rem !important;
    vertical-align: top !important;
    border-top: 1px solid #dee2e6 !important; }
  .table-material thead th {
    vertical-align: bottom !important;
    border-bottom: 2px solid #dee2e6 !important; }
  .table-material tbody + tbody {
    border-top: 2px solid #dee2e6 !important; }
  .table-material .table-material {
    background-color: #f4f4f4 !important; }

.d-flex-stretch {
  -webkit-align-items: stretch;
  align-items: stretch;
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.default-table {
  margin-bottom: 0; }
  .default-table thead th {
    font-weight: 400;
    font-size: 16px; }
  .default-table td {
    vertical-align: middle; }

.table-unbordered th,
.table-unbordered td {
  border: 0 none !important; }

.table-unbordered .th-border-b th {
  border-bottom: 2px solid #ced4da !important; }

.table-responsive-material {
  min-height: .01%;
  overflow-x: auto; }

@media screen and (max-width: 767px) {
  .table-responsive-material {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd; }
  .table-responsive-material > .table {
    margin-bottom: 0; }
  .table-responsive-material > .table > thead > tr > th,
  .table-responsive-material > .table > tbody > tr > th,
  .table-responsive-material > .table > tfoot > tr > th,
  .table-responsive-material > .table > thead > tr > td,
  .table-responsive-material > .table > tbody > tr > td,
  .table-responsive-material > .table > tfoot > tr > td {
    white-space: nowrap; }
  .table-responsive-material > .table-bordered {
    border: 0; }
  .table-responsive-material > .table-bordered > thead > tr > th:first-child,
  .table-responsive-material > .table-bordered > tbody > tr > th:first-child,
  .table-responsive-material > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive-material > .table-bordered > thead > tr > td:first-child,
  .table-responsive-material > .table-bordered > tbody > tr > td:first-child,
  .table-responsive-material > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .table-responsive-material > .table-bordered > thead > tr > th:last-child,
  .table-responsive-material > .table-bordered > tbody > tr > th:last-child,
  .table-responsive-material > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive-material > .table-bordered > thead > tr > td:last-child,
  .table-responsive-material > .table-bordered > tbody > tr > td:last-child,
  .table-responsive-material > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .table-responsive-material > .table-bordered > tbody > tr:last-child > th,
  .table-responsive-material > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive-material > .table-bordered > tbody > tr:last-child > td,
  .table-responsive-material > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0; } }

.fst-td-br-tp-0 tr:first-child td {
  border-top: 0 none; }

/*Tabs Styles*/
.tab {
  min-width: 10px !important; }

.tab-icon > span {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

/*Form Styles*/
@media screen and (max-width: 575px) {
  .horizontal-stepper p,
  .horizontal-stepper span {
    opacity: 0;
    visibility: hidden;
    font-size: 12px; }
  .horizontal-stepper.active p,
  .horizontal-stepper.active span {
    opacity: 1;
    visibility: visible; }
  .horizontal-stepper > *,
  .horizontal-stepper > button > * {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .horizontal-stepper .stepperlabel,
  .horizontal-stepper .stepperbutton,
  .horizontal-stepper .stepperlabel span:first-child,
  .horizontal-stepper .stepperbutton span:first-child {
    opacity: 1;
    visibility: visible; }
  .horizontal-stepper-linear {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

.form-checkbox {
  position: relative;
  z-index: 1; }

.form-checkbox > input {
  height: 18px;
  opacity: 0;
  position: absolute;
  padding: 10px;
  top: 0;
  width: 18px;
  z-index: 2;
  cursor: pointer; }

.form-control-checkbox .form-checkbox > input {
  height: 48px;
  width: 48px; }

.form-control-checkbox .form-checkbox {
  height: 48px;
  display: flex;
  align-items: center;
  width: 48px;
  justify-content: center; }

.form-checkbox > input + .check {
  border: 1px solid #868e96;
  border-radius: 2px;
  color: #3f51b5;
  cursor: pointer;
  display: inline-block;
  height: 18px;
  position: relative;
  width: 18px;
  z-index: -1; }

.form-checkbox > input.sm + .check {
  border: 1px solid #868e96;
  border-radius: 2px;
  color: #3f51b5;
  cursor: pointer;
  display: inline-block;
  height: 14px;
  position: relative;
  top: 3px;
  width: 14px;
  z-index: -1; }

.form-checkbox > input + .check i {
  display: none;
  left: 1px;
  opacity: 0;
  position: absolute;
  top: 2px; }

.form-checkbox > input.sm + .check i {
  display: none;
  left: 1px;
  opacity: 0;
  position: absolute;
  top: 2px; }

.form-checkbox > input:checked + span i {
  display: block;
  opacity: 1; }

/*Radio and Checkbox styles*/
.checkbox-replace {
  padding-left: 20px; }

.checkbox-replace input[type="checkbox"],
.radio-replace input[type="radio"] {
  margin-left: -20px;
  position: absolute; }

.form-inline .checkbox-replace label {
  padding-left: 5px; }

.form-inline .checkbox-replace,
.form-inline .radio-replace {
  margin-right: 20px; }

.form-inline .checkbox-replace:last-child {
  margin-right: 0; }

.checkbox-replace label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }

.checkbox-replace label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  margin-left: -20px;
  margin-top: 2px;
  border: 1px solid #868e96;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }

.checkbox-replace label::after {
  display: inline-block;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 3px;
  top: 5px;
  margin-left: -20px;
  font-size: 11px;
  color: #495057; }

.checkbox-replace input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer; }

.checkbox-replace input[type="checkbox"]:focus + label::before {
  outline: 0 none; }

.checkbox-replace input[type="checkbox"]:checked + label::after {
  background-color: #ced4da;
  content: ""; }

.checkbox-replace input[type="checkbox"]:disabled + label {
  opacity: 0.65; }

.checkbox-replace input[type="checkbox"]:disabled + label::before {
  background-color: #fff;
  cursor: not-allowed; }

.checkbox-replace.checkbox-inline {
  margin-top: 0; }

.checkbox-primary label::before {
  border-color: #3f51b5; }

.checkbox-primary input[type="checkbox"]:checked + label::after {
  background-color: #3f51b5; }

.checkbox-danger label::before {
  border-color: #E91E63; }

.checkbox-danger input[type="checkbox"]:checked + label::after {
  border-color: #E91E63; }

.checkbox-info label::before {
  border-color: #00bcd4; }

.checkbox-info input[type="checkbox"]:checked + label::after {
  border-color: #00bcd4; }

.checkbox-warning label::before {
  border-color: #ff9800; }

.checkbox-warning input[type="checkbox"]:checked + label::after {
  border-color: #ff9800; }

.checkbox-success label::before {
  border-color: #4caf50; }

.checkbox-success input[type="checkbox"]:checked + label::after {
  border-color: #4caf50; }

.radio-replace {
  padding-left: 20px; }

.radio-replace label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px; }

.radio-replace label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  margin-left: -20px;
  margin-top: 2px;
  border: 1px solid #ced4da;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out; }

.radio-replace label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 12px;
  height: 12px;
  left: 3px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #ced4da;
  -webkit-transform: rotate(scale(0, 0));
  -ms-transform: rotate(scale(0, 0));
  -o-transform: rotate(scale(0, 0));
  transform: rotate(scale(0, 0));
  -webkit-transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }

.radio-replace input[type="radio"] {
  opacity: 0;
  z-index: 1;
  cursor: pointer; }

.radio-replace input[type="radio"]:focus + label::before {
  outline: 0 none; }

.radio-replace input[type="radio"]:checked + label::after {
  -webkit-transform: rotate(scale(1, 1));
  -ms-transform: rotate(scale(1, 1));
  -o-transform: rotate(scale(1, 1));
  transform: rotate(scale(1, 1)); }

.radio-replace input[type="radio"]:disabled + label {
  opacity: 0.65; }

.radio-replace input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }

.radio-replace.radio-inline {
  margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
  background-color: #3f51b5; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #3f51b5; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #3f51b5; }

.radio-danger input[type="radio"] + label::after {
  background-color: #E91E63; }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #E91E63; }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #E91E63; }

.radio-info input[type="radio"] + label::after {
  background-color: #00bcd4; }

.radio-info input[type="radio"]:checked + label::before {
  border-color: #00bcd4; }

.radio-info input[type="radio"]:checked + label::after {
  background-color: #00bcd4; }

.radio-warning input[type="radio"] + label::after {
  background-color: #ff9800; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ff9800; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ff9800; }

.radio-success input[type="radio"] + label::after {
  background-color: #4caf50; }

.radio-success input[type="radio"]:checked + label::before {
  border-color: #4caf50; }

.radio-success input[type="radio"]:checked + label::after {
  background-color: #4caf50; }

.form-horizontal .checkbox, .form-horizontal .checkbox-inline, .form-horizontal .radio, .form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0; }

.form-horizontal .form-inline .checkbox,
.form-horizontal .form-inline .checkbox-inline,
.form-horizontal .form-inline .radio,
.form-horizontal .form-inline .radio-inline {
  padding-top: 0; }

.form-horizontal .checkbox, .form-horizontal .radio {
  min-height: 27px; }

.form-wizard .tab-content .pager.wizard .first a {
  margin-right: 8px; }

.form-wizard .tab-content .pager.wizard .last a {
  margin-left: 8px; }

.wizard-navbar {
  margin: 60px auto 20px; }

.wizard-navbar > ul {
  display: table;
  width: 100%;
  text-align: center;
  margin: 0;
  font-weight: 600;
  padding: 0;
  list-style: none outside none;
  table-layout: fixed; }

.wizard-navbar > ul li {
  display: table-cell;
  width: 1%;
  position: relative; }

.wizard-navbar > ul li a {
  color: #868e96;
  position: relative;
  padding: 0 10px;
  display: block;
  text-decoration: none; }

.wizard-navbar > ul li a span.wz-number {
  background-color: #e9ecef;
  border-radius: 50%;
  color: #adb5bd;
  display: block;
  height: 40px;
  left: 50%;
  line-height: 40px;
  margin-left: -20px;
  margin-top: -50px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
  width: 40px;
  z-index: 5; }

.wizard-navbar > ul li.completed a span.wz-number {
  background-color: #3f51b5;
  color: #fff; }

.wizard-navbar > ul li a.active span.wz-number {
  background-color: #E91E63;
  color: #fff; }

.wizard-navbar > ul li a.active,
.wizard-navbar > ul li.completed a {
  color: #6c757d; }

.wizard-navbar > ul li a:before,
.wizard-navbar > ul li a:after {
  content: '';
  position: absolute;
  height: 4px;
  width: 50%;
  background-color: #e9ecef;
  top: -32px;
  left: 0; }

.wizard-navbar > ul li a:after {
  left: auto;
  right: 0; }

.wizard-navbar > ul li a.active:before,
.wizard-navbar > ul li a.active:after {
  background-color: #E91E63; }

.wizard-navbar > ul li.completed a:before,
.wizard-navbar > ul li.completed a:after {
  background-color: #3f51b5; }

label.error {
  display: block;
  color: #f44336;
  font-weight: 400;
  margin: 5px 0; }

@media screen and (max-width: 479px) {
  .wizard-navbar > ul li a span.wz-label {
    visibility: hidden; }
  .wizard-navbar > ul li a.active span.wz-label {
    visibility: visible; } }

.form-checkbox-custom {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  margin-bottom: 12px; }

.form-checkbox-custom .check {
  margin-right: 10px; }

.react-switch {
  vertical-align: middle;
  margin-left: 4px; }

/*Input Styles*/
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px;
  color: gainsboro; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: gainsboro; }

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px;
  color: gainsboro; }

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: gainsboro; }

.module-box-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px; }

.module-box-header ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px; }

.module-box-header :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px; }

.module-box-header :-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px; }

.search-bar {
  background-color: #fff;
  position: relative; }
  .search-bar .form-group {
    width: 100%;
    height: 100%;
    margin-bottom: 0; }
  .search-bar input[type="search"] {
    padding-right: 50px; }
  .search-bar .search-icon {
    background-color: transparent;
    border: 0 none;
    color: #868e96;
    height: 36px;
    width: 30px; }
  .search-bar .form-control {
    box-shadow: none; }
    .search-bar .form-control.form-control-lg + .search-icon {
      position: absolute;
      right: 14px;
      top: 6px; }
  .search-bar .search-icon {
    position: absolute;
    right: 14px;
    top: 3px; }
  .search-bar.right-side-icon input[type="search"] {
    padding-right: 16px;
    padding-left: 50px; }
  .search-bar.right-side-icon .search-icon {
    position: absolute;
    left: 14px;
    top: 2px; }
  .search-bar.right-side-icon .form-control {
    box-shadow: none; }
    .search-bar.right-side-icon .form-control.form-control-lg + .search-icon {
      position: absolute;
      left: 14px;
      top: 6px; }

.nav-searchbox .search-bar input + .search-icon i {
  color: rgba(92, 92, 92, 0.8); }

@media screen and (max-width: 767px) {
  .jr-card .app-main-header .search-bar {
    width: 150px; } }

/* Pages
   ========================================================================== */
/*Login Styles*/
/*Login Styles*/
.login-container {
  position: relative;
  height: 100%; }

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #ced4da;
  font-size: 14px;
  max-width: 94%; }

.login-header {
  margin-bottom: 30px; }

.login-content .form-control {
  background-color: #f5f5f5; }
  .login-content .form-control:focus {
    box-shadow: none;
    border-color: #3f51b5; }

.login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important; }

.app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto; }
  .app-login-container .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2; }

.app-login-main-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 14px;
  overflow: hidden; }

.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
  order: 1; }
  @media screen and (max-width: 575px) {
    .app-login-content {
      width: 100%;
      order: 2; } }

.app-login-header {
  margin-bottom: 30px; }

.app-login-content .form-control {
  background-color: #f5f5f5; }
  .app-login-content .form-control:focus {
    box-shadow: none;
    border-color: #3f51b5; }

.app-login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important; }

.app-logo-content {
  background-color: #3f51b5;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2; }
  @media screen and (max-width: 575px) {
    .app-logo-content {
      width: 100%;
      order: 1; } }

.app-social-block {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .app-social-block .social-link,
  .app-social-block .social-link li {
    margin: 0; }
  .app-social-block .social-link span {
    border: solid 1px #3f51b5;
    border-radius: 50%;
    color: #3f51b5; }
    .app-social-block .social-link span:hover, .app-social-block .social-link span:focus {
      color: #fff;
      background-color: #3f51b5; }
  .app-social-block p {
    margin-bottom: 0; }

/* Modules
   ========================================================================== */
/* App Module Style */
.app-wrapper-module {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  height: 100%; }

.app-module {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: -36px -40px 0 -40px; }
  @media screen and (max-width: 575px) {
    .app-module {
      margin: -20px; } }

.module-side {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 0 1 230px;
  -ms-flex: 0 1 230px;
  flex: 0 1 230px;
  max-width: 230px;
  min-width: 230px; }

.module-side-header {
  border-bottom: solid 1px #e9ecef;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }
  .module-side-header .user-detail {
    padding: 15px 10px;
    text-align: center; }

.module-logo {
  padding: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 78px;
  font-size: 20px;
  background-color: #f9fafb;
  color: #2f353a; }
  .module-logo .zmdi {
    font-size: 28px; }

.module-user-info, .module-side-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.module-user-detail {
  font-size: 12px; }

.module-add-task {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 24px; }

.module-nav {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center; }
  .module-nav li {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .module-nav li a {
    padding: 12px 29px;
    color: #8f8f8f;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    text-decoration: none; }
    .module-nav li a:hover, .module-nav li a:focus {
      color: #5c5c5c; }
    .module-nav li a.active {
      color: #3f51b5; }
  .module-nav li i {
    margin-right: 16px;
    font-size: 16px; }
  .module-nav li span {
    display: inline-block;
    vertical-align: middle; }
  .module-nav li.module-nav-label {
    padding: 12px 29px; }

.module-box {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: calc(100% - 231px); }

.module-box-header {
  padding: 10px 26px;
  min-height: 79px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #fff;
  border-bottom: solid 1px #e9ecef; }
  .module-box-header .search-bar.right-side-icon {
    min-width: 350px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      border-radius: 0;
      height: 46px;
      padding: 10px 18px 10px 40px; }
      .module-box-header .search-bar.right-side-icon .form-control:focus {
        background-color: #f8f9fa; }
    .module-box-header .search-bar.right-side-icon .search-icon {
      width: 25px;
      height: 46px;
      font-size: 16px;
      left: 10px;
      top: 0;
      padding: 0; }

.module-box-header-inner {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between; }

.module-box-content {
  background-color: #fff;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.module-box-topbar {
  padding: 8px 12px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: solid 1px #e9ecef; }
  .module-box-topbar button + button {
    border-radius: 0; }

.module-box-topbar-todo {
  padding-left: 64px; }
  @media screen and (max-width: 575px) {
    .module-box-topbar-todo {
      padding-left: 27px; } }

.module-list-icon {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  padding-right: 10px; }

.bar-icon {
  margin-right: 15px; }

.toolbar-separator {
  border-left: solid 1px #ced4da;
  height: 100%;
  width: 1px;
  margin: 0 12px; }

.toolbar-left {
  margin-left: auto; }

.module-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center; }

.module-list-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px 28px;
  border-bottom: solid 1px #e9ecef;
  z-index: 5;
  position: relative;
  cursor: pointer; }
  .module-list-item > .check-label {
    margin-left: 0;
    margin-bottom: 0; }
  .module-list-item.mail-cell {
    padding-left: 12px;
    -webkit-align-items: start;
    align-items: start; }

.module-detail-header {
  margin-bottom: 10px; }
  .module-detail-header > .check-label {
    margin-right: auto; }

.module-list-info {
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: calc(100% - 169px); }

.module-todo-content {
  position: relative; }
  .module-todo-content .subject {
    margin-bottom: 5px; }

.module-list-actions {
  text-align: right; }

.app-module-sidenav {
  background-color: #fff;
  border-right: solid 1px #e9ecef;
  position: relative;
  z-index: 9; }
  .app-module-sidenav .chat-sidenav-main {
    max-width: 300px;
    min-width: 300px;
    width: 90%; }

.module-date {
  max-width: 160px; }
  .module-date > div:before {
    display: none; }

.module-detail-item {
  padding: 10px 28px; }

.task-title {
  font-size: 18px; }

.chat-todo-avatar {
  margin-right: 15px; }

.modal-title {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  font-size: 18px; }

.loader-view-block, .loader-view {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center; }

.module-date > div {
  -webkit-align-items: center;
  align-items: center; }

@media screen and (max-width: 1199px) {
  .module-side {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-block;
    display: -o-block;
    display: block;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 280px;
    min-width: 280px;
    background-color: #fff; }
  .module-box-header {
    position: relative;
    min-height: 58px;
    padding: 5px 26px 5px 75px; }
    .module-box-header .drawer-btn {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      border-right: solid 1px #e9ecef;
      height: 58px;
      width: 56px;
      border-radius: 0;
      font-size: 22px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      height: 38px;
      padding: 5px 18px 5px 40px; }
    .module-box-header .search-bar.right-side-icon .search-icon {
      height: 38px; }
  .chat-loader-view {
    height: calc(100vh - 120px); }
  .module-box {
    max-width: 100%; } }

@media screen and (min-width: 991px) {
  .modal-dialog {
    max-width: 750px; } }

@media screen and (max-width: 991px) {
  .app-module {
    padding-top: 0; } }

@media screen and (max-width: 599px) {
  .module-box-header .search-bar {
    margin-left: 0;
    max-width: none; }
    .module-box-header .search-bar.right-side-icon {
      min-width: 250px; } }

@media screen and (max-width: 575px) {
  .module-list-item {
    padding: 10px 12px; }
  .module-list-info {
    max-width: 100%; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu {
    right: auto !important;
    left: 0 !important; }
  .module-box-header .search-bar.right-side-icon {
    min-width: 200px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      border-radius: 0.25rem; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu:after {
    right: auto;
    left: 9px; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu:before {
    right: auto;
    left: 9px; }
  .module-box-header .search-bar {
    padding-right: 2px; } }

@media screen and (max-width: 399px) {
  .module-box-header .search-bar.right-side-icon {
    min-width: 100px; } }

@media screen and (min-width: 399px) {
  .d-xs-flex {
    display: flex !important; } }

.module-box {
  max-width: 100%; }

/* Color Theme
   ========================================================================== */
/*Theme Indigo Styles*/
.indigo .right-arrow {
  color: #3f51b5; }
  .indigo .right-arrow:after {
    color: #3f51b5; }

.indigo .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .indigo .btn-primary:focus, .indigo .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .indigo .btn-primary.disabled, .indigo .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .indigo .btn-primary:not(:disabled):not(.disabled):active, .indigo .btn-primary:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .indigo .btn-primary:not(:disabled):not(.disabled):active:focus, .indigo .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.indigo .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .indigo .btn-secondary:focus, .indigo .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .indigo .btn-secondary.disabled, .indigo .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .indigo .btn-secondary:not(:disabled):not(.disabled):active, .indigo .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .indigo .btn-secondary:not(:disabled):not(.disabled):active:focus, .indigo .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.indigo .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .indigo .btn-success:focus, .indigo .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .indigo .btn-success.disabled, .indigo .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .indigo .btn-success:not(:disabled):not(.disabled):active, .indigo .btn-success:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .indigo .btn-success:not(:disabled):not(.disabled):active:focus, .indigo .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.indigo .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .indigo .btn-info:focus, .indigo .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .indigo .btn-info.disabled, .indigo .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .indigo .btn-info:not(:disabled):not(.disabled):active, .indigo .btn-info:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .indigo .btn-info:not(:disabled):not(.disabled):active:focus, .indigo .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.indigo .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .indigo .btn-warning:focus, .indigo .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .indigo .btn-warning.disabled, .indigo .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .indigo .btn-warning:not(:disabled):not(.disabled):active, .indigo .btn-warning:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .indigo .btn-warning:not(:disabled):not(.disabled):active:focus, .indigo .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.indigo .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .indigo .btn-danger:focus, .indigo .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .indigo .btn-danger.disabled, .indigo .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .indigo .btn-danger:not(:disabled):not(.disabled):active, .indigo .btn-danger:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .indigo .btn-danger:not(:disabled):not(.disabled):active:focus, .indigo .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.indigo .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .indigo .btn-light:focus, .indigo .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .indigo .btn-light.disabled, .indigo .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .indigo .btn-light:not(:disabled):not(.disabled):active, .indigo .btn-light:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .indigo .btn-light:not(:disabled):not(.disabled):active:focus, .indigo .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.indigo .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .indigo .btn-dark:focus, .indigo .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .indigo .btn-dark.disabled, .indigo .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .indigo .btn-dark:not(:disabled):not(.disabled):active, .indigo .btn-dark:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .indigo .btn-dark:not(:disabled):not(.disabled):active:focus, .indigo .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.indigo a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .indigo a.btn-primary:focus, .indigo a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .indigo a.btn-primary.disabled, .indigo a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .indigo a.btn-primary:not(:disabled):not(.disabled):active, .indigo a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .indigo a.btn-primary:not(:disabled):not(.disabled):active:focus, .indigo a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.indigo a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .indigo a.btn-secondary:focus, .indigo a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .indigo a.btn-secondary.disabled, .indigo a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .indigo a.btn-secondary:not(:disabled):not(.disabled):active, .indigo a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .indigo a.btn-secondary:not(:disabled):not(.disabled):active:focus, .indigo a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.indigo a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .indigo a.btn-success:focus, .indigo a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .indigo a.btn-success.disabled, .indigo a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .indigo a.btn-success:not(:disabled):not(.disabled):active, .indigo a.btn-success:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .indigo a.btn-success:not(:disabled):not(.disabled):active:focus, .indigo a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.indigo a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .indigo a.btn-info:focus, .indigo a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .indigo a.btn-info.disabled, .indigo a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .indigo a.btn-info:not(:disabled):not(.disabled):active, .indigo a.btn-info:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .indigo a.btn-info:not(:disabled):not(.disabled):active:focus, .indigo a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.indigo a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .indigo a.btn-warning:focus, .indigo a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .indigo a.btn-warning.disabled, .indigo a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .indigo a.btn-warning:not(:disabled):not(.disabled):active, .indigo a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .indigo a.btn-warning:not(:disabled):not(.disabled):active:focus, .indigo a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.indigo a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .indigo a.btn-danger:focus, .indigo a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .indigo a.btn-danger.disabled, .indigo a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .indigo a.btn-danger:not(:disabled):not(.disabled):active, .indigo a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .indigo a.btn-danger:not(:disabled):not(.disabled):active:focus, .indigo a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.indigo a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .indigo a.btn-light:focus, .indigo a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .indigo a.btn-light.disabled, .indigo a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .indigo a.btn-light:not(:disabled):not(.disabled):active, .indigo a.btn-light:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .indigo a.btn-light:not(:disabled):not(.disabled):active:focus, .indigo a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.indigo a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .indigo a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .indigo a.btn-dark:focus, .indigo a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .indigo a.btn-dark.disabled, .indigo a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .indigo a.btn-dark:not(:disabled):not(.disabled):active, .indigo a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .indigo a.btn-dark:not(:disabled):not(.disabled):active:focus, .indigo a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.indigo .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .indigo .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .indigo .btn-outline-primary:focus, .indigo .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .indigo .btn-outline-primary.disabled, .indigo .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .indigo .btn-outline-primary:not(:disabled):not(.disabled):active, .indigo .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .indigo .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .indigo .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.indigo .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .indigo .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .indigo .btn-outline-secondary:focus, .indigo .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .indigo .btn-outline-secondary.disabled, .indigo .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .indigo .btn-outline-secondary:not(:disabled):not(.disabled):active, .indigo .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .indigo .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .indigo .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.indigo .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .indigo .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .indigo .btn-outline-success:focus, .indigo .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .indigo .btn-outline-success.disabled, .indigo .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .indigo .btn-outline-success:not(:disabled):not(.disabled):active, .indigo .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .indigo .btn-outline-success:not(:disabled):not(.disabled):active:focus, .indigo .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.indigo .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .indigo .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .indigo .btn-outline-info:focus, .indigo .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .indigo .btn-outline-info.disabled, .indigo .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .indigo .btn-outline-info:not(:disabled):not(.disabled):active, .indigo .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .indigo .btn-outline-info:not(:disabled):not(.disabled):active:focus, .indigo .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.indigo .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .indigo .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .indigo .btn-outline-warning:focus, .indigo .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .indigo .btn-outline-warning.disabled, .indigo .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .indigo .btn-outline-warning:not(:disabled):not(.disabled):active, .indigo .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .indigo .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .indigo .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.indigo .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .indigo .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .indigo .btn-outline-danger:focus, .indigo .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .indigo .btn-outline-danger.disabled, .indigo .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .indigo .btn-outline-danger:not(:disabled):not(.disabled):active, .indigo .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .indigo .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .indigo .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.indigo .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .indigo .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .indigo .btn-outline-light:focus, .indigo .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .indigo .btn-outline-light.disabled, .indigo .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .indigo .btn-outline-light:not(:disabled):not(.disabled):active, .indigo .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .indigo .btn-outline-light:not(:disabled):not(.disabled):active:focus, .indigo .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.indigo .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .indigo .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .indigo .btn-outline-dark:focus, .indigo .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .indigo .btn-outline-dark.disabled, .indigo .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .indigo .btn-outline-dark:not(:disabled):not(.disabled):active, .indigo .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .indigo .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .indigo .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .indigo .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.indigo a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .indigo a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .indigo a.btn-outline-primary:focus, .indigo a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .indigo a.btn-outline-primary.disabled, .indigo a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .indigo a.btn-outline-primary:not(:disabled):not(.disabled):active, .indigo a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .indigo a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .indigo a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.indigo a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .indigo a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .indigo a.btn-outline-secondary:focus, .indigo a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .indigo a.btn-outline-secondary.disabled, .indigo a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .indigo a.btn-outline-secondary:not(:disabled):not(.disabled):active, .indigo a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .indigo a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .indigo a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.indigo a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .indigo a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .indigo a.btn-outline-success:focus, .indigo a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .indigo a.btn-outline-success.disabled, .indigo a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .indigo a.btn-outline-success:not(:disabled):not(.disabled):active, .indigo a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .indigo a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .indigo a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.indigo a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .indigo a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .indigo a.btn-outline-info:focus, .indigo a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .indigo a.btn-outline-info.disabled, .indigo a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .indigo a.btn-outline-info:not(:disabled):not(.disabled):active, .indigo a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .indigo a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .indigo a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.indigo a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .indigo a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .indigo a.btn-outline-warning:focus, .indigo a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .indigo a.btn-outline-warning.disabled, .indigo a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .indigo a.btn-outline-warning:not(:disabled):not(.disabled):active, .indigo a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .indigo a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .indigo a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.indigo a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .indigo a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .indigo a.btn-outline-danger:focus, .indigo a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .indigo a.btn-outline-danger.disabled, .indigo a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .indigo a.btn-outline-danger:not(:disabled):not(.disabled):active, .indigo a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .indigo a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .indigo a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.indigo a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .indigo a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .indigo a.btn-outline-light:focus, .indigo a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .indigo a.btn-outline-light.disabled, .indigo a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .indigo a.btn-outline-light:not(:disabled):not(.disabled):active, .indigo a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .indigo a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .indigo a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.indigo a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .indigo a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .indigo a.btn-outline-dark:focus, .indigo a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .indigo a.btn-outline-dark.disabled, .indigo a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .indigo a.btn-outline-dark:not(:disabled):not(.disabled):active, .indigo a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .indigo a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .indigo a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .indigo a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .indigo a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.indigo .btn-outline-primary,
.indigo a.btn-outline-primary {
  color: #3f51b5;
  border-color: #3f51b5; }
  .indigo .btn-outline-primary:focus, .indigo .btn-outline-primary:hover, .indigo .btn-outline-primary.disabled, .indigo .btn-outline-primary:disabled, .indigo .btn-outline-primary:not(:disabled):not(.disabled):active, .indigo .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-primary.dropdown-toggle,
  .indigo a.btn-outline-primary:focus,
  .indigo a.btn-outline-primary:hover,
  .indigo a.btn-outline-primary.disabled,
  .indigo a.btn-outline-primary:disabled,
  .indigo a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .indigo a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .indigo a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #3f51b5;
    background-color: #3f51b5; }

.indigo .btn-outline-secondary,
.indigo a.btn-outline-secondary {
  color: #FF4081;
  border-color: #FF4081; }
  .indigo .btn-outline-secondary:focus, .indigo .btn-outline-secondary:hover, .indigo .btn-outline-secondary.disabled, .indigo .btn-outline-secondary:disabled, .indigo .btn-outline-secondary:not(:disabled):not(.disabled):active, .indigo .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .indigo .btn-outline-secondary.dropdown-toggle,
  .indigo a.btn-outline-secondary:focus,
  .indigo a.btn-outline-secondary:hover,
  .indigo a.btn-outline-secondary.disabled,
  .indigo a.btn-outline-secondary:disabled,
  .indigo a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .indigo a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .indigo a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FF4081;
    background-color: #FF4081; }

.indigo a,
.indigo a.text-primary {
  color: #3f51b5; }
  .indigo a:focus, .indigo a:hover,
  .indigo a.text-primary:focus,
  .indigo a.text-primary:hover {
    color: #32408f; }

.indigo .text-primary {
  color: #3f51b5 !important; }

.indigo .page-link {
  color: #3f51b5; }
  .indigo .page-link:focus, .indigo .page-link:hover {
    color: #3f51b5; }

.indigo .bg-primary,
.indigo .badge-primary {
  background-color: #3f51b5 !important;
  color: #fff !important; }

.indigo .btn-primary,
.indigo .jr-btn-primary,
.indigo a.btn-primary,
.indigo a.jr-btn-primary {
  background-color: #3f51b5;
  border-color: #3f51b5;
  color: #fff; }
  .indigo .btn-primary:hover, .indigo .btn-primary:focus, .indigo .btn-primary:not([disabled]):not(.disabled):active,
  .indigo .jr-btn-primary:hover,
  .indigo .jr-btn-primary:focus,
  .indigo .jr-btn-primary:not([disabled]):not(.disabled):active,
  .indigo a.btn-primary:hover,
  .indigo a.btn-primary:focus,
  .indigo a.btn-primary:not([disabled]):not(.disabled):active,
  .indigo a.jr-btn-primary:hover,
  .indigo a.jr-btn-primary:focus,
  .indigo a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #3849a2 !important;
    border-color: #3849a2 !important;
    color: #fff !important; }

.indigo .bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.indigo .bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.indigo .bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.indigo .bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.indigo .bg-primary.darken-1 {
  background-color: #3949ab !important; }

.indigo .bg-primary.darken-2 {
  background-color: #303f9f !important; }

.indigo .bg-primary.darken-3 {
  background-color: #283593 !important; }

.indigo .bg-primary.darken-4 {
  background-color: #1a237e !important; }

.indigo .bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.indigo .bg-primary.accent-2 {
  background-color: #536dfe !important; }

.indigo .bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.indigo .bg-primary.accent-4 {
  background-color: #304ffe !important; }

.indigo a.text-secondary {
  color: #FF4081; }
  .indigo a.text-secondary:focus, .indigo a.text-secondary:hover {
    color: #ff0d5f !important; }

.indigo .text-secondary {
  color: #FF4081 !important; }

.indigo .bg-secondary,
.indigo .badge-secondary {
  background-color: #FF4081 !important;
  color: #fff !important; }

.indigo .btn-secondary {
  background-color: #FF4081;
  border-color: #FF4081;
  color: #fff; }
  .indigo .btn-secondary:hover, .indigo .btn-secondary:focus, .indigo .btn-secondary:active {
    background-color: #ff2770 !important;
    border-color: #ff2770 !important;
    color: #fff !important; }

.indigo .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .indigo .jr-flat-btn:hover, .indigo .jr-flat-btn:focus, .indigo .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.indigo .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #3f51b5 !important;
  border-color: transparent !important; }
  .indigo .jr-flat-btn.jr-btn-primary:hover, .indigo .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(63, 81, 181, 0.12) !important;
    color: #3f51b5 !important; }

.indigo .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .indigo .bg-primary .jr-flat-btn.jr-btn-primary:hover, .indigo .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.indigo .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FF4081 !important;
  border-color: transparent !important; }
  .indigo .jr-flat-btn.jr-btn-secondary:hover, .indigo .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 64, 129, 0.12) !important;
    color: #FF4081 !important; }

.indigo .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .indigo .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .indigo .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

@media screen and (max-width: 575px) {
  .indigo .app-main-header .search-dropdown input {
    background-color: #fff; } }

.indigo .color-theme-header {
  background-color: #3f51b5; }

.indigo .side-nav {
  background-color: #fff !important;
  color: #868e96 !important; }
  .indigo .side-nav .user-profile {
    background-color: #f8f9fa;
    position: relative;
    z-index: 2; }
    .indigo .side-nav .user-profile .text-white {
      color: #3f51b5 !important; }
  .indigo .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .indigo .side-nav .user-detail .user-name {
    color: #3f51b5; }

.indigo ul.nav-menu li a {
  color: #868e96; }

.indigo ul.nav-menu .nav-header {
  color: #868e96; }

.indigo ul.nav-menu > li.open > a,
.indigo ul.nav-menu > li > a:hover,
.indigo ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #3f51b5; }

.indigo ul.nav-menu li.menu > a:focus:before,
.indigo ul.nav-menu li.menu > a:hover:before {
  color: #3f51b5; }

.indigo ul.nav-menu li ul {
  background-color: #fff; }

.indigo ul.nav-menu li.menu > a:before {
  color: #868e96; }

.indigo ul.nav-menu li.menu.open > a:before,
.indigo ul.nav-menu li.menu > a:focus:before,
.indigo ul.nav-menu li.menu li > a:hover:before {
  color: #3f51b5; }

.indigo ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.indigo ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #3f51b5; }

.indigo ul.nav-menu li.menu .sub-menu li > a:hover,
.indigo ul.nav-menu li.menu .sub-menu li > a:focus,
.indigo ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.indigo ul.nav-menu li.menu .sub-menu li > a:focus:before,
.indigo ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #3f51b5; }

/*Header top Navbar Styles*/
.indigo .navbar-nav li:hover > a,
.indigo .navbar-nav li:focus > a {
  color: #3f51b5; }

.indigo .navbar-nav li a {
  color: #868e96; }

.indigo .navbar-nav li a:hover,
.indigo .navbar-nav li a:focus {
  color: #3f51b5; }

.indigo .navbar-nav li.nav-item.active > a {
  color: #3f51b5; }

.indigo .navbar-nav ul.sub-menu li a.active,
.indigo .navbar-nav ul.sub-menu li.active > a {
  color: #3f51b5; }

.indigo .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.indigo .navbar-nav .nav-arrow a:hover:before,
.indigo .navbar-nav .nav-arrow a:focus:before {
  color: #3f51b5; }

.indigo .navbar-nav .nav-arrow:hover > a:before,
.indigo .navbar-nav .nav-arrow:focus > a:before,
.indigo .navbar-nav .nav-arrow.active > a:before {
  color: #3f51b5; }

.indigo .app-top-nav {
  background-color: #3f51b5; }
  .indigo .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .indigo .app-top-nav .navbar-nav li.nav-item:hover > a,
  .indigo .app-top-nav .navbar-nav li.nav-item:focus > a,
  .indigo .app-top-nav .navbar-nav li.nav-item > a:hover,
  .indigo .app-top-nav .navbar-nav li.nav-item > a:focus,
  .indigo .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FF4081; }

.indigo .module-nav li a.active {
  border-color: #3f51b5; }

.indigo .rbc-event {
  background-color: #3f51b5; }

.indigo .rbc-event.rbc-selected {
  background-color: #32408f; }

.indigo .rbc-slot-selection {
  background-color: rgba(63, 81, 181, 0.7); }

.indigo .rbc-toolbar button:active,
.indigo .rbc-toolbar button.rbc-active {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:active:hover,
.indigo .rbc-toolbar button.rbc-active:hover,
.indigo .rbc-toolbar button:active:focus,
.indigo .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-toolbar button:hover {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.indigo .rbc-day-slot .rbc-event {
  border-color: #3f51b5; }

.indigo .chat-sidenav-title {
  color: #3f51b5; }

.indigo .chat-user-item.active, .indigo .chat-user-item:hover {
  background-color: #e4e7f6; }

.indigo .contact-list i {
  color: #3f51b5; }

.indigo .Collapsible__trigger {
  background: #3f51b5; }

.indigo .categories-list li a:focus,
.indigo .categories-list li a:hover,
.indigo .categories-list li.active a {
  color: #3f51b5; }

.indigo .login-content .form-control:focus {
  border-color: #3f51b5; }

.indigo .filter-with-bg-color ul li a {
  border-color: #3f51b5; }
  .indigo .filter-with-bg-color ul li a:hover, .indigo .filter-with-bg-color ul li a:focus, .indigo .filter-with-bg-color ul li a.active {
    background-color: #3f51b5; }

.indigo .profile-intro .icon {
  color: #3f51b5; }

.indigo .social-link li.active a,
.indigo .social-link li a:hover,
.indigo .social-link li a:focus {
  color: #FF4081; }

.indigo .actions {
  color: #FF4081; }

.indigo .table-hover tbody tr:hover {
  background-color: rgba(63, 81, 181, 0.075); }

.indigo .border-primary {
  border-color: #3f51b5 !important; }

.indigo .app-logo-content {
  background-color: #3f51b5; }

.indigo .app-social-block .social-link button {
  border: solid 1px #3f51b5;
  color: #3f51b5; }
  .indigo .app-social-block .social-link button:hover, .indigo .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #3f51b5; }

.indigo .btn-group > .jr-btn.active,
.indigo .btn-group > .btn-default.active,
.indigo .btn-group-vertical > .jr-btn.active,
.indigo .btn-group-vertical > .btn-default.active {
  background-color: #3f51b5;
  border-color: #3f51b5; }

.indigo .btn-group > .jr-flat-btn,
.indigo .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .indigo .btn-group > .jr-flat-btn.active,
  .indigo .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #3f51b5 !important;
    border-color: transparent !important; }
    .indigo .btn-group > .jr-flat-btn.active:hover, .indigo .btn-group > .jr-flat-btn.active:focus,
    .indigo .btn-group-vertical > .jr-flat-btn.active:hover,
    .indigo .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(63, 81, 181, 0.12) !important;
      color: #3f51b5 !important; }

.indigo .nav-pill-sales .nav-link.active {
  background-color: #3f51b5;
  border-color: #3f51b5; }

.indigo .form-checkbox > input + .check,
.indigo .form-checkbox > input.sm + .check {
  color: #3f51b5; }

/*Theme Cyan Styles*/
.cyan .right-arrow {
  color: #00bcd4; }
  .cyan .right-arrow:after {
    color: #00bcd4; }

.cyan .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .cyan .btn-primary:focus, .cyan .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .cyan .btn-primary.disabled, .cyan .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .cyan .btn-primary:not(:disabled):not(.disabled):active, .cyan .btn-primary:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .cyan .btn-primary:not(:disabled):not(.disabled):active:focus, .cyan .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.cyan .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .cyan .btn-secondary:focus, .cyan .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .cyan .btn-secondary.disabled, .cyan .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .cyan .btn-secondary:not(:disabled):not(.disabled):active, .cyan .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .cyan .btn-secondary:not(:disabled):not(.disabled):active:focus, .cyan .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.cyan .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .cyan .btn-success:focus, .cyan .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .cyan .btn-success.disabled, .cyan .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .cyan .btn-success:not(:disabled):not(.disabled):active, .cyan .btn-success:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .cyan .btn-success:not(:disabled):not(.disabled):active:focus, .cyan .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.cyan .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .cyan .btn-info:focus, .cyan .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .cyan .btn-info.disabled, .cyan .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .cyan .btn-info:not(:disabled):not(.disabled):active, .cyan .btn-info:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .cyan .btn-info:not(:disabled):not(.disabled):active:focus, .cyan .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.cyan .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .cyan .btn-warning:focus, .cyan .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .cyan .btn-warning.disabled, .cyan .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .cyan .btn-warning:not(:disabled):not(.disabled):active, .cyan .btn-warning:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .cyan .btn-warning:not(:disabled):not(.disabled):active:focus, .cyan .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.cyan .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .cyan .btn-danger:focus, .cyan .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .cyan .btn-danger.disabled, .cyan .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .cyan .btn-danger:not(:disabled):not(.disabled):active, .cyan .btn-danger:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .cyan .btn-danger:not(:disabled):not(.disabled):active:focus, .cyan .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.cyan .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .cyan .btn-light:focus, .cyan .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .cyan .btn-light.disabled, .cyan .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .cyan .btn-light:not(:disabled):not(.disabled):active, .cyan .btn-light:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .cyan .btn-light:not(:disabled):not(.disabled):active:focus, .cyan .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.cyan .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .cyan .btn-dark:focus, .cyan .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .cyan .btn-dark.disabled, .cyan .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .cyan .btn-dark:not(:disabled):not(.disabled):active, .cyan .btn-dark:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .cyan .btn-dark:not(:disabled):not(.disabled):active:focus, .cyan .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.cyan a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .cyan a.btn-primary:focus, .cyan a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .cyan a.btn-primary.disabled, .cyan a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .cyan a.btn-primary:not(:disabled):not(.disabled):active, .cyan a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .cyan a.btn-primary:not(:disabled):not(.disabled):active:focus, .cyan a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.cyan a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .cyan a.btn-secondary:focus, .cyan a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .cyan a.btn-secondary.disabled, .cyan a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .cyan a.btn-secondary:not(:disabled):not(.disabled):active, .cyan a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .cyan a.btn-secondary:not(:disabled):not(.disabled):active:focus, .cyan a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.cyan a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .cyan a.btn-success:focus, .cyan a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .cyan a.btn-success.disabled, .cyan a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .cyan a.btn-success:not(:disabled):not(.disabled):active, .cyan a.btn-success:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .cyan a.btn-success:not(:disabled):not(.disabled):active:focus, .cyan a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.cyan a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .cyan a.btn-info:focus, .cyan a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .cyan a.btn-info.disabled, .cyan a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .cyan a.btn-info:not(:disabled):not(.disabled):active, .cyan a.btn-info:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .cyan a.btn-info:not(:disabled):not(.disabled):active:focus, .cyan a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.cyan a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .cyan a.btn-warning:focus, .cyan a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .cyan a.btn-warning.disabled, .cyan a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .cyan a.btn-warning:not(:disabled):not(.disabled):active, .cyan a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .cyan a.btn-warning:not(:disabled):not(.disabled):active:focus, .cyan a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.cyan a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .cyan a.btn-danger:focus, .cyan a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .cyan a.btn-danger.disabled, .cyan a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .cyan a.btn-danger:not(:disabled):not(.disabled):active, .cyan a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .cyan a.btn-danger:not(:disabled):not(.disabled):active:focus, .cyan a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.cyan a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .cyan a.btn-light:focus, .cyan a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .cyan a.btn-light.disabled, .cyan a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .cyan a.btn-light:not(:disabled):not(.disabled):active, .cyan a.btn-light:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .cyan a.btn-light:not(:disabled):not(.disabled):active:focus, .cyan a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.cyan a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .cyan a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .cyan a.btn-dark:focus, .cyan a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .cyan a.btn-dark.disabled, .cyan a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .cyan a.btn-dark:not(:disabled):not(.disabled):active, .cyan a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .cyan a.btn-dark:not(:disabled):not(.disabled):active:focus, .cyan a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.cyan .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .cyan .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .cyan .btn-outline-primary:focus, .cyan .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .cyan .btn-outline-primary.disabled, .cyan .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .cyan .btn-outline-primary:not(:disabled):not(.disabled):active, .cyan .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .cyan .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .cyan .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.cyan .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .cyan .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .cyan .btn-outline-secondary:focus, .cyan .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .cyan .btn-outline-secondary.disabled, .cyan .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .cyan .btn-outline-secondary:not(:disabled):not(.disabled):active, .cyan .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .cyan .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .cyan .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.cyan .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .cyan .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .cyan .btn-outline-success:focus, .cyan .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .cyan .btn-outline-success.disabled, .cyan .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .cyan .btn-outline-success:not(:disabled):not(.disabled):active, .cyan .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .cyan .btn-outline-success:not(:disabled):not(.disabled):active:focus, .cyan .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.cyan .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .cyan .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .cyan .btn-outline-info:focus, .cyan .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .cyan .btn-outline-info.disabled, .cyan .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .cyan .btn-outline-info:not(:disabled):not(.disabled):active, .cyan .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .cyan .btn-outline-info:not(:disabled):not(.disabled):active:focus, .cyan .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.cyan .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .cyan .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .cyan .btn-outline-warning:focus, .cyan .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .cyan .btn-outline-warning.disabled, .cyan .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .cyan .btn-outline-warning:not(:disabled):not(.disabled):active, .cyan .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .cyan .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .cyan .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.cyan .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .cyan .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .cyan .btn-outline-danger:focus, .cyan .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .cyan .btn-outline-danger.disabled, .cyan .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .cyan .btn-outline-danger:not(:disabled):not(.disabled):active, .cyan .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .cyan .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .cyan .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.cyan .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .cyan .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .cyan .btn-outline-light:focus, .cyan .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .cyan .btn-outline-light.disabled, .cyan .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .cyan .btn-outline-light:not(:disabled):not(.disabled):active, .cyan .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .cyan .btn-outline-light:not(:disabled):not(.disabled):active:focus, .cyan .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.cyan .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .cyan .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .cyan .btn-outline-dark:focus, .cyan .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .cyan .btn-outline-dark.disabled, .cyan .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .cyan .btn-outline-dark:not(:disabled):not(.disabled):active, .cyan .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .cyan .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .cyan .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .cyan .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.cyan a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .cyan a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .cyan a.btn-outline-primary:focus, .cyan a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .cyan a.btn-outline-primary.disabled, .cyan a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .cyan a.btn-outline-primary:not(:disabled):not(.disabled):active, .cyan a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .cyan a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .cyan a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.cyan a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .cyan a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .cyan a.btn-outline-secondary:focus, .cyan a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .cyan a.btn-outline-secondary.disabled, .cyan a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .cyan a.btn-outline-secondary:not(:disabled):not(.disabled):active, .cyan a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .cyan a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .cyan a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.cyan a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .cyan a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .cyan a.btn-outline-success:focus, .cyan a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .cyan a.btn-outline-success.disabled, .cyan a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .cyan a.btn-outline-success:not(:disabled):not(.disabled):active, .cyan a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .cyan a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .cyan a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.cyan a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .cyan a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .cyan a.btn-outline-info:focus, .cyan a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .cyan a.btn-outline-info.disabled, .cyan a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .cyan a.btn-outline-info:not(:disabled):not(.disabled):active, .cyan a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .cyan a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .cyan a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.cyan a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .cyan a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .cyan a.btn-outline-warning:focus, .cyan a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .cyan a.btn-outline-warning.disabled, .cyan a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .cyan a.btn-outline-warning:not(:disabled):not(.disabled):active, .cyan a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .cyan a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .cyan a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.cyan a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .cyan a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .cyan a.btn-outline-danger:focus, .cyan a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .cyan a.btn-outline-danger.disabled, .cyan a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .cyan a.btn-outline-danger:not(:disabled):not(.disabled):active, .cyan a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .cyan a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .cyan a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.cyan a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .cyan a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .cyan a.btn-outline-light:focus, .cyan a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .cyan a.btn-outline-light.disabled, .cyan a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .cyan a.btn-outline-light:not(:disabled):not(.disabled):active, .cyan a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .cyan a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .cyan a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.cyan a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .cyan a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .cyan a.btn-outline-dark:focus, .cyan a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .cyan a.btn-outline-dark.disabled, .cyan a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .cyan a.btn-outline-dark:not(:disabled):not(.disabled):active, .cyan a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .cyan a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .cyan a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .cyan a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .cyan a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.cyan .btn-outline-primary,
.cyan a.btn-outline-primary {
  color: #00bcd4;
  border-color: #00bcd4; }
  .cyan .btn-outline-primary:focus, .cyan .btn-outline-primary:hover, .cyan .btn-outline-primary.disabled, .cyan .btn-outline-primary:disabled, .cyan .btn-outline-primary:not(:disabled):not(.disabled):active, .cyan .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-primary.dropdown-toggle,
  .cyan a.btn-outline-primary:focus,
  .cyan a.btn-outline-primary:hover,
  .cyan a.btn-outline-primary.disabled,
  .cyan a.btn-outline-primary:disabled,
  .cyan a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .cyan a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .cyan a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #00bcd4;
    background-color: #00bcd4; }

.cyan .btn-outline-secondary,
.cyan a.btn-outline-secondary {
  color: #69F0AE;
  border-color: #69F0AE; }
  .cyan .btn-outline-secondary:focus, .cyan .btn-outline-secondary:hover, .cyan .btn-outline-secondary.disabled, .cyan .btn-outline-secondary:disabled, .cyan .btn-outline-secondary:not(:disabled):not(.disabled):active, .cyan .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .cyan .btn-outline-secondary.dropdown-toggle,
  .cyan a.btn-outline-secondary:focus,
  .cyan a.btn-outline-secondary:hover,
  .cyan a.btn-outline-secondary.disabled,
  .cyan a.btn-outline-secondary:disabled,
  .cyan a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .cyan a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .cyan a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #69F0AE;
    background-color: #69F0AE; }

.cyan a,
.cyan a.text-primary {
  color: #00bcd4; }
  .cyan a:focus, .cyan a:hover,
  .cyan a.text-primary:focus,
  .cyan a.text-primary:hover {
    color: #008fa1; }

.cyan .text-primary {
  color: #00bcd4 !important; }

.cyan .page-link {
  color: #00bcd4; }
  .cyan .page-link:focus, .cyan .page-link:hover {
    color: #00bcd4; }

.cyan .bg-primary,
.cyan .badge-primary {
  background-color: #00bcd4 !important;
  color: #fff !important; }

.cyan .btn-primary,
.cyan .jr-btn-primary,
.cyan a.btn-primary,
.cyan a.jr-btn-primary {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff; }
  .cyan .btn-primary:hover, .cyan .btn-primary:focus, .cyan .btn-primary:not([disabled]):not(.disabled):active,
  .cyan .jr-btn-primary:hover,
  .cyan .jr-btn-primary:focus,
  .cyan .jr-btn-primary:not([disabled]):not(.disabled):active,
  .cyan a.btn-primary:hover,
  .cyan a.btn-primary:focus,
  .cyan a.btn-primary:not([disabled]):not(.disabled):active,
  .cyan a.jr-btn-primary:hover,
  .cyan a.jr-btn-primary:focus,
  .cyan a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #00a5bb !important;
    border-color: #00a5bb !important;
    color: #fff !important; }

.cyan .bg-primary.lighten-4 {
  background-color: #b2ebf2 !important; }

.cyan .bg-primary.lighten-3 {
  background-color: #80deea !important; }

.cyan .bg-primary.lighten-2 {
  background-color: #4dd0e1 !important; }

.cyan .bg-primary.lighten-1 {
  background-color: #26c6da !important; }

.cyan .bg-primary.darken-1 {
  background-color: #00acc1 !important; }

.cyan .bg-primary.darken-2 {
  background-color: #0097a7 !important; }

.cyan .bg-primary.darken-3 {
  background-color: #00838f !important; }

.cyan .bg-primary.darken-4 {
  background-color: #006064 !important; }

.cyan .bg-primary.accent-1 {
  background-color: #84ffff !important; }

.cyan .bg-primary.accent-2 {
  background-color: #18ffff !important; }

.cyan .bg-primary.accent-3 {
  background-color: #00e5ff !important; }

.cyan .bg-primary.accent-4 {
  background-color: #00b8d4 !important; }

.cyan a.text-secondary {
  color: #69F0AE; }
  .cyan a.text-secondary:focus, .cyan a.text-secondary:hover {
    color: #3beb95 !important; }

.cyan .text-secondary {
  color: #69F0AE !important; }

.cyan .bg-secondary,
.cyan .badge-secondary {
  background-color: #69F0AE !important;
  color: #fff !important; }

.cyan .btn-secondary {
  background-color: #69F0AE;
  border-color: #69F0AE;
  color: #fff; }
  .cyan .btn-secondary:hover, .cyan .btn-secondary:focus, .cyan .btn-secondary:active {
    background-color: #52eea1 !important;
    border-color: #52eea1 !important;
    color: #fff !important; }

.cyan .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .cyan .jr-flat-btn:hover, .cyan .jr-flat-btn:focus, .cyan .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.cyan .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #00bcd4 !important;
  border-color: transparent !important; }
  .cyan .jr-flat-btn.jr-btn-primary:hover, .cyan .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(0, 188, 212, 0.12) !important;
    color: #00bcd4 !important; }

.cyan .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .cyan .bg-primary .jr-flat-btn.jr-btn-primary:hover, .cyan .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.cyan .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #69F0AE !important;
  border-color: transparent !important; }
  .cyan .jr-flat-btn.jr-btn-secondary:hover, .cyan .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(105, 240, 174, 0.12) !important;
    color: #69F0AE !important; }

.cyan .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .cyan .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .cyan .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

@media screen and (max-width: 575px) {
  .cyan .app-main-header .search-dropdown input {
    background-color: #fff; } }

.cyan .color-theme-header {
  background-color: #00bcd4; }

.cyan .side-nav {
  background-color: #fff !important;
  color: #868e96 !important; }
  .cyan .side-nav .user-profile {
    background-color: #f8f9fa;
    position: relative;
    z-index: 2; }
    .cyan .side-nav .user-profile .text-white {
      color: #00bcd4 !important; }
  .cyan .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .cyan .side-nav .user-detail .user-name {
    color: #00bcd4; }

.cyan ul.nav-menu li a {
  color: #868e96; }

.cyan ul.nav-menu .nav-header {
  color: #868e96; }

.cyan ul.nav-menu > li.open > a,
.cyan ul.nav-menu > li > a:hover,
.cyan ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #00bcd4; }

.cyan ul.nav-menu li.menu > a:focus:before,
.cyan ul.nav-menu li.menu > a:hover:before {
  color: #00bcd4; }

.cyan ul.nav-menu li ul {
  background-color: #fff; }

.cyan ul.nav-menu li.menu > a:before {
  color: #868e96; }

.cyan ul.nav-menu li.menu.open > a:before,
.cyan ul.nav-menu li.menu > a:focus:before,
.cyan ul.nav-menu li.menu li > a:hover:before {
  color: #00bcd4; }

.cyan ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.cyan ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #00bcd4; }

.cyan ul.nav-menu li.menu .sub-menu li > a:hover,
.cyan ul.nav-menu li.menu .sub-menu li > a:focus,
.cyan ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.cyan ul.nav-menu li.menu .sub-menu li > a:focus:before,
.cyan ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #00bcd4; }

/*Header top Navbar Styles*/
.cyan .navbar-nav li:hover > a,
.cyan .navbar-nav li:focus > a {
  color: #00bcd4; }

.cyan .navbar-nav li a {
  color: #868e96; }

.cyan .navbar-nav li a:hover,
.cyan .navbar-nav li a:focus {
  color: #00bcd4; }

.cyan .navbar-nav li.nav-item.active > a {
  color: #00bcd4; }

.cyan .navbar-nav ul.sub-menu li a.active,
.cyan .navbar-nav ul.sub-menu li.active > a {
  color: #00bcd4; }

.cyan .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.cyan .navbar-nav .nav-arrow a:hover:before,
.cyan .navbar-nav .nav-arrow a:focus:before {
  color: #00bcd4; }

.cyan .navbar-nav .nav-arrow:hover > a:before,
.cyan .navbar-nav .nav-arrow:focus > a:before,
.cyan .navbar-nav .nav-arrow.active > a:before {
  color: #00bcd4; }

.cyan .app-top-nav {
  background-color: #00bcd4; }
  .cyan .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .cyan .app-top-nav .navbar-nav li.nav-item:hover > a,
  .cyan .app-top-nav .navbar-nav li.nav-item:focus > a,
  .cyan .app-top-nav .navbar-nav li.nav-item > a:hover,
  .cyan .app-top-nav .navbar-nav li.nav-item > a:focus,
  .cyan .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #69F0AE; }

.cyan .module-nav li a.active {
  border-color: #00bcd4; }

.cyan .rbc-event {
  background-color: #00bcd4; }

.cyan .rbc-event.rbc-selected {
  background-color: #008fa1; }

.cyan .rbc-slot-selection {
  background-color: rgba(0, 188, 212, 0.7); }

.cyan .rbc-toolbar button:active,
.cyan .rbc-toolbar button.rbc-active {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:active:hover,
.cyan .rbc-toolbar button.rbc-active:hover,
.cyan .rbc-toolbar button:active:focus,
.cyan .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-toolbar button:hover {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.cyan .rbc-day-slot .rbc-event {
  border-color: #00bcd4; }

.cyan .chat-sidenav-title {
  color: #00bcd4; }

.cyan .chat-user-item.active, .cyan .chat-user-item:hover {
  background-color: #bbf7ff; }

.cyan .contact-list i {
  color: #00bcd4; }

.cyan .Collapsible__trigger {
  background: #00bcd4; }

.cyan .categories-list li a:focus,
.cyan .categories-list li a:hover,
.cyan .categories-list li.active a {
  color: #00bcd4; }

.cyan .login-content .form-control:focus {
  border-color: #00bcd4; }

.cyan .filter-with-bg-color ul li a {
  border-color: #00bcd4; }
  .cyan .filter-with-bg-color ul li a:hover, .cyan .filter-with-bg-color ul li a:focus, .cyan .filter-with-bg-color ul li a.active {
    background-color: #00bcd4; }

.cyan .profile-intro .icon {
  color: #00bcd4; }

.cyan .social-link li.active a,
.cyan .social-link li a:hover,
.cyan .social-link li a:focus {
  color: #69F0AE; }

.cyan .actions {
  color: #69F0AE; }

.cyan .table-hover tbody tr:hover {
  background-color: rgba(0, 188, 212, 0.075); }

.cyan .border-primary {
  border-color: #00bcd4 !important; }

.cyan .app-logo-content {
  background-color: #00bcd4; }

.cyan .app-social-block .social-link button {
  border: solid 1px #00bcd4;
  color: #00bcd4; }
  .cyan .app-social-block .social-link button:hover, .cyan .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #00bcd4; }

.cyan .btn-group > .jr-btn.active,
.cyan .btn-group > .btn-default.active,
.cyan .btn-group-vertical > .jr-btn.active,
.cyan .btn-group-vertical > .btn-default.active {
  background-color: #00bcd4;
  border-color: #00bcd4; }

.cyan .btn-group > .jr-flat-btn,
.cyan .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .cyan .btn-group > .jr-flat-btn.active,
  .cyan .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #00bcd4 !important;
    border-color: transparent !important; }
    .cyan .btn-group > .jr-flat-btn.active:hover, .cyan .btn-group > .jr-flat-btn.active:focus,
    .cyan .btn-group-vertical > .jr-flat-btn.active:hover,
    .cyan .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(0, 188, 212, 0.12) !important;
      color: #00bcd4 !important; }

.cyan .nav-pill-sales .nav-link.active {
  background-color: #00bcd4;
  border-color: #00bcd4; }

.cyan .form-checkbox > input + .check,
.cyan .form-checkbox > input.sm + .check {
  color: #00bcd4; }

/*Theme Amber Styles*/
.amber ul.nav-menu li.open > a {
  border-color: #FFC107; }

.amber .right-arrow {
  color: #FFC107; }
  .amber .right-arrow:after {
    color: #FFC107; }

.amber .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .amber .btn-primary:focus, .amber .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .amber .btn-primary.disabled, .amber .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .amber .btn-primary:not(:disabled):not(.disabled):active, .amber .btn-primary:not(:disabled):not(.disabled).active,
  .show > .amber .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .amber .btn-primary:not(:disabled):not(.disabled):active:focus, .amber .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.amber .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .amber .btn-secondary:focus, .amber .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .amber .btn-secondary.disabled, .amber .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .amber .btn-secondary:not(:disabled):not(.disabled):active, .amber .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .amber .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .amber .btn-secondary:not(:disabled):not(.disabled):active:focus, .amber .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.amber .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .amber .btn-success:focus, .amber .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .amber .btn-success.disabled, .amber .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .amber .btn-success:not(:disabled):not(.disabled):active, .amber .btn-success:not(:disabled):not(.disabled).active,
  .show > .amber .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .amber .btn-success:not(:disabled):not(.disabled):active:focus, .amber .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.amber .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .amber .btn-info:focus, .amber .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .amber .btn-info.disabled, .amber .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .amber .btn-info:not(:disabled):not(.disabled):active, .amber .btn-info:not(:disabled):not(.disabled).active,
  .show > .amber .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .amber .btn-info:not(:disabled):not(.disabled):active:focus, .amber .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.amber .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .amber .btn-warning:focus, .amber .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .amber .btn-warning.disabled, .amber .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .amber .btn-warning:not(:disabled):not(.disabled):active, .amber .btn-warning:not(:disabled):not(.disabled).active,
  .show > .amber .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .amber .btn-warning:not(:disabled):not(.disabled):active:focus, .amber .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.amber .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .amber .btn-danger:focus, .amber .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .amber .btn-danger.disabled, .amber .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .amber .btn-danger:not(:disabled):not(.disabled):active, .amber .btn-danger:not(:disabled):not(.disabled).active,
  .show > .amber .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .amber .btn-danger:not(:disabled):not(.disabled):active:focus, .amber .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.amber .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .amber .btn-light:focus, .amber .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .amber .btn-light.disabled, .amber .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .amber .btn-light:not(:disabled):not(.disabled):active, .amber .btn-light:not(:disabled):not(.disabled).active,
  .show > .amber .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .amber .btn-light:not(:disabled):not(.disabled):active:focus, .amber .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.amber .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .amber .btn-dark:focus, .amber .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .amber .btn-dark.disabled, .amber .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .amber .btn-dark:not(:disabled):not(.disabled):active, .amber .btn-dark:not(:disabled):not(.disabled).active,
  .show > .amber .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .amber .btn-dark:not(:disabled):not(.disabled):active:focus, .amber .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.amber a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .amber a.btn-primary:focus, .amber a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .amber a.btn-primary.disabled, .amber a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .amber a.btn-primary:not(:disabled):not(.disabled):active, .amber a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .amber a.btn-primary:not(:disabled):not(.disabled):active:focus, .amber a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.amber a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .amber a.btn-secondary:focus, .amber a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .amber a.btn-secondary.disabled, .amber a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .amber a.btn-secondary:not(:disabled):not(.disabled):active, .amber a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .amber a.btn-secondary:not(:disabled):not(.disabled):active:focus, .amber a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.amber a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .amber a.btn-success:focus, .amber a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .amber a.btn-success.disabled, .amber a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .amber a.btn-success:not(:disabled):not(.disabled):active, .amber a.btn-success:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .amber a.btn-success:not(:disabled):not(.disabled):active:focus, .amber a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.amber a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .amber a.btn-info:focus, .amber a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .amber a.btn-info.disabled, .amber a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .amber a.btn-info:not(:disabled):not(.disabled):active, .amber a.btn-info:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .amber a.btn-info:not(:disabled):not(.disabled):active:focus, .amber a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.amber a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .amber a.btn-warning:focus, .amber a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .amber a.btn-warning.disabled, .amber a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .amber a.btn-warning:not(:disabled):not(.disabled):active, .amber a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .amber a.btn-warning:not(:disabled):not(.disabled):active:focus, .amber a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.amber a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .amber a.btn-danger:focus, .amber a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .amber a.btn-danger.disabled, .amber a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .amber a.btn-danger:not(:disabled):not(.disabled):active, .amber a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .amber a.btn-danger:not(:disabled):not(.disabled):active:focus, .amber a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.amber a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .amber a.btn-light:focus, .amber a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .amber a.btn-light.disabled, .amber a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .amber a.btn-light:not(:disabled):not(.disabled):active, .amber a.btn-light:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .amber a.btn-light:not(:disabled):not(.disabled):active:focus, .amber a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.amber a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .amber a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .amber a.btn-dark:focus, .amber a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .amber a.btn-dark.disabled, .amber a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .amber a.btn-dark:not(:disabled):not(.disabled):active, .amber a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .amber a.btn-dark:not(:disabled):not(.disabled):active:focus, .amber a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.amber .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .amber .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .amber .btn-outline-primary:focus, .amber .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .amber .btn-outline-primary.disabled, .amber .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .amber .btn-outline-primary:not(:disabled):not(.disabled):active, .amber .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .amber .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .amber .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.amber .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .amber .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .amber .btn-outline-secondary:focus, .amber .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .amber .btn-outline-secondary.disabled, .amber .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .amber .btn-outline-secondary:not(:disabled):not(.disabled):active, .amber .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .amber .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .amber .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.amber .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .amber .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .amber .btn-outline-success:focus, .amber .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .amber .btn-outline-success.disabled, .amber .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .amber .btn-outline-success:not(:disabled):not(.disabled):active, .amber .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .amber .btn-outline-success:not(:disabled):not(.disabled):active:focus, .amber .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.amber .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .amber .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .amber .btn-outline-info:focus, .amber .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .amber .btn-outline-info.disabled, .amber .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .amber .btn-outline-info:not(:disabled):not(.disabled):active, .amber .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .amber .btn-outline-info:not(:disabled):not(.disabled):active:focus, .amber .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.amber .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .amber .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .amber .btn-outline-warning:focus, .amber .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .amber .btn-outline-warning.disabled, .amber .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .amber .btn-outline-warning:not(:disabled):not(.disabled):active, .amber .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .amber .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .amber .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.amber .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .amber .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .amber .btn-outline-danger:focus, .amber .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .amber .btn-outline-danger.disabled, .amber .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .amber .btn-outline-danger:not(:disabled):not(.disabled):active, .amber .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .amber .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .amber .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.amber .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .amber .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .amber .btn-outline-light:focus, .amber .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .amber .btn-outline-light.disabled, .amber .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .amber .btn-outline-light:not(:disabled):not(.disabled):active, .amber .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .amber .btn-outline-light:not(:disabled):not(.disabled):active:focus, .amber .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.amber .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .amber .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .amber .btn-outline-dark:focus, .amber .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .amber .btn-outline-dark.disabled, .amber .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .amber .btn-outline-dark:not(:disabled):not(.disabled):active, .amber .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .amber .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .amber .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .amber .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.amber a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .amber a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .amber a.btn-outline-primary:focus, .amber a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .amber a.btn-outline-primary.disabled, .amber a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .amber a.btn-outline-primary:not(:disabled):not(.disabled):active, .amber a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .amber a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .amber a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.amber a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .amber a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .amber a.btn-outline-secondary:focus, .amber a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .amber a.btn-outline-secondary.disabled, .amber a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .amber a.btn-outline-secondary:not(:disabled):not(.disabled):active, .amber a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .amber a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .amber a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.amber a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .amber a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .amber a.btn-outline-success:focus, .amber a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .amber a.btn-outline-success.disabled, .amber a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .amber a.btn-outline-success:not(:disabled):not(.disabled):active, .amber a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .amber a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .amber a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.amber a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .amber a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .amber a.btn-outline-info:focus, .amber a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .amber a.btn-outline-info.disabled, .amber a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .amber a.btn-outline-info:not(:disabled):not(.disabled):active, .amber a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .amber a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .amber a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.amber a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .amber a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .amber a.btn-outline-warning:focus, .amber a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .amber a.btn-outline-warning.disabled, .amber a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .amber a.btn-outline-warning:not(:disabled):not(.disabled):active, .amber a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .amber a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .amber a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.amber a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .amber a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .amber a.btn-outline-danger:focus, .amber a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .amber a.btn-outline-danger.disabled, .amber a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .amber a.btn-outline-danger:not(:disabled):not(.disabled):active, .amber a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .amber a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .amber a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.amber a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .amber a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .amber a.btn-outline-light:focus, .amber a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .amber a.btn-outline-light.disabled, .amber a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .amber a.btn-outline-light:not(:disabled):not(.disabled):active, .amber a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .amber a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .amber a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.amber a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .amber a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .amber a.btn-outline-dark:focus, .amber a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .amber a.btn-outline-dark.disabled, .amber a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .amber a.btn-outline-dark:not(:disabled):not(.disabled):active, .amber a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .amber a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .amber a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .amber a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .amber a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.amber .btn-outline-primary,
.amber a.btn-outline-primary {
  color: #FFC107;
  border-color: #FFC107; }
  .amber .btn-outline-primary:focus, .amber .btn-outline-primary:hover, .amber .btn-outline-primary.disabled, .amber .btn-outline-primary:disabled, .amber .btn-outline-primary:not(:disabled):not(.disabled):active, .amber .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-primary.dropdown-toggle,
  .amber a.btn-outline-primary:focus,
  .amber a.btn-outline-primary:hover,
  .amber a.btn-outline-primary.disabled,
  .amber a.btn-outline-primary:disabled,
  .amber a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .amber a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .amber a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #FFC107;
    background-color: #FFC107; }

.amber .btn-outline-secondary,
.amber a.btn-outline-secondary {
  color: #FF3D00;
  border-color: #FF3D00; }
  .amber .btn-outline-secondary:focus, .amber .btn-outline-secondary:hover, .amber .btn-outline-secondary.disabled, .amber .btn-outline-secondary:disabled, .amber .btn-outline-secondary:not(:disabled):not(.disabled):active, .amber .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .amber .btn-outline-secondary.dropdown-toggle,
  .amber a.btn-outline-secondary:focus,
  .amber a.btn-outline-secondary:hover,
  .amber a.btn-outline-secondary.disabled,
  .amber a.btn-outline-secondary:disabled,
  .amber a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .amber a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .amber a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FF3D00;
    background-color: #FF3D00; }

.amber a,
.amber a.text-primary {
  color: #FFC107; }
  .amber a:focus, .amber a:hover,
  .amber a.text-primary:focus,
  .amber a.text-primary:hover {
    color: #d39e00; }

.amber .text-primary {
  color: #FFC107 !important; }

.amber .page-link {
  color: #FFC107; }
  .amber .page-link:focus, .amber .page-link:hover {
    color: #FFC107; }

.amber .bg-primary,
.amber .badge-primary {
  background-color: #FFC107 !important;
  color: #fff !important; }

.amber .btn-primary,
.amber .jr-btn-primary,
.amber a.btn-primary,
.amber a.jr-btn-primary {
  background-color: #FFC107;
  border-color: #FFC107;
  color: #fff; }
  .amber .btn-primary:hover, .amber .btn-primary:focus, .amber .btn-primary:not([disabled]):not(.disabled):active,
  .amber .jr-btn-primary:hover,
  .amber .jr-btn-primary:focus,
  .amber .jr-btn-primary:not([disabled]):not(.disabled):active,
  .amber a.btn-primary:hover,
  .amber a.btn-primary:focus,
  .amber a.btn-primary:not([disabled]):not(.disabled):active,
  .amber a.jr-btn-primary:hover,
  .amber a.jr-btn-primary:focus,
  .amber a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #edb100 !important;
    border-color: #edb100 !important;
    color: #fff !important; }

.amber .bg-primary.lighten-4 {
  background-color: #ffecb3 !important; }

.amber .bg-primary.lighten-3 {
  background-color: #ffe082 !important; }

.amber .bg-primary.lighten-2 {
  background-color: #ffd54f !important; }

.amber .bg-primary.lighten-1 {
  background-color: #ffca28 !important; }

.amber .bg-primary.darken-1 {
  background-color: #ffb300 !important; }

.amber .bg-primary.darken-2 {
  background-color: #ffa000 !important; }

.amber .bg-primary.darken-3 {
  background-color: #ff8f00 !important; }

.amber .bg-primary.darken-4 {
  background-color: #ff6f00 !important; }

.amber .bg-primary.accent-1 {
  background-color: #ffe57f !important; }

.amber .bg-primary.accent-2 {
  background-color: #ffd740 !important; }

.amber .bg-primary.accent-3 {
  background-color: #ffc400 !important; }

.amber .bg-primary.accent-4 {
  background-color: #ffab00 !important; }

.amber a.text-secondary {
  color: #FF3D00; }
  .amber a.text-secondary:focus, .amber a.text-secondary:hover {
    color: #cc3100 !important; }

.amber .text-secondary {
  color: #FF3D00 !important; }

.amber .bg-secondary,
.amber .badge-secondary {
  background-color: #FF3D00 !important;
  color: #fff !important; }

.amber .btn-secondary {
  background-color: #FF3D00;
  border-color: #FF3D00;
  color: #fff; }
  .amber .btn-secondary:hover, .amber .btn-secondary:focus, .amber .btn-secondary:active {
    background-color: #e63700 !important;
    border-color: #e63700 !important;
    color: #fff !important; }

.amber .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .amber .jr-flat-btn:hover, .amber .jr-flat-btn:focus, .amber .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.amber .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #FFC107 !important;
  border-color: transparent !important; }
  .amber .jr-flat-btn.jr-btn-primary:hover, .amber .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 193, 7, 0.12) !important;
    color: #FFC107 !important; }

.amber .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .amber .bg-primary .jr-flat-btn.jr-btn-primary:hover, .amber .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.amber .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FF3D00 !important;
  border-color: transparent !important; }
  .amber .jr-flat-btn.jr-btn-secondary:hover, .amber .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 61, 0, 0.12) !important;
    color: #FF3D00 !important; }

.amber .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .amber .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .amber .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

@media screen and (max-width: 575px) {
  .amber .app-main-header .search-dropdown input {
    background-color: #fff; } }

.amber .color-theme-header {
  background-color: #FFC107; }

.amber .side-nav {
  background-color: #fff !important;
  color: #868e96 !important; }
  .amber .side-nav .user-profile {
    background-color: #f8f9fa; }
    .amber .side-nav .user-profile .text-white {
      color: #FFC107 !important; }
  .amber .side-nav .user-detail .user-name {
    color: #FFC107; }
  .amber .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }

.amber ul.nav-menu li a {
  color: #868e96; }

.amber ul.nav-menu .nav-header {
  color: #868e96; }

.amber ul.nav-menu > li.open > a,
.amber ul.nav-menu > li > a:hover,
.amber ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #FFC107; }

.amber ul.nav-menu li.menu > a:focus:before,
.amber ul.nav-menu li.menu > a:hover:before {
  color: #FFC107; }

.amber ul.nav-menu li ul {
  background-color: #fff; }

.amber ul.nav-menu li.menu > a:before {
  color: #868e96; }

.amber ul.nav-menu li.menu.open > a:before,
.amber ul.nav-menu li.menu > a:focus:before,
.amber ul.nav-menu li.menu li > a:hover:before {
  color: #FFC107; }

.amber ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.amber ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #FFC107; }

.amber ul.nav-menu li.menu .sub-menu li > a:hover,
.amber ul.nav-menu li.menu .sub-menu li > a:focus,
.amber ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.amber ul.nav-menu li.menu .sub-menu li > a:focus:before,
.amber ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #FFC107; }

/*Header top Navbar Styles*/
.amber .navbar-nav li:hover > a,
.amber .navbar-nav li:focus > a {
  color: #FFC107; }

.amber .navbar-nav li a {
  color: #868e96; }

.amber .navbar-nav li a:hover,
.amber .navbar-nav li a:focus {
  color: #FFC107; }

.amber .navbar-nav li.nav-item.active > a {
  color: #FFC107; }

.amber .navbar-nav ul.sub-menu li a.active,
.amber .navbar-nav ul.sub-menu li.active > a {
  color: #FFC107; }

.amber .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.amber .navbar-nav .nav-arrow a:hover:before,
.amber .navbar-nav .nav-arrow a:focus:before {
  color: #FFC107; }

.amber .navbar-nav .nav-arrow:hover > a:before,
.amber .navbar-nav .nav-arrow:focus > a:before,
.amber .navbar-nav .nav-arrow.active > a:before {
  color: #FFC107; }

.amber .app-top-nav {
  background-color: #FFC107; }
  .amber .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .amber .app-top-nav .navbar-nav li.nav-item:hover > a,
  .amber .app-top-nav .navbar-nav li.nav-item:focus > a,
  .amber .app-top-nav .navbar-nav li.nav-item > a:hover,
  .amber .app-top-nav .navbar-nav li.nav-item > a:focus,
  .amber .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FF3D00; }

.amber .module-nav li a.active {
  border-color: #FFC107; }

.amber .rbc-event {
  background-color: #FFC107; }

.amber .rbc-event.rbc-selected {
  background-color: #d39e00; }

.amber .rbc-slot-selection {
  background-color: rgba(255, 193, 7, 0.7); }

.amber .rbc-toolbar button:active,
.amber .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:active:hover,
.amber .rbc-toolbar button.rbc-active:hover,
.amber .rbc-toolbar button:active:focus,
.amber .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-toolbar button:hover {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #FFC107; }

.amber .rbc-day-slot .rbc-event {
  border-color: #FFC107; }

.amber .chat-sidenav-title {
  color: #FFC107; }

.amber .chat-user-item.active, .amber .chat-user-item:hover {
  background-color: #fffaed; }

.amber .contact-list i {
  color: #FFC107; }

.amber .Collapsible__trigger {
  background: #FFC107; }

.amber .categories-list li a:focus,
.amber .categories-list li a:hover,
.amber .categories-list li.active a {
  color: #FFC107; }

.amber .login-content .form-control:focus {
  border-color: #FFC107; }

.amber .filter-with-bg-color ul li a {
  border-color: #FFC107; }
  .amber .filter-with-bg-color ul li a:hover, .amber .filter-with-bg-color ul li a:focus, .amber .filter-with-bg-color ul li a.active {
    background-color: #FFC107; }

.amber .profile-intro .icon {
  color: #FFC107; }

.amber .social-link li.active a,
.amber .social-link li a:hover,
.amber .social-link li a:focus {
  color: #FF3D00; }

.amber .actions {
  color: #FF3D00; }

.amber .table-hover tbody tr:hover {
  background-color: rgba(255, 193, 7, 0.075); }

.amber .border-primary {
  border-color: #FFC107 !important; }

.amber .app-logo-content {
  background-color: #FFC107; }

.amber .app-social-block .social-link button {
  border: solid 1px #FFC107;
  color: #FFC107; }
  .amber .app-social-block .social-link button:hover, .amber .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #FFC107; }

.amber .btn-group > .jr-btn.active,
.amber .btn-group > .btn-default.active,
.amber .btn-group-vertical > .jr-btn.active,
.amber .btn-group-vertical > .btn-default.active {
  background-color: #FFC107;
  border-color: #FFC107; }

.amber .btn-group > .jr-flat-btn,
.amber .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .amber .btn-group > .jr-flat-btn.active,
  .amber .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #FFC107 !important;
    border-color: transparent !important; }
    .amber .btn-group > .jr-flat-btn.active:hover, .amber .btn-group > .jr-flat-btn.active:focus,
    .amber .btn-group-vertical > .jr-flat-btn.active:hover,
    .amber .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(255, 193, 7, 0.12) !important;
      color: #FFC107 !important; }

.amber .nav-pill-sales .nav-link.active {
  background-color: #FFC107;
  border-color: #FFC107; }

.amber .form-checkbox > input + .check,
.amber .form-checkbox > input.sm + .check {
  color: #FFC107; }

/*Theme Deep Orange Styles*/
.deep-orange .right-arrow {
  color: #ff5722; }
  .deep-orange .right-arrow:after {
    color: #ff5722; }

.deep-orange .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .deep-orange .btn-primary:focus, .deep-orange .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .deep-orange .btn-primary.disabled, .deep-orange .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .deep-orange .btn-primary:not(:disabled):not(.disabled):active, .deep-orange .btn-primary:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .deep-orange .btn-primary:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.deep-orange .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .deep-orange .btn-secondary:focus, .deep-orange .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .deep-orange .btn-secondary.disabled, .deep-orange .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .deep-orange .btn-secondary:not(:disabled):not(.disabled):active, .deep-orange .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .deep-orange .btn-secondary:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.deep-orange .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .deep-orange .btn-success:focus, .deep-orange .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .deep-orange .btn-success.disabled, .deep-orange .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .deep-orange .btn-success:not(:disabled):not(.disabled):active, .deep-orange .btn-success:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .deep-orange .btn-success:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.deep-orange .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .deep-orange .btn-info:focus, .deep-orange .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .deep-orange .btn-info.disabled, .deep-orange .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .deep-orange .btn-info:not(:disabled):not(.disabled):active, .deep-orange .btn-info:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .deep-orange .btn-info:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.deep-orange .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .deep-orange .btn-warning:focus, .deep-orange .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .deep-orange .btn-warning.disabled, .deep-orange .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .deep-orange .btn-warning:not(:disabled):not(.disabled):active, .deep-orange .btn-warning:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .deep-orange .btn-warning:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.deep-orange .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .deep-orange .btn-danger:focus, .deep-orange .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .deep-orange .btn-danger.disabled, .deep-orange .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .deep-orange .btn-danger:not(:disabled):not(.disabled):active, .deep-orange .btn-danger:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .deep-orange .btn-danger:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.deep-orange .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .deep-orange .btn-light:focus, .deep-orange .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .deep-orange .btn-light.disabled, .deep-orange .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .deep-orange .btn-light:not(:disabled):not(.disabled):active, .deep-orange .btn-light:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .deep-orange .btn-light:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.deep-orange .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .deep-orange .btn-dark:focus, .deep-orange .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .deep-orange .btn-dark.disabled, .deep-orange .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .deep-orange .btn-dark:not(:disabled):not(.disabled):active, .deep-orange .btn-dark:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .deep-orange .btn-dark:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.deep-orange a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .deep-orange a.btn-primary:focus, .deep-orange a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .deep-orange a.btn-primary.disabled, .deep-orange a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .deep-orange a.btn-primary:not(:disabled):not(.disabled):active, .deep-orange a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .deep-orange a.btn-primary:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.deep-orange a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .deep-orange a.btn-secondary:focus, .deep-orange a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .deep-orange a.btn-secondary.disabled, .deep-orange a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .deep-orange a.btn-secondary:not(:disabled):not(.disabled):active, .deep-orange a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .deep-orange a.btn-secondary:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.deep-orange a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .deep-orange a.btn-success:focus, .deep-orange a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .deep-orange a.btn-success.disabled, .deep-orange a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .deep-orange a.btn-success:not(:disabled):not(.disabled):active, .deep-orange a.btn-success:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .deep-orange a.btn-success:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.deep-orange a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .deep-orange a.btn-info:focus, .deep-orange a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .deep-orange a.btn-info.disabled, .deep-orange a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .deep-orange a.btn-info:not(:disabled):not(.disabled):active, .deep-orange a.btn-info:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .deep-orange a.btn-info:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.deep-orange a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .deep-orange a.btn-warning:focus, .deep-orange a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .deep-orange a.btn-warning.disabled, .deep-orange a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .deep-orange a.btn-warning:not(:disabled):not(.disabled):active, .deep-orange a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .deep-orange a.btn-warning:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.deep-orange a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .deep-orange a.btn-danger:focus, .deep-orange a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .deep-orange a.btn-danger.disabled, .deep-orange a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .deep-orange a.btn-danger:not(:disabled):not(.disabled):active, .deep-orange a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .deep-orange a.btn-danger:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.deep-orange a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .deep-orange a.btn-light:focus, .deep-orange a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .deep-orange a.btn-light.disabled, .deep-orange a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .deep-orange a.btn-light:not(:disabled):not(.disabled):active, .deep-orange a.btn-light:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .deep-orange a.btn-light:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.deep-orange a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-orange a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .deep-orange a.btn-dark:focus, .deep-orange a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .deep-orange a.btn-dark.disabled, .deep-orange a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .deep-orange a.btn-dark:not(:disabled):not(.disabled):active, .deep-orange a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .deep-orange a.btn-dark:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.deep-orange .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .deep-orange .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .deep-orange .btn-outline-primary:focus, .deep-orange .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .deep-orange .btn-outline-primary.disabled, .deep-orange .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .deep-orange .btn-outline-primary:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .deep-orange .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.deep-orange .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .deep-orange .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .deep-orange .btn-outline-secondary:focus, .deep-orange .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .deep-orange .btn-outline-secondary.disabled, .deep-orange .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .deep-orange .btn-outline-secondary:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .deep-orange .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.deep-orange .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .deep-orange .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .deep-orange .btn-outline-success:focus, .deep-orange .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .deep-orange .btn-outline-success.disabled, .deep-orange .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .deep-orange .btn-outline-success:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .deep-orange .btn-outline-success:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.deep-orange .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .deep-orange .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .deep-orange .btn-outline-info:focus, .deep-orange .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .deep-orange .btn-outline-info.disabled, .deep-orange .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .deep-orange .btn-outline-info:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .deep-orange .btn-outline-info:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.deep-orange .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .deep-orange .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .deep-orange .btn-outline-warning:focus, .deep-orange .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .deep-orange .btn-outline-warning.disabled, .deep-orange .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .deep-orange .btn-outline-warning:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .deep-orange .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.deep-orange .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .deep-orange .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .deep-orange .btn-outline-danger:focus, .deep-orange .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .deep-orange .btn-outline-danger.disabled, .deep-orange .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .deep-orange .btn-outline-danger:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .deep-orange .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.deep-orange .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .deep-orange .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .deep-orange .btn-outline-light:focus, .deep-orange .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .deep-orange .btn-outline-light.disabled, .deep-orange .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .deep-orange .btn-outline-light:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .deep-orange .btn-outline-light:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.deep-orange .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .deep-orange .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .deep-orange .btn-outline-dark:focus, .deep-orange .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .deep-orange .btn-outline-dark.disabled, .deep-orange .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .deep-orange .btn-outline-dark:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .deep-orange .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .deep-orange .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.deep-orange a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .deep-orange a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .deep-orange a.btn-outline-primary:focus, .deep-orange a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .deep-orange a.btn-outline-primary.disabled, .deep-orange a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .deep-orange a.btn-outline-primary:not(:disabled):not(.disabled):active, .deep-orange a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .deep-orange a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.deep-orange a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .deep-orange a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .deep-orange a.btn-outline-secondary:focus, .deep-orange a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .deep-orange a.btn-outline-secondary.disabled, .deep-orange a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled):active, .deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.deep-orange a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .deep-orange a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .deep-orange a.btn-outline-success:focus, .deep-orange a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .deep-orange a.btn-outline-success.disabled, .deep-orange a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .deep-orange a.btn-outline-success:not(:disabled):not(.disabled):active, .deep-orange a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .deep-orange a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.deep-orange a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .deep-orange a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .deep-orange a.btn-outline-info:focus, .deep-orange a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .deep-orange a.btn-outline-info.disabled, .deep-orange a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .deep-orange a.btn-outline-info:not(:disabled):not(.disabled):active, .deep-orange a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .deep-orange a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.deep-orange a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .deep-orange a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .deep-orange a.btn-outline-warning:focus, .deep-orange a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .deep-orange a.btn-outline-warning.disabled, .deep-orange a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .deep-orange a.btn-outline-warning:not(:disabled):not(.disabled):active, .deep-orange a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .deep-orange a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.deep-orange a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .deep-orange a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .deep-orange a.btn-outline-danger:focus, .deep-orange a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .deep-orange a.btn-outline-danger.disabled, .deep-orange a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .deep-orange a.btn-outline-danger:not(:disabled):not(.disabled):active, .deep-orange a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .deep-orange a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.deep-orange a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .deep-orange a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .deep-orange a.btn-outline-light:focus, .deep-orange a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .deep-orange a.btn-outline-light.disabled, .deep-orange a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .deep-orange a.btn-outline-light:not(:disabled):not(.disabled):active, .deep-orange a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .deep-orange a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.deep-orange a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .deep-orange a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .deep-orange a.btn-outline-dark:focus, .deep-orange a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .deep-orange a.btn-outline-dark.disabled, .deep-orange a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .deep-orange a.btn-outline-dark:not(:disabled):not(.disabled):active, .deep-orange a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .deep-orange a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .deep-orange a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .deep-orange a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .deep-orange a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.deep-orange .btn-outline-primary,
.deep-orange a.btn-outline-primary {
  color: #ff5722;
  border-color: #ff5722; }
  .deep-orange .btn-outline-primary:focus, .deep-orange .btn-outline-primary:hover, .deep-orange .btn-outline-primary.disabled, .deep-orange .btn-outline-primary:disabled, .deep-orange .btn-outline-primary:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-primary.dropdown-toggle,
  .deep-orange a.btn-outline-primary:focus,
  .deep-orange a.btn-outline-primary:hover,
  .deep-orange a.btn-outline-primary.disabled,
  .deep-orange a.btn-outline-primary:disabled,
  .deep-orange a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .deep-orange a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .deep-orange a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #ff5722;
    background-color: #ff5722; }

.deep-orange .btn-outline-secondary,
.deep-orange a.btn-outline-secondary {
  color: #40C4FF;
  border-color: #40C4FF; }
  .deep-orange .btn-outline-secondary:focus, .deep-orange .btn-outline-secondary:hover, .deep-orange .btn-outline-secondary.disabled, .deep-orange .btn-outline-secondary:disabled, .deep-orange .btn-outline-secondary:not(:disabled):not(.disabled):active, .deep-orange .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-orange .btn-outline-secondary.dropdown-toggle,
  .deep-orange a.btn-outline-secondary:focus,
  .deep-orange a.btn-outline-secondary:hover,
  .deep-orange a.btn-outline-secondary.disabled,
  .deep-orange a.btn-outline-secondary:disabled,
  .deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .deep-orange a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #40C4FF;
    background-color: #40C4FF; }

.deep-orange a,
.deep-orange a.text-primary {
  color: #ff5722; }
  .deep-orange a:focus, .deep-orange a:hover,
  .deep-orange a.text-primary:focus,
  .deep-orange a.text-primary:hover {
    color: #ee3900; }

.deep-orange .text-primary {
  color: #ff5722 !important; }

.deep-orange .page-link {
  color: #ff5722; }
  .deep-orange .page-link:focus, .deep-orange .page-link:hover {
    color: #ff5722; }

.deep-orange .bg-primary,
.deep-orange .badge-primary {
  background-color: #ff5722 !important;
  color: #fff !important; }

.deep-orange .btn-primary,
.deep-orange .jr-btn-primary,
.deep-orange a.btn-primary,
.deep-orange a.jr-btn-primary {
  background-color: #ff5722;
  border-color: #ff5722;
  color: #fff; }
  .deep-orange .btn-primary:hover, .deep-orange .btn-primary:focus, .deep-orange .btn-primary:not([disabled]):not(.disabled):active,
  .deep-orange .jr-btn-primary:hover,
  .deep-orange .jr-btn-primary:focus,
  .deep-orange .jr-btn-primary:not([disabled]):not(.disabled):active,
  .deep-orange a.btn-primary:hover,
  .deep-orange a.btn-primary:focus,
  .deep-orange a.btn-primary:not([disabled]):not(.disabled):active,
  .deep-orange a.jr-btn-primary:hover,
  .deep-orange a.jr-btn-primary:focus,
  .deep-orange a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #ff4409 !important;
    border-color: #ff4409 !important;
    color: #fff !important; }

.deep-orange .bg-primary.lighten-4 {
  background-color: #ffccbc !important; }

.deep-orange .bg-primary.lighten-3 {
  background-color: #ffab91 !important; }

.deep-orange .bg-primary.lighten-2 {
  background-color: #ff8a65 !important; }

.deep-orange .bg-primary.lighten-1 {
  background-color: #ff7043 !important; }

.deep-orange .bg-primary.darken-1 {
  background-color: #f4511e !important; }

.deep-orange .bg-primary.darken-2 {
  background-color: #e64a19 !important; }

.deep-orange .bg-primary.darken-3 {
  background-color: #d84315 !important; }

.deep-orange .bg-primary.darken-4 {
  background-color: #bf360c !important; }

.deep-orange .bg-primary.accent-1 {
  background-color: #ff9e80 !important; }

.deep-orange .bg-primary.accent-2 {
  background-color: #ff6e40 !important; }

.deep-orange .bg-primary.accent-3 {
  background-color: #ff3d00 !important; }

.deep-orange .bg-primary.accent-4 {
  background-color: #dd2c00 !important; }

.deep-orange a.text-secondary {
  color: #40C4FF; }
  .deep-orange a.text-secondary:focus, .deep-orange a.text-secondary:hover {
    color: #0db4ff !important; }

.deep-orange .text-secondary {
  color: #40C4FF !important; }

.deep-orange .bg-secondary,
.deep-orange .badge-secondary {
  background-color: #40C4FF !important;
  color: #fff !important; }

.deep-orange .btn-secondary {
  background-color: #40C4FF;
  border-color: #40C4FF;
  color: #fff; }
  .deep-orange .btn-secondary:hover, .deep-orange .btn-secondary:focus, .deep-orange .btn-secondary:active {
    background-color: #27bcff !important;
    border-color: #27bcff !important;
    color: #fff !important; }

.deep-orange .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .deep-orange .jr-flat-btn:hover, .deep-orange .jr-flat-btn:focus, .deep-orange .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.deep-orange .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #ff5722 !important;
  border-color: transparent !important; }
  .deep-orange .jr-flat-btn.jr-btn-primary:hover, .deep-orange .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 87, 34, 0.12) !important;
    color: #ff5722 !important; }

.deep-orange .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .deep-orange .bg-primary .jr-flat-btn.jr-btn-primary:hover, .deep-orange .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.deep-orange .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #40C4FF !important;
  border-color: transparent !important; }
  .deep-orange .jr-flat-btn.jr-btn-secondary:hover, .deep-orange .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(64, 196, 255, 0.12) !important;
    color: #40C4FF !important; }

.deep-orange .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .deep-orange .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .deep-orange .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

@media screen and (max-width: 575px) {
  .deep-orange .app-main-header .search-dropdown input {
    background-color: #fff; } }

.deep-orange .color-theme-header {
  background-color: #ff5722; }

.deep-orange .side-nav {
  background-color: #fff !important;
  color: #868e96 !important; }
  .deep-orange .side-nav .user-profile {
    background-color: #f8f9fa;
    position: relative;
    z-index: 2; }
    .deep-orange .side-nav .user-profile .text-white {
      color: #ff5722 !important; }
  .deep-orange .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .deep-orange .side-nav .user-detail .user-name {
    color: #ff5722; }

.deep-orange ul.nav-menu li a {
  color: #868e96; }

.deep-orange ul.nav-menu .nav-header {
  color: #868e96; }

.deep-orange ul.nav-menu > li.open > a,
.deep-orange ul.nav-menu > li > a:hover,
.deep-orange ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #ff5722; }

.deep-orange ul.nav-menu li.menu > a:focus:before,
.deep-orange ul.nav-menu li.menu > a:hover:before {
  color: #ff5722; }

.deep-orange ul.nav-menu li ul {
  background-color: #fff; }

.deep-orange ul.nav-menu li.menu > a:before {
  color: #868e96; }

.deep-orange ul.nav-menu li.menu.open > a:before,
.deep-orange ul.nav-menu li.menu > a:focus:before,
.deep-orange ul.nav-menu li.menu li > a:hover:before {
  color: #ff5722; }

.deep-orange ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.deep-orange ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #ff5722; }

.deep-orange ul.nav-menu li.menu .sub-menu li > a:hover,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:focus,
.deep-orange ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:focus:before,
.deep-orange ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #ff5722; }

/*Header top Navbar Styles*/
.deep-orange .navbar-nav li:hover > a,
.deep-orange .navbar-nav li:focus > a {
  color: #ff5722; }

.deep-orange .navbar-nav li a {
  color: #868e96; }

.deep-orange .navbar-nav li a:hover,
.deep-orange .navbar-nav li a:focus {
  color: #ff5722; }

.deep-orange .navbar-nav li.nav-item.active > a {
  color: #ff5722; }

.deep-orange .navbar-nav ul.sub-menu li a.active,
.deep-orange .navbar-nav ul.sub-menu li.active > a {
  color: #ff5722; }

.deep-orange .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.deep-orange .navbar-nav .nav-arrow a:hover:before,
.deep-orange .navbar-nav .nav-arrow a:focus:before {
  color: #ff5722; }

.deep-orange .navbar-nav .nav-arrow:hover > a:before,
.deep-orange .navbar-nav .nav-arrow:focus > a:before,
.deep-orange .navbar-nav .nav-arrow.active > a:before {
  color: #ff5722; }

.deep-orange .app-top-nav {
  background-color: #ff5722; }
  .deep-orange .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .deep-orange .app-top-nav .navbar-nav li.nav-item:hover > a,
  .deep-orange .app-top-nav .navbar-nav li.nav-item:focus > a,
  .deep-orange .app-top-nav .navbar-nav li.nav-item > a:hover,
  .deep-orange .app-top-nav .navbar-nav li.nav-item > a:focus,
  .deep-orange .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #40C4FF; }

.deep-orange .module-nav li a.active {
  border-color: #ff5722; }

.deep-orange .rbc-event {
  background-color: #ff5722; }

.deep-orange .rbc-event.rbc-selected {
  background-color: #ee3900; }

.deep-orange .rbc-slot-selection {
  background-color: rgba(255, 87, 34, 0.7); }

.deep-orange .rbc-toolbar button:active,
.deep-orange .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:active:hover,
.deep-orange .rbc-toolbar button.rbc-active:hover,
.deep-orange .rbc-toolbar button:active:focus,
.deep-orange .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-toolbar button:hover {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.deep-orange .rbc-day-slot .rbc-event {
  border-color: #ff5722; }

.deep-orange .chat-sidenav-title {
  color: #ff5722; }

.deep-orange .chat-user-item.active, .deep-orange .chat-user-item:hover {
  background-color: #fff2ee; }

.deep-orange .contact-list i {
  color: #ff5722; }

.deep-orange .Collapsible__trigger {
  background: #ff5722; }

.deep-orange .categories-list li a:focus,
.deep-orange .categories-list li a:hover,
.deep-orange .categories-list li.active a {
  color: #ff5722; }

.deep-orange .login-content .form-control:focus {
  border-color: #ff5722; }

.deep-orange .filter-with-bg-color ul li a {
  border-color: #ff5722; }
  .deep-orange .filter-with-bg-color ul li a:hover, .deep-orange .filter-with-bg-color ul li a:focus, .deep-orange .filter-with-bg-color ul li a.active {
    background-color: #ff5722; }

.deep-orange .profile-intro .icon {
  color: #ff5722; }

.deep-orange .social-link li.active a,
.deep-orange .social-link li a:hover,
.deep-orange .social-link li a:focus {
  color: #40C4FF; }

.deep-orange .actions {
  color: #40C4FF; }

.deep-orange .table-hover tbody tr:hover {
  background-color: rgba(255, 87, 34, 0.075); }

.deep-orange .border-primary {
  border-color: #ff5722 !important; }

.deep-orange .app-logo-content {
  background-color: #ff5722; }

.deep-orange .app-social-block .social-link button {
  border: solid 1px #ff5722;
  color: #ff5722; }
  .deep-orange .app-social-block .social-link button:hover, .deep-orange .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ff5722; }

.deep-orange .btn-group > .jr-btn.active,
.deep-orange .btn-group > .btn-default.active,
.deep-orange .btn-group-vertical > .jr-btn.active,
.deep-orange .btn-group-vertical > .btn-default.active {
  background-color: #ff5722;
  border-color: #ff5722; }

.deep-orange .btn-group > .jr-flat-btn,
.deep-orange .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .deep-orange .btn-group > .jr-flat-btn.active,
  .deep-orange .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #ff5722 !important;
    border-color: transparent !important; }
    .deep-orange .btn-group > .jr-flat-btn.active:hover, .deep-orange .btn-group > .jr-flat-btn.active:focus,
    .deep-orange .btn-group-vertical > .jr-flat-btn.active:hover,
    .deep-orange .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(255, 87, 34, 0.12) !important;
      color: #ff5722 !important; }

.deep-orange .nav-pill-sales .nav-link.active {
  background-color: #ff5722;
  border-color: #ff5722; }

.deep-orange .form-checkbox > input + .check,
.deep-orange .form-checkbox > input.sm + .check {
  color: #ff5722; }

/*Theme Pink Styles*/
.pink .right-arrow {
  color: #e91e63; }
  .pink .right-arrow:after {
    color: #e91e63; }

.pink .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .pink .btn-primary:focus, .pink .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .pink .btn-primary.disabled, .pink .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .pink .btn-primary:not(:disabled):not(.disabled):active, .pink .btn-primary:not(:disabled):not(.disabled).active,
  .show > .pink .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .pink .btn-primary:not(:disabled):not(.disabled):active:focus, .pink .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.pink .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .pink .btn-secondary:focus, .pink .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .pink .btn-secondary.disabled, .pink .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .pink .btn-secondary:not(:disabled):not(.disabled):active, .pink .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .pink .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .pink .btn-secondary:not(:disabled):not(.disabled):active:focus, .pink .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.pink .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .pink .btn-success:focus, .pink .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .pink .btn-success.disabled, .pink .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .pink .btn-success:not(:disabled):not(.disabled):active, .pink .btn-success:not(:disabled):not(.disabled).active,
  .show > .pink .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .pink .btn-success:not(:disabled):not(.disabled):active:focus, .pink .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.pink .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .pink .btn-info:focus, .pink .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .pink .btn-info.disabled, .pink .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .pink .btn-info:not(:disabled):not(.disabled):active, .pink .btn-info:not(:disabled):not(.disabled).active,
  .show > .pink .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .pink .btn-info:not(:disabled):not(.disabled):active:focus, .pink .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.pink .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .pink .btn-warning:focus, .pink .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .pink .btn-warning.disabled, .pink .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .pink .btn-warning:not(:disabled):not(.disabled):active, .pink .btn-warning:not(:disabled):not(.disabled).active,
  .show > .pink .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .pink .btn-warning:not(:disabled):not(.disabled):active:focus, .pink .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.pink .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .pink .btn-danger:focus, .pink .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .pink .btn-danger.disabled, .pink .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .pink .btn-danger:not(:disabled):not(.disabled):active, .pink .btn-danger:not(:disabled):not(.disabled).active,
  .show > .pink .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .pink .btn-danger:not(:disabled):not(.disabled):active:focus, .pink .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.pink .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .pink .btn-light:focus, .pink .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .pink .btn-light.disabled, .pink .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .pink .btn-light:not(:disabled):not(.disabled):active, .pink .btn-light:not(:disabled):not(.disabled).active,
  .show > .pink .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .pink .btn-light:not(:disabled):not(.disabled):active:focus, .pink .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.pink .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .pink .btn-dark:focus, .pink .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .pink .btn-dark.disabled, .pink .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .pink .btn-dark:not(:disabled):not(.disabled):active, .pink .btn-dark:not(:disabled):not(.disabled).active,
  .show > .pink .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .pink .btn-dark:not(:disabled):not(.disabled):active:focus, .pink .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.pink a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .pink a.btn-primary:focus, .pink a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .pink a.btn-primary.disabled, .pink a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .pink a.btn-primary:not(:disabled):not(.disabled):active, .pink a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .pink a.btn-primary:not(:disabled):not(.disabled):active:focus, .pink a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.pink a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .pink a.btn-secondary:focus, .pink a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .pink a.btn-secondary.disabled, .pink a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .pink a.btn-secondary:not(:disabled):not(.disabled):active, .pink a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .pink a.btn-secondary:not(:disabled):not(.disabled):active:focus, .pink a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.pink a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .pink a.btn-success:focus, .pink a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .pink a.btn-success.disabled, .pink a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .pink a.btn-success:not(:disabled):not(.disabled):active, .pink a.btn-success:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .pink a.btn-success:not(:disabled):not(.disabled):active:focus, .pink a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.pink a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .pink a.btn-info:focus, .pink a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .pink a.btn-info.disabled, .pink a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .pink a.btn-info:not(:disabled):not(.disabled):active, .pink a.btn-info:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .pink a.btn-info:not(:disabled):not(.disabled):active:focus, .pink a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.pink a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .pink a.btn-warning:focus, .pink a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .pink a.btn-warning.disabled, .pink a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .pink a.btn-warning:not(:disabled):not(.disabled):active, .pink a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .pink a.btn-warning:not(:disabled):not(.disabled):active:focus, .pink a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.pink a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .pink a.btn-danger:focus, .pink a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .pink a.btn-danger.disabled, .pink a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .pink a.btn-danger:not(:disabled):not(.disabled):active, .pink a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .pink a.btn-danger:not(:disabled):not(.disabled):active:focus, .pink a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.pink a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .pink a.btn-light:focus, .pink a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .pink a.btn-light.disabled, .pink a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .pink a.btn-light:not(:disabled):not(.disabled):active, .pink a.btn-light:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .pink a.btn-light:not(:disabled):not(.disabled):active:focus, .pink a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.pink a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .pink a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .pink a.btn-dark:focus, .pink a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .pink a.btn-dark.disabled, .pink a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .pink a.btn-dark:not(:disabled):not(.disabled):active, .pink a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .pink a.btn-dark:not(:disabled):not(.disabled):active:focus, .pink a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.pink .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .pink .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .pink .btn-outline-primary:focus, .pink .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .pink .btn-outline-primary.disabled, .pink .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .pink .btn-outline-primary:not(:disabled):not(.disabled):active, .pink .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .pink .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .pink .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.pink .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .pink .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .pink .btn-outline-secondary:focus, .pink .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .pink .btn-outline-secondary.disabled, .pink .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .pink .btn-outline-secondary:not(:disabled):not(.disabled):active, .pink .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .pink .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .pink .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.pink .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .pink .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .pink .btn-outline-success:focus, .pink .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .pink .btn-outline-success.disabled, .pink .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .pink .btn-outline-success:not(:disabled):not(.disabled):active, .pink .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .pink .btn-outline-success:not(:disabled):not(.disabled):active:focus, .pink .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.pink .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .pink .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .pink .btn-outline-info:focus, .pink .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .pink .btn-outline-info.disabled, .pink .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .pink .btn-outline-info:not(:disabled):not(.disabled):active, .pink .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .pink .btn-outline-info:not(:disabled):not(.disabled):active:focus, .pink .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.pink .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .pink .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .pink .btn-outline-warning:focus, .pink .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .pink .btn-outline-warning.disabled, .pink .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .pink .btn-outline-warning:not(:disabled):not(.disabled):active, .pink .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .pink .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .pink .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.pink .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .pink .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .pink .btn-outline-danger:focus, .pink .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .pink .btn-outline-danger.disabled, .pink .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .pink .btn-outline-danger:not(:disabled):not(.disabled):active, .pink .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .pink .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .pink .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.pink .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .pink .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .pink .btn-outline-light:focus, .pink .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .pink .btn-outline-light.disabled, .pink .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .pink .btn-outline-light:not(:disabled):not(.disabled):active, .pink .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .pink .btn-outline-light:not(:disabled):not(.disabled):active:focus, .pink .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.pink .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .pink .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .pink .btn-outline-dark:focus, .pink .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .pink .btn-outline-dark.disabled, .pink .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .pink .btn-outline-dark:not(:disabled):not(.disabled):active, .pink .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .pink .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .pink .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .pink .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.pink a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .pink a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .pink a.btn-outline-primary:focus, .pink a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .pink a.btn-outline-primary.disabled, .pink a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .pink a.btn-outline-primary:not(:disabled):not(.disabled):active, .pink a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .pink a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .pink a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.pink a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .pink a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .pink a.btn-outline-secondary:focus, .pink a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .pink a.btn-outline-secondary.disabled, .pink a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .pink a.btn-outline-secondary:not(:disabled):not(.disabled):active, .pink a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .pink a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .pink a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.pink a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .pink a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .pink a.btn-outline-success:focus, .pink a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .pink a.btn-outline-success.disabled, .pink a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .pink a.btn-outline-success:not(:disabled):not(.disabled):active, .pink a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .pink a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .pink a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.pink a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .pink a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .pink a.btn-outline-info:focus, .pink a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .pink a.btn-outline-info.disabled, .pink a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .pink a.btn-outline-info:not(:disabled):not(.disabled):active, .pink a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .pink a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .pink a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.pink a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .pink a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .pink a.btn-outline-warning:focus, .pink a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .pink a.btn-outline-warning.disabled, .pink a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .pink a.btn-outline-warning:not(:disabled):not(.disabled):active, .pink a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .pink a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .pink a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.pink a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .pink a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .pink a.btn-outline-danger:focus, .pink a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .pink a.btn-outline-danger.disabled, .pink a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .pink a.btn-outline-danger:not(:disabled):not(.disabled):active, .pink a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .pink a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .pink a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.pink a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .pink a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .pink a.btn-outline-light:focus, .pink a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .pink a.btn-outline-light.disabled, .pink a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .pink a.btn-outline-light:not(:disabled):not(.disabled):active, .pink a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .pink a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .pink a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.pink a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .pink a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .pink a.btn-outline-dark:focus, .pink a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .pink a.btn-outline-dark.disabled, .pink a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .pink a.btn-outline-dark:not(:disabled):not(.disabled):active, .pink a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .pink a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .pink a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .pink a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .pink a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.pink .btn-outline-primary,
.pink a.btn-outline-primary {
  color: #e91e63;
  border-color: #e91e63; }
  .pink .btn-outline-primary:focus, .pink .btn-outline-primary:hover, .pink .btn-outline-primary.disabled, .pink .btn-outline-primary:disabled, .pink .btn-outline-primary:not(:disabled):not(.disabled):active, .pink .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-primary.dropdown-toggle,
  .pink a.btn-outline-primary:focus,
  .pink a.btn-outline-primary:hover,
  .pink a.btn-outline-primary.disabled,
  .pink a.btn-outline-primary:disabled,
  .pink a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .pink a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .pink a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #e91e63;
    background-color: #e91e63; }

.pink .btn-outline-secondary,
.pink a.btn-outline-secondary {
  color: #00BFA5;
  border-color: #00BFA5; }
  .pink .btn-outline-secondary:focus, .pink .btn-outline-secondary:hover, .pink .btn-outline-secondary.disabled, .pink .btn-outline-secondary:disabled, .pink .btn-outline-secondary:not(:disabled):not(.disabled):active, .pink .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .pink .btn-outline-secondary.dropdown-toggle,
  .pink a.btn-outline-secondary:focus,
  .pink a.btn-outline-secondary:hover,
  .pink a.btn-outline-secondary.disabled,
  .pink a.btn-outline-secondary:disabled,
  .pink a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .pink a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .pink a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #00BFA5;
    background-color: #00BFA5; }

.pink a,
.pink a.text-primary {
  color: #e91e63; }
  .pink a:focus, .pink a:hover,
  .pink a.text-primary:focus,
  .pink a.text-primary:hover {
    color: #c1134e; }

.pink .text-primary {
  color: #e91e63 !important; }

.pink .page-link {
  color: #e91e63; }
  .pink .page-link:focus, .pink .page-link:hover {
    color: #e91e63; }

.pink .bg-primary,
.pink .badge-primary {
  background-color: #e91e63 !important;
  color: #fff !important; }

.pink .btn-primary,
.pink .jr-btn-primary,
.pink a.btn-primary,
.pink a.jr-btn-primary {
  background-color: #e91e63;
  border-color: #e91e63;
  color: #fff; }
  .pink .btn-primary:hover, .pink .btn-primary:focus, .pink .btn-primary:not([disabled]):not(.disabled):active,
  .pink .jr-btn-primary:hover,
  .pink .jr-btn-primary:focus,
  .pink .jr-btn-primary:not([disabled]):not(.disabled):active,
  .pink a.btn-primary:hover,
  .pink a.btn-primary:focus,
  .pink a.btn-primary:not([disabled]):not(.disabled):active,
  .pink a.jr-btn-primary:hover,
  .pink a.jr-btn-primary:focus,
  .pink a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #d81558 !important;
    border-color: #d81558 !important;
    color: #fff !important; }

.pink .bg-primary.lighten-4 {
  background-color: #f8bbd0 !important; }

.pink .bg-primary.lighten-3 {
  background-color: #f48fb1 !important; }

.pink .bg-primary.lighten-2 {
  background-color: #f06292 !important; }

.pink .bg-primary.lighten-1 {
  background-color: #ec407a !important; }

.pink .bg-primary.darken-1 {
  background-color: #d81b60 !important; }

.pink .bg-primary.darken-2 {
  background-color: #c2185b !important; }

.pink .bg-primary.darken-3 {
  background-color: #ad1457 !important; }

.pink .bg-primary.darken-4 {
  background-color: #880e4f !important; }

.pink .bg-primary.accent-1 {
  background-color: #ff80ab !important; }

.pink .bg-primary.accent-2 {
  background-color: #ff4081 !important; }

.pink .bg-primary.accent-3 {
  background-color: #f50057 !important; }

.pink .bg-primary.accent-4 {
  background-color: #c51162 !important; }

.pink a.text-secondary {
  color: #00BFA5; }
  .pink a.text-secondary:focus, .pink a.text-secondary:hover {
    color: #008c79 !important; }

.pink .text-secondary {
  color: #00BFA5 !important; }

.pink .bg-secondary,
.pink .badge-secondary {
  background-color: #00BFA5 !important;
  color: #fff !important; }

.pink .btn-secondary {
  background-color: #00BFA5;
  border-color: #00BFA5;
  color: #fff; }
  .pink .btn-secondary:hover, .pink .btn-secondary:focus, .pink .btn-secondary:active {
    background-color: #00a68f !important;
    border-color: #00a68f !important;
    color: #fff !important; }

.pink .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .pink .jr-flat-btn:hover, .pink .jr-flat-btn:focus, .pink .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.pink .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #e91e63 !important;
  border-color: transparent !important; }
  .pink .jr-flat-btn.jr-btn-primary:hover, .pink .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(233, 30, 99, 0.12) !important;
    color: #e91e63 !important; }

.pink .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .pink .bg-primary .jr-flat-btn.jr-btn-primary:hover, .pink .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.pink .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #00BFA5 !important;
  border-color: transparent !important; }
  .pink .jr-flat-btn.jr-btn-secondary:hover, .pink .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(0, 191, 165, 0.12) !important;
    color: #00BFA5 !important; }

.pink .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .pink .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .pink .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

@media screen and (max-width: 575px) {
  .pink .app-main-header .search-dropdown input {
    background-color: #fff; } }

.pink .color-theme-header {
  background-color: #e91e63; }

.pink .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  border-right: solid 1px #e9ecef !important; }
  .pink .side-nav .user-profile {
    background-color: #f8f9fa;
    position: relative;
    z-index: 2; }
    .pink .side-nav .user-profile .text-white {
      color: #e91e63 !important; }
  .pink .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .pink .side-nav .user-detail .user-name {
    color: #e91e63; }

.pink ul.nav-menu li a {
  color: #868e96; }

.pink ul.nav-menu .nav-header {
  color: #868e96; }

.pink ul.nav-menu > li.open > a,
.pink ul.nav-menu > li > a:hover,
.pink ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #e91e63; }

.pink ul.nav-menu li.menu > a:focus:before,
.pink ul.nav-menu li.menu > a:hover:before {
  color: #e91e63; }

.pink ul.nav-menu li ul {
  background-color: #fff; }

.pink ul.nav-menu li.menu > a:before {
  color: #868e96; }

.pink ul.nav-menu li.menu.open > a:before,
.pink ul.nav-menu li.menu > a:focus:before,
.pink ul.nav-menu li.menu li > a:hover:before {
  color: #e91e63; }

.pink ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.pink ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #e91e63; }

.pink ul.nav-menu li.menu .sub-menu li > a:hover,
.pink ul.nav-menu li.menu .sub-menu li > a:focus,
.pink ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.pink ul.nav-menu li.menu .sub-menu li > a:focus:before,
.pink ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #e91e63; }

/*Header top Navbar Styles*/
.pink .navbar-nav li:hover > a,
.pink .navbar-nav li:focus > a {
  color: #e91e63; }

.pink .navbar-nav li a {
  color: #868e96; }

.pink .navbar-nav li a:hover,
.pink .navbar-nav li a:focus {
  color: #e91e63; }

.pink .navbar-nav li.nav-item.active > a {
  color: #e91e63; }

.pink .navbar-nav ul.sub-menu li a.active,
.pink .navbar-nav ul.sub-menu li.active > a {
  color: #e91e63; }

.pink .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.pink .navbar-nav .nav-arrow a:hover:before,
.pink .navbar-nav .nav-arrow a:focus:before {
  color: #e91e63; }

.pink .navbar-nav .nav-arrow:hover > a:before,
.pink .navbar-nav .nav-arrow:focus > a:before,
.pink .navbar-nav .nav-arrow.active > a:before {
  color: #e91e63; }

.pink .app-top-nav {
  background-color: #e91e63; }
  .pink .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .pink .app-top-nav .navbar-nav li.nav-item:hover > a,
  .pink .app-top-nav .navbar-nav li.nav-item:focus > a,
  .pink .app-top-nav .navbar-nav li.nav-item > a:hover,
  .pink .app-top-nav .navbar-nav li.nav-item > a:focus,
  .pink .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #00BFA5; }

.pink .module-nav li a.active {
  border-color: #e91e63; }

.pink .rbc-event {
  background-color: #e91e63; }

.pink .rbc-event.rbc-selected {
  background-color: #c1134e; }

.pink .rbc-slot-selection {
  background-color: rgba(233, 30, 99, 0.7); }

.pink .rbc-toolbar button:active,
.pink .rbc-toolbar button.rbc-active {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.pink .rbc-toolbar button:active:hover,
.pink .rbc-toolbar button.rbc-active:hover,
.pink .rbc-toolbar button:active:focus,
.pink .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.pink .rbc-toolbar button:focus {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.pink .rbc-toolbar button:hover {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.pink .rbc-day-slot .rbc-event {
  border-color: #e91e63; }

.pink .chat-sidenav-title {
  color: #e91e63; }

.pink .chat-user-item.active, .pink .chat-user-item:hover {
  background-color: #fdeff4; }

.pink .contact-list i {
  color: #e91e63; }

.pink .Collapsible__trigger {
  background: #e91e63; }

.pink .categories-list li a:focus,
.pink .categories-list li a:hover,
.pink .categories-list li.active a {
  color: #e91e63; }

.pink .login-content .form-control:focus {
  border-color: #e91e63; }

.pink .filter-with-bg-color ul li a {
  border-color: #e91e63; }
  .pink .filter-with-bg-color ul li a:hover, .pink .filter-with-bg-color ul li a:focus, .pink .filter-with-bg-color ul li a.active {
    background-color: #e91e63; }

.pink .profile-intro .icon {
  color: #e91e63; }

.pink .social-link li.active a,
.pink .social-link li a:hover,
.pink .social-link li a:focus {
  color: #00BFA5; }

.pink .actions {
  color: #00BFA5; }

.pink .table-hover tbody tr:hover {
  background-color: rgba(233, 30, 99, 0.075); }

.pink .border-primary {
  border-color: #e91e63 !important; }

.pink .app-logo-content {
  background-color: #e91e63; }

.pink .app-social-block .social-link button {
  border: solid 1px #e91e63;
  color: #e91e63; }
  .pink .app-social-block .social-link button:hover, .pink .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #e91e63; }

.pink .btn-group > .jr-btn.active,
.pink .btn-group > .btn-default.active,
.pink .btn-group-vertical > .jr-btn.active,
.pink .btn-group-vertical > .btn-default.active {
  background-color: #e91e63;
  border-color: #e91e63; }

.pink .btn-group > .jr-flat-btn,
.pink .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .pink .btn-group > .jr-flat-btn.active,
  .pink .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #e91e63 !important;
    border-color: transparent !important; }
    .pink .btn-group > .jr-flat-btn.active:hover, .pink .btn-group > .jr-flat-btn.active:focus,
    .pink .btn-group-vertical > .jr-flat-btn.active:hover,
    .pink .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(233, 30, 99, 0.12) !important;
      color: #e91e63 !important; }

.pink .nav-pill-sales .nav-link.active {
  background-color: #e91e63;
  border-color: #e91e63; }

.pink .form-checkbox > input + .check,
.pink .form-checkbox > input.sm + .check {
  color: #e91e63; }

/*Theme Blue Styles*/
.blue .right-arrow {
  color: #2196F3; }
  .blue .right-arrow:after {
    color: #2196F3; }

.blue .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .blue .btn-primary:focus, .blue .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .blue .btn-primary.disabled, .blue .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .blue .btn-primary:not(:disabled):not(.disabled):active, .blue .btn-primary:not(:disabled):not(.disabled).active,
  .show > .blue .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .blue .btn-primary:not(:disabled):not(.disabled):active:focus, .blue .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.blue .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .blue .btn-secondary:focus, .blue .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .blue .btn-secondary.disabled, .blue .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .blue .btn-secondary:not(:disabled):not(.disabled):active, .blue .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .blue .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .blue .btn-secondary:not(:disabled):not(.disabled):active:focus, .blue .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.blue .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .blue .btn-success:focus, .blue .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .blue .btn-success.disabled, .blue .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .blue .btn-success:not(:disabled):not(.disabled):active, .blue .btn-success:not(:disabled):not(.disabled).active,
  .show > .blue .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .blue .btn-success:not(:disabled):not(.disabled):active:focus, .blue .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.blue .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .blue .btn-info:focus, .blue .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .blue .btn-info.disabled, .blue .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .blue .btn-info:not(:disabled):not(.disabled):active, .blue .btn-info:not(:disabled):not(.disabled).active,
  .show > .blue .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .blue .btn-info:not(:disabled):not(.disabled):active:focus, .blue .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.blue .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .blue .btn-warning:focus, .blue .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .blue .btn-warning.disabled, .blue .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .blue .btn-warning:not(:disabled):not(.disabled):active, .blue .btn-warning:not(:disabled):not(.disabled).active,
  .show > .blue .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .blue .btn-warning:not(:disabled):not(.disabled):active:focus, .blue .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.blue .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .blue .btn-danger:focus, .blue .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .blue .btn-danger.disabled, .blue .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .blue .btn-danger:not(:disabled):not(.disabled):active, .blue .btn-danger:not(:disabled):not(.disabled).active,
  .show > .blue .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .blue .btn-danger:not(:disabled):not(.disabled):active:focus, .blue .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.blue .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .blue .btn-light:focus, .blue .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .blue .btn-light.disabled, .blue .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .blue .btn-light:not(:disabled):not(.disabled):active, .blue .btn-light:not(:disabled):not(.disabled).active,
  .show > .blue .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .blue .btn-light:not(:disabled):not(.disabled):active:focus, .blue .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.blue .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .blue .btn-dark:focus, .blue .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .blue .btn-dark.disabled, .blue .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .blue .btn-dark:not(:disabled):not(.disabled):active, .blue .btn-dark:not(:disabled):not(.disabled).active,
  .show > .blue .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .blue .btn-dark:not(:disabled):not(.disabled):active:focus, .blue .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.blue a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .blue a.btn-primary:focus, .blue a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .blue a.btn-primary.disabled, .blue a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .blue a.btn-primary:not(:disabled):not(.disabled):active, .blue a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .blue a.btn-primary:not(:disabled):not(.disabled):active:focus, .blue a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.blue a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .blue a.btn-secondary:focus, .blue a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .blue a.btn-secondary.disabled, .blue a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .blue a.btn-secondary:not(:disabled):not(.disabled):active, .blue a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .blue a.btn-secondary:not(:disabled):not(.disabled):active:focus, .blue a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.blue a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .blue a.btn-success:focus, .blue a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .blue a.btn-success.disabled, .blue a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .blue a.btn-success:not(:disabled):not(.disabled):active, .blue a.btn-success:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .blue a.btn-success:not(:disabled):not(.disabled):active:focus, .blue a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.blue a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .blue a.btn-info:focus, .blue a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .blue a.btn-info.disabled, .blue a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .blue a.btn-info:not(:disabled):not(.disabled):active, .blue a.btn-info:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .blue a.btn-info:not(:disabled):not(.disabled):active:focus, .blue a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.blue a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .blue a.btn-warning:focus, .blue a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .blue a.btn-warning.disabled, .blue a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .blue a.btn-warning:not(:disabled):not(.disabled):active, .blue a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .blue a.btn-warning:not(:disabled):not(.disabled):active:focus, .blue a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.blue a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .blue a.btn-danger:focus, .blue a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .blue a.btn-danger.disabled, .blue a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .blue a.btn-danger:not(:disabled):not(.disabled):active, .blue a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .blue a.btn-danger:not(:disabled):not(.disabled):active:focus, .blue a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.blue a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .blue a.btn-light:focus, .blue a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .blue a.btn-light.disabled, .blue a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .blue a.btn-light:not(:disabled):not(.disabled):active, .blue a.btn-light:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .blue a.btn-light:not(:disabled):not(.disabled):active:focus, .blue a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.blue a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .blue a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .blue a.btn-dark:focus, .blue a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .blue a.btn-dark.disabled, .blue a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .blue a.btn-dark:not(:disabled):not(.disabled):active, .blue a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .blue a.btn-dark:not(:disabled):not(.disabled):active:focus, .blue a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.blue .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .blue .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .blue .btn-outline-primary:focus, .blue .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .blue .btn-outline-primary.disabled, .blue .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .blue .btn-outline-primary:not(:disabled):not(.disabled):active, .blue .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .blue .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .blue .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.blue .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .blue .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .blue .btn-outline-secondary:focus, .blue .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .blue .btn-outline-secondary.disabled, .blue .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .blue .btn-outline-secondary:not(:disabled):not(.disabled):active, .blue .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .blue .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .blue .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.blue .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .blue .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .blue .btn-outline-success:focus, .blue .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .blue .btn-outline-success.disabled, .blue .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .blue .btn-outline-success:not(:disabled):not(.disabled):active, .blue .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .blue .btn-outline-success:not(:disabled):not(.disabled):active:focus, .blue .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.blue .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .blue .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .blue .btn-outline-info:focus, .blue .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .blue .btn-outline-info.disabled, .blue .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .blue .btn-outline-info:not(:disabled):not(.disabled):active, .blue .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .blue .btn-outline-info:not(:disabled):not(.disabled):active:focus, .blue .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.blue .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .blue .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .blue .btn-outline-warning:focus, .blue .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .blue .btn-outline-warning.disabled, .blue .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .blue .btn-outline-warning:not(:disabled):not(.disabled):active, .blue .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .blue .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .blue .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.blue .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .blue .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .blue .btn-outline-danger:focus, .blue .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .blue .btn-outline-danger.disabled, .blue .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .blue .btn-outline-danger:not(:disabled):not(.disabled):active, .blue .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .blue .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .blue .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.blue .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .blue .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .blue .btn-outline-light:focus, .blue .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .blue .btn-outline-light.disabled, .blue .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .blue .btn-outline-light:not(:disabled):not(.disabled):active, .blue .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .blue .btn-outline-light:not(:disabled):not(.disabled):active:focus, .blue .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.blue .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .blue .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .blue .btn-outline-dark:focus, .blue .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .blue .btn-outline-dark.disabled, .blue .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .blue .btn-outline-dark:not(:disabled):not(.disabled):active, .blue .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .blue .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .blue .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .blue .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.blue a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .blue a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .blue a.btn-outline-primary:focus, .blue a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .blue a.btn-outline-primary.disabled, .blue a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .blue a.btn-outline-primary:not(:disabled):not(.disabled):active, .blue a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .blue a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .blue a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.blue a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .blue a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .blue a.btn-outline-secondary:focus, .blue a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .blue a.btn-outline-secondary.disabled, .blue a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .blue a.btn-outline-secondary:not(:disabled):not(.disabled):active, .blue a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .blue a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .blue a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.blue a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .blue a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .blue a.btn-outline-success:focus, .blue a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .blue a.btn-outline-success.disabled, .blue a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .blue a.btn-outline-success:not(:disabled):not(.disabled):active, .blue a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .blue a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .blue a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.blue a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .blue a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .blue a.btn-outline-info:focus, .blue a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .blue a.btn-outline-info.disabled, .blue a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .blue a.btn-outline-info:not(:disabled):not(.disabled):active, .blue a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .blue a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .blue a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.blue a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .blue a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .blue a.btn-outline-warning:focus, .blue a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .blue a.btn-outline-warning.disabled, .blue a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .blue a.btn-outline-warning:not(:disabled):not(.disabled):active, .blue a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .blue a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .blue a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.blue a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .blue a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .blue a.btn-outline-danger:focus, .blue a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .blue a.btn-outline-danger.disabled, .blue a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .blue a.btn-outline-danger:not(:disabled):not(.disabled):active, .blue a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .blue a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .blue a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.blue a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .blue a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .blue a.btn-outline-light:focus, .blue a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .blue a.btn-outline-light.disabled, .blue a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .blue a.btn-outline-light:not(:disabled):not(.disabled):active, .blue a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .blue a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .blue a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.blue a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .blue a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .blue a.btn-outline-dark:focus, .blue a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .blue a.btn-outline-dark.disabled, .blue a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .blue a.btn-outline-dark:not(:disabled):not(.disabled):active, .blue a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .blue a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .blue a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .blue a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .blue a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.blue .btn-outline-primary,
.blue a.btn-outline-primary {
  color: #2196F3;
  border-color: #2196F3; }
  .blue .btn-outline-primary:focus, .blue .btn-outline-primary:hover, .blue .btn-outline-primary.disabled, .blue .btn-outline-primary:disabled, .blue .btn-outline-primary:not(:disabled):not(.disabled):active, .blue .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-primary.dropdown-toggle,
  .blue a.btn-outline-primary:focus,
  .blue a.btn-outline-primary:hover,
  .blue a.btn-outline-primary.disabled,
  .blue a.btn-outline-primary:disabled,
  .blue a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .blue a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .blue a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #2196F3;
    background-color: #2196F3; }

.blue .btn-outline-secondary,
.blue a.btn-outline-secondary {
  color: #FF6E40;
  border-color: #FF6E40; }
  .blue .btn-outline-secondary:focus, .blue .btn-outline-secondary:hover, .blue .btn-outline-secondary.disabled, .blue .btn-outline-secondary:disabled, .blue .btn-outline-secondary:not(:disabled):not(.disabled):active, .blue .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .blue .btn-outline-secondary.dropdown-toggle,
  .blue a.btn-outline-secondary:focus,
  .blue a.btn-outline-secondary:hover,
  .blue a.btn-outline-secondary.disabled,
  .blue a.btn-outline-secondary:disabled,
  .blue a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .blue a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .blue a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FF6E40;
    background-color: #FF6E40; }

.blue a,
.blue a.text-primary {
  color: #2196F3; }
  .blue a:focus, .blue a:hover,
  .blue a.text-primary:focus,
  .blue a.text-primary:hover {
    color: #0c7cd5; }

.blue .text-primary {
  color: #2196F3 !important; }

.blue .page-link {
  color: #2196F3; }
  .blue .page-link:focus, .blue .page-link:hover {
    color: #2196F3; }

.blue .bg-primary,
.blue .badge-primary {
  background-color: #2196F3 !important;
  color: #fff !important; }

.blue .btn-primary,
.blue .jr-btn-primary,
.blue a.btn-primary,
.blue a.jr-btn-primary {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #fff; }
  .blue .btn-primary:hover, .blue .btn-primary:focus, .blue .btn-primary:not([disabled]):not(.disabled):active,
  .blue .jr-btn-primary:hover,
  .blue .jr-btn-primary:focus,
  .blue .jr-btn-primary:not([disabled]):not(.disabled):active,
  .blue a.btn-primary:hover,
  .blue a.btn-primary:focus,
  .blue a.btn-primary:not([disabled]):not(.disabled):active,
  .blue a.jr-btn-primary:hover,
  .blue a.jr-btn-primary:focus,
  .blue a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #0d8aee !important;
    border-color: #0d8aee !important;
    color: #fff !important; }

.blue .bg-primary.lighten-4 {
  background-color: #BBDEFB !important; }

.blue .bg-primary.lighten-3 {
  background-color: #90CAF9 !important; }

.blue .bg-primary.lighten-2 {
  background-color: #64B5F6 !important; }

.blue .bg-primary.lighten-1 {
  background-color: #42A5F5 !important; }

.blue .bg-primary.darken-1 {
  background-color: #1E88E5 !important; }

.blue .bg-primary.darken-2 {
  background-color: #1976D2 !important; }

.blue .bg-primary.darken-3 {
  background-color: #1565C0 !important; }

.blue .bg-primary.darken-4 {
  background-color: #0D47A1 !important; }

.blue .bg-primary.accent-1 {
  background-color: #82B1FF !important; }

.blue .bg-primary.accent-2 {
  background-color: #448AFF !important; }

.blue .bg-primary.accent-3 {
  background-color: #2979FF !important; }

.blue .bg-primary.accent-4 {
  background-color: #2962FF !important; }

.blue a.text-secondary {
  color: #FF6E40; }
  .blue a.text-secondary:focus, .blue a.text-secondary:hover {
    color: #ff470d !important; }

.blue .text-secondary {
  color: #FF6E40 !important; }

.blue .bg-secondary,
.blue .badge-secondary {
  background-color: #FF6E40 !important;
  color: #fff !important; }

.blue .btn-secondary {
  background-color: #FF6E40;
  border-color: #FF6E40;
  color: #fff; }
  .blue .btn-secondary:hover, .blue .btn-secondary:focus, .blue .btn-secondary:active {
    background-color: #ff5b27 !important;
    border-color: #ff5b27 !important;
    color: #fff !important; }

.blue .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .blue .jr-flat-btn:hover, .blue .jr-flat-btn:focus, .blue .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.blue .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #2196F3 !important;
  border-color: transparent !important; }
  .blue .jr-flat-btn.jr-btn-primary:hover, .blue .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(33, 150, 243, 0.12) !important;
    color: #2196F3 !important; }

.blue .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .blue .bg-primary .jr-flat-btn.jr-btn-primary:hover, .blue .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.blue .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FF6E40 !important;
  border-color: #FF6E40 !important; }
  .blue .jr-flat-btn.jr-btn-secondary:hover, .blue .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 110, 64, 0.12) !important;
    color: #FF6E40 !important; }

.blue .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .blue .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .blue .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

@media screen and (max-width: 575px) {
  .blue .app-main-header .search-dropdown input {
    background-color: #fff; } }

.blue .color-theme-header {
  background-color: #2196F3; }

.blue .side-nav {
  background-color: #fff !important;
  color: #868e96 !important; }
  .blue .side-nav .user-profile {
    background-color: #f8f9fa;
    position: relative;
    z-index: 2; }
    .blue .side-nav .user-profile .text-white {
      color: #2196F3 !important; }
  .blue .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .blue .side-nav .user-detail .user-name {
    color: #2196F3; }

.blue ul.nav-menu li a {
  color: #868e96; }

.blue ul.nav-menu .nav-header {
  color: #868e96; }

.blue ul.nav-menu > li.open > a,
.blue ul.nav-menu > li > a:hover,
.blue ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #2196F3; }

.blue ul.nav-menu li.menu > a:focus:before,
.blue ul.nav-menu li.menu > a:hover:before {
  color: #2196F3; }

.blue ul.nav-menu li ul {
  background-color: #fff; }

.blue ul.nav-menu li.menu > a:before {
  color: #868e96; }

.blue ul.nav-menu li.menu.open > a:before,
.blue ul.nav-menu li.menu > a:focus:before,
.blue ul.nav-menu li.menu li > a:hover:before {
  color: #2196F3; }

.blue ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.blue ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #2196F3; }

.blue ul.nav-menu li.menu .sub-menu li > a:hover,
.blue ul.nav-menu li.menu .sub-menu li > a:focus,
.blue ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.blue ul.nav-menu li.menu .sub-menu li > a:focus:before,
.blue ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #2196F3; }

/*Header top Navbar Styles*/
.blue .navbar-nav li:hover > a,
.blue .navbar-nav li:focus > a {
  color: #2196F3; }

.blue .navbar-nav li a {
  color: #868e96; }

.blue .navbar-nav li a:hover,
.blue .navbar-nav li a:focus {
  color: #2196F3; }

.blue .navbar-nav li.nav-item.active > a {
  color: #2196F3; }

.blue .navbar-nav ul.sub-menu li a.active,
.blue .navbar-nav ul.sub-menu li.active > a {
  color: #2196F3; }

.blue .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.blue .navbar-nav .nav-arrow a:hover:before,
.blue .navbar-nav .nav-arrow a:focus:before {
  color: #2196F3; }

.blue .navbar-nav .nav-arrow:hover > a:before,
.blue .navbar-nav .nav-arrow:focus > a:before,
.blue .navbar-nav .nav-arrow.active > a:before {
  color: #2196F3; }

.blue .app-top-nav {
  background-color: #2196F3; }
  .blue .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .blue .app-top-nav .navbar-nav li.nav-item:hover > a,
  .blue .app-top-nav .navbar-nav li.nav-item:focus > a,
  .blue .app-top-nav .navbar-nav li.nav-item > a:hover,
  .blue .app-top-nav .navbar-nav li.nav-item > a:focus,
  .blue .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FF6E40; }

.blue .module-nav li a.active {
  border-color: #2196F3; }

.blue .rbc-event {
  background-color: #2196F3; }

.blue .rbc-event.rbc-selected {
  background-color: #0c7cd5; }

.blue .rbc-slot-selection {
  background-color: rgba(33, 150, 243, 0.7); }

.blue .rbc-toolbar button:active,
.blue .rbc-toolbar button.rbc-active {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:active:hover,
.blue .rbc-toolbar button.rbc-active:hover,
.blue .rbc-toolbar button:active:focus,
.blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-toolbar button:hover {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.blue .rbc-day-slot .rbc-event {
  border-color: #2196F3; }

.blue .chat-sidenav-title {
  color: #2196F3; }

.blue .chat-user-item.active, .blue .chat-user-item:hover {
  background-color: #e3f2fd; }

.blue .contact-list i {
  color: #2196F3; }

.blue .Collapsible__trigger {
  background: #2196F3; }

.blue .categories-list li a:focus,
.blue .categories-list li a:hover,
.blue .categories-list li.active a {
  color: #2196F3; }

.blue .login-content .form-control:focus {
  border-color: #2196F3; }

.blue .filter-with-bg-color ul li a {
  border-color: #2196F3; }
  .blue .filter-with-bg-color ul li a:hover, .blue .filter-with-bg-color ul li a:focus, .blue .filter-with-bg-color ul li a.active {
    background-color: #2196F3; }

.blue .profile-intro .icon {
  color: #2196F3; }

.blue .social-link li.active a,
.blue .social-link li a:hover,
.blue .social-link li a:focus {
  color: #FF6E40; }

.blue .actions {
  color: #FF6E40; }

.blue .table-hover tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.075); }

.blue .border-primary {
  border-color: #2196F3 !important; }

.blue .app-logo-content {
  background-color: #2196F3; }

.blue .app-social-block .social-link button {
  border: solid 1px #2196F3;
  color: #2196F3; }
  .blue .app-social-block .social-link button:hover, .blue .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #2196F3; }

.blue .btn-group > .jr-btn.active,
.blue .btn-group > .btn-default.active,
.blue .btn-group-vertical > .jr-btn.active,
.blue .btn-group-vertical > .btn-default.active {
  background-color: #2196F3;
  border-color: #2196F3; }

.blue .btn-group > .jr-flat-btn,
.blue .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .blue .btn-group > .jr-flat-btn.active,
  .blue .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #2196F3 !important;
    border-color: transparent !important; }
    .blue .btn-group > .jr-flat-btn.active:hover, .blue .btn-group > .jr-flat-btn.active:focus,
    .blue .btn-group-vertical > .jr-flat-btn.active:hover,
    .blue .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(33, 150, 243, 0.12) !important;
      color: #2196F3 !important; }

.blue .nav-pill-sales .nav-link.active {
  background-color: #2196F3;
  border-color: #2196F3; }

.blue .form-checkbox > input + .check,
.blue .form-checkbox > input.sm + .check {
  color: #2196F3; }

/*Theme Deep Purple Styles*/
.deep-purple .right-arrow {
  color: #673ab7; }
  .deep-purple .right-arrow:after {
    color: #673ab7; }

.deep-purple .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .deep-purple .btn-primary:focus, .deep-purple .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .deep-purple .btn-primary.disabled, .deep-purple .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .deep-purple .btn-primary:not(:disabled):not(.disabled):active, .deep-purple .btn-primary:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .deep-purple .btn-primary:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.deep-purple .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .deep-purple .btn-secondary:focus, .deep-purple .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .deep-purple .btn-secondary.disabled, .deep-purple .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .deep-purple .btn-secondary:not(:disabled):not(.disabled):active, .deep-purple .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .deep-purple .btn-secondary:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.deep-purple .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .deep-purple .btn-success:focus, .deep-purple .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .deep-purple .btn-success.disabled, .deep-purple .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .deep-purple .btn-success:not(:disabled):not(.disabled):active, .deep-purple .btn-success:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .deep-purple .btn-success:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.deep-purple .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .deep-purple .btn-info:focus, .deep-purple .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .deep-purple .btn-info.disabled, .deep-purple .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .deep-purple .btn-info:not(:disabled):not(.disabled):active, .deep-purple .btn-info:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .deep-purple .btn-info:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.deep-purple .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .deep-purple .btn-warning:focus, .deep-purple .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .deep-purple .btn-warning.disabled, .deep-purple .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .deep-purple .btn-warning:not(:disabled):not(.disabled):active, .deep-purple .btn-warning:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .deep-purple .btn-warning:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.deep-purple .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .deep-purple .btn-danger:focus, .deep-purple .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .deep-purple .btn-danger.disabled, .deep-purple .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .deep-purple .btn-danger:not(:disabled):not(.disabled):active, .deep-purple .btn-danger:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .deep-purple .btn-danger:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.deep-purple .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .deep-purple .btn-light:focus, .deep-purple .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .deep-purple .btn-light.disabled, .deep-purple .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .deep-purple .btn-light:not(:disabled):not(.disabled):active, .deep-purple .btn-light:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .deep-purple .btn-light:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.deep-purple .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .deep-purple .btn-dark:focus, .deep-purple .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .deep-purple .btn-dark.disabled, .deep-purple .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .deep-purple .btn-dark:not(:disabled):not(.disabled):active, .deep-purple .btn-dark:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .deep-purple .btn-dark:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.deep-purple a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .deep-purple a.btn-primary:focus, .deep-purple a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .deep-purple a.btn-primary.disabled, .deep-purple a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .deep-purple a.btn-primary:not(:disabled):not(.disabled):active, .deep-purple a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .deep-purple a.btn-primary:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.deep-purple a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .deep-purple a.btn-secondary:focus, .deep-purple a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .deep-purple a.btn-secondary.disabled, .deep-purple a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .deep-purple a.btn-secondary:not(:disabled):not(.disabled):active, .deep-purple a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .deep-purple a.btn-secondary:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.deep-purple a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .deep-purple a.btn-success:focus, .deep-purple a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .deep-purple a.btn-success.disabled, .deep-purple a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .deep-purple a.btn-success:not(:disabled):not(.disabled):active, .deep-purple a.btn-success:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .deep-purple a.btn-success:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.deep-purple a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .deep-purple a.btn-info:focus, .deep-purple a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .deep-purple a.btn-info.disabled, .deep-purple a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .deep-purple a.btn-info:not(:disabled):not(.disabled):active, .deep-purple a.btn-info:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .deep-purple a.btn-info:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.deep-purple a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .deep-purple a.btn-warning:focus, .deep-purple a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .deep-purple a.btn-warning.disabled, .deep-purple a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .deep-purple a.btn-warning:not(:disabled):not(.disabled):active, .deep-purple a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .deep-purple a.btn-warning:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.deep-purple a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .deep-purple a.btn-danger:focus, .deep-purple a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .deep-purple a.btn-danger.disabled, .deep-purple a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .deep-purple a.btn-danger:not(:disabled):not(.disabled):active, .deep-purple a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .deep-purple a.btn-danger:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.deep-purple a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .deep-purple a.btn-light:focus, .deep-purple a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .deep-purple a.btn-light.disabled, .deep-purple a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .deep-purple a.btn-light:not(:disabled):not(.disabled):active, .deep-purple a.btn-light:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .deep-purple a.btn-light:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.deep-purple a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .deep-purple a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .deep-purple a.btn-dark:focus, .deep-purple a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .deep-purple a.btn-dark.disabled, .deep-purple a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .deep-purple a.btn-dark:not(:disabled):not(.disabled):active, .deep-purple a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .deep-purple a.btn-dark:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.deep-purple .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .deep-purple .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .deep-purple .btn-outline-primary:focus, .deep-purple .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .deep-purple .btn-outline-primary.disabled, .deep-purple .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .deep-purple .btn-outline-primary:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .deep-purple .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.deep-purple .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .deep-purple .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .deep-purple .btn-outline-secondary:focus, .deep-purple .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .deep-purple .btn-outline-secondary.disabled, .deep-purple .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .deep-purple .btn-outline-secondary:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .deep-purple .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.deep-purple .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .deep-purple .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .deep-purple .btn-outline-success:focus, .deep-purple .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .deep-purple .btn-outline-success.disabled, .deep-purple .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .deep-purple .btn-outline-success:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .deep-purple .btn-outline-success:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.deep-purple .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .deep-purple .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .deep-purple .btn-outline-info:focus, .deep-purple .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .deep-purple .btn-outline-info.disabled, .deep-purple .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .deep-purple .btn-outline-info:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .deep-purple .btn-outline-info:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.deep-purple .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .deep-purple .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .deep-purple .btn-outline-warning:focus, .deep-purple .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .deep-purple .btn-outline-warning.disabled, .deep-purple .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .deep-purple .btn-outline-warning:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .deep-purple .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.deep-purple .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .deep-purple .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .deep-purple .btn-outline-danger:focus, .deep-purple .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .deep-purple .btn-outline-danger.disabled, .deep-purple .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .deep-purple .btn-outline-danger:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .deep-purple .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.deep-purple .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .deep-purple .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .deep-purple .btn-outline-light:focus, .deep-purple .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .deep-purple .btn-outline-light.disabled, .deep-purple .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .deep-purple .btn-outline-light:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .deep-purple .btn-outline-light:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.deep-purple .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .deep-purple .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .deep-purple .btn-outline-dark:focus, .deep-purple .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .deep-purple .btn-outline-dark.disabled, .deep-purple .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .deep-purple .btn-outline-dark:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .deep-purple .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .deep-purple .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.deep-purple a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .deep-purple a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .deep-purple a.btn-outline-primary:focus, .deep-purple a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .deep-purple a.btn-outline-primary.disabled, .deep-purple a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .deep-purple a.btn-outline-primary:not(:disabled):not(.disabled):active, .deep-purple a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .deep-purple a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.deep-purple a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .deep-purple a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .deep-purple a.btn-outline-secondary:focus, .deep-purple a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .deep-purple a.btn-outline-secondary.disabled, .deep-purple a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled):active, .deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.deep-purple a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .deep-purple a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .deep-purple a.btn-outline-success:focus, .deep-purple a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .deep-purple a.btn-outline-success.disabled, .deep-purple a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .deep-purple a.btn-outline-success:not(:disabled):not(.disabled):active, .deep-purple a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .deep-purple a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.deep-purple a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .deep-purple a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .deep-purple a.btn-outline-info:focus, .deep-purple a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .deep-purple a.btn-outline-info.disabled, .deep-purple a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .deep-purple a.btn-outline-info:not(:disabled):not(.disabled):active, .deep-purple a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .deep-purple a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.deep-purple a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .deep-purple a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .deep-purple a.btn-outline-warning:focus, .deep-purple a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .deep-purple a.btn-outline-warning.disabled, .deep-purple a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .deep-purple a.btn-outline-warning:not(:disabled):not(.disabled):active, .deep-purple a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .deep-purple a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.deep-purple a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .deep-purple a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .deep-purple a.btn-outline-danger:focus, .deep-purple a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .deep-purple a.btn-outline-danger.disabled, .deep-purple a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .deep-purple a.btn-outline-danger:not(:disabled):not(.disabled):active, .deep-purple a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .deep-purple a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.deep-purple a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .deep-purple a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .deep-purple a.btn-outline-light:focus, .deep-purple a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .deep-purple a.btn-outline-light.disabled, .deep-purple a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .deep-purple a.btn-outline-light:not(:disabled):not(.disabled):active, .deep-purple a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .deep-purple a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.deep-purple a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .deep-purple a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .deep-purple a.btn-outline-dark:focus, .deep-purple a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .deep-purple a.btn-outline-dark.disabled, .deep-purple a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .deep-purple a.btn-outline-dark:not(:disabled):not(.disabled):active, .deep-purple a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .deep-purple a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .deep-purple a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .deep-purple a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .deep-purple a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.deep-purple .btn-outline-primary,
.deep-purple a.btn-outline-primary {
  color: #673ab7;
  border-color: #673ab7; }
  .deep-purple .btn-outline-primary:focus, .deep-purple .btn-outline-primary:hover, .deep-purple .btn-outline-primary.disabled, .deep-purple .btn-outline-primary:disabled, .deep-purple .btn-outline-primary:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-primary.dropdown-toggle,
  .deep-purple a.btn-outline-primary:focus,
  .deep-purple a.btn-outline-primary:hover,
  .deep-purple a.btn-outline-primary.disabled,
  .deep-purple a.btn-outline-primary:disabled,
  .deep-purple a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .deep-purple a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .deep-purple a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #673ab7;
    background-color: #673ab7; }

.deep-purple .btn-outline-secondary,
.deep-purple a.btn-outline-secondary {
  color: #FFAB00;
  border-color: #FFAB00; }
  .deep-purple .btn-outline-secondary:focus, .deep-purple .btn-outline-secondary:hover, .deep-purple .btn-outline-secondary.disabled, .deep-purple .btn-outline-secondary:disabled, .deep-purple .btn-outline-secondary:not(:disabled):not(.disabled):active, .deep-purple .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .deep-purple .btn-outline-secondary.dropdown-toggle,
  .deep-purple a.btn-outline-secondary:focus,
  .deep-purple a.btn-outline-secondary:hover,
  .deep-purple a.btn-outline-secondary.disabled,
  .deep-purple a.btn-outline-secondary:disabled,
  .deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .deep-purple a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FFAB00;
    background-color: #FFAB00; }

.deep-purple a,
.deep-purple a.text-primary {
  color: #673ab7; }
  .deep-purple a:focus, .deep-purple a:hover,
  .deep-purple a.text-primary:focus,
  .deep-purple a.text-primary:hover {
    color: #512e90; }

.deep-purple .text-primary {
  color: #673ab7 !important; }

.deep-purple .page-link {
  color: #673ab7; }
  .deep-purple .page-link:focus, .deep-purple .page-link:hover {
    color: #673ab7; }

.deep-purple .bg-primary,
.deep-purple .badge-primary {
  background-color: #673ab7 !important;
  color: #fff !important; }

.deep-purple .btn-primary,
.deep-purple .jr-btn-primary,
.deep-purple a.btn-primary,
.deep-purple a.jr-btn-primary {
  background-color: #673ab7;
  border-color: #673ab7;
  color: #fff; }
  .deep-purple .btn-primary:hover, .deep-purple .btn-primary:focus, .deep-purple .btn-primary:not([disabled]):not(.disabled):active,
  .deep-purple .jr-btn-primary:hover,
  .deep-purple .jr-btn-primary:focus,
  .deep-purple .jr-btn-primary:not([disabled]):not(.disabled):active,
  .deep-purple a.btn-primary:hover,
  .deep-purple a.btn-primary:focus,
  .deep-purple a.btn-primary:not([disabled]):not(.disabled):active,
  .deep-purple a.jr-btn-primary:hover,
  .deep-purple a.jr-btn-primary:focus,
  .deep-purple a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #5c34a4 !important;
    border-color: #5c34a4 !important;
    color: #fff !important; }

.deep-purple .bg-primary.lighten-4 {
  background-color: #d1c4e9 !important; }

.deep-purple .bg-primary.lighten-3 {
  background-color: #b39ddb !important; }

.deep-purple .bg-primary.lighten-2 {
  background-color: #9575cd !important; }

.deep-purple .bg-primary.lighten-1 {
  background-color: #7e57c2 !important; }

.deep-purple .bg-primary.darken-1 {
  background-color: #5e35b1 !important; }

.deep-purple .bg-primary.darken-2 {
  background-color: #512da8 !important; }

.deep-purple .bg-primary.darken-3 {
  background-color: #4527a0 !important; }

.deep-purple .bg-primary.darken-4 {
  background-color: #311b92 !important; }

.deep-purple .bg-primary.accent-1 {
  background-color: #b388ff !important; }

.deep-purple .bg-primary.accent-2 {
  background-color: #7c4dff !important; }

.deep-purple .bg-primary.accent-3 {
  background-color: #651fff !important; }

.deep-purple .bg-primary.accent-4 {
  background-color: #6200ea !important; }

.deep-purple a.text-secondary {
  color: #FFAB00; }
  .deep-purple a.text-secondary:focus, .deep-purple a.text-secondary:hover {
    color: #cc8900 !important; }

.deep-purple .text-secondary {
  color: #FFAB00 !important; }

.deep-purple .bg-secondary,
.deep-purple .badge-secondary {
  background-color: #FFAB00 !important;
  color: #fff !important; }

.deep-purple .btn-secondary {
  background-color: #FFAB00;
  border-color: #FFAB00;
  color: #fff; }
  .deep-purple .btn-secondary:hover, .deep-purple .btn-secondary:focus, .deep-purple .btn-secondary:active {
    background-color: #e69a00 !important;
    border-color: #e69a00 !important;
    color: #fff !important; }

.deep-purple .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .deep-purple .jr-flat-btn:hover, .deep-purple .jr-flat-btn:focus, .deep-purple .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.deep-purple .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #673ab7 !important;
  border-color: transparent !important; }
  .deep-purple .jr-flat-btn.jr-btn-primary:hover, .deep-purple .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(103, 58, 183, 0.12) !important;
    color: #673ab7 !important; }

.deep-purple .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .deep-purple .bg-primary .jr-flat-btn.jr-btn-primary:hover, .deep-purple .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.deep-purple .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FFAB00 !important;
  border-color: transparent !important; }
  .deep-purple .jr-flat-btn.jr-btn-secondary:hover, .deep-purple .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 171, 0, 0.12) !important;
    color: #FFAB00 !important; }

.deep-purple .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .deep-purple .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .deep-purple .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

@media screen and (max-width: 575px) {
  .deep-purple .app-main-header .search-dropdown input {
    background-color: #fff; } }

.deep-purple .color-theme-header {
  background-color: #673ab7; }

.deep-purple .side-nav {
  background-color: #fff !important;
  color: #868e96 !important; }
  .deep-purple .side-nav .user-profile {
    background-color: #f8f9fa;
    position: relative;
    z-index: 2; }
    .deep-purple .side-nav .user-profile .text-white {
      color: #673ab7 !important; }
  .deep-purple .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .deep-purple .side-nav .user-detail .user-name {
    color: #673ab7; }

.deep-purple ul.nav-menu li a {
  color: #868e96; }

.deep-purple ul.nav-menu .nav-header {
  color: #868e96; }

.deep-purple ul.nav-menu > li.open > a,
.deep-purple ul.nav-menu > li > a:hover,
.deep-purple ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #673ab7; }

.deep-purple ul.nav-menu li.menu > a:focus:before,
.deep-purple ul.nav-menu li.menu > a:hover:before {
  color: #673ab7; }

.deep-purple ul.nav-menu li ul {
  background-color: #fff; }

.deep-purple ul.nav-menu li.menu > a:before {
  color: #868e96; }

.deep-purple ul.nav-menu li.menu.open > a:before,
.deep-purple ul.nav-menu li.menu > a:focus:before,
.deep-purple ul.nav-menu li.menu li > a:hover:before {
  color: #673ab7; }

.deep-purple ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.deep-purple ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #673ab7; }

.deep-purple ul.nav-menu li.menu .sub-menu li > a:hover,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:focus,
.deep-purple ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:focus:before,
.deep-purple ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #673ab7; }

/*Header top Navbar Styles*/
.deep-purple .navbar-nav li:hover > a,
.deep-purple .navbar-nav li:focus > a {
  color: #673ab7; }

.deep-purple .navbar-nav li a {
  color: #868e96; }

.deep-purple .navbar-nav li a:hover,
.deep-purple .navbar-nav li a:focus {
  color: #673ab7; }

.deep-purple .navbar-nav li.nav-item.active > a {
  color: #673ab7; }

.deep-purple .navbar-nav ul.sub-menu li a.active,
.deep-purple .navbar-nav ul.sub-menu li.active > a {
  color: #673ab7; }

.deep-purple .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.deep-purple .navbar-nav .nav-arrow a:hover:before,
.deep-purple .navbar-nav .nav-arrow a:focus:before {
  color: #673ab7; }

.deep-purple .navbar-nav .nav-arrow:hover > a:before,
.deep-purple .navbar-nav .nav-arrow:focus > a:before,
.deep-purple .navbar-nav .nav-arrow.active > a:before {
  color: #673ab7; }

.deep-purple .app-top-nav {
  background-color: #673ab7; }
  .deep-purple .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .deep-purple .app-top-nav .navbar-nav li.nav-item:hover > a,
  .deep-purple .app-top-nav .navbar-nav li.nav-item:focus > a,
  .deep-purple .app-top-nav .navbar-nav li.nav-item > a:hover,
  .deep-purple .app-top-nav .navbar-nav li.nav-item > a:focus,
  .deep-purple .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FFAB00; }

.deep-purple .module-nav li a.active {
  border-color: #673ab7; }

.deep-purple .rbc-event {
  background-color: #673ab7; }

.deep-purple .rbc-event.rbc-selected {
  background-color: #512e90; }

.deep-purple .rbc-slot-selection {
  background-color: rgba(103, 58, 183, 0.7); }

.deep-purple .rbc-toolbar button:active,
.deep-purple .rbc-toolbar button.rbc-active {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:active:hover,
.deep-purple .rbc-toolbar button.rbc-active:hover,
.deep-purple .rbc-toolbar button:active:focus,
.deep-purple .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-toolbar button:hover {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.deep-purple .rbc-day-slot .rbc-event {
  border-color: #673ab7; }

.deep-purple .chat-sidenav-title {
  color: #673ab7; }

.deep-purple .chat-user-item.active, .deep-purple .chat-user-item:hover {
  background-color: #e8e1f5; }

.deep-purple .contact-list i {
  color: #673ab7; }

.deep-purple .Collapsible__trigger {
  background: #673ab7; }

.deep-purple .categories-list li a:focus,
.deep-purple .categories-list li a:hover,
.deep-purple .categories-list li.active a {
  color: #673ab7; }

.deep-purple .login-content .form-control:focus {
  border-color: #673ab7; }

.deep-purple .filter-with-bg-color ul li a {
  border-color: #673ab7; }
  .deep-purple .filter-with-bg-color ul li a:hover, .deep-purple .filter-with-bg-color ul li a:focus, .deep-purple .filter-with-bg-color ul li a.active {
    background-color: #673ab7; }

.deep-purple .profile-intro .icon {
  color: #673ab7; }

.deep-purple .social-link li.active a,
.deep-purple .social-link li a:hover,
.deep-purple .social-link li a:focus {
  color: #FFAB00; }

.deep-purple .actions {
  color: #FFAB00; }

.deep-purple .table-hover tbody tr:hover {
  background-color: rgba(103, 58, 183, 0.075); }

.deep-purple .border-primary {
  border-color: #673ab7 !important; }

.deep-purple .app-logo-content {
  background-color: #673ab7; }

.deep-purple .app-social-block .social-link button {
  border: solid 1px #673ab7;
  color: #673ab7; }
  .deep-purple .app-social-block .social-link button:hover, .deep-purple .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #673ab7; }

.deep-purple .btn-group > .jr-btn.active,
.deep-purple .btn-group > .btn-default.active,
.deep-purple .btn-group-vertical > .jr-btn.active,
.deep-purple .btn-group-vertical > .btn-default.active {
  background-color: #673ab7;
  border-color: #673ab7; }

.deep-purple .btn-group > .jr-flat-btn,
.deep-purple .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .deep-purple .btn-group > .jr-flat-btn.active,
  .deep-purple .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #673ab7 !important;
    border-color: transparent !important; }
    .deep-purple .btn-group > .jr-flat-btn.active:hover, .deep-purple .btn-group > .jr-flat-btn.active:focus,
    .deep-purple .btn-group-vertical > .jr-flat-btn.active:hover,
    .deep-purple .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(103, 58, 183, 0.12) !important;
      color: #673ab7 !important; }

.deep-purple .nav-pill-sales .nav-link.active {
  background-color: #673ab7;
  border-color: #673ab7; }

.deep-purple .form-checkbox > input + .check,
.deep-purple .form-checkbox > input.sm + .check {
  color: #673ab7; }

/*Theme Green Styles*/
.green .right-arrow {
  color: #4CAF50; }
  .green .right-arrow:after {
    color: #4CAF50; }

.green .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .green .btn-primary:focus, .green .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .green .btn-primary.disabled, .green .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .green .btn-primary:not(:disabled):not(.disabled):active, .green .btn-primary:not(:disabled):not(.disabled).active,
  .show > .green .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .green .btn-primary:not(:disabled):not(.disabled):active:focus, .green .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.green .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .green .btn-secondary:focus, .green .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .green .btn-secondary.disabled, .green .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .green .btn-secondary:not(:disabled):not(.disabled):active, .green .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .green .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .green .btn-secondary:not(:disabled):not(.disabled):active:focus, .green .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.green .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .green .btn-success:focus, .green .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .green .btn-success.disabled, .green .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .green .btn-success:not(:disabled):not(.disabled):active, .green .btn-success:not(:disabled):not(.disabled).active,
  .show > .green .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .green .btn-success:not(:disabled):not(.disabled):active:focus, .green .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.green .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .green .btn-info:focus, .green .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .green .btn-info.disabled, .green .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .green .btn-info:not(:disabled):not(.disabled):active, .green .btn-info:not(:disabled):not(.disabled).active,
  .show > .green .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .green .btn-info:not(:disabled):not(.disabled):active:focus, .green .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.green .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .green .btn-warning:focus, .green .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .green .btn-warning.disabled, .green .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .green .btn-warning:not(:disabled):not(.disabled):active, .green .btn-warning:not(:disabled):not(.disabled).active,
  .show > .green .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .green .btn-warning:not(:disabled):not(.disabled):active:focus, .green .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.green .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .green .btn-danger:focus, .green .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .green .btn-danger.disabled, .green .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .green .btn-danger:not(:disabled):not(.disabled):active, .green .btn-danger:not(:disabled):not(.disabled).active,
  .show > .green .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .green .btn-danger:not(:disabled):not(.disabled):active:focus, .green .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.green .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .green .btn-light:focus, .green .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .green .btn-light.disabled, .green .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .green .btn-light:not(:disabled):not(.disabled):active, .green .btn-light:not(:disabled):not(.disabled).active,
  .show > .green .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .green .btn-light:not(:disabled):not(.disabled):active:focus, .green .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.green .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .green .btn-dark:focus, .green .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .green .btn-dark.disabled, .green .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .green .btn-dark:not(:disabled):not(.disabled):active, .green .btn-dark:not(:disabled):not(.disabled).active,
  .show > .green .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .green .btn-dark:not(:disabled):not(.disabled):active:focus, .green .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.green a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .green a.btn-primary:focus, .green a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .green a.btn-primary.disabled, .green a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .green a.btn-primary:not(:disabled):not(.disabled):active, .green a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .green a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .green a.btn-primary:not(:disabled):not(.disabled):active:focus, .green a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.green a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .green a.btn-secondary:focus, .green a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .green a.btn-secondary.disabled, .green a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .green a.btn-secondary:not(:disabled):not(.disabled):active, .green a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .green a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .green a.btn-secondary:not(:disabled):not(.disabled):active:focus, .green a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.green a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .green a.btn-success:focus, .green a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .green a.btn-success.disabled, .green a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .green a.btn-success:not(:disabled):not(.disabled):active, .green a.btn-success:not(:disabled):not(.disabled).active,
  .show > .green a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .green a.btn-success:not(:disabled):not(.disabled):active:focus, .green a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.green a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .green a.btn-info:focus, .green a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .green a.btn-info.disabled, .green a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .green a.btn-info:not(:disabled):not(.disabled):active, .green a.btn-info:not(:disabled):not(.disabled).active,
  .show > .green a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .green a.btn-info:not(:disabled):not(.disabled):active:focus, .green a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.green a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .green a.btn-warning:focus, .green a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .green a.btn-warning.disabled, .green a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .green a.btn-warning:not(:disabled):not(.disabled):active, .green a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .green a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .green a.btn-warning:not(:disabled):not(.disabled):active:focus, .green a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.green a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .green a.btn-danger:focus, .green a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .green a.btn-danger.disabled, .green a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .green a.btn-danger:not(:disabled):not(.disabled):active, .green a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .green a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .green a.btn-danger:not(:disabled):not(.disabled):active:focus, .green a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.green a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .green a.btn-light:focus, .green a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .green a.btn-light.disabled, .green a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .green a.btn-light:not(:disabled):not(.disabled):active, .green a.btn-light:not(:disabled):not(.disabled).active,
  .show > .green a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .green a.btn-light:not(:disabled):not(.disabled):active:focus, .green a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.green a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .green a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .green a.btn-dark:focus, .green a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .green a.btn-dark.disabled, .green a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .green a.btn-dark:not(:disabled):not(.disabled):active, .green a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .green a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .green a.btn-dark:not(:disabled):not(.disabled):active:focus, .green a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.green .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .green .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .green .btn-outline-primary:focus, .green .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .green .btn-outline-primary.disabled, .green .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .green .btn-outline-primary:not(:disabled):not(.disabled):active, .green .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .green .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .green .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.green .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .green .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .green .btn-outline-secondary:focus, .green .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .green .btn-outline-secondary.disabled, .green .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .green .btn-outline-secondary:not(:disabled):not(.disabled):active, .green .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .green .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .green .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.green .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .green .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .green .btn-outline-success:focus, .green .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .green .btn-outline-success.disabled, .green .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .green .btn-outline-success:not(:disabled):not(.disabled):active, .green .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .green .btn-outline-success:not(:disabled):not(.disabled):active:focus, .green .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.green .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .green .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .green .btn-outline-info:focus, .green .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .green .btn-outline-info.disabled, .green .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .green .btn-outline-info:not(:disabled):not(.disabled):active, .green .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .green .btn-outline-info:not(:disabled):not(.disabled):active:focus, .green .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.green .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .green .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .green .btn-outline-warning:focus, .green .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .green .btn-outline-warning.disabled, .green .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .green .btn-outline-warning:not(:disabled):not(.disabled):active, .green .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .green .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .green .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.green .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .green .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .green .btn-outline-danger:focus, .green .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .green .btn-outline-danger.disabled, .green .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .green .btn-outline-danger:not(:disabled):not(.disabled):active, .green .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .green .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .green .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.green .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .green .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .green .btn-outline-light:focus, .green .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .green .btn-outline-light.disabled, .green .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .green .btn-outline-light:not(:disabled):not(.disabled):active, .green .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .green .btn-outline-light:not(:disabled):not(.disabled):active:focus, .green .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.green .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .green .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .green .btn-outline-dark:focus, .green .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .green .btn-outline-dark.disabled, .green .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .green .btn-outline-dark:not(:disabled):not(.disabled):active, .green .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .green .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .green .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .green .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.green a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .green a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .green a.btn-outline-primary:focus, .green a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .green a.btn-outline-primary.disabled, .green a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .green a.btn-outline-primary:not(:disabled):not(.disabled):active, .green a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .green a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .green a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .green a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.green a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .green a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .green a.btn-outline-secondary:focus, .green a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .green a.btn-outline-secondary.disabled, .green a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .green a.btn-outline-secondary:not(:disabled):not(.disabled):active, .green a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .green a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .green a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .green a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.green a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .green a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .green a.btn-outline-success:focus, .green a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .green a.btn-outline-success.disabled, .green a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .green a.btn-outline-success:not(:disabled):not(.disabled):active, .green a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .green a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .green a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .green a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.green a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .green a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .green a.btn-outline-info:focus, .green a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .green a.btn-outline-info.disabled, .green a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .green a.btn-outline-info:not(:disabled):not(.disabled):active, .green a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .green a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .green a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .green a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.green a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .green a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .green a.btn-outline-warning:focus, .green a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .green a.btn-outline-warning.disabled, .green a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .green a.btn-outline-warning:not(:disabled):not(.disabled):active, .green a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .green a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .green a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .green a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.green a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .green a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .green a.btn-outline-danger:focus, .green a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .green a.btn-outline-danger.disabled, .green a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .green a.btn-outline-danger:not(:disabled):not(.disabled):active, .green a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .green a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .green a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .green a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.green a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .green a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .green a.btn-outline-light:focus, .green a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .green a.btn-outline-light.disabled, .green a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .green a.btn-outline-light:not(:disabled):not(.disabled):active, .green a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .green a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .green a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .green a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.green a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .green a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .green a.btn-outline-dark:focus, .green a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .green a.btn-outline-dark.disabled, .green a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .green a.btn-outline-dark:not(:disabled):not(.disabled):active, .green a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .green a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .green a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .green a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .green a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.green .btn-outline-primary,
.green a.btn-outline-primary {
  color: #4CAF50;
  border-color: #4CAF50; }
  .green .btn-outline-primary:focus, .green .btn-outline-primary:hover, .green .btn-outline-primary.disabled, .green .btn-outline-primary:disabled, .green .btn-outline-primary:not(:disabled):not(.disabled):active, .green .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-primary.dropdown-toggle,
  .green a.btn-outline-primary:focus,
  .green a.btn-outline-primary:hover,
  .green a.btn-outline-primary.disabled,
  .green a.btn-outline-primary:disabled,
  .green a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .green a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .green a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #4CAF50;
    background-color: #4CAF50; }

.green .btn-outline-secondary,
.green a.btn-outline-secondary {
  color: #FF6D00;
  border-color: #FF6D00; }
  .green .btn-outline-secondary:focus, .green .btn-outline-secondary:hover, .green .btn-outline-secondary.disabled, .green .btn-outline-secondary:disabled, .green .btn-outline-secondary:not(:disabled):not(.disabled):active, .green .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .green .btn-outline-secondary.dropdown-toggle,
  .green a.btn-outline-secondary:focus,
  .green a.btn-outline-secondary:hover,
  .green a.btn-outline-secondary.disabled,
  .green a.btn-outline-secondary:disabled,
  .green a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .green a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .green a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FF6D00;
    background-color: #FF6D00; }

.green a,
.green a.text-primary {
  color: #4CAF50; }
  .green a:focus, .green a:hover,
  .green a.text-primary:focus,
  .green a.text-primary:hover {
    color: #3d8b40; }

.green .text-primary {
  color: #4CAF50 !important; }

.green .page-link {
  color: #4CAF50; }
  .green .page-link:focus, .green .page-link:hover {
    color: #4CAF50; }

.green .bg-primary,
.green .badge-primary {
  background-color: #4CAF50 !important;
  color: #fff !important; }

.green .btn-primary,
.green .jr-btn-primary,
.green a.btn-primary,
.green a.jr-btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }
  .green .btn-primary:hover, .green .btn-primary:focus, .green .btn-primary:not([disabled]):not(.disabled):active,
  .green .jr-btn-primary:hover,
  .green .jr-btn-primary:focus,
  .green .jr-btn-primary:not([disabled]):not(.disabled):active,
  .green a.btn-primary:hover,
  .green a.btn-primary:focus,
  .green a.btn-primary:not([disabled]):not(.disabled):active,
  .green a.jr-btn-primary:hover,
  .green a.jr-btn-primary:focus,
  .green a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #449d48 !important;
    border-color: #449d48 !important;
    color: #fff !important; }

.green .bg-primary.lighten-4 {
  background-color: #C8E6C9 !important; }

.green .bg-primary.lighten-3 {
  background-color: #A5D6A7 !important; }

.green .bg-primary.lighten-2 {
  background-color: #81C784 !important; }

.green .bg-primary.lighten-1 {
  background-color: #66BB6A !important; }

.green .bg-primary.darken-1 {
  background-color: #43A047 !important; }

.green .bg-primary.darken-2 {
  background-color: #388E3C !important; }

.green .bg-primary.darken-3 {
  background-color: #2E7D32 !important; }

.green .bg-primary.darken-4 {
  background-color: #1B5E20 !important; }

.green .bg-primary.accent-1 {
  background-color: #B9F6CA !important; }

.green .bg-primary.accent-2 {
  background-color: #69F0AE !important; }

.green .bg-primary.accent-3 {
  background-color: #00E676 !important; }

.green .bg-primary.accent-4 {
  background-color: #00C853 !important; }

.green a.text-secondary {
  color: #FF6D00; }
  .green a.text-secondary:focus, .green a.text-secondary:hover {
    color: #cc5700 !important; }

.green .text-secondary {
  color: #FF6D00 !important; }

.green .bg-secondary,
.green .badge-secondary {
  background-color: #FF6D00 !important;
  color: #fff !important; }

.green .btn-secondary {
  background-color: #FF6D00;
  border-color: #FF6D00;
  color: #fff; }
  .green .btn-secondary:hover, .green .btn-secondary:focus, .green .btn-secondary:active {
    background-color: #e66200 !important;
    border-color: #e66200 !important;
    color: #fff !important; }

.green .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .green .jr-flat-btn:hover, .green .jr-flat-btn:focus, .green .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.green .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #4CAF50 !important;
  border-color: transparent !important; }
  .green .jr-flat-btn.jr-btn-primary:hover, .green .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(76, 175, 80, 0.12) !important;
    color: #4CAF50 !important; }

.green .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .green .bg-primary .jr-flat-btn.jr-btn-primary:hover, .green .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.green .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FF6D00 !important;
  border-color: transparent !important; }
  .green .jr-flat-btn.jr-btn-secondary:hover, .green .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 109, 0, 0.12) !important;
    color: #FF6D00 !important; }

.green .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .green .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .green .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

@media screen and (max-width: 575px) {
  .green .app-main-header .search-dropdown input {
    background-color: #fff; } }

.green .color-theme-header {
  background-color: #4CAF50; }

.green .side-nav {
  background-color: #fff !important;
  color: #868e96 !important;
  border-right: solid 1px #e9ecef !important; }
  .green .side-nav .user-profile {
    background-color: #f8f9fa;
    position: relative;
    z-index: 2; }
    .green .side-nav .user-profile .text-white {
      color: #4CAF50 !important; }
  .green .side-nav .customizer {
    border-bottom: solid 1px #e6e7e9; }
  .green .side-nav .user-detail .user-name {
    color: #4CAF50; }

.green ul.nav-menu li a {
  color: #868e96; }

.green ul.nav-menu .nav-header {
  color: #868e96; }

.green ul.nav-menu > li.open > a,
.green ul.nav-menu > li > a:hover,
.green ul.nav-menu > li > a:focus {
  background-color: #fff;
  color: #4CAF50; }

.green ul.nav-menu li.menu > a:focus:before,
.green ul.nav-menu li.menu > a:hover:before {
  color: #4CAF50; }

.green ul.nav-menu li ul {
  background-color: #fff; }

.green ul.nav-menu li.menu > a:before {
  color: #868e96; }

.green ul.nav-menu li.menu.open > a:before,
.green ul.nav-menu li.menu > a:focus:before,
.green ul.nav-menu li.menu li > a:hover:before {
  color: #4CAF50; }

.green ul.nav-menu li.menu .sub-menu li > a:before {
  color: #868e96; }

.green ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #4CAF50; }

.green ul.nav-menu li.menu .sub-menu li > a:hover,
.green ul.nav-menu li.menu .sub-menu li > a:focus,
.green ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.green ul.nav-menu li.menu .sub-menu li > a:focus:before,
.green ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #fff;
  color: #4CAF50; }

/*Header top Navbar Styles*/
.green .navbar-nav li:hover > a,
.green .navbar-nav li:focus > a {
  color: #4CAF50; }

.green .navbar-nav li a {
  color: #868e96; }

.green .navbar-nav li a:hover,
.green .navbar-nav li a:focus {
  color: #4CAF50; }

.green .navbar-nav li.nav-item.active > a {
  color: #4CAF50; }

.green .navbar-nav ul.sub-menu li a.active,
.green .navbar-nav ul.sub-menu li.active > a {
  color: #4CAF50; }

.green .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.green .navbar-nav .nav-arrow a:hover:before,
.green .navbar-nav .nav-arrow a:focus:before {
  color: #4CAF50; }

.green .navbar-nav .nav-arrow:hover > a:before,
.green .navbar-nav .nav-arrow:focus > a:before,
.green .navbar-nav .nav-arrow.active > a:before {
  color: #4CAF50; }

.green .app-top-nav {
  background-color: #4CAF50; }
  .green .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .green .app-top-nav .navbar-nav li.nav-item:hover > a,
  .green .app-top-nav .navbar-nav li.nav-item:focus > a,
  .green .app-top-nav .navbar-nav li.nav-item > a:hover,
  .green .app-top-nav .navbar-nav li.nav-item > a:focus,
  .green .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FF6D00; }

.green .module-nav li a.active {
  border-color: #4CAF50; }

.green .rbc-event {
  background-color: #4CAF50; }

.green .rbc-event.rbc-selected {
  background-color: #3d8b40; }

.green .rbc-slot-selection {
  background-color: rgba(76, 175, 80, 0.7); }

.green .rbc-toolbar button:active,
.green .rbc-toolbar button.rbc-active {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:active:hover,
.green .rbc-toolbar button.rbc-active:hover,
.green .rbc-toolbar button:active:focus,
.green .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-toolbar button:hover {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.green .rbc-day-slot .rbc-event {
  border-color: #4CAF50; }

.green .chat-sidenav-title {
  color: #4CAF50; }

.green .chat-user-item.active, .green .chat-user-item:hover {
  background-color: #eaf6eb; }

.green .contact-list i {
  color: #4CAF50; }

.green .Collapsible__trigger {
  background: #4CAF50; }

.green .categories-list li a:focus,
.green .categories-list li a:hover,
.green .categories-list li.active a {
  color: #4CAF50; }

.green .login-content .form-control:focus {
  border-color: #4CAF50; }

.green .filter-with-bg-color ul li a {
  border-color: #4CAF50; }
  .green .filter-with-bg-color ul li a:hover, .green .filter-with-bg-color ul li a:focus, .green .filter-with-bg-color ul li a.active {
    background-color: #4CAF50; }

.green .profile-intro .icon {
  color: #4CAF50; }

.green .social-link li.active a,
.green .social-link li a:hover,
.green .social-link li a:focus {
  color: #FF6D00; }

.green .actions {
  color: #FF6D00; }

.green .table-hover tbody tr:hover {
  background-color: rgba(76, 175, 80, 0.075); }

.green .border-primary {
  border-color: #4CAF50 !important; }

.green .app-logo-content {
  background-color: #4CAF50; }

.green .app-social-block .social-link button {
  border: solid 1px #4CAF50;
  color: #4CAF50; }
  .green .app-social-block .social-link button:hover, .green .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #4CAF50; }

.green .btn-group > .jr-btn.active,
.green .btn-group > .btn-default.active,
.green .btn-group-vertical > .jr-btn.active,
.green .btn-group-vertical > .btn-default.active {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.green .btn-group > .jr-flat-btn,
.green .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .green .btn-group > .jr-flat-btn.active,
  .green .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #4CAF50 !important;
    border-color: transparent !important; }
    .green .btn-group > .jr-flat-btn.active:hover, .green .btn-group > .jr-flat-btn.active:focus,
    .green .btn-group-vertical > .jr-flat-btn.active:hover,
    .green .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(76, 175, 80, 0.12) !important;
      color: #4CAF50 !important; }

.green .nav-pill-sales .nav-link.active {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.green .form-checkbox > input + .check,
.green .form-checkbox > input.sm + .check {
  color: #4CAF50; }

/*Theme Dark Indigo Styles*/
.dark-indigo .right-arrow {
  color: #3f51b5; }
  .dark-indigo .right-arrow:after {
    color: #3f51b5; }

.dark-indigo .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-indigo .btn-primary:focus, .dark-indigo .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-indigo .btn-primary.disabled, .dark-indigo .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-indigo .btn-primary:not(:disabled):not(.disabled):active, .dark-indigo .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-indigo .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-indigo .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-indigo .btn-secondary:focus, .dark-indigo .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-indigo .btn-secondary.disabled, .dark-indigo .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-indigo .btn-secondary:not(:disabled):not(.disabled):active, .dark-indigo .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-indigo .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-indigo .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-indigo .btn-success:focus, .dark-indigo .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-indigo .btn-success.disabled, .dark-indigo .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-indigo .btn-success:not(:disabled):not(.disabled):active, .dark-indigo .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-indigo .btn-success:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-indigo .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-indigo .btn-info:focus, .dark-indigo .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-indigo .btn-info.disabled, .dark-indigo .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-indigo .btn-info:not(:disabled):not(.disabled):active, .dark-indigo .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-indigo .btn-info:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-indigo .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-indigo .btn-warning:focus, .dark-indigo .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-indigo .btn-warning.disabled, .dark-indigo .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-indigo .btn-warning:not(:disabled):not(.disabled):active, .dark-indigo .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-indigo .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-indigo .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-indigo .btn-danger:focus, .dark-indigo .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-indigo .btn-danger.disabled, .dark-indigo .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-indigo .btn-danger:not(:disabled):not(.disabled):active, .dark-indigo .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-indigo .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-indigo .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-indigo .btn-light:focus, .dark-indigo .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-indigo .btn-light.disabled, .dark-indigo .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-indigo .btn-light:not(:disabled):not(.disabled):active, .dark-indigo .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-indigo .btn-light:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-indigo .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-indigo .btn-dark:focus, .dark-indigo .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-indigo .btn-dark.disabled, .dark-indigo .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-indigo .btn-dark:not(:disabled):not(.disabled):active, .dark-indigo .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-indigo .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-indigo a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-indigo a.btn-primary:focus, .dark-indigo a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-indigo a.btn-primary.disabled, .dark-indigo a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-indigo a.btn-primary:not(:disabled):not(.disabled):active, .dark-indigo a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-indigo a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-indigo a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-indigo a.btn-secondary:focus, .dark-indigo a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-indigo a.btn-secondary.disabled, .dark-indigo a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-indigo a.btn-secondary:not(:disabled):not(.disabled):active, .dark-indigo a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-indigo a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-indigo a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-indigo a.btn-success:focus, .dark-indigo a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-indigo a.btn-success.disabled, .dark-indigo a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-indigo a.btn-success:not(:disabled):not(.disabled):active, .dark-indigo a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-indigo a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-indigo a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-indigo a.btn-info:focus, .dark-indigo a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-indigo a.btn-info.disabled, .dark-indigo a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-indigo a.btn-info:not(:disabled):not(.disabled):active, .dark-indigo a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-indigo a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-indigo a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-indigo a.btn-warning:focus, .dark-indigo a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-indigo a.btn-warning.disabled, .dark-indigo a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-indigo a.btn-warning:not(:disabled):not(.disabled):active, .dark-indigo a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-indigo a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-indigo a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-indigo a.btn-danger:focus, .dark-indigo a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-indigo a.btn-danger.disabled, .dark-indigo a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-indigo a.btn-danger:not(:disabled):not(.disabled):active, .dark-indigo a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-indigo a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-indigo a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-indigo a.btn-light:focus, .dark-indigo a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-indigo a.btn-light.disabled, .dark-indigo a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-indigo a.btn-light:not(:disabled):not(.disabled):active, .dark-indigo a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-indigo a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-indigo a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-indigo a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-indigo a.btn-dark:focus, .dark-indigo a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-indigo a.btn-dark.disabled, .dark-indigo a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-indigo a.btn-dark:not(:disabled):not(.disabled):active, .dark-indigo a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-indigo a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-indigo .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-indigo .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-indigo .btn-outline-primary:focus, .dark-indigo .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-indigo .btn-outline-primary.disabled, .dark-indigo .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-indigo .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-indigo .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-indigo .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-indigo .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-indigo .btn-outline-secondary:focus, .dark-indigo .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-indigo .btn-outline-secondary.disabled, .dark-indigo .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-indigo .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-indigo .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-indigo .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-indigo .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-indigo .btn-outline-success:focus, .dark-indigo .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-indigo .btn-outline-success.disabled, .dark-indigo .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-indigo .btn-outline-success:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-indigo .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-indigo .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-indigo .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-indigo .btn-outline-info:focus, .dark-indigo .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-indigo .btn-outline-info.disabled, .dark-indigo .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-indigo .btn-outline-info:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-indigo .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-indigo .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-indigo .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-indigo .btn-outline-warning:focus, .dark-indigo .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-indigo .btn-outline-warning.disabled, .dark-indigo .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-indigo .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-indigo .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-indigo .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-indigo .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-indigo .btn-outline-danger:focus, .dark-indigo .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-indigo .btn-outline-danger.disabled, .dark-indigo .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-indigo .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-indigo .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-indigo .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-indigo .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-indigo .btn-outline-light:focus, .dark-indigo .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-indigo .btn-outline-light.disabled, .dark-indigo .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-indigo .btn-outline-light:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-indigo .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-indigo .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-indigo .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-indigo .btn-outline-dark:focus, .dark-indigo .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-indigo .btn-outline-dark.disabled, .dark-indigo .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-indigo .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-indigo .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-indigo .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-indigo a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-indigo a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-indigo a.btn-outline-primary:focus, .dark-indigo a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-indigo a.btn-outline-primary.disabled, .dark-indigo a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-indigo a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-indigo a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-indigo a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-indigo a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-indigo a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-indigo a.btn-outline-secondary:focus, .dark-indigo a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-indigo a.btn-outline-secondary.disabled, .dark-indigo a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-indigo a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-indigo a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-indigo a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-indigo a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-indigo a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-indigo a.btn-outline-success:focus, .dark-indigo a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-indigo a.btn-outline-success.disabled, .dark-indigo a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-indigo a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-indigo a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-indigo a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-indigo a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-indigo a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-indigo a.btn-outline-info:focus, .dark-indigo a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-indigo a.btn-outline-info.disabled, .dark-indigo a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-indigo a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-indigo a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-indigo a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-indigo a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-indigo a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-indigo a.btn-outline-warning:focus, .dark-indigo a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-indigo a.btn-outline-warning.disabled, .dark-indigo a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-indigo a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-indigo a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-indigo a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-indigo a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-indigo a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-indigo a.btn-outline-danger:focus, .dark-indigo a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-indigo a.btn-outline-danger.disabled, .dark-indigo a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-indigo a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-indigo a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-indigo a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-indigo a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-indigo a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-indigo a.btn-outline-light:focus, .dark-indigo a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-indigo a.btn-outline-light.disabled, .dark-indigo a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-indigo a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-indigo a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-indigo a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-indigo a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-indigo a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-indigo a.btn-outline-dark:focus, .dark-indigo a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-indigo a.btn-outline-dark.disabled, .dark-indigo a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-indigo a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-indigo a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-indigo a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-indigo a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-indigo a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-indigo a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-indigo .btn-outline-primary,
.dark-indigo a.btn-outline-primary {
  color: #3f51b5;
  border-color: #3f51b5; }
  .dark-indigo .btn-outline-primary:focus, .dark-indigo .btn-outline-primary:hover, .dark-indigo .btn-outline-primary.disabled, .dark-indigo .btn-outline-primary:disabled, .dark-indigo .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-primary.dropdown-toggle,
  .dark-indigo a.btn-outline-primary:focus,
  .dark-indigo a.btn-outline-primary:hover,
  .dark-indigo a.btn-outline-primary.disabled,
  .dark-indigo a.btn-outline-primary:disabled,
  .dark-indigo a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-indigo a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-indigo a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #3f51b5;
    background-color: #3f51b5; }

.dark-indigo .btn-outline-secondary,
.dark-indigo a.btn-outline-secondary {
  color: #FF4081;
  border-color: #FF4081; }
  .dark-indigo .btn-outline-secondary:focus, .dark-indigo .btn-outline-secondary:hover, .dark-indigo .btn-outline-secondary.disabled, .dark-indigo .btn-outline-secondary:disabled, .dark-indigo .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-indigo .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-indigo .btn-outline-secondary.dropdown-toggle,
  .dark-indigo a.btn-outline-secondary:focus,
  .dark-indigo a.btn-outline-secondary:hover,
  .dark-indigo a.btn-outline-secondary.disabled,
  .dark-indigo a.btn-outline-secondary:disabled,
  .dark-indigo a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-indigo a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-indigo a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FF4081;
    background-color: #FF4081; }

.dark-indigo a,
.dark-indigo a.text-primary {
  color: #3f51b5; }
  .dark-indigo a:focus, .dark-indigo a:hover,
  .dark-indigo a.text-primary:focus,
  .dark-indigo a.text-primary:hover {
    color: #32408f; }

.dark-indigo .text-primary {
  color: #3f51b5 !important; }

.dark-indigo .page-link {
  color: #3f51b5; }
  .dark-indigo .page-link:focus, .dark-indigo .page-link:hover {
    color: #3f51b5; }

.dark-indigo .bg-primary,
.dark-indigo .badge-primary {
  background-color: #3f51b5 !important;
  color: #fff !important; }

.dark-indigo .btn-primary,
.dark-indigo .jr-btn-primary,
.dark-indigo a.btn-primary,
.dark-indigo a.jr-btn-primary {
  background-color: #3f51b5;
  border-color: #3f51b5;
  color: #fff; }
  .dark-indigo .btn-primary:hover, .dark-indigo .btn-primary:focus, .dark-indigo .btn-primary:not([disabled]):not(.disabled):active,
  .dark-indigo .jr-btn-primary:hover,
  .dark-indigo .jr-btn-primary:focus,
  .dark-indigo .jr-btn-primary:not([disabled]):not(.disabled):active,
  .dark-indigo a.btn-primary:hover,
  .dark-indigo a.btn-primary:focus,
  .dark-indigo a.btn-primary:not([disabled]):not(.disabled):active,
  .dark-indigo a.jr-btn-primary:hover,
  .dark-indigo a.jr-btn-primary:focus,
  .dark-indigo a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #3849a2 !important;
    border-color: #3849a2 !important;
    color: #fff !important; }

.dark-indigo .bg-primary.lighten-4 {
  background-color: #c5cae9 !important; }

.dark-indigo .bg-primary.lighten-3 {
  background-color: #9fa8da !important; }

.dark-indigo .bg-primary.lighten-2 {
  background-color: #7986cb !important; }

.dark-indigo .bg-primary.lighten-1 {
  background-color: #5c6bc0 !important; }

.dark-indigo .bg-primary.darken-1 {
  background-color: #3949ab !important; }

.dark-indigo .bg-primary.darken-2 {
  background-color: #303f9f !important; }

.dark-indigo .bg-primary.darken-3 {
  background-color: #283593 !important; }

.dark-indigo .bg-primary.darken-4 {
  background-color: #1a237e !important; }

.dark-indigo .bg-primary.accent-1 {
  background-color: #8c9eff !important; }

.dark-indigo .bg-primary.accent-2 {
  background-color: #536dfe !important; }

.dark-indigo .bg-primary.accent-3 {
  background-color: #3d5afe !important; }

.dark-indigo .bg-primary.accent-4 {
  background-color: #304ffe !important; }

.dark-indigo a.text-secondary {
  color: #FF4081; }
  .dark-indigo a.text-secondary:focus, .dark-indigo a.text-secondary:hover {
    color: #ff0d5f !important; }

.dark-indigo .text-secondary {
  color: #FF4081 !important; }

.dark-indigo .bg-secondary,
.dark-indigo .badge-secondary {
  background-color: #FF4081 !important;
  color: #fff !important; }

.dark-indigo .btn-secondary {
  background-color: #FF4081;
  border-color: #FF4081;
  color: #fff; }
  .dark-indigo .btn-secondary:hover, .dark-indigo .btn-secondary:focus, .dark-indigo .btn-secondary:active {
    background-color: #ff2770 !important;
    border-color: #ff2770 !important;
    color: #fff !important; }

.dark-indigo .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .dark-indigo .jr-flat-btn:hover, .dark-indigo .jr-flat-btn:focus, .dark-indigo .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.dark-indigo .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #3f51b5 !important;
  border-color: transparent !important; }
  .dark-indigo .jr-flat-btn.jr-btn-primary:hover, .dark-indigo .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(63, 81, 181, 0.12) !important;
    color: #3f51b5 !important; }

.dark-indigo .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-indigo .bg-primary .jr-flat-btn.jr-btn-primary:hover, .dark-indigo .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-indigo .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FF4081 !important;
  border-color: transparent !important; }
  .dark-indigo .jr-flat-btn.jr-btn-secondary:hover, .dark-indigo .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 64, 129, 0.12) !important;
    color: #FF4081 !important; }

.dark-indigo .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-indigo .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .dark-indigo .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-indigo .app-main-header {
  background-color: #3f51b5 !important;
  color: #fff;
  border-color: #3f51b5; }
  .dark-indigo .app-main-header .search-bar input {
    background-color: #4d5ec1; }
    .dark-indigo .app-main-header .search-bar input + .search-icon i {
      color: #fff; }
    .dark-indigo .app-main-header .search-bar input:focus {
      background-color: #fff; }
      .dark-indigo .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  @media screen and (max-width: 575px) {
    .dark-indigo .app-main-header .search-dropdown input {
      background-color: #fff; }
      .dark-indigo .app-main-header .search-dropdown input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); } }
  .dark-indigo .app-main-header .jr-menu-icon .menu-icon {
    background-color: #fff !important; }

.dark-indigo .app-header .jr-menu-icon .menu-icon {
  background-color: #fff; }

.dark-indigo .quick-menu .icon-btn {
  color: #fff; }

.dark-indigo .react-logo-white.d-block {
  display: block !important; }

@media screen and (min-width: 992px) {
  .dark-indigo .react-logo-white.d-lg-block {
    display: block !important; }
  .dark-indigo .react-logo-white.d-lg-none {
    display: none !important; } }

.dark-indigo .react-logo-color.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .dark-indigo .react-logo-color.d-lg-block {
    display: none !important; } }

.dark-indigo .color-theme-header {
  background-color: #3f51b5; }

.dark-indigo .sidebar-header {
  border-bottom: 0 none;
  background-color: #1d1d1d; }

.dark-indigo .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important; }
  .dark-indigo .side-nav .user-profile {
    background-color: #1d1d1d;
    position: relative;
    z-index: 2; }
  .dark-indigo .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-indigo .side-nav .user-detail .user-name {
    color: #fff; }

.dark-indigo ul.nav-menu li a {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu > li.open > a,
.dark-indigo ul.nav-menu > li > a:hover,
.dark-indigo ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-indigo ul.nav-menu li.menu.open > a {
  border-color: #3f51b5; }

.dark-indigo ul.nav-menu li.menu > a:focus:before,
.dark-indigo ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-indigo ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-indigo ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu li.menu.open > a:before,
.dark-indigo ul.nav-menu li.menu > a:focus:before,
.dark-indigo ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-indigo ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-indigo ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #3f51b5; }

.dark-indigo ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-indigo ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-indigo ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-indigo .navbar-nav li:hover > a,
.dark-indigo .navbar-nav li:focus > a {
  color: #3f51b5; }

.dark-indigo .navbar-nav li a {
  color: #868e96; }

.dark-indigo .navbar-nav li a:hover,
.dark-indigo .navbar-nav li a:focus {
  color: #3f51b5; }

.dark-indigo .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-indigo .navbar-nav li.nav-item:hover > a,
.dark-indigo .navbar-nav li.nav-item:focus > a,
.dark-indigo .navbar-nav li.nav-item > a:hover,
.dark-indigo .navbar-nav li.nav-item > a:focus,
.dark-indigo .navbar-nav li.nav-item.active > a {
  color: #FF4081; }

.dark-indigo .navbar-nav ul.sub-menu li a.active,
.dark-indigo .navbar-nav ul.sub-menu li.active > a {
  color: #3f51b5; }

.dark-indigo .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-indigo .navbar-nav .nav-arrow a:hover:before,
.dark-indigo .navbar-nav .nav-arrow a:focus:before {
  color: #3f51b5; }

.dark-indigo .navbar-nav .nav-arrow:hover > a:before,
.dark-indigo .navbar-nav .nav-arrow:focus > a:before,
.dark-indigo .navbar-nav .nav-arrow.active > a:before {
  color: #3f51b5; }

.dark-indigo .app-top-nav {
  background-color: #252525 !important;
  border-color: #252525; }
  .dark-indigo .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-indigo .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-indigo .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-indigo .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-indigo .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-indigo .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FF4081; }

.dark-indigo .module-nav li a.active {
  color: #3f51b5; }

.dark-indigo .rbc-event {
  background-color: #3f51b5; }

.dark-indigo .rbc-event.rbc-selected {
  background-color: #32408f; }

.dark-indigo .rbc-slot-selection {
  background-color: rgba(63, 81, 181, 0.7); }

.dark-indigo .rbc-toolbar button:active,
.dark-indigo .rbc-toolbar button.rbc-active {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:active:hover,
.dark-indigo .rbc-toolbar button.rbc-active:hover,
.dark-indigo .rbc-toolbar button:active:focus,
.dark-indigo .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:focus {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-toolbar button:hover {
  background-color: rgba(63, 81, 181, 0.9);
  border-color: #3f51b5; }

.dark-indigo .rbc-day-slot .rbc-event {
  border-color: #3f51b5; }

.dark-indigo .chat-sidenav-title {
  color: #3f51b5; }

.dark-indigo .chat-user-item.active, .dark-indigo .chat-user-item:hover {
  background-color: #e4e7f6; }

.dark-indigo .contact-list i {
  color: #3f51b5; }

.dark-indigo .Collapsible__trigger {
  background: #3f51b5; }

.dark-indigo .categories-list li a:focus,
.dark-indigo .categories-list li a:hover,
.dark-indigo .categories-list li.active a {
  color: #3f51b5; }

.dark-indigo .login-content .form-control:focus {
  border-color: #3f51b5; }

.dark-indigo .filter-with-bg-color ul li a {
  border-color: #3f51b5; }
  .dark-indigo .filter-with-bg-color ul li a:hover, .dark-indigo .filter-with-bg-color ul li a:focus, .dark-indigo .filter-with-bg-color ul li a.active {
    background-color: #3f51b5; }

.dark-indigo .profile-intro .icon {
  color: #3f51b5; }

.dark-indigo .social-link li.active a,
.dark-indigo .social-link li a:hover,
.dark-indigo .social-link li a:focus {
  color: #FF4081; }

.dark-indigo .actions {
  color: #FF4081; }

.dark-indigo .table-hover tbody tr:hover {
  background-color: rgba(63, 81, 181, 0.075); }

.dark-indigo .border-primary {
  border-color: #3f51b5 !important; }

.dark-indigo .app-logo-content {
  background-color: #3f51b5; }

.dark-indigo .app-social-block .social-link button {
  border: solid 1px #3f51b5;
  color: #3f51b5; }
  .dark-indigo .app-social-block .social-link button:hover, .dark-indigo .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #3f51b5; }

.dark-indigo .btn-group > .jr-btn.active,
.dark-indigo .btn-group > .btn-default.active,
.dark-indigo .btn-group-vertical > .jr-btn.active,
.dark-indigo .btn-group-vertical > .btn-default.active {
  background-color: #3f51b5;
  border-color: #3f51b5; }

.dark-indigo .btn-group > .jr-flat-btn,
.dark-indigo .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-indigo .btn-group > .jr-flat-btn.active,
  .dark-indigo .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #3f51b5 !important;
    border-color: transparent !important; }
    .dark-indigo .btn-group > .jr-flat-btn.active:hover, .dark-indigo .btn-group > .jr-flat-btn.active:focus,
    .dark-indigo .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-indigo .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(63, 81, 181, 0.12) !important;
      color: #3f51b5 !important; }

.dark-indigo .nav-pill-sales .nav-link.active {
  background-color: #3f51b5;
  border-color: #3f51b5; }

.dark-indigo .switch.on {
  background: #3f51b5; }

.dark-indigo .form-checkbox > input + .check,
.dark-indigo .form-checkbox > input.sm + .check {
  color: #3f51b5; }

/* Input Placeholder Style */
.dark-indigo .app-main-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb; }

.dark-indigo .app-main-header ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb; }

.dark-indigo .app-main-header :-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb; }

.dark-indigo .app-main-header :-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb; }

.dark-indigo .app-main-header input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gainsboro; }

.dark-indigo .app-main-header input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: gainsboro; }

.dark-indigo .app-main-header input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: gainsboro; }

.dark-indigo .app-main-header input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: gainsboro; }

/*Theme Dark Cyan Styles*/
.dark-cyan .right-arrow {
  color: #00bcd4; }
  .dark-cyan .right-arrow:after {
    color: #00bcd4; }

.dark-cyan .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-cyan .btn-primary:focus, .dark-cyan .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-cyan .btn-primary.disabled, .dark-cyan .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-cyan .btn-primary:not(:disabled):not(.disabled):active, .dark-cyan .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-cyan .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-cyan .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-cyan .btn-secondary:focus, .dark-cyan .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-cyan .btn-secondary.disabled, .dark-cyan .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-cyan .btn-secondary:not(:disabled):not(.disabled):active, .dark-cyan .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-cyan .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-cyan .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-cyan .btn-success:focus, .dark-cyan .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-cyan .btn-success.disabled, .dark-cyan .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-cyan .btn-success:not(:disabled):not(.disabled):active, .dark-cyan .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-cyan .btn-success:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-cyan .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-cyan .btn-info:focus, .dark-cyan .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-cyan .btn-info.disabled, .dark-cyan .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-cyan .btn-info:not(:disabled):not(.disabled):active, .dark-cyan .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-cyan .btn-info:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-cyan .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-cyan .btn-warning:focus, .dark-cyan .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-cyan .btn-warning.disabled, .dark-cyan .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-cyan .btn-warning:not(:disabled):not(.disabled):active, .dark-cyan .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-cyan .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-cyan .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-cyan .btn-danger:focus, .dark-cyan .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-cyan .btn-danger.disabled, .dark-cyan .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-cyan .btn-danger:not(:disabled):not(.disabled):active, .dark-cyan .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-cyan .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-cyan .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-cyan .btn-light:focus, .dark-cyan .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-cyan .btn-light.disabled, .dark-cyan .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-cyan .btn-light:not(:disabled):not(.disabled):active, .dark-cyan .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-cyan .btn-light:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-cyan .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-cyan .btn-dark:focus, .dark-cyan .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-cyan .btn-dark.disabled, .dark-cyan .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-cyan .btn-dark:not(:disabled):not(.disabled):active, .dark-cyan .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-cyan .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-cyan a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-cyan a.btn-primary:focus, .dark-cyan a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-cyan a.btn-primary.disabled, .dark-cyan a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-cyan a.btn-primary:not(:disabled):not(.disabled):active, .dark-cyan a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-cyan a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-cyan a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-cyan a.btn-secondary:focus, .dark-cyan a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-cyan a.btn-secondary.disabled, .dark-cyan a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-cyan a.btn-secondary:not(:disabled):not(.disabled):active, .dark-cyan a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-cyan a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-cyan a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-cyan a.btn-success:focus, .dark-cyan a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-cyan a.btn-success.disabled, .dark-cyan a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-cyan a.btn-success:not(:disabled):not(.disabled):active, .dark-cyan a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-cyan a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-cyan a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-cyan a.btn-info:focus, .dark-cyan a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-cyan a.btn-info.disabled, .dark-cyan a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-cyan a.btn-info:not(:disabled):not(.disabled):active, .dark-cyan a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-cyan a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-cyan a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-cyan a.btn-warning:focus, .dark-cyan a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-cyan a.btn-warning.disabled, .dark-cyan a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-cyan a.btn-warning:not(:disabled):not(.disabled):active, .dark-cyan a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-cyan a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-cyan a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-cyan a.btn-danger:focus, .dark-cyan a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-cyan a.btn-danger.disabled, .dark-cyan a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-cyan a.btn-danger:not(:disabled):not(.disabled):active, .dark-cyan a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-cyan a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-cyan a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-cyan a.btn-light:focus, .dark-cyan a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-cyan a.btn-light.disabled, .dark-cyan a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-cyan a.btn-light:not(:disabled):not(.disabled):active, .dark-cyan a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-cyan a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-cyan a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-cyan a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-cyan a.btn-dark:focus, .dark-cyan a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-cyan a.btn-dark.disabled, .dark-cyan a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-cyan a.btn-dark:not(:disabled):not(.disabled):active, .dark-cyan a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-cyan a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-cyan .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-cyan .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-cyan .btn-outline-primary:focus, .dark-cyan .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-cyan .btn-outline-primary.disabled, .dark-cyan .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-cyan .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-cyan .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-cyan .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-cyan .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-cyan .btn-outline-secondary:focus, .dark-cyan .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-cyan .btn-outline-secondary.disabled, .dark-cyan .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-cyan .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-cyan .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-cyan .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-cyan .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-cyan .btn-outline-success:focus, .dark-cyan .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-cyan .btn-outline-success.disabled, .dark-cyan .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-cyan .btn-outline-success:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-cyan .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-cyan .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-cyan .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-cyan .btn-outline-info:focus, .dark-cyan .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-cyan .btn-outline-info.disabled, .dark-cyan .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-cyan .btn-outline-info:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-cyan .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-cyan .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-cyan .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-cyan .btn-outline-warning:focus, .dark-cyan .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-cyan .btn-outline-warning.disabled, .dark-cyan .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-cyan .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-cyan .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-cyan .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-cyan .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-cyan .btn-outline-danger:focus, .dark-cyan .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-cyan .btn-outline-danger.disabled, .dark-cyan .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-cyan .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-cyan .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-cyan .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-cyan .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-cyan .btn-outline-light:focus, .dark-cyan .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-cyan .btn-outline-light.disabled, .dark-cyan .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-cyan .btn-outline-light:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-cyan .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-cyan .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-cyan .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-cyan .btn-outline-dark:focus, .dark-cyan .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-cyan .btn-outline-dark.disabled, .dark-cyan .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-cyan .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-cyan .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-cyan .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-cyan a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-cyan a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-cyan a.btn-outline-primary:focus, .dark-cyan a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-cyan a.btn-outline-primary.disabled, .dark-cyan a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-cyan a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-cyan a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-cyan a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-cyan a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-cyan a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-cyan a.btn-outline-secondary:focus, .dark-cyan a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-cyan a.btn-outline-secondary.disabled, .dark-cyan a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-cyan a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-cyan a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-cyan a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-cyan a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-cyan a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-cyan a.btn-outline-success:focus, .dark-cyan a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-cyan a.btn-outline-success.disabled, .dark-cyan a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-cyan a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-cyan a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-cyan a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-cyan a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-cyan a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-cyan a.btn-outline-info:focus, .dark-cyan a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-cyan a.btn-outline-info.disabled, .dark-cyan a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-cyan a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-cyan a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-cyan a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-cyan a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-cyan a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-cyan a.btn-outline-warning:focus, .dark-cyan a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-cyan a.btn-outline-warning.disabled, .dark-cyan a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-cyan a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-cyan a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-cyan a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-cyan a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-cyan a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-cyan a.btn-outline-danger:focus, .dark-cyan a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-cyan a.btn-outline-danger.disabled, .dark-cyan a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-cyan a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-cyan a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-cyan a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-cyan a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-cyan a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-cyan a.btn-outline-light:focus, .dark-cyan a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-cyan a.btn-outline-light.disabled, .dark-cyan a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-cyan a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-cyan a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-cyan a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-cyan a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-cyan a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-cyan a.btn-outline-dark:focus, .dark-cyan a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-cyan a.btn-outline-dark.disabled, .dark-cyan a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-cyan a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-cyan a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-cyan a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-cyan a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-cyan a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-cyan a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-cyan .btn-outline-primary,
.dark-cyan a.btn-outline-primary {
  color: #00bcd4;
  border-color: #00bcd4; }
  .dark-cyan .btn-outline-primary:focus, .dark-cyan .btn-outline-primary:hover, .dark-cyan .btn-outline-primary.disabled, .dark-cyan .btn-outline-primary:disabled, .dark-cyan .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-primary.dropdown-toggle,
  .dark-cyan a.btn-outline-primary:focus,
  .dark-cyan a.btn-outline-primary:hover,
  .dark-cyan a.btn-outline-primary.disabled,
  .dark-cyan a.btn-outline-primary:disabled,
  .dark-cyan a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-cyan a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-cyan a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #00bcd4;
    background-color: #00bcd4; }

.dark-cyan .btn-outline-secondary,
.dark-cyan a.btn-outline-secondary {
  color: #69F0AE;
  border-color: #69F0AE; }
  .dark-cyan .btn-outline-secondary:focus, .dark-cyan .btn-outline-secondary:hover, .dark-cyan .btn-outline-secondary.disabled, .dark-cyan .btn-outline-secondary:disabled, .dark-cyan .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-cyan .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-cyan .btn-outline-secondary.dropdown-toggle,
  .dark-cyan a.btn-outline-secondary:focus,
  .dark-cyan a.btn-outline-secondary:hover,
  .dark-cyan a.btn-outline-secondary.disabled,
  .dark-cyan a.btn-outline-secondary:disabled,
  .dark-cyan a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-cyan a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-cyan a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #69F0AE;
    background-color: #69F0AE; }

.dark-cyan a,
.dark-cyan a.text-primary {
  color: #00bcd4; }
  .dark-cyan a:focus, .dark-cyan a:hover,
  .dark-cyan a.text-primary:focus,
  .dark-cyan a.text-primary:hover {
    color: #008fa1; }

.dark-cyan .text-primary {
  color: #00bcd4 !important; }

.dark-cyan .page-link {
  color: #00bcd4; }
  .dark-cyan .page-link:focus, .dark-cyan .page-link:hover {
    color: #00bcd4; }

.dark-cyan .bg-primary,
.dark-cyan .badge-primary {
  background-color: #00bcd4 !important;
  color: #fff !important; }

.dark-cyan .btn-primary,
.dark-cyan .jr-btn-primary,
.dark-cyan a.btn-primary,
.dark-cyan a.jr-btn-primary {
  background-color: #00bcd4;
  border-color: #00bcd4;
  color: #fff; }
  .dark-cyan .btn-primary:hover, .dark-cyan .btn-primary:focus, .dark-cyan .btn-primary:not([disabled]):not(.disabled):active,
  .dark-cyan .jr-btn-primary:hover,
  .dark-cyan .jr-btn-primary:focus,
  .dark-cyan .jr-btn-primary:not([disabled]):not(.disabled):active,
  .dark-cyan a.btn-primary:hover,
  .dark-cyan a.btn-primary:focus,
  .dark-cyan a.btn-primary:not([disabled]):not(.disabled):active,
  .dark-cyan a.jr-btn-primary:hover,
  .dark-cyan a.jr-btn-primary:focus,
  .dark-cyan a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #00a5bb !important;
    border-color: #00a5bb !important;
    color: #fff !important; }

.dark-cyan .bg-primary.lighten-4 {
  background-color: #b2ebf2 !important; }

.dark-cyan .bg-primary.lighten-3 {
  background-color: #80deea !important; }

.dark-cyan .bg-primary.lighten-2 {
  background-color: #4dd0e1 !important; }

.dark-cyan .bg-primary.lighten-1 {
  background-color: #26c6da !important; }

.dark-cyan .bg-primary.darken-1 {
  background-color: #00acc1 !important; }

.dark-cyan .bg-primary.darken-2 {
  background-color: #0097a7 !important; }

.dark-cyan .bg-primary.darken-3 {
  background-color: #00838f !important; }

.dark-cyan .bg-primary.darken-4 {
  background-color: #006064 !important; }

.dark-cyan .bg-primary.accent-1 {
  background-color: #84ffff !important; }

.dark-cyan .bg-primary.accent-2 {
  background-color: #18ffff !important; }

.dark-cyan .bg-primary.accent-3 {
  background-color: #00e5ff !important; }

.dark-cyan .bg-primary.accent-4 {
  background-color: #00b8d4 !important; }

.dark-cyan a.text-secondary {
  color: #69F0AE; }
  .dark-cyan a.text-secondary:focus, .dark-cyan a.text-secondary:hover {
    color: #3beb95 !important; }

.dark-cyan .text-secondary {
  color: #69F0AE !important; }

.dark-cyan .bg-secondary,
.dark-cyan .badge-secondary {
  background-color: #69F0AE !important;
  color: #fff !important; }

.dark-cyan .btn-secondary {
  background-color: #69F0AE;
  border-color: #69F0AE;
  color: #fff; }
  .dark-cyan .btn-secondary:hover, .dark-cyan .btn-secondary:focus, .dark-cyan .btn-secondary:active {
    background-color: #52eea1 !important;
    border-color: #52eea1 !important;
    color: #fff !important; }

.dark-cyan .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .dark-cyan .jr-flat-btn:hover, .dark-cyan .jr-flat-btn:focus, .dark-cyan .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.dark-cyan .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #00bcd4 !important;
  border-color: transparent !important; }
  .dark-cyan .jr-flat-btn.jr-btn-primary:hover, .dark-cyan .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(0, 188, 212, 0.12) !important;
    color: #00bcd4 !important; }

.dark-cyan .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-cyan .bg-primary .jr-flat-btn.jr-btn-primary:hover, .dark-cyan .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-cyan .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #69F0AE !important;
  border-color: transparent !important; }
  .dark-cyan .jr-flat-btn.jr-btn-secondary:hover, .dark-cyan .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(105, 240, 174, 0.12) !important;
    color: #69F0AE !important; }

.dark-cyan .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-cyan .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .dark-cyan .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-cyan .app-main-header {
  background-color: #00bcd4 !important;
  color: #fff;
  border-color: #00bcd4; }
  .dark-cyan .app-main-header .search-bar input {
    background-color: #00d3ee; }
    .dark-cyan .app-main-header .search-bar input + .search-icon i {
      color: #fff; }
    .dark-cyan .app-main-header .search-bar input:focus {
      background-color: #fff; }
      .dark-cyan .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  @media screen and (max-width: 575px) {
    .dark-cyan .app-main-header .search-dropdown input {
      background-color: #fff; }
      .dark-cyan .app-main-header .search-dropdown input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); } }
  .dark-cyan .app-main-header .jr-menu-icon .menu-icon {
    background-color: #fff !important; }

.dark-cyan .app-header .jr-menu-icon .menu-icon {
  background-color: #fff; }

.dark-cyan .quick-menu .icon-btn {
  color: #fff; }

.dark-cyan .react-logo-white.d-block {
  display: block !important; }

@media screen and (min-width: 992px) {
  .dark-cyan .react-logo-white.d-lg-block {
    display: block !important; }
  .dark-cyan .react-logo-white.d-lg-none {
    display: none !important; } }

.dark-cyan .react-logo-color.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .dark-cyan .react-logo-color.d-lg-block {
    display: none !important; } }

.dark-cyan .color-theme-header {
  background-color: #00bcd4; }

.dark-cyan .sidebar-header {
  border-bottom: 0 none;
  background-color: #1d1d1d; }

.dark-cyan .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important; }
  .dark-cyan .side-nav .user-profile {
    background-color: #1d1d1d;
    position: relative;
    z-index: 2; }
  .dark-cyan .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-cyan .side-nav .user-detail .user-name {
    color: #fff; }

.dark-cyan ul.nav-menu li a {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu > li.open > a,
.dark-cyan ul.nav-menu > li > a:hover,
.dark-cyan ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-cyan ul.nav-menu li.menu.open > a {
  border-color: #00bcd4; }

.dark-cyan ul.nav-menu li.menu > a:focus:before,
.dark-cyan ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-cyan ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-cyan ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu li.menu.open > a:before,
.dark-cyan ul.nav-menu li.menu > a:focus:before,
.dark-cyan ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-cyan ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-cyan ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #00bcd4; }

.dark-cyan ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-cyan ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-cyan ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-cyan .navbar-nav li:hover > a,
.dark-cyan .navbar-nav li:focus > a {
  color: #00bcd4; }

.dark-cyan .navbar-nav li a {
  color: #868e96; }

.dark-cyan .navbar-nav li a:hover,
.dark-cyan .navbar-nav li a:focus {
  color: #00bcd4; }

.dark-cyan .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-cyan .navbar-nav li.nav-item:hover > a,
.dark-cyan .navbar-nav li.nav-item:focus > a,
.dark-cyan .navbar-nav li.nav-item > a:hover,
.dark-cyan .navbar-nav li.nav-item > a:focus,
.dark-cyan .navbar-nav li.nav-item.active > a {
  color: #69F0AE; }

.dark-cyan .navbar-nav ul.sub-menu li a.active,
.dark-cyan .navbar-nav ul.sub-menu li.active > a {
  color: #00bcd4; }

.dark-cyan .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-cyan .navbar-nav .nav-arrow a:hover:before,
.dark-cyan .navbar-nav .nav-arrow a:focus:before {
  color: #00bcd4; }

.dark-cyan .navbar-nav .nav-arrow:hover > a:before,
.dark-cyan .navbar-nav .nav-arrow:focus > a:before,
.dark-cyan .navbar-nav .nav-arrow.active > a:before {
  color: #00bcd4; }

.dark-cyan .app-top-nav {
  background-color: #252525 !important;
  border-color: #252525; }
  .dark-cyan .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-cyan .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-cyan .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-cyan .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-cyan .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-cyan .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #69F0AE; }

.dark-cyan .module-nav li a.active {
  border-color: #00bcd4; }

.dark-cyan .rbc-event {
  background-color: #00bcd4; }

.dark-cyan .rbc-event.rbc-selected {
  background-color: #008fa1; }

.dark-cyan .rbc-slot-selection {
  background-color: rgba(0, 188, 212, 0.7); }

.dark-cyan .rbc-toolbar button:active,
.dark-cyan .rbc-toolbar button.rbc-active {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:active:hover,
.dark-cyan .rbc-toolbar button.rbc-active:hover,
.dark-cyan .rbc-toolbar button:active:focus,
.dark-cyan .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:focus {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-toolbar button:hover {
  background-color: rgba(0, 188, 212, 0.9);
  border-color: #00bcd4; }

.dark-cyan .rbc-day-slot .rbc-event {
  border-color: #00bcd4; }

.dark-cyan .chat-sidenav-title {
  color: #00bcd4; }

.dark-cyan .chat-user-item.active, .dark-cyan .chat-user-item:hover {
  background-color: #bbf7ff; }

.dark-cyan .contact-list i {
  color: #00bcd4; }

.dark-cyan .Collapsible__trigger {
  background: #00bcd4; }

.dark-cyan .categories-list li a:focus,
.dark-cyan .categories-list li a:hover,
.dark-cyan .categories-list li.active a {
  color: #00bcd4; }

.dark-cyan .login-content .form-control:focus {
  border-color: #00bcd4; }

.dark-cyan .filter-with-bg-color ul li a {
  border-color: #00bcd4; }
  .dark-cyan .filter-with-bg-color ul li a:hover, .dark-cyan .filter-with-bg-color ul li a:focus, .dark-cyan .filter-with-bg-color ul li a.active {
    background-color: #00bcd4; }

.dark-cyan .profile-intro .icon {
  color: #00bcd4; }

.dark-cyan .social-link li.active a,
.dark-cyan .social-link li a:hover,
.dark-cyan .social-link li a:focus {
  color: #69F0AE; }

.dark-cyan .actions {
  color: #69F0AE; }

.dark-cyan .table-hover tbody tr:hover {
  background-color: rgba(0, 188, 212, 0.075); }

.dark-cyan .border-primary {
  border-color: #00bcd4 !important; }

.dark-cyan .app-logo-content {
  background-color: #00bcd4; }

.dark-cyan .app-social-block .social-link button {
  border: solid 1px #00bcd4;
  color: #00bcd4; }
  .dark-cyan .app-social-block .social-link button:hover, .dark-cyan .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #00bcd4; }

.dark-cyan .btn-group > .jr-btn.active,
.dark-cyan .btn-group > .btn-default.active,
.dark-cyan .btn-group-vertical > .jr-btn.active,
.dark-cyan .btn-group-vertical > .btn-default.active {
  background-color: #00bcd4;
  border-color: #00bcd4; }

.dark-cyan .btn-group > .jr-flat-btn,
.dark-cyan .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-cyan .btn-group > .jr-flat-btn.active,
  .dark-cyan .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #00bcd4 !important;
    border-color: transparent !important; }
    .dark-cyan .btn-group > .jr-flat-btn.active:hover, .dark-cyan .btn-group > .jr-flat-btn.active:focus,
    .dark-cyan .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-cyan .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(0, 188, 212, 0.12) !important;
      color: #00bcd4 !important; }

.dark-cyan .nav-pill-sales .nav-link.active {
  background-color: #00bcd4;
  border-color: #00bcd4; }

.dark-cyan .form-checkbox > input + .check,
.dark-cyan .form-checkbox > input.sm + .check {
  color: #00bcd4; }

/* Input Placeholder Style */
.dark-cyan .app-main-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb; }

.dark-cyan .app-main-header ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb; }

.dark-cyan .app-main-header :-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb; }

.dark-cyan .app-main-header :-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb; }

.dark-cyan .app-main-header input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gainsboro; }

.dark-cyan .app-main-header input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: gainsboro; }

.dark-cyan .app-main-header input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: gainsboro; }

.dark-cyan .app-main-header input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: gainsboro; }

/*Theme Dark Amber Styles*/
.dark-amber .right-arrow {
  color: #ffc107; }
  .dark-amber .right-arrow:after {
    color: #ffc107; }

.dark-amber .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-amber .btn-primary:focus, .dark-amber .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-amber .btn-primary.disabled, .dark-amber .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-amber .btn-primary:not(:disabled):not(.disabled):active, .dark-amber .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-amber .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-amber .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-amber .btn-secondary:focus, .dark-amber .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-amber .btn-secondary.disabled, .dark-amber .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-amber .btn-secondary:not(:disabled):not(.disabled):active, .dark-amber .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-amber .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-amber .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-amber .btn-success:focus, .dark-amber .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-amber .btn-success.disabled, .dark-amber .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-amber .btn-success:not(:disabled):not(.disabled):active, .dark-amber .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-amber .btn-success:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-amber .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-amber .btn-info:focus, .dark-amber .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-amber .btn-info.disabled, .dark-amber .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-amber .btn-info:not(:disabled):not(.disabled):active, .dark-amber .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-amber .btn-info:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-amber .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-amber .btn-warning:focus, .dark-amber .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-amber .btn-warning.disabled, .dark-amber .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-amber .btn-warning:not(:disabled):not(.disabled):active, .dark-amber .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-amber .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-amber .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-amber .btn-danger:focus, .dark-amber .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-amber .btn-danger.disabled, .dark-amber .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-amber .btn-danger:not(:disabled):not(.disabled):active, .dark-amber .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-amber .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-amber .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-amber .btn-light:focus, .dark-amber .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-amber .btn-light.disabled, .dark-amber .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-amber .btn-light:not(:disabled):not(.disabled):active, .dark-amber .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-amber .btn-light:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-amber .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-amber .btn-dark:focus, .dark-amber .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-amber .btn-dark.disabled, .dark-amber .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-amber .btn-dark:not(:disabled):not(.disabled):active, .dark-amber .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-amber .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-amber a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-amber a.btn-primary:focus, .dark-amber a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-amber a.btn-primary.disabled, .dark-amber a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-amber a.btn-primary:not(:disabled):not(.disabled):active, .dark-amber a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-amber a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-amber a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-amber a.btn-secondary:focus, .dark-amber a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-amber a.btn-secondary.disabled, .dark-amber a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-amber a.btn-secondary:not(:disabled):not(.disabled):active, .dark-amber a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-amber a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-amber a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-amber a.btn-success:focus, .dark-amber a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-amber a.btn-success.disabled, .dark-amber a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-amber a.btn-success:not(:disabled):not(.disabled):active, .dark-amber a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-amber a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-amber a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-amber a.btn-info:focus, .dark-amber a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-amber a.btn-info.disabled, .dark-amber a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-amber a.btn-info:not(:disabled):not(.disabled):active, .dark-amber a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-amber a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-amber a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-amber a.btn-warning:focus, .dark-amber a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-amber a.btn-warning.disabled, .dark-amber a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-amber a.btn-warning:not(:disabled):not(.disabled):active, .dark-amber a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-amber a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-amber a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-amber a.btn-danger:focus, .dark-amber a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-amber a.btn-danger.disabled, .dark-amber a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-amber a.btn-danger:not(:disabled):not(.disabled):active, .dark-amber a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-amber a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-amber a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-amber a.btn-light:focus, .dark-amber a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-amber a.btn-light.disabled, .dark-amber a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-amber a.btn-light:not(:disabled):not(.disabled):active, .dark-amber a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-amber a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-amber a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-amber a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-amber a.btn-dark:focus, .dark-amber a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-amber a.btn-dark.disabled, .dark-amber a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-amber a.btn-dark:not(:disabled):not(.disabled):active, .dark-amber a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-amber a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-amber .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-amber .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-amber .btn-outline-primary:focus, .dark-amber .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-amber .btn-outline-primary.disabled, .dark-amber .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-amber .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-amber .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-amber .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-amber .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-amber .btn-outline-secondary:focus, .dark-amber .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-amber .btn-outline-secondary.disabled, .dark-amber .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-amber .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-amber .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-amber .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-amber .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-amber .btn-outline-success:focus, .dark-amber .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-amber .btn-outline-success.disabled, .dark-amber .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-amber .btn-outline-success:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-amber .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-amber .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-amber .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-amber .btn-outline-info:focus, .dark-amber .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-amber .btn-outline-info.disabled, .dark-amber .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-amber .btn-outline-info:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-amber .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-amber .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-amber .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-amber .btn-outline-warning:focus, .dark-amber .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-amber .btn-outline-warning.disabled, .dark-amber .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-amber .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-amber .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-amber .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-amber .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-amber .btn-outline-danger:focus, .dark-amber .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-amber .btn-outline-danger.disabled, .dark-amber .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-amber .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-amber .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-amber .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-amber .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-amber .btn-outline-light:focus, .dark-amber .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-amber .btn-outline-light.disabled, .dark-amber .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-amber .btn-outline-light:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-amber .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-amber .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-amber .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-amber .btn-outline-dark:focus, .dark-amber .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-amber .btn-outline-dark.disabled, .dark-amber .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-amber .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-amber .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-amber .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-amber a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-amber a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-amber a.btn-outline-primary:focus, .dark-amber a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-amber a.btn-outline-primary.disabled, .dark-amber a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-amber a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-amber a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-amber a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-amber a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-amber a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-amber a.btn-outline-secondary:focus, .dark-amber a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-amber a.btn-outline-secondary.disabled, .dark-amber a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-amber a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-amber a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-amber a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-amber a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-amber a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-amber a.btn-outline-success:focus, .dark-amber a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-amber a.btn-outline-success.disabled, .dark-amber a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-amber a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-amber a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-amber a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-amber a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-amber a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-amber a.btn-outline-info:focus, .dark-amber a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-amber a.btn-outline-info.disabled, .dark-amber a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-amber a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-amber a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-amber a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-amber a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-amber a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-amber a.btn-outline-warning:focus, .dark-amber a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-amber a.btn-outline-warning.disabled, .dark-amber a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-amber a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-amber a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-amber a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-amber a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-amber a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-amber a.btn-outline-danger:focus, .dark-amber a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-amber a.btn-outline-danger.disabled, .dark-amber a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-amber a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-amber a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-amber a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-amber a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-amber a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-amber a.btn-outline-light:focus, .dark-amber a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-amber a.btn-outline-light.disabled, .dark-amber a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-amber a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-amber a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-amber a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-amber a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-amber a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-amber a.btn-outline-dark:focus, .dark-amber a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-amber a.btn-outline-dark.disabled, .dark-amber a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-amber a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-amber a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-amber a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-amber a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-amber a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-amber a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-amber .btn-outline-primary,
.dark-amber a.btn-outline-primary {
  color: #ffc107;
  border-color: #ffc107; }
  .dark-amber .btn-outline-primary:focus, .dark-amber .btn-outline-primary:hover, .dark-amber .btn-outline-primary.disabled, .dark-amber .btn-outline-primary:disabled, .dark-amber .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-primary.dropdown-toggle,
  .dark-amber a.btn-outline-primary:focus,
  .dark-amber a.btn-outline-primary:hover,
  .dark-amber a.btn-outline-primary.disabled,
  .dark-amber a.btn-outline-primary:disabled,
  .dark-amber a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-amber a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-amber a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #ffc107;
    background-color: #ffc107; }

.dark-amber .btn-outline-secondary,
.dark-amber a.btn-outline-secondary {
  color: #FF3D00;
  border-color: #FF3D00; }
  .dark-amber .btn-outline-secondary:focus, .dark-amber .btn-outline-secondary:hover, .dark-amber .btn-outline-secondary.disabled, .dark-amber .btn-outline-secondary:disabled, .dark-amber .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-amber .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-amber .btn-outline-secondary.dropdown-toggle,
  .dark-amber a.btn-outline-secondary:focus,
  .dark-amber a.btn-outline-secondary:hover,
  .dark-amber a.btn-outline-secondary.disabled,
  .dark-amber a.btn-outline-secondary:disabled,
  .dark-amber a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-amber a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-amber a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FF3D00;
    background-color: #FF3D00; }

.dark-amber a,
.dark-amber a.text-primary {
  color: #ffc107; }
  .dark-amber a:focus, .dark-amber a:hover,
  .dark-amber a.text-primary:focus,
  .dark-amber a.text-primary:hover {
    color: #d39e00; }

.dark-amber .text-primary {
  color: #ffc107 !important; }

.dark-amber .page-link {
  color: #ffc107; }
  .dark-amber .page-link:focus, .dark-amber .page-link:hover {
    color: #ffc107; }

.dark-amber .bg-primary,
.dark-amber .badge-primary {
  background-color: #ffc107 !important;
  color: #fff !important; }

.dark-amber .btn-primary,
.dark-amber .jr-btn-primary,
.dark-amber a.btn-primary,
.dark-amber a.jr-btn-primary {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #fff; }
  .dark-amber .btn-primary:hover, .dark-amber .btn-primary:focus, .dark-amber .btn-primary:not([disabled]):not(.disabled):active,
  .dark-amber .jr-btn-primary:hover,
  .dark-amber .jr-btn-primary:focus,
  .dark-amber .jr-btn-primary:not([disabled]):not(.disabled):active,
  .dark-amber a.btn-primary:hover,
  .dark-amber a.btn-primary:focus,
  .dark-amber a.btn-primary:not([disabled]):not(.disabled):active,
  .dark-amber a.jr-btn-primary:hover,
  .dark-amber a.jr-btn-primary:focus,
  .dark-amber a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #edb100 !important;
    border-color: #edb100 !important;
    color: #fff !important; }

.dark-amber .bg-primary.lighten-4 {
  background-color: #ffecb3 !important; }

.dark-amber .bg-primary.lighten-3 {
  background-color: #ffe082 !important; }

.dark-amber .bg-primary.lighten-2 {
  background-color: #ffd54f !important; }

.dark-amber .bg-primary.lighten-1 {
  background-color: #ffca28 !important; }

.dark-amber .bg-primary.darken-1 {
  background-color: #ffb300 !important; }

.dark-amber .bg-primary.darken-2 {
  background-color: #ffa000 !important; }

.dark-amber .bg-primary.darken-3 {
  background-color: #ff8f00 !important; }

.dark-amber .bg-primary.darken-4 {
  background-color: #ff6f00 !important; }

.dark-amber .bg-primary.accent-1 {
  background-color: #ffe57f !important; }

.dark-amber .bg-primary.accent-2 {
  background-color: #ffd740 !important; }

.dark-amber .bg-primary.accent-3 {
  background-color: #ffc400 !important; }

.dark-amber .bg-primary.accent-4 {
  background-color: #ffab00 !important; }

.dark-amber a.text-secondary {
  color: #FF3D00; }
  .dark-amber a.text-secondary:focus, .dark-amber a.text-secondary:hover {
    color: #cc3100 !important; }

.dark-amber .text-secondary {
  color: #FF3D00 !important; }

.dark-amber .bg-secondary,
.dark-amber .badge-secondary {
  background-color: #FF3D00 !important;
  color: #fff !important; }

.dark-amber .btn-secondary {
  background-color: #FF3D00;
  border-color: #FF3D00;
  color: #fff; }
  .dark-amber .btn-secondary:hover, .dark-amber .btn-secondary:focus, .dark-amber .btn-secondary:active {
    background-color: #e63700 !important;
    border-color: #e63700 !important;
    color: #fff !important; }

.amber .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .amber .jr-flat-btn:hover, .amber .jr-flat-btn:focus, .amber .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.amber .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #FFC107 !important;
  border-color: transparent !important; }
  .amber .jr-flat-btn.jr-btn-primary:hover, .amber .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 193, 7, 0.12) !important;
    color: #FFC107 !important; }

.amber .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .amber .bg-primary .jr-flat-btn.jr-btn-primary:hover, .amber .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.amber .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FF3D00 !important;
  border-color: transparent !important; }
  .amber .jr-flat-btn.jr-btn-secondary:hover, .amber .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 61, 0, 0.12) !important;
    color: #FF3D00 !important; }

.amber .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .amber .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .amber .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-amber .app-main-header {
  background-color: #ffc107 !important;
  color: #fff;
  border-color: #ffc107; }
  .dark-amber .app-main-header .search-bar input {
    background-color: #ffc721; }
    .dark-amber .app-main-header .search-bar input + .search-icon i {
      color: #fff; }
    .dark-amber .app-main-header .search-bar input:focus {
      background-color: #fff; }
      .dark-amber .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  @media screen and (max-width: 575px) {
    .dark-amber .app-main-header .search-dropdown input {
      background-color: #fff; }
      .dark-amber .app-main-header .search-dropdown input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); } }
  .dark-amber .app-main-header .jr-menu-icon .menu-icon {
    background-color: #fff !important; }

.dark-amber .app-header .jr-menu-icon .menu-icon {
  background-color: #fff; }

.dark-amber .quick-menu .icon-btn {
  color: #fff; }

.dark-amber .react-logo-white.d-block {
  display: block !important; }

@media screen and (min-width: 992px) {
  .dark-amber .react-logo-white.d-lg-block {
    display: block !important; }
  .dark-amber .react-logo-white.d-lg-none {
    display: none !important; } }

.dark-amber .react-logo-color.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .dark-amber .react-logo-color.d-lg-block {
    display: none !important; } }

.dark-amber .color-theme-header {
  background-color: #ffc107; }

.dark-amber .sidebar-header {
  border-bottom: 0 none;
  background-color: #1d1d1d; }

.dark-amber .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important; }
  .dark-amber .side-nav .user-profile {
    background-color: #1d1d1d;
    position: relative;
    z-index: 2; }
  .dark-amber .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-amber .side-nav .user-detail .user-name {
    color: #fff; }

.dark-amber ul.nav-menu li a {
  color: #a1a1a1; }

.dark-amber ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-amber ul.nav-menu > li.open > a,
.dark-amber ul.nav-menu > li > a:hover,
.dark-amber ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-amber ul.nav-menu li.menu.open > a {
  border-color: #ffc107; }

.dark-amber ul.nav-menu li.menu > a:focus:before,
.dark-amber ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-amber ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-amber ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-amber ul.nav-menu li.menu.open > a:before,
.dark-amber ul.nav-menu li.menu > a:focus:before,
.dark-amber ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-amber ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-amber ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #ffc107; }

.dark-amber ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-amber ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-amber ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-amber .navbar-nav li:hover > a,
.dark-amber .navbar-nav li:focus > a {
  color: #ffc107; }

.dark-amber .navbar-nav li a {
  color: #868e96; }

.dark-amber .navbar-nav li a:hover,
.dark-amber .navbar-nav li a:focus {
  color: #ffc107; }

.dark-amber .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-amber .navbar-nav li.nav-item:hover > a,
.dark-amber .navbar-nav li.nav-item:focus > a,
.dark-amber .navbar-nav li.nav-item > a:hover,
.dark-amber .navbar-nav li.nav-item > a:focus,
.dark-amber .navbar-nav li.nav-item.active > a {
  color: #FF3D00; }

.dark-amber .navbar-nav ul.sub-menu li a.active,
.dark-amber .navbar-nav ul.sub-menu li.active > a {
  color: #ffc107; }

.dark-amber .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-amber .navbar-nav .nav-arrow a:hover:before,
.dark-amber .navbar-nav .nav-arrow a:focus:before {
  color: #ffc107; }

.dark-amber .navbar-nav .nav-arrow:hover > a:before,
.dark-amber .navbar-nav .nav-arrow:focus > a:before,
.dark-amber .navbar-nav .nav-arrow.active > a:before {
  color: #ffc107; }

.dark-amber .app-top-nav {
  background-color: #252525 !important;
  border-color: #252525; }
  .dark-amber .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-amber .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-amber .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-amber .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-amber .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-amber .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FF3D00; }

.dark-amber .module-nav li a.active {
  border-color: #ffc107; }

.dark-amber .rbc-event {
  background-color: #ffc107; }

.dark-amber .rbc-event.rbc-selected {
  background-color: #d39e00; }

.dark-amber .rbc-slot-selection {
  background-color: rgba(255, 193, 7, 0.7); }

.dark-amber .rbc-toolbar button:active,
.dark-amber .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:active:hover,
.dark-amber .rbc-toolbar button.rbc-active:hover,
.dark-amber .rbc-toolbar button:active:focus,
.dark-amber .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:focus {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-toolbar button:hover {
  background-color: rgba(255, 193, 7, 0.9);
  border-color: #ffc107; }

.dark-amber .rbc-day-slot .rbc-event {
  border-color: #ffc107; }

.dark-amber .chat-sidenav-title {
  color: #ffc107; }

.dark-amber .chat-user-item.active, .dark-amber .chat-user-item:hover {
  background-color: #fffaed; }

.dark-amber .contact-list i {
  color: #ffc107; }

.dark-amber .Collapsible__trigger {
  background: #ffc107; }

.dark-amber .categories-list li a:focus,
.dark-amber .categories-list li a:hover,
.dark-amber .categories-list li.active a {
  color: #ffc107; }

.dark-amber .login-content .form-control:focus {
  border-color: #ffc107; }

.dark-amber .filter-with-bg-color ul li a {
  border-color: #ffc107; }
  .dark-amber .filter-with-bg-color ul li a:hover, .dark-amber .filter-with-bg-color ul li a:focus, .dark-amber .filter-with-bg-color ul li a.active {
    background-color: #ffc107; }

.dark-amber .profile-intro .icon {
  color: #ffc107; }

.dark-amber .social-link li.active a,
.dark-amber .social-link li a:hover,
.dark-amber .social-link li a:focus {
  color: #FF3D00; }

.dark-amber .actions {
  color: #FF3D00; }

.dark-amber .table-hover tbody tr:hover {
  background-color: rgba(255, 193, 7, 0.075); }

.dark-amber .border-primary {
  border-color: #ffc107 !important; }

.dark-amber .app-logo-content {
  background-color: #ffc107; }

.dark-amber .app-social-block .social-link button {
  border: solid 1px #ffc107;
  color: #ffc107; }
  .dark-amber .app-social-block .social-link button:hover, .dark-amber .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ffc107; }

.dark-amber .btn-group > .jr-btn.active,
.dark-amber .btn-group > .btn-default.active,
.dark-amber .btn-group-vertical > .jr-btn.active,
.dark-amber .btn-group-vertical > .btn-default.active {
  background-color: #ffc107;
  border-color: #ffc107; }

.dark-amber .btn-group > .jr-flat-btn,
.dark-amber .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-amber .btn-group > .jr-flat-btn.active,
  .dark-amber .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #ffc107 !important;
    border-color: transparent !important; }
    .dark-amber .btn-group > .jr-flat-btn.active:hover, .dark-amber .btn-group > .jr-flat-btn.active:focus,
    .dark-amber .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-amber .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(255, 193, 7, 0.12) !important;
      color: #ffc107 !important; }

.dark-amber .nav-pill-sales .nav-link.active {
  background-color: #ffc107;
  border-color: #ffc107; }

.dark-amber .form-checkbox > input + .check,
.dark-amber .form-checkbox > input.sm + .check {
  color: #ffc107; }

/* Input Placeholder Style */
.dark-amber .app-main-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb; }

.dark-amber .app-main-header ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb; }

.dark-amber .app-main-header :-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb; }

.dark-amber .app-main-header :-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb; }

.dark-amber .app-main-header input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gainsboro; }

.dark-amber .app-main-header input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: gainsboro; }

.dark-amber .app-main-header input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: gainsboro; }

.dark-amber .app-main-header input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: gainsboro; }

/*Theme Dark Deep Orange Styles*/
.dark-deep-orange .right-arrow {
  color: #ff5722; }
  .dark-deep-orange .right-arrow:after {
    color: #ff5722; }

.dark-deep-orange .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-deep-orange .btn-primary:focus, .dark-deep-orange .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-deep-orange .btn-primary.disabled, .dark-deep-orange .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-deep-orange .btn-primary:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-deep-orange .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-deep-orange .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-deep-orange .btn-secondary:focus, .dark-deep-orange .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-deep-orange .btn-secondary.disabled, .dark-deep-orange .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-deep-orange .btn-secondary:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-deep-orange .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-deep-orange .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-deep-orange .btn-success:focus, .dark-deep-orange .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-deep-orange .btn-success.disabled, .dark-deep-orange .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-deep-orange .btn-success:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-deep-orange .btn-success:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-deep-orange .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-deep-orange .btn-info:focus, .dark-deep-orange .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-deep-orange .btn-info.disabled, .dark-deep-orange .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-deep-orange .btn-info:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-deep-orange .btn-info:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-deep-orange .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-deep-orange .btn-warning:focus, .dark-deep-orange .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-deep-orange .btn-warning.disabled, .dark-deep-orange .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-deep-orange .btn-warning:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-deep-orange .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-deep-orange .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-deep-orange .btn-danger:focus, .dark-deep-orange .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-deep-orange .btn-danger.disabled, .dark-deep-orange .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-deep-orange .btn-danger:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-deep-orange .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-deep-orange .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-deep-orange .btn-light:focus, .dark-deep-orange .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-deep-orange .btn-light.disabled, .dark-deep-orange .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-deep-orange .btn-light:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-deep-orange .btn-light:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-deep-orange .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-deep-orange .btn-dark:focus, .dark-deep-orange .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-deep-orange .btn-dark.disabled, .dark-deep-orange .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-deep-orange .btn-dark:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-deep-orange .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-deep-orange a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-deep-orange a.btn-primary:focus, .dark-deep-orange a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-deep-orange a.btn-primary.disabled, .dark-deep-orange a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-deep-orange a.btn-primary:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-deep-orange a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-deep-orange a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-deep-orange a.btn-secondary:focus, .dark-deep-orange a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-deep-orange a.btn-secondary.disabled, .dark-deep-orange a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-deep-orange a.btn-secondary:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-deep-orange a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-deep-orange a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-deep-orange a.btn-success:focus, .dark-deep-orange a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-deep-orange a.btn-success.disabled, .dark-deep-orange a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-deep-orange a.btn-success:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-deep-orange a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-deep-orange a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-deep-orange a.btn-info:focus, .dark-deep-orange a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-deep-orange a.btn-info.disabled, .dark-deep-orange a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-deep-orange a.btn-info:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-deep-orange a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-deep-orange a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-deep-orange a.btn-warning:focus, .dark-deep-orange a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-deep-orange a.btn-warning.disabled, .dark-deep-orange a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-deep-orange a.btn-warning:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-deep-orange a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-deep-orange a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-deep-orange a.btn-danger:focus, .dark-deep-orange a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-deep-orange a.btn-danger.disabled, .dark-deep-orange a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-deep-orange a.btn-danger:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-deep-orange a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-deep-orange a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-deep-orange a.btn-light:focus, .dark-deep-orange a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-deep-orange a.btn-light.disabled, .dark-deep-orange a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-deep-orange a.btn-light:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-deep-orange a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-deep-orange a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-orange a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-deep-orange a.btn-dark:focus, .dark-deep-orange a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-deep-orange a.btn-dark.disabled, .dark-deep-orange a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-deep-orange a.btn-dark:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-deep-orange a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-deep-orange .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-deep-orange .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-deep-orange .btn-outline-primary:focus, .dark-deep-orange .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-deep-orange .btn-outline-primary.disabled, .dark-deep-orange .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-deep-orange .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-deep-orange .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-deep-orange .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-deep-orange .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-deep-orange .btn-outline-secondary:focus, .dark-deep-orange .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-deep-orange .btn-outline-secondary.disabled, .dark-deep-orange .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-deep-orange .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-deep-orange .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-deep-orange .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-deep-orange .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-deep-orange .btn-outline-success:focus, .dark-deep-orange .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-deep-orange .btn-outline-success.disabled, .dark-deep-orange .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-deep-orange .btn-outline-success:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-deep-orange .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-deep-orange .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-deep-orange .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-deep-orange .btn-outline-info:focus, .dark-deep-orange .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-deep-orange .btn-outline-info.disabled, .dark-deep-orange .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-deep-orange .btn-outline-info:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-deep-orange .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-deep-orange .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-deep-orange .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-deep-orange .btn-outline-warning:focus, .dark-deep-orange .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-deep-orange .btn-outline-warning.disabled, .dark-deep-orange .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-deep-orange .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-deep-orange .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-deep-orange .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-deep-orange .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-deep-orange .btn-outline-danger:focus, .dark-deep-orange .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-deep-orange .btn-outline-danger.disabled, .dark-deep-orange .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-deep-orange .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-deep-orange .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-deep-orange .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-deep-orange .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-deep-orange .btn-outline-light:focus, .dark-deep-orange .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-deep-orange .btn-outline-light.disabled, .dark-deep-orange .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-deep-orange .btn-outline-light:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-deep-orange .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-deep-orange .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-deep-orange .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-deep-orange .btn-outline-dark:focus, .dark-deep-orange .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-deep-orange .btn-outline-dark.disabled, .dark-deep-orange .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-deep-orange .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-deep-orange .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-deep-orange .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-deep-orange a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-deep-orange a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-deep-orange a.btn-outline-primary:focus, .dark-deep-orange a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-deep-orange a.btn-outline-primary.disabled, .dark-deep-orange a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-deep-orange a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-deep-orange a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-deep-orange a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-deep-orange a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-deep-orange a.btn-outline-secondary:focus, .dark-deep-orange a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-deep-orange a.btn-outline-secondary.disabled, .dark-deep-orange a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-deep-orange a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-deep-orange a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-deep-orange a.btn-outline-success:focus, .dark-deep-orange a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-deep-orange a.btn-outline-success.disabled, .dark-deep-orange a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-deep-orange a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-deep-orange a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-deep-orange a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-deep-orange a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-deep-orange a.btn-outline-info:focus, .dark-deep-orange a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-deep-orange a.btn-outline-info.disabled, .dark-deep-orange a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-deep-orange a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-deep-orange a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-deep-orange a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-deep-orange a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-deep-orange a.btn-outline-warning:focus, .dark-deep-orange a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-deep-orange a.btn-outline-warning.disabled, .dark-deep-orange a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-deep-orange a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-deep-orange a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-deep-orange a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-deep-orange a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-deep-orange a.btn-outline-danger:focus, .dark-deep-orange a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-deep-orange a.btn-outline-danger.disabled, .dark-deep-orange a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-deep-orange a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-deep-orange a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-deep-orange a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-deep-orange a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-deep-orange a.btn-outline-light:focus, .dark-deep-orange a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-deep-orange a.btn-outline-light.disabled, .dark-deep-orange a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-deep-orange a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-deep-orange a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-deep-orange a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-deep-orange a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-deep-orange a.btn-outline-dark:focus, .dark-deep-orange a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-deep-orange a.btn-outline-dark.disabled, .dark-deep-orange a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-deep-orange a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-deep-orange a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-deep-orange a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-deep-orange a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-orange a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-deep-orange .btn-outline-primary,
.dark-deep-orange a.btn-outline-primary {
  color: #ff5722;
  border-color: #ff5722; }
  .dark-deep-orange .btn-outline-primary:focus, .dark-deep-orange .btn-outline-primary:hover, .dark-deep-orange .btn-outline-primary.disabled, .dark-deep-orange .btn-outline-primary:disabled, .dark-deep-orange .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-primary.dropdown-toggle,
  .dark-deep-orange a.btn-outline-primary:focus,
  .dark-deep-orange a.btn-outline-primary:hover,
  .dark-deep-orange a.btn-outline-primary.disabled,
  .dark-deep-orange a.btn-outline-primary:disabled,
  .dark-deep-orange a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-deep-orange a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-deep-orange a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #ff5722;
    background-color: #ff5722; }

.dark-deep-orange .btn-outline-secondary,
.dark-deep-orange a.btn-outline-secondary {
  color: #40C4FF;
  border-color: #40C4FF; }
  .dark-deep-orange .btn-outline-secondary:focus, .dark-deep-orange .btn-outline-secondary:hover, .dark-deep-orange .btn-outline-secondary.disabled, .dark-deep-orange .btn-outline-secondary:disabled, .dark-deep-orange .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-deep-orange .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-orange .btn-outline-secondary.dropdown-toggle,
  .dark-deep-orange a.btn-outline-secondary:focus,
  .dark-deep-orange a.btn-outline-secondary:hover,
  .dark-deep-orange a.btn-outline-secondary.disabled,
  .dark-deep-orange a.btn-outline-secondary:disabled,
  .dark-deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-deep-orange a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-deep-orange a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #40C4FF;
    background-color: #40C4FF; }

.dark-deep-orange a,
.dark-deep-orange a.text-primary {
  color: #ff5722; }
  .dark-deep-orange a:focus, .dark-deep-orange a:hover,
  .dark-deep-orange a.text-primary:focus,
  .dark-deep-orange a.text-primary:hover {
    color: #ee3900; }

.dark-deep-orange .text-primary {
  color: #ff5722 !important; }

.dark-deep-orange .page-link {
  color: #ff5722; }
  .dark-deep-orange .page-link:focus, .dark-deep-orange .page-link:hover {
    color: #ff5722; }

.dark-deep-orange .bg-primary,
.dark-deep-orange .badge-primary {
  background-color: #ff5722 !important;
  color: #fff !important; }

.dark-deep-orange .btn-primary,
.dark-deep-orange .jr-btn-primary,
.dark-deep-orange a.btn-primary,
.dark-deep-orange a.jr-btn-primary {
  background-color: #ff5722;
  border-color: #ff5722;
  color: #fff; }
  .dark-deep-orange .btn-primary:hover, .dark-deep-orange .btn-primary:focus, .dark-deep-orange .btn-primary:not([disabled]):not(.disabled):active,
  .dark-deep-orange .jr-btn-primary:hover,
  .dark-deep-orange .jr-btn-primary:focus,
  .dark-deep-orange .jr-btn-primary:not([disabled]):not(.disabled):active,
  .dark-deep-orange a.btn-primary:hover,
  .dark-deep-orange a.btn-primary:focus,
  .dark-deep-orange a.btn-primary:not([disabled]):not(.disabled):active,
  .dark-deep-orange a.jr-btn-primary:hover,
  .dark-deep-orange a.jr-btn-primary:focus,
  .dark-deep-orange a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #ff4409 !important;
    border-color: #ff4409 !important;
    color: #fff !important; }

.dark-deep-orange .bg-primary.lighten-4 {
  background-color: #ffccbc !important; }

.dark-deep-orange .bg-primary.lighten-3 {
  background-color: #ffab91 !important; }

.dark-deep-orange .bg-primary.lighten-2 {
  background-color: #ff8a65 !important; }

.dark-deep-orange .bg-primary.lighten-1 {
  background-color: #ff7043 !important; }

.dark-deep-orange .bg-primary.darken-1 {
  background-color: #f4511e !important; }

.dark-deep-orange .bg-primary.darken-2 {
  background-color: #e64a19 !important; }

.dark-deep-orange .bg-primary.darken-3 {
  background-color: #d84315 !important; }

.dark-deep-orange .bg-primary.darken-4 {
  background-color: #bf360c !important; }

.dark-deep-orange .bg-primary.accent-1 {
  background-color: #ff9e80 !important; }

.dark-deep-orange .bg-primary.accent-2 {
  background-color: #ff6e40 !important; }

.dark-deep-orange .bg-primary.accent-3 {
  background-color: #ff3d00 !important; }

.dark-deep-orange .bg-primary.accent-4 {
  background-color: #dd2c00 !important; }

.dark-deep-orange a.text-secondary {
  color: #40C4FF; }
  .dark-deep-orange a.text-secondary:focus, .dark-deep-orange a.text-secondary:hover {
    color: #0db4ff !important; }

.dark-deep-orange .text-secondary {
  color: #40C4FF !important; }

.dark-deep-orange .bg-secondary,
.dark-deep-orange .badge-secondary {
  background-color: #40C4FF !important;
  color: #fff !important; }

.dark-deep-orange .btn-secondary {
  background-color: #40C4FF;
  border-color: #40C4FF;
  color: #fff; }
  .dark-deep-orange .btn-secondary:hover, .dark-deep-orange .btn-secondary:focus, .dark-deep-orange .btn-secondary:active {
    background-color: #27bcff !important;
    border-color: #27bcff !important;
    color: #fff !important; }

.dark-deep-orange .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .dark-deep-orange .jr-flat-btn:hover, .dark-deep-orange .jr-flat-btn:focus, .dark-deep-orange .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.dark-deep-orange .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #ff5722 !important;
  border-color: transparent !important; }
  .dark-deep-orange .jr-flat-btn.jr-btn-primary:hover, .dark-deep-orange .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 87, 34, 0.12) !important;
    color: #ff5722 !important; }

.dark-deep-orange .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-deep-orange .bg-primary .jr-flat-btn.jr-btn-primary:hover, .dark-deep-orange .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-deep-orange .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #40C4FF !important;
  border-color: transparent !important; }
  .dark-deep-orange .jr-flat-btn.jr-btn-secondary:hover, .dark-deep-orange .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(64, 196, 255, 0.12) !important;
    color: #40C4FF !important; }

.dark-deep-orange .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-deep-orange .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .dark-deep-orange .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-deep-orange .app-main-header {
  background-color: #ff5722 !important;
  color: #fff;
  border-color: #ff5722; }
  .dark-deep-orange .app-main-header .search-bar input {
    background-color: #ff6a3c; }
    .dark-deep-orange .app-main-header .search-bar input + .search-icon i {
      color: #fff; }
    .dark-deep-orange .app-main-header .search-bar input:focus {
      background-color: #fff; }
      .dark-deep-orange .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  @media screen and (max-width: 575px) {
    .dark-deep-orange .app-main-header .search-dropdown input {
      background-color: #fff; }
      .dark-deep-orange .app-main-header .search-dropdown input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); } }
  .dark-deep-orange .app-main-header .jr-menu-icon .menu-icon {
    background-color: #fff !important; }

.dark-deep-orange .app-header .jr-menu-icon .menu-icon {
  background-color: #fff; }

.dark-deep-orange .quick-menu .icon-btn {
  color: #fff; }

.dark-deep-orange .react-logo-white.d-block {
  display: block !important; }

@media screen and (min-width: 992px) {
  .dark-deep-orange .react-logo-white.d-lg-block {
    display: block !important; }
  .dark-deep-orange .react-logo-white.d-lg-none {
    display: none !important; } }

.dark-deep-orange .react-logo-color.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .dark-deep-orange .react-logo-color.d-lg-block {
    display: none !important; } }

.dark-deep-orange .color-theme-header {
  background-color: #ff5722; }

.dark-deep-orange .sidebar-header {
  border-bottom: 0 none;
  background-color: #1d1d1d; }

.dark-deep-orange .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important; }
  .dark-deep-orange .side-nav .user-profile {
    background-color: #1d1d1d;
    position: relative;
    z-index: 2; }
  .dark-deep-orange .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-deep-orange .side-nav .user-detail .user-name {
    color: #fff; }

.dark-deep-orange ul.nav-menu li a {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu > li.open > a,
.dark-deep-orange ul.nav-menu > li > a:hover,
.dark-deep-orange ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-orange ul.nav-menu li.menu.open > a {
  border-color: #ff5722; }

.dark-deep-orange ul.nav-menu li.menu > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-deep-orange ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-deep-orange ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu li.menu.open > a:before,
.dark-deep-orange ul.nav-menu li.menu > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #ff5722; }

.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-deep-orange ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-deep-orange ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-deep-orange .navbar-nav li:hover > a,
.dark-deep-orange .navbar-nav li:focus > a {
  color: #ff5722; }

.dark-deep-orange .navbar-nav li a {
  color: #868e96; }

.dark-deep-orange .navbar-nav li a:hover,
.dark-deep-orange .navbar-nav li a:focus {
  color: #ff5722; }

.dark-deep-orange .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-deep-orange .navbar-nav li.nav-item:hover > a,
.dark-deep-orange .navbar-nav li.nav-item:focus > a,
.dark-deep-orange .navbar-nav li.nav-item > a:hover,
.dark-deep-orange .navbar-nav li.nav-item > a:focus,
.dark-deep-orange .navbar-nav li.nav-item.active > a {
  color: #40C4FF; }

.dark-deep-orange .navbar-nav ul.sub-menu li a.active,
.dark-deep-orange .navbar-nav ul.sub-menu li.active > a {
  color: #ff5722; }

.dark-deep-orange .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-deep-orange .navbar-nav .nav-arrow a:hover:before,
.dark-deep-orange .navbar-nav .nav-arrow a:focus:before {
  color: #ff5722; }

.dark-deep-orange .navbar-nav .nav-arrow:hover > a:before,
.dark-deep-orange .navbar-nav .nav-arrow:focus > a:before,
.dark-deep-orange .navbar-nav .nav-arrow.active > a:before {
  color: #ff5722; }

.dark-deep-orange .app-top-nav {
  background-color: #252525 !important;
  border-color: #252525; }
  .dark-deep-orange .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-deep-orange .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-deep-orange .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-deep-orange .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-deep-orange .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-deep-orange .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #40C4FF; }

.dark-deep-orange .module-nav li a.active {
  border-color: #ff5722; }

.dark-deep-orange .rbc-event {
  background-color: #ff5722; }

.dark-deep-orange .rbc-event.rbc-selected {
  background-color: #ee3900; }

.dark-deep-orange .rbc-slot-selection {
  background-color: rgba(255, 87, 34, 0.7); }

.dark-deep-orange .rbc-toolbar button:active,
.dark-deep-orange .rbc-toolbar button.rbc-active {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:active:hover,
.dark-deep-orange .rbc-toolbar button.rbc-active:hover,
.dark-deep-orange .rbc-toolbar button:active:focus,
.dark-deep-orange .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:focus {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-toolbar button:hover {
  background-color: rgba(255, 87, 34, 0.9);
  border-color: #ff5722; }

.dark-deep-orange .rbc-day-slot .rbc-event {
  border-color: #ff5722; }

.dark-deep-orange .chat-sidenav-title {
  color: #ff5722; }

.dark-deep-orange .chat-user-item.active, .dark-deep-orange .chat-user-item:hover {
  background-color: #fff2ee; }

.dark-deep-orange .contact-list i {
  color: #ff5722; }

.dark-deep-orange .Collapsible__trigger {
  background: #ff5722; }

.dark-deep-orange .categories-list li a:focus,
.dark-deep-orange .categories-list li a:hover,
.dark-deep-orange .categories-list li.active a {
  color: #ff5722; }

.dark-deep-orange .login-content .form-control:focus {
  border-color: #ff5722; }

.dark-deep-orange .filter-with-bg-color ul li a {
  border-color: #ff5722; }
  .dark-deep-orange .filter-with-bg-color ul li a:hover, .dark-deep-orange .filter-with-bg-color ul li a:focus, .dark-deep-orange .filter-with-bg-color ul li a.active {
    background-color: #ff5722; }

.dark-deep-orange .profile-intro .icon {
  color: #ff5722; }

.dark-deep-orange .social-link li.active a,
.dark-deep-orange .social-link li a:hover,
.dark-deep-orange .social-link li a:focus {
  color: #40C4FF; }

.dark-deep-orange .actions {
  color: #40C4FF; }

.dark-deep-orange .table-hover tbody tr:hover {
  background-color: rgba(255, 87, 34, 0.075); }

.dark-deep-orange .border-primary {
  border-color: #ff5722 !important; }

.dark-deep-orange .app-logo-content {
  background-color: #ff5722; }

.dark-deep-orange .app-social-block .social-link button {
  border: solid 1px #ff5722;
  color: #ff5722; }
  .dark-deep-orange .app-social-block .social-link button:hover, .dark-deep-orange .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #ff5722; }

.dark-deep-orange .btn-group > .jr-btn.active,
.dark-deep-orange .btn-group > .btn-default.active,
.dark-deep-orange .btn-group-vertical > .jr-btn.active,
.dark-deep-orange .btn-group-vertical > .btn-default.active {
  background-color: #ff5722;
  border-color: #ff5722; }

.dark-deep-orange .btn-group > .jr-flat-btn,
.dark-deep-orange .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-deep-orange .btn-group > .jr-flat-btn.active,
  .dark-deep-orange .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #ff5722 !important;
    border-color: transparent !important; }
    .dark-deep-orange .btn-group > .jr-flat-btn.active:hover, .dark-deep-orange .btn-group > .jr-flat-btn.active:focus,
    .dark-deep-orange .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-deep-orange .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(255, 87, 34, 0.12) !important;
      color: #ff5722 !important; }

.dark-deep-orange .nav-pill-sales .nav-link.active {
  background-color: #ff5722;
  border-color: #ff5722; }

.dark-deep-orange .form-checkbox > input + .check,
.dark-deep-orange .form-checkbox > input.sm + .check {
  color: #ff5722; }

/* Input Placeholder Style */
.dark-deep-orange .app-main-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb; }

.dark-deep-orange .app-main-header ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb; }

.dark-deep-orange .app-main-header :-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb; }

.dark-deep-orange .app-main-header :-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb; }

.dark-deep-orange .app-main-header input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gainsboro; }

.dark-deep-orange .app-main-header input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: gainsboro; }

.dark-deep-orange .app-main-header input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: gainsboro; }

.dark-deep-orange .app-main-header input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: gainsboro; }

/*Theme Dark Pink Styles*/
.dark-pink .right-arrow {
  color: #e91e63; }
  .dark-pink .right-arrow:after {
    color: #e91e63; }

.dark-pink .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-pink .btn-primary:focus, .dark-pink .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-pink .btn-primary.disabled, .dark-pink .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-pink .btn-primary:not(:disabled):not(.disabled):active, .dark-pink .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-pink .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-pink .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-pink .btn-secondary:focus, .dark-pink .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-pink .btn-secondary.disabled, .dark-pink .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-pink .btn-secondary:not(:disabled):not(.disabled):active, .dark-pink .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-pink .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-pink .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-pink .btn-success:focus, .dark-pink .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-pink .btn-success.disabled, .dark-pink .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-pink .btn-success:not(:disabled):not(.disabled):active, .dark-pink .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-pink .btn-success:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-pink .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-pink .btn-info:focus, .dark-pink .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-pink .btn-info.disabled, .dark-pink .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-pink .btn-info:not(:disabled):not(.disabled):active, .dark-pink .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-pink .btn-info:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-pink .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-pink .btn-warning:focus, .dark-pink .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-pink .btn-warning.disabled, .dark-pink .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-pink .btn-warning:not(:disabled):not(.disabled):active, .dark-pink .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-pink .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-pink .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-pink .btn-danger:focus, .dark-pink .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-pink .btn-danger.disabled, .dark-pink .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-pink .btn-danger:not(:disabled):not(.disabled):active, .dark-pink .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-pink .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-pink .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-pink .btn-light:focus, .dark-pink .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-pink .btn-light.disabled, .dark-pink .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-pink .btn-light:not(:disabled):not(.disabled):active, .dark-pink .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-pink .btn-light:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-pink .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-pink .btn-dark:focus, .dark-pink .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-pink .btn-dark.disabled, .dark-pink .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-pink .btn-dark:not(:disabled):not(.disabled):active, .dark-pink .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-pink .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-pink a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-pink a.btn-primary:focus, .dark-pink a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-pink a.btn-primary.disabled, .dark-pink a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-pink a.btn-primary:not(:disabled):not(.disabled):active, .dark-pink a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-pink a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-pink a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-pink a.btn-secondary:focus, .dark-pink a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-pink a.btn-secondary.disabled, .dark-pink a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-pink a.btn-secondary:not(:disabled):not(.disabled):active, .dark-pink a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-pink a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-pink a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-pink a.btn-success:focus, .dark-pink a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-pink a.btn-success.disabled, .dark-pink a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-pink a.btn-success:not(:disabled):not(.disabled):active, .dark-pink a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-pink a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-pink a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-pink a.btn-info:focus, .dark-pink a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-pink a.btn-info.disabled, .dark-pink a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-pink a.btn-info:not(:disabled):not(.disabled):active, .dark-pink a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-pink a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-pink a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-pink a.btn-warning:focus, .dark-pink a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-pink a.btn-warning.disabled, .dark-pink a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-pink a.btn-warning:not(:disabled):not(.disabled):active, .dark-pink a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-pink a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-pink a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-pink a.btn-danger:focus, .dark-pink a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-pink a.btn-danger.disabled, .dark-pink a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-pink a.btn-danger:not(:disabled):not(.disabled):active, .dark-pink a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-pink a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-pink a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-pink a.btn-light:focus, .dark-pink a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-pink a.btn-light.disabled, .dark-pink a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-pink a.btn-light:not(:disabled):not(.disabled):active, .dark-pink a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-pink a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-pink a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-pink a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-pink a.btn-dark:focus, .dark-pink a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-pink a.btn-dark.disabled, .dark-pink a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-pink a.btn-dark:not(:disabled):not(.disabled):active, .dark-pink a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-pink a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-pink .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-pink .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-pink .btn-outline-primary:focus, .dark-pink .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-pink .btn-outline-primary.disabled, .dark-pink .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-pink .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-pink .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-pink .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-pink .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-pink .btn-outline-secondary:focus, .dark-pink .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-pink .btn-outline-secondary.disabled, .dark-pink .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-pink .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-pink .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-pink .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-pink .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-pink .btn-outline-success:focus, .dark-pink .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-pink .btn-outline-success.disabled, .dark-pink .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-pink .btn-outline-success:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-pink .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-pink .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-pink .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-pink .btn-outline-info:focus, .dark-pink .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-pink .btn-outline-info.disabled, .dark-pink .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-pink .btn-outline-info:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-pink .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-pink .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-pink .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-pink .btn-outline-warning:focus, .dark-pink .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-pink .btn-outline-warning.disabled, .dark-pink .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-pink .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-pink .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-pink .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-pink .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-pink .btn-outline-danger:focus, .dark-pink .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-pink .btn-outline-danger.disabled, .dark-pink .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-pink .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-pink .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-pink .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-pink .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-pink .btn-outline-light:focus, .dark-pink .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-pink .btn-outline-light.disabled, .dark-pink .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-pink .btn-outline-light:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-pink .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-pink .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-pink .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-pink .btn-outline-dark:focus, .dark-pink .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-pink .btn-outline-dark.disabled, .dark-pink .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-pink .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-pink .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-pink .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-pink a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-pink a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-pink a.btn-outline-primary:focus, .dark-pink a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-pink a.btn-outline-primary.disabled, .dark-pink a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-pink a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-pink a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-pink a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-pink a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-pink a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-pink a.btn-outline-secondary:focus, .dark-pink a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-pink a.btn-outline-secondary.disabled, .dark-pink a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-pink a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-pink a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-pink a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-pink a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-pink a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-pink a.btn-outline-success:focus, .dark-pink a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-pink a.btn-outline-success.disabled, .dark-pink a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-pink a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-pink a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-pink a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-pink a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-pink a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-pink a.btn-outline-info:focus, .dark-pink a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-pink a.btn-outline-info.disabled, .dark-pink a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-pink a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-pink a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-pink a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-pink a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-pink a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-pink a.btn-outline-warning:focus, .dark-pink a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-pink a.btn-outline-warning.disabled, .dark-pink a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-pink a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-pink a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-pink a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-pink a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-pink a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-pink a.btn-outline-danger:focus, .dark-pink a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-pink a.btn-outline-danger.disabled, .dark-pink a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-pink a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-pink a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-pink a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-pink a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-pink a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-pink a.btn-outline-light:focus, .dark-pink a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-pink a.btn-outline-light.disabled, .dark-pink a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-pink a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-pink a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-pink a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-pink a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-pink a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-pink a.btn-outline-dark:focus, .dark-pink a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-pink a.btn-outline-dark.disabled, .dark-pink a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-pink a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-pink a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-pink a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-pink a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-pink a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-pink a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-pink .btn-outline-primary,
.dark-pink a.btn-outline-primary {
  color: #e91e63;
  border-color: #e91e63; }
  .dark-pink .btn-outline-primary:focus, .dark-pink .btn-outline-primary:hover, .dark-pink .btn-outline-primary.disabled, .dark-pink .btn-outline-primary:disabled, .dark-pink .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-primary.dropdown-toggle,
  .dark-pink a.btn-outline-primary:focus,
  .dark-pink a.btn-outline-primary:hover,
  .dark-pink a.btn-outline-primary.disabled,
  .dark-pink a.btn-outline-primary:disabled,
  .dark-pink a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-pink a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-pink a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #e91e63;
    background-color: #e91e63; }

.dark-pink .btn-outline-secondary,
.dark-pink a.btn-outline-secondary {
  color: #00BFA5;
  border-color: #00BFA5; }
  .dark-pink .btn-outline-secondary:focus, .dark-pink .btn-outline-secondary:hover, .dark-pink .btn-outline-secondary.disabled, .dark-pink .btn-outline-secondary:disabled, .dark-pink .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-pink .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-pink .btn-outline-secondary.dropdown-toggle,
  .dark-pink a.btn-outline-secondary:focus,
  .dark-pink a.btn-outline-secondary:hover,
  .dark-pink a.btn-outline-secondary.disabled,
  .dark-pink a.btn-outline-secondary:disabled,
  .dark-pink a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-pink a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-pink a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #00BFA5;
    background-color: #00BFA5; }

.dark-pink a,
.dark-pink a.text-primary {
  color: #e91e63; }
  .dark-pink a:focus, .dark-pink a:hover,
  .dark-pink a.text-primary:focus,
  .dark-pink a.text-primary:hover {
    color: #c1134e; }

.dark-pink .text-primary {
  color: #e91e63 !important; }

.dark-pink .page-link {
  color: #e91e63; }
  .dark-pink .page-link:focus, .dark-pink .page-link:hover {
    color: #e91e63; }

.dark-pink .bg-primary,
.dark-pink .badge-primary {
  background-color: #e91e63 !important;
  color: #fff !important; }

.dark-pink .btn-primary,
.dark-pink .jr-btn-primary,
.dark-pink a.btn-primary,
.dark-pink a.jr-btn-primary {
  background-color: #e91e63;
  border-color: #e91e63;
  color: #fff; }
  .dark-pink .btn-primary:hover, .dark-pink .btn-primary:focus, .dark-pink .btn-primary:not([disabled]):not(.disabled):active,
  .dark-pink .jr-btn-primary:hover,
  .dark-pink .jr-btn-primary:focus,
  .dark-pink .jr-btn-primary:not([disabled]):not(.disabled):active,
  .dark-pink a.btn-primary:hover,
  .dark-pink a.btn-primary:focus,
  .dark-pink a.btn-primary:not([disabled]):not(.disabled):active,
  .dark-pink a.jr-btn-primary:hover,
  .dark-pink a.jr-btn-primary:focus,
  .dark-pink a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #d81558 !important;
    border-color: #d81558 !important;
    color: #fff !important; }

.dark-pink .bg-primary.lighten-4 {
  background-color: #f8bbd0 !important; }

.dark-pink .bg-primary.lighten-3 {
  background-color: #f48fb1 !important; }

.dark-pink .bg-primary.lighten-2 {
  background-color: #f06292 !important; }

.dark-pink .bg-primary.lighten-1 {
  background-color: #ec407a !important; }

.dark-pink .bg-primary.darken-1 {
  background-color: #d81b60 !important; }

.dark-pink .bg-primary.darken-2 {
  background-color: #c2185b !important; }

.dark-pink .bg-primary.darken-3 {
  background-color: #ad1457 !important; }

.dark-pink .bg-primary.darken-4 {
  background-color: #880e4f !important; }

.dark-pink .bg-primary.accent-1 {
  background-color: #ff80ab !important; }

.dark-pink .bg-primary.accent-2 {
  background-color: #ff4081 !important; }

.dark-pink .bg-primary.accent-3 {
  background-color: #f50057 !important; }

.dark-pink .bg-primary.accent-4 {
  background-color: #c51162 !important; }

.dark-pink a.text-secondary {
  color: #00BFA5; }
  .dark-pink a.text-secondary:focus, .dark-pink a.text-secondary:hover {
    color: #008c79 !important; }

.dark-pink .text-secondary {
  color: #00BFA5 !important; }

.dark-pink .bg-secondary,
.dark-pink .badge-secondary {
  background-color: #00BFA5 !important;
  color: #fff !important; }

.dark-pink .btn-secondary {
  background-color: #00BFA5;
  border-color: #00BFA5;
  color: #fff; }
  .dark-pink .btn-secondary:hover, .dark-pink .btn-secondary:focus, .dark-pink .btn-secondary:active {
    background-color: #00a68f !important;
    border-color: #00a68f !important;
    color: #fff !important; }

.dark-pink .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .dark-pink .jr-flat-btn:hover, .dark-pink .jr-flat-btn:focus, .dark-pink .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.dark-pink .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #e91e63 !important;
  border-color: transparent !important; }
  .dark-pink .jr-flat-btn.jr-btn-primary:hover, .dark-pink .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(233, 30, 99, 0.12) !important;
    color: #e91e63 !important; }

.dark-pink .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-pink .bg-primary .jr-flat-btn.jr-btn-primary:hover, .dark-pink .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-pink .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #00BFA5 !important;
  border-color: transparent !important; }
  .dark-pink .jr-flat-btn.jr-btn-secondary:hover, .dark-pink .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(0, 191, 165, 0.12) !important;
    color: #00BFA5 !important; }

.dark-pink .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-pink .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .dark-pink .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-pink .app-main-header {
  background-color: #e91e63 !important;
  color: #fff;
  border-color: #e91e63; }
  .dark-pink .app-main-header .search-bar input {
    background-color: #eb3573; }
    .dark-pink .app-main-header .search-bar input + .search-icon i {
      color: #fff; }
    .dark-pink .app-main-header .search-bar input:focus {
      background-color: #fff; }
      .dark-pink .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  @media screen and (max-width: 575px) {
    .dark-pink .app-main-header .search-dropdown input {
      background-color: #fff; }
      .dark-pink .app-main-header .search-dropdown input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); } }
  .dark-pink .app-main-header .jr-menu-icon .menu-icon {
    background-color: #fff !important; }

.dark-pink .app-header .jr-menu-icon .menu-icon {
  background-color: #fff; }

.dark-pink .quick-menu .icon-btn {
  color: #fff; }

.dark-pink .react-logo-white.d-block {
  display: block !important; }

@media screen and (min-width: 992px) {
  .dark-pink .react-logo-white.d-lg-block {
    display: block !important; }
  .dark-pink .react-logo-white.d-lg-none {
    display: none !important; } }

.dark-pink .react-logo-color.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .dark-pink .react-logo-color.d-lg-block {
    display: none !important; } }

.dark-pink .color-theme-header {
  background-color: #e91e63; }

.dark-pink .sidebar-header {
  border-bottom: 0 none;
  background-color: #1d1d1d; }

.dark-pink .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important; }
  .dark-pink .side-nav .user-profile {
    background-color: #1d1d1d;
    position: relative;
    z-index: 2; }
  .dark-pink .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-pink .side-nav .user-detail .user-name {
    color: #fff; }

.dark-pink ul.nav-menu li a {
  color: #a1a1a1; }

.dark-pink ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-pink ul.nav-menu > li.open > a,
.dark-pink ul.nav-menu > li > a:hover,
.dark-pink ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-pink ul.nav-menu li.menu.open > a {
  border-color: #e91e63; }

.dark-pink ul.nav-menu li.menu > a:focus:before,
.dark-pink ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-pink ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-pink ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-pink ul.nav-menu li.menu.open > a:before,
.dark-pink ul.nav-menu li.menu > a:focus:before,
.dark-pink ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-pink ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-pink ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #e91e63; }

.dark-pink ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-pink ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-pink ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-pink .navbar-nav li:hover > a,
.dark-pink .navbar-nav li:focus > a {
  color: #e91e63; }

.dark-pink .navbar-nav li a {
  color: #868e96; }

.dark-pink .navbar-nav li a:hover,
.dark-pink .navbar-nav li a:focus {
  color: #e91e63; }

.dark-pink .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-pink .navbar-nav li.nav-item:hover > a,
.dark-pink .navbar-nav li.nav-item:focus > a,
.dark-pink .navbar-nav li.nav-item > a:hover,
.dark-pink .navbar-nav li.nav-item > a:focus,
.dark-pink .navbar-nav li.nav-item.active > a {
  color: #00BFA5; }

.dark-pink .navbar-nav ul.sub-menu li a.active,
.dark-pink .navbar-nav ul.sub-menu li.active > a {
  color: #e91e63; }

.dark-pink .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-pink .navbar-nav .nav-arrow a:hover:before,
.dark-pink .navbar-nav .nav-arrow a:focus:before {
  color: #e91e63; }

.dark-pink .navbar-nav .nav-arrow:hover > a:before,
.dark-pink .navbar-nav .nav-arrow:focus > a:before,
.dark-pink .navbar-nav .nav-arrow.active > a:before {
  color: #e91e63; }

.dark-pink .app-top-nav {
  background-color: #252525 !important;
  border-color: #252525; }
  .dark-pink .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-pink .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-pink .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-pink .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-pink .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-pink .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #00BFA5; }

.dark-pink .module-nav li a.active {
  border-color: #e91e63; }

.dark-pink .rbc-event {
  background-color: #e91e63; }

.dark-pink .rbc-event.rbc-selected {
  background-color: #c1134e; }

.dark-pink .rbc-slot-selection {
  background-color: rgba(233, 30, 99, 0.7); }

.dark-pink .rbc-toolbar button:active,
.dark-pink .rbc-toolbar button.rbc-active {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.dark-pink .rbc-toolbar button:active:hover,
.dark-pink .rbc-toolbar button.rbc-active:hover,
.dark-pink .rbc-toolbar button:active:focus,
.dark-pink .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.dark-pink .rbc-toolbar button:focus {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.dark-pink .rbc-toolbar button:hover {
  background-color: rgba(233, 30, 99, 0.9);
  border-color: #e91e63; }

.dark-pink .rbc-day-slot .rbc-event {
  border-color: #e91e63; }

.dark-pink .chat-sidenav-title {
  color: #e91e63; }

.dark-pink .chat-user-item.active, .dark-pink .chat-user-item:hover {
  background-color: #fdeff4; }

.dark-pink .contact-list i {
  color: #e91e63; }

.dark-pink .Collapsible__trigger {
  background: #e91e63; }

.dark-pink .categories-list li a:focus,
.dark-pink .categories-list li a:hover,
.dark-pink .categories-list li.active a {
  color: #e91e63; }

.dark-pink .login-content .form-control:focus {
  border-color: #e91e63; }

.dark-pink .filter-with-bg-color ul li a {
  border-color: #e91e63; }
  .dark-pink .filter-with-bg-color ul li a:hover, .dark-pink .filter-with-bg-color ul li a:focus, .dark-pink .filter-with-bg-color ul li a.active {
    background-color: #e91e63; }

.dark-pink .profile-intro .icon {
  color: #e91e63; }

.dark-pink .social-link li.active a,
.dark-pink .social-link li a:hover,
.dark-pink .social-link li a:focus {
  color: #00BFA5; }

.dark-pink .actions {
  color: #00BFA5; }

.dark-pink .table-hover tbody tr:hover {
  background-color: rgba(233, 30, 99, 0.075); }

.dark-pink .border-primary {
  border-color: #e91e63 !important; }

.dark-pink .app-logo-content {
  background-color: #e91e63; }

.dark-pink .app-social-block .social-link button {
  border: solid 1px #e91e63;
  color: #e91e63; }
  .dark-pink .app-social-block .social-link button:hover, .dark-pink .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #e91e63; }

.dark-pink .btn-group > .jr-btn.active,
.dark-pink .btn-group > .btn-default.active,
.dark-pink .btn-group-vertical > .jr-btn.active,
.dark-pink .btn-group-vertical > .btn-default.active {
  background-color: #e91e63;
  border-color: #e91e63; }

.dark-pink .btn-group > .jr-flat-btn,
.dark-pink .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-pink .btn-group > .jr-flat-btn.active,
  .dark-pink .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #e91e63 !important;
    border-color: transparent !important; }
    .dark-pink .btn-group > .jr-flat-btn.active:hover, .dark-pink .btn-group > .jr-flat-btn.active:focus,
    .dark-pink .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-pink .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(233, 30, 99, 0.12) !important;
      color: #e91e63 !important; }

.dark-pink .nav-pill-sales .nav-link.active {
  background-color: #e91e63;
  border-color: #e91e63; }

.dark-pink .form-checkbox > input + .check,
.dark-pink .form-checkbox > input.sm + .check {
  color: #e91e63; }

/* Input Placeholder Style */
.dark-pink .app-main-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb; }

.dark-pink .app-main-header ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb; }

.dark-pink .app-main-header :-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb; }

.dark-pink .app-main-header :-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb; }

.dark-pink .app-main-header input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gainsboro; }

.dark-pink .app-main-header input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: gainsboro; }

.dark-pink .app-main-header input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: gainsboro; }

.dark-pink .app-main-header input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: gainsboro; }

/*Theme Dark Blue Styles*/
.dark-blue .right-arrow {
  color: #2196F3; }
  .dark-blue .right-arrow:after {
    color: #2196F3; }

.dark-blue .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-blue .btn-primary:focus, .dark-blue .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-blue .btn-primary.disabled, .dark-blue .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-blue .btn-primary:not(:disabled):not(.disabled):active, .dark-blue .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-blue .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-blue .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-blue .btn-secondary:focus, .dark-blue .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-blue .btn-secondary.disabled, .dark-blue .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-blue .btn-secondary:not(:disabled):not(.disabled):active, .dark-blue .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-blue .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-blue .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-blue .btn-success:focus, .dark-blue .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-blue .btn-success.disabled, .dark-blue .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-blue .btn-success:not(:disabled):not(.disabled):active, .dark-blue .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-blue .btn-success:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-blue .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-blue .btn-info:focus, .dark-blue .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-blue .btn-info.disabled, .dark-blue .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-blue .btn-info:not(:disabled):not(.disabled):active, .dark-blue .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-blue .btn-info:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-blue .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-blue .btn-warning:focus, .dark-blue .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-blue .btn-warning.disabled, .dark-blue .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-blue .btn-warning:not(:disabled):not(.disabled):active, .dark-blue .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-blue .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-blue .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-blue .btn-danger:focus, .dark-blue .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-blue .btn-danger.disabled, .dark-blue .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-blue .btn-danger:not(:disabled):not(.disabled):active, .dark-blue .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-blue .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-blue .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-blue .btn-light:focus, .dark-blue .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-blue .btn-light.disabled, .dark-blue .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-blue .btn-light:not(:disabled):not(.disabled):active, .dark-blue .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-blue .btn-light:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-blue .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-blue .btn-dark:focus, .dark-blue .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-blue .btn-dark.disabled, .dark-blue .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-blue .btn-dark:not(:disabled):not(.disabled):active, .dark-blue .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-blue .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-blue a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-blue a.btn-primary:focus, .dark-blue a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-blue a.btn-primary.disabled, .dark-blue a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-blue a.btn-primary:not(:disabled):not(.disabled):active, .dark-blue a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-blue a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-blue a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-blue a.btn-secondary:focus, .dark-blue a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-blue a.btn-secondary.disabled, .dark-blue a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-blue a.btn-secondary:not(:disabled):not(.disabled):active, .dark-blue a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-blue a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-blue a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-blue a.btn-success:focus, .dark-blue a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-blue a.btn-success.disabled, .dark-blue a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-blue a.btn-success:not(:disabled):not(.disabled):active, .dark-blue a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-blue a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-blue a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-blue a.btn-info:focus, .dark-blue a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-blue a.btn-info.disabled, .dark-blue a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-blue a.btn-info:not(:disabled):not(.disabled):active, .dark-blue a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-blue a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-blue a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-blue a.btn-warning:focus, .dark-blue a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-blue a.btn-warning.disabled, .dark-blue a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-blue a.btn-warning:not(:disabled):not(.disabled):active, .dark-blue a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-blue a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-blue a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-blue a.btn-danger:focus, .dark-blue a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-blue a.btn-danger.disabled, .dark-blue a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-blue a.btn-danger:not(:disabled):not(.disabled):active, .dark-blue a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-blue a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-blue a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-blue a.btn-light:focus, .dark-blue a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-blue a.btn-light.disabled, .dark-blue a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-blue a.btn-light:not(:disabled):not(.disabled):active, .dark-blue a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-blue a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-blue a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-blue a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-blue a.btn-dark:focus, .dark-blue a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-blue a.btn-dark.disabled, .dark-blue a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-blue a.btn-dark:not(:disabled):not(.disabled):active, .dark-blue a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-blue a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-blue .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-blue .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-blue .btn-outline-primary:focus, .dark-blue .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-blue .btn-outline-primary.disabled, .dark-blue .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-blue .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-blue .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-blue .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-blue .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-blue .btn-outline-secondary:focus, .dark-blue .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-blue .btn-outline-secondary.disabled, .dark-blue .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-blue .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-blue .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-blue .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-blue .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-blue .btn-outline-success:focus, .dark-blue .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-blue .btn-outline-success.disabled, .dark-blue .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-blue .btn-outline-success:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-blue .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-blue .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-blue .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-blue .btn-outline-info:focus, .dark-blue .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-blue .btn-outline-info.disabled, .dark-blue .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-blue .btn-outline-info:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-blue .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-blue .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-blue .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-blue .btn-outline-warning:focus, .dark-blue .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-blue .btn-outline-warning.disabled, .dark-blue .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-blue .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-blue .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-blue .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-blue .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-blue .btn-outline-danger:focus, .dark-blue .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-blue .btn-outline-danger.disabled, .dark-blue .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-blue .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-blue .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-blue .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-blue .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-blue .btn-outline-light:focus, .dark-blue .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-blue .btn-outline-light.disabled, .dark-blue .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-blue .btn-outline-light:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-blue .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-blue .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-blue .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-blue .btn-outline-dark:focus, .dark-blue .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-blue .btn-outline-dark.disabled, .dark-blue .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-blue .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-blue .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-blue .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-blue a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-blue a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-blue a.btn-outline-primary:focus, .dark-blue a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-blue a.btn-outline-primary.disabled, .dark-blue a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-blue a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-blue a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-blue a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-blue a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-blue a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-blue a.btn-outline-secondary:focus, .dark-blue a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-blue a.btn-outline-secondary.disabled, .dark-blue a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-blue a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-blue a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-blue a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-blue a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-blue a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-blue a.btn-outline-success:focus, .dark-blue a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-blue a.btn-outline-success.disabled, .dark-blue a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-blue a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-blue a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-blue a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-blue a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-blue a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-blue a.btn-outline-info:focus, .dark-blue a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-blue a.btn-outline-info.disabled, .dark-blue a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-blue a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-blue a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-blue a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-blue a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-blue a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-blue a.btn-outline-warning:focus, .dark-blue a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-blue a.btn-outline-warning.disabled, .dark-blue a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-blue a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-blue a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-blue a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-blue a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-blue a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-blue a.btn-outline-danger:focus, .dark-blue a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-blue a.btn-outline-danger.disabled, .dark-blue a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-blue a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-blue a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-blue a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-blue a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-blue a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-blue a.btn-outline-light:focus, .dark-blue a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-blue a.btn-outline-light.disabled, .dark-blue a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-blue a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-blue a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-blue a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-blue a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-blue a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-blue a.btn-outline-dark:focus, .dark-blue a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-blue a.btn-outline-dark.disabled, .dark-blue a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-blue a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-blue a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-blue a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-blue a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-blue a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-blue a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-blue .btn-outline-primary,
.dark-blue a.btn-outline-primary {
  color: #2196F3;
  border-color: #2196F3; }
  .dark-blue .btn-outline-primary:focus, .dark-blue .btn-outline-primary:hover, .dark-blue .btn-outline-primary.disabled, .dark-blue .btn-outline-primary:disabled, .dark-blue .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-primary.dropdown-toggle,
  .dark-blue a.btn-outline-primary:focus,
  .dark-blue a.btn-outline-primary:hover,
  .dark-blue a.btn-outline-primary.disabled,
  .dark-blue a.btn-outline-primary:disabled,
  .dark-blue a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-blue a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-blue a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #2196F3;
    background-color: #2196F3; }

.dark-blue .btn-outline-secondary,
.dark-blue a.btn-outline-secondary {
  color: #FF6E40;
  border-color: #FF6E40; }
  .dark-blue .btn-outline-secondary:focus, .dark-blue .btn-outline-secondary:hover, .dark-blue .btn-outline-secondary.disabled, .dark-blue .btn-outline-secondary:disabled, .dark-blue .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-blue .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-blue .btn-outline-secondary.dropdown-toggle,
  .dark-blue a.btn-outline-secondary:focus,
  .dark-blue a.btn-outline-secondary:hover,
  .dark-blue a.btn-outline-secondary.disabled,
  .dark-blue a.btn-outline-secondary:disabled,
  .dark-blue a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-blue a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-blue a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FF6E40;
    background-color: #FF6E40; }

.dark-blue a,
.dark-blue a.text-primary {
  color: #2196F3; }
  .dark-blue a:focus, .dark-blue a:hover,
  .dark-blue a.text-primary:focus,
  .dark-blue a.text-primary:hover {
    color: #0c7cd5; }

.dark-blue .text-primary {
  color: #2196F3 !important; }

.dark-blue .page-link {
  color: #2196F3; }
  .dark-blue .page-link:focus, .dark-blue .page-link:hover {
    color: #2196F3; }

.dark-blue .bg-primary,
.dark-blue .badge-primary {
  background-color: #2196F3 !important;
  color: #fff !important; }

.dark-blue .btn-primary,
.dark-blue .jr-btn-primary,
.dark-blue a.btn-primary,
.dark-blue a.jr-btn-primary {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #fff; }
  .dark-blue .btn-primary:hover, .dark-blue .btn-primary:focus, .dark-blue .btn-primary:not([disabled]):not(.disabled):active,
  .dark-blue .jr-btn-primary:hover,
  .dark-blue .jr-btn-primary:focus,
  .dark-blue .jr-btn-primary:not([disabled]):not(.disabled):active,
  .dark-blue a.btn-primary:hover,
  .dark-blue a.btn-primary:focus,
  .dark-blue a.btn-primary:not([disabled]):not(.disabled):active,
  .dark-blue a.jr-btn-primary:hover,
  .dark-blue a.jr-btn-primary:focus,
  .dark-blue a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #0d8aee !important;
    border-color: #0d8aee !important;
    color: #fff !important; }

.dark-blue .bg-primary.lighten-4 {
  background-color: #BBDEFB !important; }

.dark-blue .bg-primary.lighten-3 {
  background-color: #90CAF9 !important; }

.dark-blue .bg-primary.lighten-2 {
  background-color: #64B5F6 !important; }

.dark-blue .bg-primary.lighten-1 {
  background-color: #42A5F5 !important; }

.dark-blue .bg-primary.darken-1 {
  background-color: #1E88E5 !important; }

.dark-blue .bg-primary.darken-2 {
  background-color: #1976D2 !important; }

.dark-blue .bg-primary.darken-3 {
  background-color: #1565C0 !important; }

.dark-blue .bg-primary.darken-4 {
  background-color: #0D47A1 !important; }

.dark-blue .bg-primary.accent-1 {
  background-color: #82B1FF !important; }

.dark-blue .bg-primary.accent-2 {
  background-color: #448AFF !important; }

.dark-blue .bg-primary.accent-3 {
  background-color: #2979FF !important; }

.dark-blue .bg-primary.accent-4 {
  background-color: #2962FF !important; }

.dark-blue a.text-secondary {
  color: #FF6E40; }
  .dark-blue a.text-secondary:focus, .dark-blue a.text-secondary:hover {
    color: #ff470d !important; }

.dark-blue .text-secondary {
  color: #FF6E40 !important; }

.dark-blue .bg-secondary,
.dark-blue .badge-secondary {
  background-color: #FF6E40 !important;
  color: #fff !important; }

.dark-blue .btn-secondary {
  background-color: #FF6E40;
  border-color: #FF6E40;
  color: #fff; }
  .dark-blue .btn-secondary:hover, .dark-blue .btn-secondary:focus, .dark-blue .btn-secondary:active {
    background-color: #ff5b27 !important;
    border-color: #ff5b27 !important;
    color: #fff !important; }

.dark-blue .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .dark-blue .jr-flat-btn:hover, .dark-blue .jr-flat-btn:focus, .dark-blue .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.dark-blue .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #2196F3 !important;
  border-color: transparent !important; }
  .dark-blue .jr-flat-btn.jr-btn-primary:hover, .dark-blue .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(33, 150, 243, 0.12) !important;
    color: #2196F3 !important; }

.dark-blue .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-blue .bg-primary .jr-flat-btn.jr-btn-primary:hover, .dark-blue .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-blue .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FF6E40 !important;
  border-color: transparent !important; }
  .dark-blue .jr-flat-btn.jr-btn-secondary:hover, .dark-blue .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 110, 64, 0.12) !important;
    color: #FF6E40 !important; }

.dark-blue .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-blue .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .dark-blue .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-blue .app-main-header {
  background-color: #2196F3 !important;
  color: #fff;
  border-color: #2196F3; }
  .dark-blue .app-main-header .search-bar input {
    background-color: #39a1f4; }
    .dark-blue .app-main-header .search-bar input + .search-icon i {
      color: #fff; }
    .dark-blue .app-main-header .search-bar input:focus {
      background-color: #fff; }
      .dark-blue .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  @media screen and (max-width: 575px) {
    .dark-blue .app-main-header .search-dropdown input {
      background-color: #fff; }
      .dark-blue .app-main-header .search-dropdown input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); } }
  .dark-blue .app-main-header .jr-menu-icon .menu-icon {
    background-color: #fff !important; }

.dark-blue .app-header .jr-menu-icon .menu-icon {
  background-color: #fff; }

.dark-blue .quick-menu .icon-btn {
  color: #fff; }

.dark-blue .react-logo-white.d-block {
  display: block !important; }

@media screen and (min-width: 992px) {
  .dark-blue .react-logo-white.d-lg-block {
    display: block !important; }
  .dark-blue .react-logo-white.d-lg-none {
    display: none !important; } }

.dark-blue .react-logo-color.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .dark-blue .react-logo-color.d-lg-block {
    display: none !important; } }

.dark-blue .color-theme-header {
  background-color: #2196F3; }

.dark-blue .sidebar-header {
  border-bottom: 0 none;
  background-color: #1d1d1d; }

.dark-blue .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important; }
  .dark-blue .side-nav .user-profile {
    background-color: #1d1d1d;
    position: relative;
    z-index: 2; }
  .dark-blue .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-blue .side-nav .user-detail .user-name {
    color: #fff; }

.dark-blue ul.nav-menu li a {
  color: #a1a1a1; }

.dark-blue ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-blue ul.nav-menu > li.open > a,
.dark-blue ul.nav-menu > li > a:hover,
.dark-blue ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-blue ul.nav-menu li.menu.open > a {
  border-color: #2196F3; }

.dark-blue ul.nav-menu li.menu > a:focus:before,
.dark-blue ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-blue ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-blue ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-blue ul.nav-menu li.menu.open > a:before,
.dark-blue ul.nav-menu li.menu > a:focus:before,
.dark-blue ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-blue ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-blue ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #2196F3; }

.dark-blue ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-blue ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-blue ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-blue .navbar-nav li:hover > a,
.dark-blue .navbar-nav li:focus > a {
  color: #2196F3; }

.dark-blue .navbar-nav li a {
  color: #868e96; }

.dark-blue .navbar-nav li a:hover,
.dark-blue .navbar-nav li a:focus {
  color: #2196F3; }

.dark-blue .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-blue .navbar-nav li.nav-item:hover > a,
.dark-blue .navbar-nav li.nav-item:focus > a,
.dark-blue .navbar-nav li.nav-item > a:hover,
.dark-blue .navbar-nav li.nav-item > a:focus,
.dark-blue .navbar-nav li.nav-item.active > a {
  color: #FF6E40; }

.dark-blue .navbar-nav ul.sub-menu li a.active,
.dark-blue .navbar-nav ul.sub-menu li.active > a {
  color: #2196F3; }

.dark-blue .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-blue .navbar-nav .nav-arrow a:hover:before,
.dark-blue .navbar-nav .nav-arrow a:focus:before {
  color: #2196F3; }

.dark-blue .navbar-nav .nav-arrow:hover > a:before,
.dark-blue .navbar-nav .nav-arrow:focus > a:before,
.dark-blue .navbar-nav .nav-arrow.active > a:before {
  color: #2196F3; }

.dark-blue .app-top-nav {
  background-color: #252525 !important;
  border-color: #252525; }
  .dark-blue .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-blue .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-blue .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-blue .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-blue .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-blue .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FF6E40; }

.dark-blue .module-nav li a.active {
  border-color: #2196F3; }

.dark-blue .rbc-event {
  background-color: #2196F3; }

.dark-blue .rbc-event.rbc-selected {
  background-color: #0c7cd5; }

.dark-blue .rbc-slot-selection {
  background-color: rgba(33, 150, 243, 0.7); }

.dark-blue .rbc-toolbar button:active,
.dark-blue .rbc-toolbar button.rbc-active {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:active:hover,
.dark-blue .rbc-toolbar button.rbc-active:hover,
.dark-blue .rbc-toolbar button:active:focus,
.dark-blue .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:focus {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-toolbar button:hover {
  background-color: rgba(33, 150, 243, 0.9);
  border-color: #2196F3; }

.dark-blue .rbc-day-slot .rbc-event {
  border-color: #2196F3; }

.dark-blue .chat-sidenav-title {
  color: #2196F3; }

.dark-blue .chat-user-item.active, .dark-blue .chat-user-item:hover {
  background-color: #e3f2fd; }

.dark-blue .contact-list i {
  color: #2196F3; }

.dark-blue .Collapsible__trigger {
  background: #2196F3; }

.dark-blue .categories-list li a:focus,
.dark-blue .categories-list li a:hover,
.dark-blue .categories-list li.active a {
  color: #2196F3; }

.dark-blue .login-content .form-control:focus {
  border-color: #2196F3; }

.dark-blue .filter-with-bg-color ul li a {
  border-color: #2196F3; }
  .dark-blue .filter-with-bg-color ul li a:hover, .dark-blue .filter-with-bg-color ul li a:focus, .dark-blue .filter-with-bg-color ul li a.active {
    background-color: #2196F3; }

.dark-blue .profile-intro .icon {
  color: #2196F3; }

.dark-blue .social-link li.active a,
.dark-blue .social-link li a:hover,
.dark-blue .social-link li a:focus {
  color: #FF6E40; }

.dark-blue .actions {
  color: #FF6E40; }

.dark-blue .table-hover tbody tr:hover {
  background-color: rgba(33, 150, 243, 0.075); }

.dark-blue .border-primary {
  border-color: #2196F3 !important; }

.dark-blue .app-logo-content {
  background-color: #2196F3; }

.dark-blue .app-social-block .social-link button {
  border: solid 1px #2196F3;
  color: #2196F3; }
  .dark-blue .app-social-block .social-link button:hover, .dark-blue .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #2196F3; }

.dark-blue .btn-group > .jr-btn.active,
.dark-blue .btn-group > .btn-default.active,
.dark-blue .btn-group-vertical > .jr-btn.active,
.dark-blue .btn-group-vertical > .btn-default.active {
  background-color: #2196F3;
  border-color: #2196F3; }

.dark-blue .btn-group > .jr-flat-btn,
.dark-blue .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-blue .btn-group > .jr-flat-btn.active,
  .dark-blue .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #2196F3 !important;
    border-color: transparent !important; }
    .dark-blue .btn-group > .jr-flat-btn.active:hover, .dark-blue .btn-group > .jr-flat-btn.active:focus,
    .dark-blue .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-blue .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(33, 150, 243, 0.12) !important;
      color: #2196F3 !important; }

.dark-blue .nav-pill-sales .nav-link.active {
  background-color: #2196F3;
  border-color: #2196F3; }

.dark-blue .form-checkbox > input + .check,
.dark-blue .form-checkbox > input.sm + .check {
  color: #2196F3; }

/* Input Placeholder Style */
.dark-blue .app-main-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb; }

.dark-blue .app-main-header ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb; }

.dark-blue .app-main-header :-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb; }

.dark-blue .app-main-header :-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb; }

.dark-blue .app-main-header input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gainsboro; }

.dark-blue .app-main-header input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: gainsboro; }

.dark-blue .app-main-header input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: gainsboro; }

.dark-blue .app-main-header input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: gainsboro; }

/*Theme Dark Deep Purple Styles*/
.dark-deep-purple .right-arrow {
  color: #673ab7; }
  .dark-deep-purple .right-arrow:after {
    color: #673ab7; }

.dark-deep-purple .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-deep-purple .btn-primary:focus, .dark-deep-purple .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-deep-purple .btn-primary.disabled, .dark-deep-purple .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-deep-purple .btn-primary:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-deep-purple .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-deep-purple .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-deep-purple .btn-secondary:focus, .dark-deep-purple .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-deep-purple .btn-secondary.disabled, .dark-deep-purple .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-deep-purple .btn-secondary:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-deep-purple .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-deep-purple .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-deep-purple .btn-success:focus, .dark-deep-purple .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-deep-purple .btn-success.disabled, .dark-deep-purple .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-deep-purple .btn-success:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-deep-purple .btn-success:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-deep-purple .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-deep-purple .btn-info:focus, .dark-deep-purple .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-deep-purple .btn-info.disabled, .dark-deep-purple .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-deep-purple .btn-info:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-deep-purple .btn-info:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-deep-purple .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-deep-purple .btn-warning:focus, .dark-deep-purple .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-deep-purple .btn-warning.disabled, .dark-deep-purple .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-deep-purple .btn-warning:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-deep-purple .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-deep-purple .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-deep-purple .btn-danger:focus, .dark-deep-purple .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-deep-purple .btn-danger.disabled, .dark-deep-purple .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-deep-purple .btn-danger:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-deep-purple .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-deep-purple .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-deep-purple .btn-light:focus, .dark-deep-purple .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-deep-purple .btn-light.disabled, .dark-deep-purple .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-deep-purple .btn-light:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-deep-purple .btn-light:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-deep-purple .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-deep-purple .btn-dark:focus, .dark-deep-purple .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-deep-purple .btn-dark.disabled, .dark-deep-purple .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-deep-purple .btn-dark:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-deep-purple .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-deep-purple a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-deep-purple a.btn-primary:focus, .dark-deep-purple a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-deep-purple a.btn-primary.disabled, .dark-deep-purple a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-deep-purple a.btn-primary:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-deep-purple a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-deep-purple a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-deep-purple a.btn-secondary:focus, .dark-deep-purple a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-deep-purple a.btn-secondary.disabled, .dark-deep-purple a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-deep-purple a.btn-secondary:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-deep-purple a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-deep-purple a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-deep-purple a.btn-success:focus, .dark-deep-purple a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-deep-purple a.btn-success.disabled, .dark-deep-purple a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-deep-purple a.btn-success:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-deep-purple a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-deep-purple a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-deep-purple a.btn-info:focus, .dark-deep-purple a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-deep-purple a.btn-info.disabled, .dark-deep-purple a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-deep-purple a.btn-info:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-deep-purple a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-deep-purple a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-deep-purple a.btn-warning:focus, .dark-deep-purple a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-deep-purple a.btn-warning.disabled, .dark-deep-purple a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-deep-purple a.btn-warning:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-deep-purple a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-deep-purple a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-deep-purple a.btn-danger:focus, .dark-deep-purple a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-deep-purple a.btn-danger.disabled, .dark-deep-purple a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-deep-purple a.btn-danger:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-deep-purple a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-deep-purple a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-deep-purple a.btn-light:focus, .dark-deep-purple a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-deep-purple a.btn-light.disabled, .dark-deep-purple a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-deep-purple a.btn-light:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-deep-purple a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-deep-purple a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-deep-purple a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-deep-purple a.btn-dark:focus, .dark-deep-purple a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-deep-purple a.btn-dark.disabled, .dark-deep-purple a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-deep-purple a.btn-dark:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-deep-purple a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-deep-purple .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-deep-purple .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-deep-purple .btn-outline-primary:focus, .dark-deep-purple .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-deep-purple .btn-outline-primary.disabled, .dark-deep-purple .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-deep-purple .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-deep-purple .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-deep-purple .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-deep-purple .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-deep-purple .btn-outline-secondary:focus, .dark-deep-purple .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-deep-purple .btn-outline-secondary.disabled, .dark-deep-purple .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-deep-purple .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-deep-purple .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-deep-purple .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-deep-purple .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-deep-purple .btn-outline-success:focus, .dark-deep-purple .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-deep-purple .btn-outline-success.disabled, .dark-deep-purple .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-deep-purple .btn-outline-success:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-deep-purple .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-deep-purple .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-deep-purple .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-deep-purple .btn-outline-info:focus, .dark-deep-purple .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-deep-purple .btn-outline-info.disabled, .dark-deep-purple .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-deep-purple .btn-outline-info:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-deep-purple .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-deep-purple .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-deep-purple .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-deep-purple .btn-outline-warning:focus, .dark-deep-purple .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-deep-purple .btn-outline-warning.disabled, .dark-deep-purple .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-deep-purple .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-deep-purple .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-deep-purple .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-deep-purple .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-deep-purple .btn-outline-danger:focus, .dark-deep-purple .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-deep-purple .btn-outline-danger.disabled, .dark-deep-purple .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-deep-purple .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-deep-purple .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-deep-purple .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-deep-purple .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-deep-purple .btn-outline-light:focus, .dark-deep-purple .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-deep-purple .btn-outline-light.disabled, .dark-deep-purple .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-deep-purple .btn-outline-light:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-deep-purple .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-deep-purple .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-deep-purple .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-deep-purple .btn-outline-dark:focus, .dark-deep-purple .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-deep-purple .btn-outline-dark.disabled, .dark-deep-purple .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-deep-purple .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-deep-purple .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-deep-purple .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-deep-purple a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-deep-purple a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-deep-purple a.btn-outline-primary:focus, .dark-deep-purple a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-deep-purple a.btn-outline-primary.disabled, .dark-deep-purple a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-deep-purple a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-deep-purple a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-deep-purple a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-deep-purple a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-deep-purple a.btn-outline-secondary:focus, .dark-deep-purple a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-deep-purple a.btn-outline-secondary.disabled, .dark-deep-purple a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-deep-purple a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-deep-purple a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-deep-purple a.btn-outline-success:focus, .dark-deep-purple a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-deep-purple a.btn-outline-success.disabled, .dark-deep-purple a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-deep-purple a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-deep-purple a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-deep-purple a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-deep-purple a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-deep-purple a.btn-outline-info:focus, .dark-deep-purple a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-deep-purple a.btn-outline-info.disabled, .dark-deep-purple a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-deep-purple a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-deep-purple a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-deep-purple a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-deep-purple a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-deep-purple a.btn-outline-warning:focus, .dark-deep-purple a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-deep-purple a.btn-outline-warning.disabled, .dark-deep-purple a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-deep-purple a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-deep-purple a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-deep-purple a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-deep-purple a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-deep-purple a.btn-outline-danger:focus, .dark-deep-purple a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-deep-purple a.btn-outline-danger.disabled, .dark-deep-purple a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-deep-purple a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-deep-purple a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-deep-purple a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-deep-purple a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-deep-purple a.btn-outline-light:focus, .dark-deep-purple a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-deep-purple a.btn-outline-light.disabled, .dark-deep-purple a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-deep-purple a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-deep-purple a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-deep-purple a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-deep-purple a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-deep-purple a.btn-outline-dark:focus, .dark-deep-purple a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-deep-purple a.btn-outline-dark.disabled, .dark-deep-purple a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-deep-purple a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-deep-purple a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-deep-purple a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-deep-purple a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-deep-purple a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-deep-purple .btn-outline-primary,
.dark-deep-purple a.btn-outline-primary {
  color: #673ab7;
  border-color: #673ab7; }
  .dark-deep-purple .btn-outline-primary:focus, .dark-deep-purple .btn-outline-primary:hover, .dark-deep-purple .btn-outline-primary.disabled, .dark-deep-purple .btn-outline-primary:disabled, .dark-deep-purple .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-primary.dropdown-toggle,
  .dark-deep-purple a.btn-outline-primary:focus,
  .dark-deep-purple a.btn-outline-primary:hover,
  .dark-deep-purple a.btn-outline-primary.disabled,
  .dark-deep-purple a.btn-outline-primary:disabled,
  .dark-deep-purple a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-deep-purple a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-deep-purple a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #673ab7;
    background-color: #673ab7; }

.dark-deep-purple .btn-outline-secondary,
.dark-deep-purple a.btn-outline-secondary {
  color: #FFAB00;
  border-color: #FFAB00; }
  .dark-deep-purple .btn-outline-secondary:focus, .dark-deep-purple .btn-outline-secondary:hover, .dark-deep-purple .btn-outline-secondary.disabled, .dark-deep-purple .btn-outline-secondary:disabled, .dark-deep-purple .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-deep-purple .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-deep-purple .btn-outline-secondary.dropdown-toggle,
  .dark-deep-purple a.btn-outline-secondary:focus,
  .dark-deep-purple a.btn-outline-secondary:hover,
  .dark-deep-purple a.btn-outline-secondary.disabled,
  .dark-deep-purple a.btn-outline-secondary:disabled,
  .dark-deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-deep-purple a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-deep-purple a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FFAB00;
    background-color: #FFAB00; }

.dark-deep-purple a,
.dark-deep-purple a.text-primary {
  color: #673ab7; }
  .dark-deep-purple a:focus, .dark-deep-purple a:hover,
  .dark-deep-purple a.text-primary:focus,
  .dark-deep-purple a.text-primary:hover {
    color: #512e90; }

.dark-deep-purple .text-primary {
  color: #673ab7 !important; }

.dark-deep-purple .page-link {
  color: #673ab7; }
  .dark-deep-purple .page-link:focus, .dark-deep-purple .page-link:hover {
    color: #673ab7; }

.dark-deep-purple .bg-primary,
.dark-deep-purple .badge-primary {
  background-color: #673ab7 !important;
  color: #fff !important; }

.dark-deep-purple .btn-primary,
.dark-deep-purple .jr-btn-primary,
.dark-deep-purple a.btn-primary,
.dark-deep-purple a.jr-btn-primary {
  background-color: #673ab7;
  border-color: #673ab7;
  color: #fff; }
  .dark-deep-purple .btn-primary:hover, .dark-deep-purple .btn-primary:focus, .dark-deep-purple .btn-primary:not([disabled]):not(.disabled):active,
  .dark-deep-purple .jr-btn-primary:hover,
  .dark-deep-purple .jr-btn-primary:focus,
  .dark-deep-purple .jr-btn-primary:not([disabled]):not(.disabled):active,
  .dark-deep-purple a.btn-primary:hover,
  .dark-deep-purple a.btn-primary:focus,
  .dark-deep-purple a.btn-primary:not([disabled]):not(.disabled):active,
  .dark-deep-purple a.jr-btn-primary:hover,
  .dark-deep-purple a.jr-btn-primary:focus,
  .dark-deep-purple a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #5c34a4 !important;
    border-color: #5c34a4 !important;
    color: #fff !important; }

.dark-deep-purple .bg-primary.lighten-4 {
  background-color: #d1c4e9 !important; }

.dark-deep-purple .bg-primary.lighten-3 {
  background-color: #b39ddb !important; }

.dark-deep-purple .bg-primary.lighten-2 {
  background-color: #9575cd !important; }

.dark-deep-purple .bg-primary.lighten-1 {
  background-color: #7e57c2 !important; }

.dark-deep-purple .bg-primary.darken-1 {
  background-color: #5e35b1 !important; }

.dark-deep-purple .bg-primary.darken-2 {
  background-color: #512da8 !important; }

.dark-deep-purple .bg-primary.darken-3 {
  background-color: #4527a0 !important; }

.dark-deep-purple .bg-primary.darken-4 {
  background-color: #311b92 !important; }

.dark-deep-purple .bg-primary.accent-1 {
  background-color: #b388ff !important; }

.dark-deep-purple .bg-primary.accent-2 {
  background-color: #7c4dff !important; }

.dark-deep-purple .bg-primary.accent-3 {
  background-color: #651fff !important; }

.dark-deep-purple .bg-primary.accent-4 {
  background-color: #6200ea !important; }

.dark-deep-purple a.text-secondary {
  color: #FFAB00; }
  .dark-deep-purple a.text-secondary:focus, .dark-deep-purple a.text-secondary:hover {
    color: #cc8900 !important; }

.dark-deep-purple .text-secondary {
  color: #FFAB00 !important; }

.dark-deep-purple .bg-secondary,
.dark-deep-purple .badge-secondary {
  background-color: #FFAB00 !important;
  color: #fff !important; }

.dark-deep-purple .btn-secondary {
  background-color: #FFAB00;
  border-color: #FFAB00;
  color: #fff; }
  .dark-deep-purple .btn-secondary:hover, .dark-deep-purple .btn-secondary:focus, .dark-deep-purple .btn-secondary:active {
    background-color: #e69a00 !important;
    border-color: #e69a00 !important;
    color: #fff !important; }

.dark-deep-purple .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .dark-deep-purple .jr-flat-btn:hover, .dark-deep-purple .jr-flat-btn:focus, .dark-deep-purple .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.dark-deep-purple .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #673ab7 !important;
  border-color: transparent !important; }
  .dark-deep-purple .jr-flat-btn.jr-btn-primary:hover, .dark-deep-purple .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(103, 58, 183, 0.12) !important;
    color: #673ab7 !important; }

.dark-deep-purple .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-deep-purple .bg-primary .jr-flat-btn.jr-btn-primary:hover, .dark-deep-purple .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-deep-purple .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FFAB00 !important;
  border-color: transparent !important; }
  .dark-deep-purple .jr-flat-btn.jr-btn-secondary:hover, .dark-deep-purple .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 171, 0, 0.12) !important;
    color: #FFAB00 !important; }

.dark-deep-purple .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-deep-purple .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .dark-deep-purple .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-deep-purple .app-main-header {
  background-color: #673ab7 !important;
  color: #fff;
  border-color: #673ab7; }
  .dark-deep-purple .app-main-header .search-bar input {
    background-color: #7446c4; }
    .dark-deep-purple .app-main-header .search-bar input + .search-icon i {
      color: #fff; }
    .dark-deep-purple .app-main-header .search-bar input:focus {
      background-color: #fff; }
      .dark-deep-purple .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  @media screen and (max-width: 575px) {
    .dark-deep-purple .app-main-header .search-dropdown input {
      background-color: #fff; }
      .dark-deep-purple .app-main-header .search-dropdown input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); } }
  .dark-deep-purple .app-main-header .jr-menu-icon .menu-icon {
    background-color: #fff !important; }

.dark-deep-purple .app-header .jr-menu-icon .menu-icon {
  background-color: #fff; }

.dark-deep-purple .quick-menu .icon-btn {
  color: #fff; }

.dark-deep-purple .react-logo-white.d-block {
  display: block !important; }

@media screen and (min-width: 992px) {
  .dark-deep-purple .react-logo-white.d-lg-block {
    display: block !important; }
  .dark-deep-purple .react-logo-white.d-lg-none {
    display: none !important; } }

.dark-deep-purple .react-logo-color.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .dark-deep-purple .react-logo-color.d-lg-block {
    display: none !important; } }

.dark-deep-purple .color-theme-header {
  background-color: #673ab7; }

.dark-deep-purple .sidebar-header {
  border-bottom: 0 none;
  background-color: #1d1d1d; }

.dark-deep-purple .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important; }
  .dark-deep-purple .side-nav .user-profile {
    background-color: #1d1d1d;
    position: relative;
    z-index: 2; }
  .dark-deep-purple .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-deep-purple .side-nav .user-detail .user-name {
    color: #fff; }

.dark-deep-purple ul.nav-menu li a {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu > li.open > a,
.dark-deep-purple ul.nav-menu > li > a:hover,
.dark-deep-purple ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-deep-purple ul.nav-menu li.menu.open > a {
  border-color: #FFAB00; }

.dark-deep-purple ul.nav-menu li.menu > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-deep-purple ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-deep-purple ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu li.menu.open > a:before,
.dark-deep-purple ul.nav-menu li.menu > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #673ab7; }

.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-deep-purple ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-deep-purple ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-deep-purple .navbar-nav li:hover > a,
.dark-deep-purple .navbar-nav li:focus > a {
  color: #673ab7; }

.dark-deep-purple .navbar-nav li a {
  color: #868e96; }

.dark-deep-purple .navbar-nav li a:hover,
.dark-deep-purple .navbar-nav li a:focus {
  color: #673ab7; }

.dark-deep-purple .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-deep-purple .navbar-nav li.nav-item:hover > a,
.dark-deep-purple .navbar-nav li.nav-item:focus > a,
.dark-deep-purple .navbar-nav li.nav-item > a:hover,
.dark-deep-purple .navbar-nav li.nav-item > a:focus,
.dark-deep-purple .navbar-nav li.nav-item.active > a {
  color: #FFAB00; }

.dark-deep-purple .navbar-nav ul.sub-menu li a.active,
.dark-deep-purple .navbar-nav ul.sub-menu li.active > a {
  color: #673ab7; }

.dark-deep-purple .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-deep-purple .navbar-nav .nav-arrow a:hover:before,
.dark-deep-purple .navbar-nav .nav-arrow a:focus:before {
  color: #673ab7; }

.dark-deep-purple .navbar-nav .nav-arrow:hover > a:before,
.dark-deep-purple .navbar-nav .nav-arrow:focus > a:before,
.dark-deep-purple .navbar-nav .nav-arrow.active > a:before {
  color: #673ab7; }

.dark-deep-purple .app-top-nav {
  background-color: #252525 !important;
  border-color: #252525; }
  .dark-deep-purple .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-deep-purple .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-deep-purple .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-deep-purple .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-deep-purple .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-deep-purple .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FFAB00; }

.dark-deep-purple .module-nav li a.active {
  border-color: #673ab7; }

.dark-deep-purple .rbc-event {
  background-color: #673ab7; }

.dark-deep-purple .rbc-event.rbc-selected {
  background-color: #512e90; }

.dark-deep-purple .rbc-slot-selection {
  background-color: rgba(103, 58, 183, 0.7); }

.dark-deep-purple .rbc-toolbar button:active,
.dark-deep-purple .rbc-toolbar button.rbc-active {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:active:hover,
.dark-deep-purple .rbc-toolbar button.rbc-active:hover,
.dark-deep-purple .rbc-toolbar button:active:focus,
.dark-deep-purple .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:focus {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-toolbar button:hover {
  background-color: rgba(103, 58, 183, 0.9);
  border-color: #673ab7; }

.dark-deep-purple .rbc-day-slot .rbc-event {
  border-color: #673ab7; }

.dark-deep-purple .chat-sidenav-title {
  color: #673ab7; }

.dark-deep-purple .chat-user-item.active, .dark-deep-purple .chat-user-item:hover {
  background-color: #e8e1f5; }

.dark-deep-purple .contact-list i {
  color: #673ab7; }

.dark-deep-purple .Collapsible__trigger {
  background: #673ab7; }

.dark-deep-purple .categories-list li a:focus,
.dark-deep-purple .categories-list li a:hover,
.dark-deep-purple .categories-list li.active a {
  color: #673ab7; }

.dark-deep-purple .login-content .form-control:focus {
  border-color: #673ab7; }

.dark-deep-purple .filter-with-bg-color ul li a {
  border-color: #673ab7; }
  .dark-deep-purple .filter-with-bg-color ul li a:hover, .dark-deep-purple .filter-with-bg-color ul li a:focus, .dark-deep-purple .filter-with-bg-color ul li a.active {
    background-color: #673ab7; }

.dark-deep-purple .profile-intro .icon {
  color: #673ab7; }

.dark-deep-purple .social-link li.active a,
.dark-deep-purple .social-link li a:hover,
.dark-deep-purple .social-link li a:focus {
  color: #FFAB00; }

.dark-deep-purple .actions {
  color: #FFAB00; }

.dark-deep-purple .table-hover tbody tr:hover {
  background-color: rgba(103, 58, 183, 0.075); }

.dark-deep-purple .border-primary {
  border-color: #673ab7 !important; }

.dark-deep-purple .app-logo-content {
  background-color: #673ab7; }

.dark-deep-purple .app-social-block .social-link button {
  border: solid 1px #673ab7;
  color: #673ab7; }
  .dark-deep-purple .app-social-block .social-link button:hover, .dark-deep-purple .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #673ab7; }

.dark-deep-purple .btn-group > .jr-btn.active,
.dark-deep-purple .btn-group > .btn-default.active,
.dark-deep-purple .btn-group-vertical > .jr-btn.active,
.dark-deep-purple .btn-group-vertical > .btn-default.active {
  background-color: #673ab7;
  border-color: #673ab7; }

.dark-deep-purple .btn-group > .jr-flat-btn,
.dark-deep-purple .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-deep-purple .btn-group > .jr-flat-btn.active,
  .dark-deep-purple .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #673ab7 !important;
    border-color: transparent !important; }
    .dark-deep-purple .btn-group > .jr-flat-btn.active:hover, .dark-deep-purple .btn-group > .jr-flat-btn.active:focus,
    .dark-deep-purple .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-deep-purple .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(103, 58, 183, 0.12) !important;
      color: #673ab7 !important; }

.dark-deep-purple .nav-pill-sales .nav-link.active {
  background-color: #673ab7;
  border-color: #673ab7; }

.dark-deep-purple .form-checkbox > input + .check,
.dark-deep-purple .form-checkbox > input.sm + .check {
  color: #673ab7; }

/* Input Placeholder Style */
.dark-deep-purple .app-main-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb; }

.dark-deep-purple .app-main-header ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb; }

.dark-deep-purple .app-main-header :-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb; }

.dark-deep-purple .app-main-header :-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb; }

.dark-deep-purple .app-main-header input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gainsboro; }

.dark-deep-purple .app-main-header input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: gainsboro; }

.dark-deep-purple .app-main-header input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: gainsboro; }

.dark-deep-purple .app-main-header input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: gainsboro; }

/*Theme Dark Green Styles*/
.dark-green .right-arrow {
  color: #4CAF50; }
  .dark-green .right-arrow:after {
    color: #4CAF50; }

.dark-green .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-green .btn-primary:focus, .dark-green .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-green .btn-primary.disabled, .dark-green .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-green .btn-primary:not(:disabled):not(.disabled):active, .dark-green .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-green .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-green .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-green .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-green .btn-secondary:focus, .dark-green .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-green .btn-secondary.disabled, .dark-green .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-green .btn-secondary:not(:disabled):not(.disabled):active, .dark-green .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-green .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-green .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-green .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-green .btn-success:focus, .dark-green .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-green .btn-success.disabled, .dark-green .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-green .btn-success:not(:disabled):not(.disabled):active, .dark-green .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-green .btn-success:not(:disabled):not(.disabled):active:focus, .dark-green .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-green .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-green .btn-info:focus, .dark-green .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-green .btn-info.disabled, .dark-green .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-green .btn-info:not(:disabled):not(.disabled):active, .dark-green .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-green .btn-info:not(:disabled):not(.disabled):active:focus, .dark-green .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-green .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-green .btn-warning:focus, .dark-green .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-green .btn-warning.disabled, .dark-green .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-green .btn-warning:not(:disabled):not(.disabled):active, .dark-green .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-green .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-green .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-green .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-green .btn-danger:focus, .dark-green .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-green .btn-danger.disabled, .dark-green .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-green .btn-danger:not(:disabled):not(.disabled):active, .dark-green .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-green .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-green .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-green .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-green .btn-light:focus, .dark-green .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-green .btn-light.disabled, .dark-green .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-green .btn-light:not(:disabled):not(.disabled):active, .dark-green .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-green .btn-light:not(:disabled):not(.disabled):active:focus, .dark-green .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-green .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-green .btn-dark:focus, .dark-green .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-green .btn-dark.disabled, .dark-green .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-green .btn-dark:not(:disabled):not(.disabled):active, .dark-green .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-green .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-green .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-green a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-green a.btn-primary:focus, .dark-green a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-green a.btn-primary.disabled, .dark-green a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-green a.btn-primary:not(:disabled):not(.disabled):active, .dark-green a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-green a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-green a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-green a.btn-secondary:focus, .dark-green a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-green a.btn-secondary.disabled, .dark-green a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-green a.btn-secondary:not(:disabled):not(.disabled):active, .dark-green a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-green a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-green a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-green a.btn-success:focus, .dark-green a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-green a.btn-success.disabled, .dark-green a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-green a.btn-success:not(:disabled):not(.disabled):active, .dark-green a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-green a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-green a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-green a.btn-info:focus, .dark-green a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-green a.btn-info.disabled, .dark-green a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-green a.btn-info:not(:disabled):not(.disabled):active, .dark-green a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-green a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-green a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-green a.btn-warning:focus, .dark-green a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-green a.btn-warning.disabled, .dark-green a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-green a.btn-warning:not(:disabled):not(.disabled):active, .dark-green a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-green a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-green a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-green a.btn-danger:focus, .dark-green a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-green a.btn-danger.disabled, .dark-green a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-green a.btn-danger:not(:disabled):not(.disabled):active, .dark-green a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-green a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-green a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-green a.btn-light:focus, .dark-green a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-green a.btn-light.disabled, .dark-green a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-green a.btn-light:not(:disabled):not(.disabled):active, .dark-green a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-green a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-green a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-green a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-green a.btn-dark:focus, .dark-green a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-green a.btn-dark.disabled, .dark-green a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-green a.btn-dark:not(:disabled):not(.disabled):active, .dark-green a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-green a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-green .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-green .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-green .btn-outline-primary:focus, .dark-green .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-green .btn-outline-primary.disabled, .dark-green .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-green .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-green .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-green .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-green .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-green .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-green .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-green .btn-outline-secondary:focus, .dark-green .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-green .btn-outline-secondary.disabled, .dark-green .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-green .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-green .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-green .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-green .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-green .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-green .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-green .btn-outline-success:focus, .dark-green .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-green .btn-outline-success.disabled, .dark-green .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-green .btn-outline-success:not(:disabled):not(.disabled):active, .dark-green .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-green .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-green .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-green .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-green .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-green .btn-outline-info:focus, .dark-green .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-green .btn-outline-info.disabled, .dark-green .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-green .btn-outline-info:not(:disabled):not(.disabled):active, .dark-green .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-green .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-green .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-green .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-green .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-green .btn-outline-warning:focus, .dark-green .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-green .btn-outline-warning.disabled, .dark-green .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-green .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-green .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-green .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-green .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-green .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-green .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-green .btn-outline-danger:focus, .dark-green .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-green .btn-outline-danger.disabled, .dark-green .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-green .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-green .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-green .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-green .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-green .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-green .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-green .btn-outline-light:focus, .dark-green .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-green .btn-outline-light.disabled, .dark-green .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-green .btn-outline-light:not(:disabled):not(.disabled):active, .dark-green .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-green .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-green .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-green .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-green .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-green .btn-outline-dark:focus, .dark-green .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-green .btn-outline-dark.disabled, .dark-green .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-green .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-green .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-green .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-green .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-green a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-green a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-green a.btn-outline-primary:focus, .dark-green a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-green a.btn-outline-primary.disabled, .dark-green a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-green a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-green a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-green a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-green a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-green a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-green a.btn-outline-secondary:focus, .dark-green a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-green a.btn-outline-secondary.disabled, .dark-green a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-green a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-green a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-green a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-green a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-green a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-green a.btn-outline-success:focus, .dark-green a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-green a.btn-outline-success.disabled, .dark-green a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-green a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-green a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-green a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-green a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-green a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-green a.btn-outline-info:focus, .dark-green a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-green a.btn-outline-info.disabled, .dark-green a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-green a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-green a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-green a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-green a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-green a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-green a.btn-outline-warning:focus, .dark-green a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-green a.btn-outline-warning.disabled, .dark-green a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-green a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-green a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-green a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-green a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-green a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-green a.btn-outline-danger:focus, .dark-green a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-green a.btn-outline-danger.disabled, .dark-green a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-green a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-green a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-green a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-green a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-green a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-green a.btn-outline-light:focus, .dark-green a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-green a.btn-outline-light.disabled, .dark-green a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-green a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-green a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-green a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-green a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-green a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-green a.btn-outline-dark:focus, .dark-green a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-green a.btn-outline-dark.disabled, .dark-green a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-green a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-green a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-green a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-green a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-green a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-green a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-green .btn-outline-primary,
.dark-green a.btn-outline-primary {
  color: #4CAF50;
  border-color: #4CAF50; }
  .dark-green .btn-outline-primary:focus, .dark-green .btn-outline-primary:hover, .dark-green .btn-outline-primary.disabled, .dark-green .btn-outline-primary:disabled, .dark-green .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-green .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-primary.dropdown-toggle,
  .dark-green a.btn-outline-primary:focus,
  .dark-green a.btn-outline-primary:hover,
  .dark-green a.btn-outline-primary.disabled,
  .dark-green a.btn-outline-primary:disabled,
  .dark-green a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-green a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-green a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #4CAF50;
    background-color: #4CAF50; }

.dark-green .btn-outline-secondary,
.dark-green a.btn-outline-secondary {
  color: #FF6D00;
  border-color: #FF6D00; }
  .dark-green .btn-outline-secondary:focus, .dark-green .btn-outline-secondary:hover, .dark-green .btn-outline-secondary.disabled, .dark-green .btn-outline-secondary:disabled, .dark-green .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-green .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-green .btn-outline-secondary.dropdown-toggle,
  .dark-green a.btn-outline-secondary:focus,
  .dark-green a.btn-outline-secondary:hover,
  .dark-green a.btn-outline-secondary.disabled,
  .dark-green a.btn-outline-secondary:disabled,
  .dark-green a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-green a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-green a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #FF6D00;
    background-color: #FF6D00; }

.dark-green a,
.dark-green a.text-primary {
  color: #4CAF50; }
  .dark-green a:focus, .dark-green a:hover,
  .dark-green a.text-primary:focus,
  .dark-green a.text-primary:hover {
    color: #3d8b40; }

.dark-green .text-primary {
  color: #4CAF50 !important; }

.dark-green .page-link {
  color: #4CAF50; }
  .dark-green .page-link:focus, .dark-green .page-link:hover {
    color: #4CAF50; }

.dark-green .bg-primary,
.dark-green .badge-primary {
  background-color: #4CAF50 !important;
  color: #fff !important; }

.dark-green .btn-primary,
.dark-green .jr-btn-primary,
.dark-green a.btn-primary,
.dark-green a.jr-btn-primary {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: #fff; }
  .dark-green .btn-primary:hover, .dark-green .btn-primary:focus, .dark-green .btn-primary:not([disabled]):not(.disabled):active,
  .dark-green .jr-btn-primary:hover,
  .dark-green .jr-btn-primary:focus,
  .dark-green .jr-btn-primary:not([disabled]):not(.disabled):active,
  .dark-green a.btn-primary:hover,
  .dark-green a.btn-primary:focus,
  .dark-green a.btn-primary:not([disabled]):not(.disabled):active,
  .dark-green a.jr-btn-primary:hover,
  .dark-green a.jr-btn-primary:focus,
  .dark-green a.jr-btn-primary:not([disabled]):not(.disabled):active {
    background-color: #449d48 !important;
    border-color: #449d48 !important;
    color: #fff !important; }

.dark-green .bg-primary.lighten-4 {
  background-color: #C8E6C9 !important; }

.dark-green .bg-primary.lighten-3 {
  background-color: #A5D6A7 !important; }

.dark-green .bg-primary.lighten-2 {
  background-color: #81C784 !important; }

.dark-green .bg-primary.lighten-1 {
  background-color: #66BB6A !important; }

.dark-green .bg-primary.darken-1 {
  background-color: #43A047 !important; }

.dark-green .bg-primary.darken-2 {
  background-color: #388E3C !important; }

.dark-green .bg-primary.darken-3 {
  background-color: #2E7D32 !important; }

.dark-green .bg-primary.darken-4 {
  background-color: #1B5E20 !important; }

.dark-green .bg-primary.accent-1 {
  background-color: #B9F6CA !important; }

.dark-green .bg-primary.accent-2 {
  background-color: #69F0AE !important; }

.dark-green .bg-primary.accent-3 {
  background-color: #00E676 !important; }

.dark-green .bg-primary.accent-4 {
  background-color: #00C853 !important; }

.dark-green a.text-secondary {
  color: #FF6D00; }
  .dark-green a.text-secondary:focus, .dark-green a.text-secondary:hover {
    color: #cc5700 !important; }

.dark-green .text-secondary {
  color: #FF6D00 !important; }

.dark-green .bg-secondary,
.dark-green .badge-secondary {
  background-color: #FF6D00 !important;
  color: #fff !important; }

.dark-green .btn-secondary {
  background-color: #FF6D00;
  border-color: #FF6D00;
  color: #fff; }
  .dark-green .btn-secondary:hover, .dark-green .btn-secondary:focus, .dark-green .btn-secondary:active {
    background-color: #e66200 !important;
    border-color: #e66200 !important;
    color: #fff !important; }

.dark-green .jr-flat-btn {
  background-color: transparent !important;
  color: #5c5c5c !important; }
  .dark-green .jr-flat-btn:hover, .dark-green .jr-flat-btn:focus, .dark-green .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.dark-green .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #4CAF50 !important;
  border-color: transparent !important; }
  .dark-green .jr-flat-btn.jr-btn-primary:hover, .dark-green .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(76, 175, 80, 0.12) !important;
    color: #4CAF50 !important; }

.dark-green .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-green .bg-primary .jr-flat-btn.jr-btn-primary:hover, .dark-green .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-green .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #FF6D00 !important;
  border-color: transparent !important; }
  .dark-green .jr-flat-btn.jr-btn-secondary:hover, .dark-green .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 109, 0, 0.12) !important;
    color: #FF6D00 !important; }

.dark-green .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-green .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .dark-green .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-green .app-main-header {
  background-color: #4CAF50 !important;
  color: #fff;
  border-color: #4CAF50; }
  .dark-green .app-main-header .search-bar input {
    background-color: #5cb860; }
    .dark-green .app-main-header .search-bar input + .search-icon i {
      color: #fff; }
    .dark-green .app-main-header .search-bar input:focus {
      background-color: #fff; }
      .dark-green .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(128, 128, 128, 0.8); }
  @media screen and (max-width: 575px) {
    .dark-green .app-main-header .search-dropdown input {
      background-color: #fff; }
      .dark-green .app-main-header .search-dropdown input + .search-icon i {
        color: rgba(128, 128, 128, 0.8); } }
  .dark-green .app-main-header .jr-menu-icon .menu-icon {
    background-color: #fff !important; }

.dark-green .app-header .jr-menu-icon .menu-icon {
  background-color: #fff; }

.dark-green .quick-menu .icon-btn {
  color: #fff; }

.dark-green .react-logo-white.d-block {
  display: block !important; }

@media screen and (min-width: 992px) {
  .dark-green .react-logo-white.d-lg-block {
    display: block !important; }
  .dark-green .react-logo-white.d-lg-none {
    display: none !important; } }

.dark-green .react-logo-color.d-block {
  display: none !important; }

@media screen and (min-width: 992px) {
  .dark-green .react-logo-color.d-lg-block {
    display: none !important; } }

.dark-green .color-theme-header {
  background-color: #4CAF50; }

.dark-green .sidebar-header {
  border-bottom: 0 none;
  background-color: #1d1d1d; }

.dark-green .side-nav {
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important; }
  .dark-green .side-nav .user-profile {
    background-color: #1d1d1d;
    position: relative;
    z-index: 2; }
  .dark-green .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-green .side-nav .user-detail .user-name {
    color: #fff; }

.dark-green ul.nav-menu li a {
  color: #a1a1a1; }

.dark-green ul.nav-menu .nav-header {
  color: #a1a1a1; }

.dark-green ul.nav-menu > li.open > a,
.dark-green ul.nav-menu > li > a:hover,
.dark-green ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

.dark-green ul.nav-menu li.menu.open > a {
  border-color: #4CAF50; }

.dark-green ul.nav-menu li.menu > a:focus:before,
.dark-green ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

.dark-green ul.nav-menu li ul {
  background-color: #1d1d1d; }

.dark-green ul.nav-menu li.menu > a:before {
  color: #a1a1a1; }

.dark-green ul.nav-menu li.menu.open > a:before,
.dark-green ul.nav-menu li.menu > a:focus:before,
.dark-green ul.nav-menu li.menu li > a:hover:before {
  color: #fff; }

.dark-green ul.nav-menu li.menu .sub-menu li > a:before {
  color: #a1a1a1; }

.dark-green ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #4CAF50; }

.dark-green ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-green ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-green ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-green ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-green ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

/*Header top Navbar Styles*/
.dark-green .navbar-nav li:hover > a,
.dark-green .navbar-nav li:focus > a {
  color: #4CAF50; }

.dark-green .navbar-nav li a {
  color: #868e96; }

.dark-green .navbar-nav li a:hover,
.dark-green .navbar-nav li a:focus {
  color: #4CAF50; }

.dark-green .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-green .navbar-nav li.nav-item:hover > a,
.dark-green .navbar-nav li.nav-item:focus > a,
.dark-green .navbar-nav li.nav-item > a:hover,
.dark-green .navbar-nav li.nav-item > a:focus,
.dark-green .navbar-nav li.nav-item.active > a {
  color: #FF6D00; }

.dark-green .navbar-nav ul.sub-menu li a.active,
.dark-green .navbar-nav ul.sub-menu li.active > a {
  color: #4CAF50; }

.dark-green .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-green .navbar-nav .nav-arrow a:hover:before,
.dark-green .navbar-nav .nav-arrow a:focus:before {
  color: #4CAF50; }

.dark-green .navbar-nav .nav-arrow:hover > a:before,
.dark-green .navbar-nav .nav-arrow:focus > a:before,
.dark-green .navbar-nav .nav-arrow.active > a:before {
  color: #4CAF50; }

.dark-green .app-top-nav {
  background-color: #252525 !important;
  border-color: #252525; }
  .dark-green .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-green .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-green .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-green .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-green .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-green .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #FF6D00; }

.dark-green .module-nav li a.active {
  border-color: #4CAF50; }

.dark-green .rbc-event {
  background-color: #4CAF50; }

.dark-green .rbc-event.rbc-selected {
  background-color: #3d8b40; }

.dark-green .rbc-slot-selection {
  background-color: rgba(76, 175, 80, 0.7); }

.dark-green .rbc-toolbar button:active,
.dark-green .rbc-toolbar button.rbc-active {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:active:hover,
.dark-green .rbc-toolbar button.rbc-active:hover,
.dark-green .rbc-toolbar button:active:focus,
.dark-green .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:focus {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-toolbar button:hover {
  background-color: rgba(76, 175, 80, 0.9);
  border-color: #4CAF50; }

.dark-green .rbc-day-slot .rbc-event {
  border-color: #4CAF50; }

.dark-green .chat-sidenav-title {
  color: #4CAF50; }

.dark-green .chat-user-item.active, .dark-green .chat-user-item:hover {
  background-color: #eaf6eb; }

.dark-green .contact-list i {
  color: #4CAF50; }

.dark-green .Collapsible__trigger {
  background: #4CAF50; }

.dark-green .categories-list li a:focus,
.dark-green .categories-list li a:hover,
.dark-green .categories-list li.active a {
  color: #4CAF50; }

.dark-green .login-content .form-control:focus {
  border-color: #4CAF50; }

.dark-green .filter-with-bg-color ul li a {
  border-color: #4CAF50; }
  .dark-green .filter-with-bg-color ul li a:hover, .dark-green .filter-with-bg-color ul li a:focus, .dark-green .filter-with-bg-color ul li a.active {
    background-color: #4CAF50; }

.dark-green .profile-intro .icon {
  color: #4CAF50; }

.dark-green .social-link li.active a,
.dark-green .social-link li a:hover,
.dark-green .social-link li a:focus {
  color: #FF6D00; }

.dark-green .actions {
  color: #FF6D00; }

.dark-green .table-hover tbody tr:hover {
  background-color: rgba(76, 175, 80, 0.075); }

.dark-green .border-primary {
  border-color: #4CAF50 !important; }

.dark-green .app-logo-content {
  background-color: #4CAF50; }

.dark-green .app-social-block .social-link button {
  border: solid 1px #4CAF50;
  color: #4CAF50; }
  .dark-green .app-social-block .social-link button:hover, .dark-green .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #4CAF50; }

.dark-green .btn-group > .jr-btn.active,
.dark-green .btn-group > .btn-default.active,
.dark-green .btn-group-vertical > .jr-btn.active,
.dark-green .btn-group-vertical > .btn-default.active {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.dark-green .btn-group > .jr-flat-btn,
.dark-green .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-green .btn-group > .jr-flat-btn.active,
  .dark-green .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #4CAF50 !important;
    border-color: transparent !important; }
    .dark-green .btn-group > .jr-flat-btn.active:hover, .dark-green .btn-group > .jr-flat-btn.active:focus,
    .dark-green .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-green .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(76, 175, 80, 0.12) !important;
      color: #4CAF50 !important; }

.dark-green .nav-pill-sales .nav-link.active {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.dark-green .form-checkbox > input + .check,
.dark-green .form-checkbox > input.sm + .check {
  color: #4CAF50; }

/* Input Placeholder Style */
.dark-green .app-main-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ebebeb; }

.dark-green .app-main-header ::-moz-placeholder {
  /* Firefox 19+ */
  color: #ebebeb; }

.dark-green .app-main-header :-ms-input-placeholder {
  /* IE 10+ */
  color: #ebebeb; }

.dark-green .app-main-header :-moz-placeholder {
  /* Firefox 18- */
  color: #ebebeb; }

.dark-green .app-main-header input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: gainsboro; }

.dark-green .app-main-header input:focus::-moz-placeholder {
  /* Firefox 19+ */
  color: gainsboro; }

.dark-green .app-main-header input:focus:-ms-input-placeholder {
  /* IE 10+ */
  color: gainsboro; }

.dark-green .app-main-header input:focus:-moz-placeholder {
  /* Firefox 18- */
  color: gainsboro; }

/*Theme Dark Amber Styles*/
body.dark-theme {
  color: #E0E0E0;
  background-color: #38424B; }

.dark-theme .modal {
  color: #38424B; }

.dark-theme .project-list .project-name {
  color: #E0E0E0; }

.dark-theme .list-group-item {
  background-color: #434f5a;
  color: #E0E0E0;
  border-color: #495762; }
  .dark-theme .list-group-item:hover {
    background-color: #414c57; }

.dark-theme .modal .list-group-item {
  background-color: #fff;
  color: #38424B;
  border-color: #fff; }
  .dark-theme .modal .list-group-item:hover {
    background-color: #fff; }

.dark-theme .quick-menu .icon-btn {
  color: #E0E0E0; }

.dark-theme .text-dark {
  color: #E0E0E0 !important; }

.dark-theme .nav-pill-sales .nav-link {
  background-color: #495762;
  color: #E0E0E0;
  border-color: #495762; }
  .dark-theme .nav-pill-sales .nav-link.active {
    background-color: #38424B;
    color: #E0E0E0;
    border-color: #38424B; }
    .dark-theme .nav-pill-sales .nav-link.active:hover, .dark-theme .nav-pill-sales .nav-link.active:focus {
      color: #E0E0E0; }

.dark-theme .nav-tabs {
  border-color: #38424B;
  background-color: #22282e; }
  .dark-theme .nav-tabs .nav-link {
    border-color: transparent; }
    .dark-theme .nav-tabs .nav-link:focus, .dark-theme .nav-tabs .nav-link:hover {
      border-color: transparent; }
  .dark-theme .nav-tabs .nav-link.active,
  .dark-theme .nav-tabs .nav-item.show .nav-link {
    color: #E0E0E0;
    background-color: #22282e;
    border-color: #22282e;
    border-bottom-color: #495762; }

.dark-theme .bg-grey.lighten-4,
.dark-theme .jr-card-header-color {
  background-color: #495762 !important;
  color: #E0E0E0; }

.dark-theme .card,
.dark-theme .jr-card {
  background-color: #434f5a;
  border-color: #495762; }

.dark-theme .card-footer {
  border-color: #495762;
  background-color: transparent !important; }

.dark-theme .sub-heading,
.dark-theme .meta-date,
.dark-theme .text-muted,
.dark-theme .meta-comment,
.dark-theme .user-detail .user-description {
  color: #adadad !important; }

.dark-theme .right-arrow {
  color: lightgray; }
  .dark-theme .right-arrow:after {
    color: lightgray; }

.dark-theme .btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme .btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-theme .btn-primary:focus, .dark-theme .btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-theme .btn-primary.disabled, .dark-theme .btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-theme .btn-primary:not(:disabled):not(.disabled):active, .dark-theme .btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-theme .btn-primary:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-theme .btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme .btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-theme .btn-secondary:focus, .dark-theme .btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-theme .btn-secondary.disabled, .dark-theme .btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-theme .btn-secondary:not(:disabled):not(.disabled):active, .dark-theme .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-theme .btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-theme .btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-theme .btn-success:focus, .dark-theme .btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-theme .btn-success.disabled, .dark-theme .btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-theme .btn-success:not(:disabled):not(.disabled):active, .dark-theme .btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-theme .btn-success:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-theme .btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-theme .btn-info:focus, .dark-theme .btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-theme .btn-info.disabled, .dark-theme .btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-theme .btn-info:not(:disabled):not(.disabled):active, .dark-theme .btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-theme .btn-info:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-theme .btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme .btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-theme .btn-warning:focus, .dark-theme .btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-theme .btn-warning.disabled, .dark-theme .btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-theme .btn-warning:not(:disabled):not(.disabled):active, .dark-theme .btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-theme .btn-warning:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-theme .btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-theme .btn-danger:focus, .dark-theme .btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-theme .btn-danger.disabled, .dark-theme .btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-theme .btn-danger:not(:disabled):not(.disabled):active, .dark-theme .btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-theme .btn-danger:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-theme .btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme .btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-theme .btn-light:focus, .dark-theme .btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-theme .btn-light.disabled, .dark-theme .btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-theme .btn-light:not(:disabled):not(.disabled):active, .dark-theme .btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-theme .btn-light:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-theme .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-theme .btn-dark:focus, .dark-theme .btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-theme .btn-dark.disabled, .dark-theme .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-theme .btn-dark:not(:disabled):not(.disabled):active, .dark-theme .btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-theme .btn-dark:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-theme a.btn-primary {
  color: #fff;
  background-color: #3f51b5;
  border-color: #3f51b5;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme a.btn-primary:hover {
    color: #fff;
    background-color: #354499;
    border-color: #32408f; }
  .dark-theme a.btn-primary:focus, .dark-theme a.btn-primary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-theme a.btn-primary.disabled, .dark-theme a.btn-primary:disabled {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-theme a.btn-primary:not(:disabled):not(.disabled):active, .dark-theme a.btn-primary:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #32408f;
    border-color: #2f3c86; }
    .dark-theme a.btn-primary:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-theme a.btn-secondary {
  color: #fff;
  background-color: #E91E63;
  border-color: #E91E63;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme a.btn-secondary:hover {
    color: #fff;
    background-color: #cd1453;
    border-color: #c1134e; }
  .dark-theme a.btn-secondary:focus, .dark-theme a.btn-secondary.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-theme a.btn-secondary.disabled, .dark-theme a.btn-secondary:disabled {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-theme a.btn-secondary:not(:disabled):not(.disabled):active, .dark-theme a.btn-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #c1134e;
    border-color: #b61249; }
    .dark-theme a.btn-secondary:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-theme a.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme a.btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .dark-theme a.btn-success:focus, .dark-theme a.btn-success.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-theme a.btn-success.disabled, .dark-theme a.btn-success:disabled {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-theme a.btn-success:not(:disabled):not(.disabled):active, .dark-theme a.btn-success:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .dark-theme a.btn-success:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-theme a.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme a.btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .dark-theme a.btn-info:focus, .dark-theme a.btn-info.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-theme a.btn-info.disabled, .dark-theme a.btn-info:disabled {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-theme a.btn-info:not(:disabled):not(.disabled):active, .dark-theme a.btn-info:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .dark-theme a.btn-info:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-theme a.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme a.btn-warning:hover {
    color: #fff;
    background-color: #d98100;
    border-color: #cc7a00; }
  .dark-theme a.btn-warning:focus, .dark-theme a.btn-warning.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-theme a.btn-warning.disabled, .dark-theme a.btn-warning:disabled {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-theme a.btn-warning:not(:disabled):not(.disabled):active, .dark-theme a.btn-warning:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #cc7a00;
    border-color: #bf7200; }
    .dark-theme a.btn-warning:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-theme a.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme a.btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .dark-theme a.btn-danger:focus, .dark-theme a.btn-danger.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-theme a.btn-danger.disabled, .dark-theme a.btn-danger:disabled {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-theme a.btn-danger:not(:disabled):not(.disabled):active, .dark-theme a.btn-danger:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .dark-theme a.btn-danger:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-theme a.btn-light {
  color: #212529;
  background-color: #e8eaf6;
  border-color: #e8eaf6;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme a.btn-light:hover {
    color: #212529;
    background-color: #cdd1eb;
    border-color: #c3c9e8; }
  .dark-theme a.btn-light:focus, .dark-theme a.btn-light.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-theme a.btn-light.disabled, .dark-theme a.btn-light:disabled {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-theme a.btn-light:not(:disabled):not(.disabled):active, .dark-theme a.btn-light:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #c3c9e8;
    border-color: #bac0e4; }
    .dark-theme a.btn-light:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-theme a.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075); }
  .dark-theme a.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .dark-theme a.btn-dark:focus, .dark-theme a.btn-dark.focus {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-theme a.btn-dark.disabled, .dark-theme a.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-theme a.btn-dark:not(:disabled):not(.disabled):active, .dark-theme a.btn-dark:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .dark-theme a.btn-dark:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-theme .btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-theme .btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-theme .btn-outline-primary:focus, .dark-theme .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-theme .btn-outline-primary.disabled, .dark-theme .btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-theme .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-theme .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-theme .btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-theme .btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-theme .btn-outline-secondary:focus, .dark-theme .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-theme .btn-outline-secondary.disabled, .dark-theme .btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-theme .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-theme .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-theme .btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-theme .btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-theme .btn-outline-success:focus, .dark-theme .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-theme .btn-outline-success.disabled, .dark-theme .btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-theme .btn-outline-success:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-theme .btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-theme .btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-theme .btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-theme .btn-outline-info:focus, .dark-theme .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-theme .btn-outline-info.disabled, .dark-theme .btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-theme .btn-outline-info:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-theme .btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-theme .btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-theme .btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-theme .btn-outline-warning:focus, .dark-theme .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-theme .btn-outline-warning.disabled, .dark-theme .btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-theme .btn-outline-warning:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-theme .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-theme .btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-theme .btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-theme .btn-outline-danger:focus, .dark-theme .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-theme .btn-outline-danger.disabled, .dark-theme .btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-theme .btn-outline-danger:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-theme .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-theme .btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-theme .btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-theme .btn-outline-light:focus, .dark-theme .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-theme .btn-outline-light.disabled, .dark-theme .btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-theme .btn-outline-light:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-theme .btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-theme .btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-theme .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-theme .btn-outline-dark:focus, .dark-theme .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-theme .btn-outline-dark.disabled, .dark-theme .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-theme .btn-outline-dark:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-theme .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-theme .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-theme a.btn-outline-primary {
  color: #3f51b5;
  background-color: transparent;
  background-image: none;
  border-color: #3f51b5; }
  .dark-theme a.btn-outline-primary:hover {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
  .dark-theme a.btn-outline-primary:focus, .dark-theme a.btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }
  .dark-theme a.btn-outline-primary.disabled, .dark-theme a.btn-outline-primary:disabled {
    color: #3f51b5;
    background-color: transparent; }
  .dark-theme a.btn-outline-primary:not(:disabled):not(.disabled):active, .dark-theme a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5; }
    .dark-theme a.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-outline-primary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(63, 81, 181, 0.5); }

.dark-theme a.btn-outline-secondary {
  color: #E91E63;
  background-color: transparent;
  background-image: none;
  border-color: #E91E63; }
  .dark-theme a.btn-outline-secondary:hover {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
  .dark-theme a.btn-outline-secondary:focus, .dark-theme a.btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }
  .dark-theme a.btn-outline-secondary.disabled, .dark-theme a.btn-outline-secondary:disabled {
    color: #E91E63;
    background-color: transparent; }
  .dark-theme a.btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-theme a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #E91E63;
    border-color: #E91E63; }
    .dark-theme a.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(233, 30, 99, 0.5); }

.dark-theme a.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50; }
  .dark-theme a.btn-outline-success:hover {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
  .dark-theme a.btn-outline-success:focus, .dark-theme a.btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .dark-theme a.btn-outline-success.disabled, .dark-theme a.btn-outline-success:disabled {
    color: #4caf50;
    background-color: transparent; }
  .dark-theme a.btn-outline-success:not(:disabled):not(.disabled):active, .dark-theme a.btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4caf50;
    border-color: #4caf50; }
    .dark-theme a.btn-outline-success:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-outline-success.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.dark-theme a.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4; }
  .dark-theme a.btn-outline-info:hover {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
  .dark-theme a.btn-outline-info:focus, .dark-theme a.btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .dark-theme a.btn-outline-info.disabled, .dark-theme a.btn-outline-info:disabled {
    color: #00bcd4;
    background-color: transparent; }
  .dark-theme a.btn-outline-info:not(:disabled):not(.disabled):active, .dark-theme a.btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00bcd4;
    border-color: #00bcd4; }
    .dark-theme a.btn-outline-info:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-outline-info.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.dark-theme a.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800; }
  .dark-theme a.btn-outline-warning:hover {
    color: #fff;
    background-color: #ff9800;
    border-color: #ff9800; }
  .dark-theme a.btn-outline-warning:focus, .dark-theme a.btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }
  .dark-theme a.btn-outline-warning.disabled, .dark-theme a.btn-outline-warning:disabled {
    color: #ff9800;
    background-color: transparent; }
  .dark-theme a.btn-outline-warning:not(:disabled):not(.disabled):active, .dark-theme a.btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff9800;
    border-color: #ff9800; }
    .dark-theme a.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-outline-warning.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(255, 152, 0, 0.5); }

.dark-theme a.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336; }
  .dark-theme a.btn-outline-danger:hover {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
  .dark-theme a.btn-outline-danger:focus, .dark-theme a.btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .dark-theme a.btn-outline-danger.disabled, .dark-theme a.btn-outline-danger:disabled {
    color: #f44336;
    background-color: transparent; }
  .dark-theme a.btn-outline-danger:not(:disabled):not(.disabled):active, .dark-theme a.btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f44336;
    border-color: #f44336; }
    .dark-theme a.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-outline-danger.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.dark-theme a.btn-outline-light {
  color: #e8eaf6;
  background-color: transparent;
  background-image: none;
  border-color: #e8eaf6; }
  .dark-theme a.btn-outline-light:hover {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
  .dark-theme a.btn-outline-light:focus, .dark-theme a.btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }
  .dark-theme a.btn-outline-light.disabled, .dark-theme a.btn-outline-light:disabled {
    color: #e8eaf6;
    background-color: transparent; }
  .dark-theme a.btn-outline-light:not(:disabled):not(.disabled):active, .dark-theme a.btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #e8eaf6;
    border-color: #e8eaf6; }
    .dark-theme a.btn-outline-light:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-outline-light.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(232, 234, 246, 0.5); }

.dark-theme a.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .dark-theme a.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .dark-theme a.btn-outline-dark:focus, .dark-theme a.btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .dark-theme a.btn-outline-dark.disabled, .dark-theme a.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .dark-theme a.btn-outline-dark:not(:disabled):not(.disabled):active, .dark-theme a.btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .dark-theme a.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .dark-theme a.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .dark-theme a.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .dark-theme a.btn-outline-dark.dropdown-toggle:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.dark-theme .btn-outline-primary,
.dark-theme a.btn-outline-primary {
  color: #2d353c;
  border-color: #2d353c; }
  .dark-theme .btn-outline-primary:focus, .dark-theme .btn-outline-primary:hover, .dark-theme .btn-outline-primary.disabled, .dark-theme .btn-outline-primary:disabled, .dark-theme .btn-outline-primary:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-primary.dropdown-toggle,
  .dark-theme a.btn-outline-primary:focus,
  .dark-theme a.btn-outline-primary:hover,
  .dark-theme a.btn-outline-primary.disabled,
  .dark-theme a.btn-outline-primary:disabled,
  .dark-theme a.btn-outline-primary:not(:disabled):not(.disabled):active,
  .dark-theme a.btn-outline-primary:not(:disabled):not(.disabled).active,
  .show >
  .dark-theme a.btn-outline-primary.dropdown-toggle {
    color: #fff;
    border-color: #2d353c;
    background-color: #2d353c; }

.dark-theme .btn-outline-secondary,
.dark-theme a.btn-outline-secondary {
  color: #4e5c68;
  border-color: #4e5c68; }
  .dark-theme .btn-outline-secondary:focus, .dark-theme .btn-outline-secondary:hover, .dark-theme .btn-outline-secondary.disabled, .dark-theme .btn-outline-secondary:disabled, .dark-theme .btn-outline-secondary:not(:disabled):not(.disabled):active, .dark-theme .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .dark-theme .btn-outline-secondary.dropdown-toggle,
  .dark-theme a.btn-outline-secondary:focus,
  .dark-theme a.btn-outline-secondary:hover,
  .dark-theme a.btn-outline-secondary.disabled,
  .dark-theme a.btn-outline-secondary:disabled,
  .dark-theme a.btn-outline-secondary:not(:disabled):not(.disabled):active,
  .dark-theme a.btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show >
  .dark-theme a.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    border-color: #4e5c68;
    background-color: #4e5c68; }

.dark-theme a,
.dark-theme a.text-primary {
  color: lightgray; }
  .dark-theme a:focus, .dark-theme a:hover,
  .dark-theme a.text-primary:focus,
  .dark-theme a.text-primary:hover {
    color: #c7c7c7 !important; }

.dark-theme a.badge-light {
  color: #616161; }
  .dark-theme a.badge-light:focus, .dark-theme a.badge-light:hover {
    color: #474747 !important; }

.dark-theme .text-primary {
  color: lightgray !important; }

.dark-theme .page-link {
  color: #596977; }
  .dark-theme .page-link:focus, .dark-theme .page-link:hover {
    color: #4e5c68; }

.dark-theme .bg-primary,
.dark-theme .badge-primary {
  background-color: #2d353c !important;
  color: #E0E0E0 !important; }

.dark-theme .btn-primary,
.dark-theme .jr-btn-primary,
.dark-theme a.btn-primary,
.dark-theme a.jr-btn-primary {
  border-color: #38424B;
  background-color: #2d353c !important;
  color: #E0E0E0 !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none; }
  .dark-theme .btn-primary:hover, .dark-theme .btn-primary:focus, .dark-theme .btn-primary:active, .dark-theme .btn-primary.disabled, .dark-theme .btn-primary:disabled,
  .dark-theme .jr-btn-primary:hover,
  .dark-theme .jr-btn-primary:focus,
  .dark-theme .jr-btn-primary:active,
  .dark-theme .jr-btn-primary.disabled,
  .dark-theme .jr-btn-primary:disabled,
  .dark-theme a.btn-primary:hover,
  .dark-theme a.btn-primary:focus,
  .dark-theme a.btn-primary:active,
  .dark-theme a.btn-primary.disabled,
  .dark-theme a.btn-primary:disabled,
  .dark-theme a.jr-btn-primary:hover,
  .dark-theme a.jr-btn-primary:focus,
  .dark-theme a.jr-btn-primary:active,
  .dark-theme a.jr-btn-primary.disabled,
  .dark-theme a.jr-btn-primary:disabled {
    background-color: #2d353c !important;
    border-color: #2d353c !important;
    color: lightgray !important; }

.dark-theme .btn-outline-primary {
  color: lightgray;
  border-color: lightgray; }
  .dark-theme .btn-outline-primary:hover, .dark-theme .btn-outline-primary:focus, .dark-theme .btn-outline-primary:active {
    background-color: #2d353c;
    border-color: #2d353c;
    color: lightgray; }

.dark-theme .jr-flat-btn {
  color: lightgray; }
  .dark-theme .jr-flat-btn:hover, .dark-theme .jr-flat-btn:focus, .dark-theme .jr-flat-btn:active {
    background-color: #f5f5f5;
    color: #5c5c5c; }

.dark-theme .jr-flat-btn.jr-btn-secondary {
  color: #E91E63; }
  .dark-theme .jr-flat-btn.jr-btn-secondary:hover, .dark-theme .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(233, 30, 99, 0.12);
    color: #E91E63; }

.dark-theme .jr-flat-btn.jr-btn-primary {
  color: lightgray; }
  .dark-theme .jr-flat-btn.jr-btn-primary:hover, .dark-theme .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(211, 211, 211, 0.12);
    color: lightgray; }

.dark-theme .btn-group > .jr-btn.active,
.dark-theme .btn-group > .btn-default.active,
.dark-theme .btn-group-vertical > .jr-btn.active,
.dark-theme .btn-group-vertical > .btn-default.active {
  background-color: #38424B;
  border-color: #38424B;
  color: lightgray; }

.dark-theme .btn-group > .jr-flat-btn,
.dark-theme .btn-group-vertical > .jr-flat-btn {
  border-color: transparent; }
  .dark-theme .btn-group > .jr-flat-btn.active,
  .dark-theme .btn-group-vertical > .jr-flat-btn.active {
    background-color: transparent;
    color: #0c0f11; }
    .dark-theme .btn-group > .jr-flat-btn.active:hover, .dark-theme .btn-group > .jr-flat-btn.active:focus,
    .dark-theme .btn-group-vertical > .jr-flat-btn.active:hover,
    .dark-theme .btn-group-vertical > .jr-flat-btn.active:focus {
      background-color: rgba(56, 66, 75, 0.5);
      color: #0c0f11; }

.dark-theme a.text-secondary {
  color: #4e5c68; }
  .dark-theme a.text-secondary:focus, .dark-theme a.text-secondary:hover {
    color: #38424b !important; }

.dark-theme .text-secondary {
  color: #4e5c68 !important; }

.dark-theme .bg-secondary {
  background-color: #4e5c68 !important;
  color: lightgray !important; }

.dark-theme .badge-secondary {
  background-color: #38424b !important;
  color: lightgray !important; }

.dark-theme .btn-secondary {
  background-color: #4e5c68;
  border-color: #4e5c68;
  color: lightgray; }
  .dark-theme .btn-secondary:hover, .dark-theme .btn-secondary:focus, .dark-theme .btn-secondary:active {
    background-color: #38424b !important;
    border-color: #38424b !important;
    color: lightgray !important; }

.dark-theme .jr-flat-btn {
  background-color: transparent !important;
  color: lightgray !important; }
  .dark-theme .jr-flat-btn:hover, .dark-theme .jr-flat-btn:focus, .dark-theme .jr-flat-btn:active {
    background-color: #f5f5f5 !important;
    color: #5c5c5c !important; }

.dark-theme .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: lightgray !important;
  border-color: transparent !important; }
  .dark-theme .jr-flat-btn.jr-btn-primary:hover, .dark-theme .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(56, 66, 75, 0.5) !important;
    color: lightgray !important; }

.dark-theme .bg-primary .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-theme .bg-primary .jr-flat-btn.jr-btn-primary:hover, .dark-theme .bg-primary .jr-flat-btn.jr-btn-primary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-theme .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #e0e0e0 !important;
  border-color: transparent !important; }
  .dark-theme .jr-flat-btn.jr-btn-secondary:hover, .dark-theme .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(78, 92, 104, 0.5) !important;
    color: #e0e0e0 !important; }

.dark-theme .bg-secondary .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: #fff !important;
  border-color: transparent !important; }
  .dark-theme .bg-secondary .jr-flat-btn.jr-btn-secondary:hover, .dark-theme .bg-secondary .jr-flat-btn.jr-btn-secondary:focus {
    background-color: rgba(255, 255, 255, 0.12) !important;
    color: #fff !important; }

.dark-theme .app-main-header {
  background-color: #2d353c !important; }
  .dark-theme .app-main-header .search-bar input {
    background-color: #434f5a; }
    .dark-theme .app-main-header .search-bar input:focus {
      background-color: #fff; }
  @media screen and (max-width: 575px) {
    .dark-theme .app-main-header .search-dropdown input {
      background-color: #fff; } }

.dark-theme .jr-card .app-main-header,
.dark-theme .sidebar-header,
.dark-theme .card-header,
.dark-theme .col-with-divider div[class*="col-"] {
  border-color: #495762; }

.dark-theme .app-header .app-main-header {
  border-color: #2d353c; }

.dark-theme .app-header {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none; }

.dark-theme .app-footer {
  padding: 0 24px;
  border-color: #495762;
  background-color: #38424B;
  color: #E0E0E0; }
  .dark-theme .app-footer a.text-secondary {
    color: #E0E0E0 !important; }
    .dark-theme .app-footer a.text-secondary:hover {
      color: #c7c7c7 !important; }

.dark-theme .media-list:not(:last-child) {
  border-color: #495762; }

.dark-theme .app-main-header.bg-white {
  color: #fff !important; }

.dark-theme .color-theme-header {
  background-color: #38424B; }

.dark-theme .side-nav {
  background-color: #2d353c !important;
  color: #E0E0E0 !important;
  border-color: #495762 !important; }
  .dark-theme .side-nav .user-profile {
    background-color: #343d45;
    position: relative;
    z-index: 2; }
  .dark-theme .side-nav .customizer {
    border-bottom: 0 none; }
  .dark-theme .side-nav .user-detail .user-name {
    color: lightgray; }

.dark-theme ul.nav-menu li a {
  color: #E0E0E0; }

.dark-theme ul.nav-menu .nav-header {
  color: #E0E0E0; }

.dark-theme ul.nav-menu > li.open > a,
.dark-theme ul.nav-menu > li > a:hover,
.dark-theme ul.nav-menu > li > a:focus {
  background-color: #343d45;
  color: lightgray; }

.dark-theme ul.nav-menu li.menu.open > a {
  border-color: lightgray; }

.dark-theme ul.nav-menu li.menu > a:focus:before,
.dark-theme ul.nav-menu li.menu > a:hover:before {
  color: lightgray; }

.dark-theme ul.nav-menu li ul {
  background-color: #343d45; }

.dark-theme ul.nav-menu li.menu > a:before {
  color: #E0E0E0; }

.dark-theme ul.nav-menu li.menu.open > a:before,
.dark-theme ul.nav-menu li.menu > a:focus:before,
.dark-theme ul.nav-menu li.menu li > a:hover:before {
  color: lightgray; }

.dark-theme ul.nav-menu li.menu .sub-menu li > a:before {
  color: #E0E0E0; }

.dark-theme ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: #2d353c;
  color: #fff; }

.dark-theme ul.nav-menu li.menu .sub-menu li > a:hover,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:focus,
.dark-theme ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:focus:before,
.dark-theme ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #2d353c;
  color: #fff; }

.dark-theme ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: #38424B; }

/*Header top Navbar Styles*/
.dark-theme .navbar-nav li:hover > a,
.dark-theme .navbar-nav li:focus > a {
  color: #b8c1ca; }

.dark-theme .navbar-nav li a {
  color: #868e96; }

.dark-theme .navbar-nav li a:hover,
.dark-theme .navbar-nav li a:focus {
  color: #b8c1ca; }

.dark-theme .navbar-nav li.nav-item > a {
  color: #fff; }

.dark-theme .navbar-nav li.nav-item:hover > a,
.dark-theme .navbar-nav li.nav-item:focus > a,
.dark-theme .navbar-nav li.nav-item > a:hover,
.dark-theme .navbar-nav li.nav-item > a:focus,
.dark-theme .navbar-nav li.nav-item.active > a {
  color: #b8c1ca; }

.dark-theme .navbar-nav ul.sub-menu {
  background-color: #434f5a;
  border-color: #4c5965; }
  .dark-theme .navbar-nav ul.sub-menu li a.active,
  .dark-theme .navbar-nav ul.sub-menu li.active > a {
    color: #b8c1ca; }

.dark-theme .navbar-nav .nav-arrow > a:before {
  color: #868e96; }

.dark-theme .navbar-nav .nav-arrow a:hover:before,
.dark-theme .navbar-nav .nav-arrow a:focus:before {
  color: #b8c1ca; }

.dark-theme .navbar-nav .nav-arrow:hover > a:before,
.dark-theme .navbar-nav .nav-arrow:focus > a:before,
.dark-theme .navbar-nav .nav-arrow.active > a:before {
  color: #b8c1ca; }

.dark-theme .app-top-nav {
  background-color: #252525 !important; }
  .dark-theme .app-top-nav .navbar-nav li.nav-item > a {
    color: #fff; }
  .dark-theme .app-top-nav .navbar-nav li.nav-item:hover > a,
  .dark-theme .app-top-nav .navbar-nav li.nav-item:focus > a,
  .dark-theme .app-top-nav .navbar-nav li.nav-item > a:hover,
  .dark-theme .app-top-nav .navbar-nav li.nav-item > a:focus,
  .dark-theme .app-top-nav .navbar-nav li.nav-item.active > a {
    color: #b8c1ca; }

.dark-theme .rbc-event {
  background-color: #7e8f9e; }

.dark-theme .rbc-event.rbc-selected {
  background-color: #22282e; }

.dark-theme .rbc-slot-selection {
  background-color: rgba(56, 66, 75, 0.7); }

.dark-theme .rbc-toolbar button:active,
.dark-theme .rbc-toolbar button.rbc-active {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button:active:hover,
.dark-theme .rbc-toolbar button.rbc-active:hover,
.dark-theme .rbc-toolbar button:active:focus,
.dark-theme .rbc-toolbar button.rbc-active:focus {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button:hover,
.dark-theme .rbc-toolbar button:focus {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: white; }

.dark-theme .rbc-toolbar button {
  background-color: rgba(56, 66, 75, 0.9);
  border-color: #9ba8b4;
  color: #9ba8b4; }

.dark-theme .rbc-show-more {
  background-color: #7e8f9e; }

.dark-theme .rbc-off-range-bg {
  background-color: #4e5c68; }

.dark-theme .rbc-day-slot .rbc-event {
  border-color: #38424B; }

.dark-theme .chat-sidenav-title {
  color: #38424B; }

.dark-theme .chat-user-item.active, .dark-theme .chat-user-item:hover {
  background-color: #a9b5bf; }

.dark-theme .contact-list i {
  color: #38424B; }

.dark-theme .login-content .form-control:focus {
  border-color: #38424B; }

.dark-theme .filter-with-bg-color ul li a {
  border-color: #38424B; }
  .dark-theme .filter-with-bg-color ul li a:hover, .dark-theme .filter-with-bg-color ul li a:focus, .dark-theme .filter-with-bg-color ul li a.active {
    background-color: #38424B; }

.dark-theme .profile-intro .icon {
  color: #647585; }

.dark-theme .social-link li.active a,
.dark-theme .social-link li a:hover,
.dark-theme .social-link li a:focus {
  color: lightgray; }

.dark-theme .actions {
  color: lightgray; }

.dark-theme .table-hover tbody tr:hover {
  background-color: rgba(56, 66, 75, 0.075); }

.dark-theme .border-primary {
  border-color: lightgray !important; }

.dark-theme .breadcrumb {
  background-color: #38424B; }

.dark-theme .desc-list.even-odd li:nth-child(2n) {
  background-color: #38424B; }

.dark-theme .timeline-item::before {
  border-color: #495762; }

.dark-theme .timeline-panel {
  background-color: #434f5a;
  border-color: #495762; }
  .dark-theme .timeline-panel:after {
    border-color: transparent #434f5a; }
  .dark-theme .timeline-panel:before {
    border-color: transparent #4c5965; }

.dark-theme .login-content {
  background-color: #434f5a;
  border-color: #495762; }

.dark-theme .chat-sidenav-header,
.dark-theme .chat-tabs {
  background-color: #22282e; }

.dark-theme .module-nav li a {
  color: #adb5bd !important; }
  .dark-theme .module-nav li a:hover, .dark-theme .module-nav li a:focus, .dark-theme .module-nav li a.active {
    color: #fff !important; }
  .dark-theme .module-nav li a.active {
    border-color: #22282e; }

.dark-theme .module-side {
  background-color: #434f5a; }

.dark-theme .module-box-header,
.dark-theme .app-module-sidenav,
.dark-theme .dropdown-menu {
  background-color: #434f5a;
  border-color: #495762;
  color: #E0E0E0; }

.dark-theme .dropdown-item {
  color: #E0E0E0; }
  .dark-theme .dropdown-item:hover, .dark-theme .dropdown-item:focus {
    background-color: #38424B;
    color: #E0E0E0; }

.dark-theme .quick-menu .dropdown-menu .jr-card-header {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border-color: #495762; }

.dark-theme .quick-menu .dropdown-menu:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #434f5a; }

.dark-theme .quick-menu .dropdown-menu:before {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #495762; }

.dark-theme .module-box-header .icon-btn {
  color: #E0E0E0; }

.dark-theme .module-box-header .search-bar input {
  background-color: #38424B; }
  .dark-theme .module-box-header .search-bar input:focus {
    background-color: #fff; }

.dark-theme .chat-sidenav-header .search-bar input {
  background-color: #38424B; }
  .dark-theme .chat-sidenav-header .search-bar input:focus {
    background-color: #fff; }

.dark-theme .module-box-topbar,
.dark-theme .module-list-item,
.dark-theme .module-side-header,
.dark-theme .chat-main-footer,
.dark-theme .messages-list ul li,
.dark-theme hr,
.dark-theme .chat-sidenav,
.dark-theme .chat-user-item,
.dark-theme .chat-main-header,
.dark-theme .modal-header {
  border-color: #495762; }

.dark-theme .module-logo,
.dark-theme .user-nav .quick-menu .user-profile {
  background-color: #38424B;
  color: #E0E0E0; }

.dark-theme .messages-list ul li .user-name a {
  color: #E0E0E0; }

.dark-theme .chat-module-box,
.dark-theme .chat-sidenav-content,
.dark-theme .chat-main-header,
.dark-theme .chat-main-footer,
.dark-theme .chat-textarea,
.dark-theme .module-detail-header,
.dark-theme .module-box-content,
.dark-theme .modal-box-footer {
  background-color: #38424B; }

.dark-theme .modal-box-content {
  background-color: #434f5a; }

.dark-theme .chat-user-item.active, .dark-theme .chat-user-item:hover {
  background-color: #22282e; }

.dark-theme .chat-textarea {
  color: #fff; }

.dark-theme .chat-item {
  padding: 16px; }
  .dark-theme .chat-item .bubble {
    background-color: #4e5c68;
    border-color: #2f383f; }
    .dark-theme .chat-item .bubble:before {
      border-right: 9px solid #4e5c68; }
    .dark-theme .chat-item .bubble:after {
      border-right: 8px solid #4e5c68; }
  .dark-theme .chat-item.flex-row-reverse .bubble {
    margin-right: 16px;
    margin-left: 0;
    background-color: #22282e;
    border-color: #4e5c68; }
    .dark-theme .chat-item.flex-row-reverse .bubble:before {
      left: auto;
      right: -9px;
      border-left: 9px solid #22282e;
      border-right: 0 none; }
    .dark-theme .chat-item.flex-row-reverse .bubble:after {
      left: auto;
      right: -8px;
      border-left: 8px solid #22282e;
      border-right: 0 none; }

.dark-theme .modal-box-footer {
  padding-top: 12px; }

.dark-theme .app-logo-content {
  background-color: #38424B; }

.dark-theme .app-social-block .social-link button {
  border: solid 1px #38424B;
  color: #38424B; }
  .dark-theme .app-social-block .social-link button:hover, .dark-theme .app-social-block .social-link button:focus {
    color: #fff;
    background-color: #38424B; }

.dark-theme .form-checkbox > input + .check,
.dark-theme .form-checkbox > input.sm + .check {
  color: #868e96; }

.dark-theme .rmc-drawer-sidebar-dark-theme,
.dark-theme .rmc-drawer-pin {
  color: #E0E0E0;
  background-color: #38424B; }

.dark-theme .rmc-drawer-left .rmc-drawer-sidebar-dark-theme,
.dark-theme .rmc-drawer-right .rmc-drawer-sidebar-dark-theme {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100vh; }

.dark-theme .rmc-drawer-pin {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  padding: 20px; }

.dark-theme .rmc-drawer-docked .rmc-drawer-content {
  border-left: 0 none; }

.dark-theme .rmc-drawer-sidebar {
  background-color: #38424B; }

.dark-theme .rc-collapse {
  background-color: #434f5a;
  border-color: #495762; }
  .dark-theme .rc-collapse > .rc-collapse-item {
    border-color: #495762; }
    .dark-theme .rc-collapse > .rc-collapse-item > .rc-collapse-header {
      color: #E0E0E0; }
    .dark-theme .rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
      border-left-color: #E0E0E0; }
  .dark-theme .rc-collapse .rc-collapse-content {
    background-color: #434f5a;
    border-color: #495762;
    color: #E0E0E0; }
  .dark-theme .rc-collapse .rc-collapse-item-disabled > .rc-collapse-header {
    background-color: #434f5a;
    color: #E0E0E0; }

.dark-theme .rc-time-picker-panel {
  color: #38424B; }

.dark-theme .Collapsible__trigger {
  background-color: #2d353c; }

.dark-theme .Collapsible__contentInner {
  background-color: #434f5a;
  border-color: #495762; }

.dark-theme .categories-list li a:focus,
.dark-theme .categories-list li a:hover,
.dark-theme .categories-list li.active a {
  color: #a0a0a0; }

.dark-theme .table th,
.dark-theme .table td {
  border-color: #495762; }

.dark-theme .table-unbordered .th-border-b th {
  border-color: #495762 !important; }

.dark-theme .ReactTable {
  border-color: #495762; }
  .dark-theme .ReactTable .rt-thead .rt-th,
  .dark-theme .ReactTable .rt-thead .rt-td {
    border-color: #495762; }
  .dark-theme .ReactTable .rt-thead.-header {
    border-color: #495762; }
  .dark-theme .ReactTable .rt-table .rt-thead.-headerGroups {
    background-color: #434f5a;
    border-color: #495762; }
  .dark-theme .ReactTable.-striped .rt-tbody .rt-tr-group .rt-tr.-odd,
  .dark-theme .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: #495762 !important; }
  .dark-theme .ReactTable .rt-tbody .rt-tr-group,
  .dark-theme .ReactTable .rt-tbody .rt-td {
    border-color: #495762; }
  .dark-theme .ReactTable .-pagination {
    border-color: #495762; }
    .dark-theme .ReactTable .-pagination .-btn:not([disabled]) {
      background-color: #3c4751;
      color: #E0E0E0; }
      .dark-theme .ReactTable .-pagination .-btn:not([disabled]):hover {
        background-color: #38424b;
        color: #E0E0E0; }
    .dark-theme .ReactTable .-pagination .-btn[disabled] {
      color: #E0E0E0; }

.dark-theme .card-strip,
.dark-theme .contact-item {
  border-color: #495762; }
