/*Theme Indigo Styles*/
$app-primary-indigo: #3f51b5 !default;
$secondary-indigo: #FF4081;

$sidebar-indigo: $white !default;
$sidebar-text-indigo: #868e96 !default;

//-Base-scss
.indigo .right-arrow {
  color: $app-primary-indigo;
  &:after {
    color: $app-primary-indigo;
  }
}

//
// Alternate buttons
//
@each $color, $value in $theme-colors {
  .indigo .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .indigo a.btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .indigo .btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-900);
    } @else {
      @include button-outline-variant($value, $sidebar-indigo);
    }
  }
}

@each $color, $value in $theme-colors {
  .indigo a.btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-900);
    } @else {
      @include button-outline-variant($value, $sidebar-indigo);
    }
  }
}

.indigo .btn-outline-primary,
.indigo a.btn-outline-primary {
  color: $app-primary-indigo;
  border-color: $app-primary-indigo;

  &:focus,
  &:hover,
  &.disabled,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
    border-color: $app-primary-indigo;
    background-color: $app-primary-indigo;
  }
}

.indigo .btn-outline-secondary,
.indigo a.btn-outline-secondary {
  color: $secondary-indigo;
  border-color: $secondary-indigo;

  &:focus,
  &:hover,
  &.disabled,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
    border-color: $secondary-indigo;
    background-color: $secondary-indigo;
  }
}


//Bootstrap file Style
.indigo {
  & a,
  & a.text-primary {
    color: $app-primary-indigo;
    &:focus,
    &:hover {
      color: darken($app-primary-indigo, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-indigo !important;
  }

  & .page-link {
    color: $app-primary-indigo;
    &:focus,
    &:hover {
      color: $app-primary-indigo;
    }
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-indigo !important;
    color: $sidebar-indigo !important;
  }

  & .btn-primary,
  & .jr-btn-primary,
  & a.btn-primary,
  & a.jr-btn-primary {
    background-color: $app-primary-indigo;
    border-color: $app-primary-indigo;
    color: $sidebar-indigo;

    &:hover,
    &:focus,
    &:not([disabled]):not(.disabled):active {
      background-color: darken($app-primary-indigo, 5%) !important;
      border-color: darken($app-primary-indigo, 5%) !important;
      color: $sidebar-indigo !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #c5cae9 !important;
    }

    &.lighten-3 {
      background-color: #9fa8da !important;
    }

    &.lighten-2 {
      background-color: #7986cb !important;
    }

    &.lighten-1 {
      background-color: #5c6bc0 !important;
    }

    &.darken-1 {
      background-color: #3949ab !important;
    }

    &.darken-2 {
      background-color: #303f9f !important;
    }

    &.darken-3 {
      background-color: #283593 !important;
    }

    &.darken-4 {
      background-color: #1a237e !important;
    }

    &.accent-1 {
      background-color: #8c9eff !important;
    }

    &.accent-2 {
      background-color: #536dfe !important;
    }

    &.accent-3 {
      background-color: #3d5afe !important;
    }

    &.accent-4 {
      background-color: #304ffe !important;
    }
  }
}

//Secondary
.indigo {
  & a.text-secondary {
    color: $secondary-indigo;
    &:focus,
    &:hover {
      color: darken($secondary-indigo, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-indigo !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-indigo !important;
    color: $sidebar-indigo !important;
  }

  & .btn-secondary {
    background-color: $secondary-indigo;
    border-color: $secondary-indigo;
    color: $sidebar-indigo;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-indigo, 5%) !important;
      border-color: darken($secondary-indigo, 5%) !important;
      color: $sidebar-indigo !important;
    }
  }
}

//flat-btn
.indigo .jr-flat-btn {
  background-color: transparent !important;
  color: $body-color !important;
  &:hover,
  &:focus,
  &:active {
    background-color: map_get($gray, lighten-4) !important;
    color: $body-color !important;
  }
}

.indigo .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: $app-primary-indigo !important;
  border-color: transparent !important;

  &:hover,
  &:focus {
    background-color: rgba($app-primary-indigo, 0.12) !important;
    color: $app-primary-indigo !important;
  }
}

.indigo .bg-primary {
  & .jr-flat-btn.jr-btn-primary {
    background-color: transparent !important;
    color: $white !important;
    border-color: transparent !important;

    &:hover,
    &:focus {
      background-color: rgba($white, 0.12) !important;
      color: $white !important;
    }
  }
}

.indigo .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: $secondary-indigo !important;
  border-color: transparent !important;

  &:hover,
  &:focus {
    background-color: rgba($secondary-indigo, 0.12) !important;
    color: $secondary-indigo !important;
  }
}

.indigo .bg-secondary {
  & .jr-flat-btn.jr-btn-secondary {
    background-color: transparent !important;
    color: $white !important;
    border-color: transparent !important;

    &:hover,
    &:focus {
      background-color: rgba($white, 0.12) !important;
      color: $white !important;
    }
  }
}

//_header.scss
.indigo .app-main-header {

  & .search-dropdown input {
    @media screen and (max-width: 575px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.indigo .color-theme-header {
  background-color: $app-primary-indigo;

}

//_sidebar.scss
.indigo .side-nav {
  background-color: $sidebar-indigo !important;
  color: $sidebar-text-indigo !important;

  & .user-profile {
    background-color: $gray-100;
    position: relative;
    z-index: 2;

    & .text-white {
      color: $app-primary-indigo !important;
    }
  }

  & .customizer {
    border-bottom: solid 1px lighten($sidebar-text-indigo, 35%);
  }

  & .user-detail {
    & .user-name {
      color: $app-primary-indigo;
    }
  }
}

.indigo ul.nav-menu li a {
  color: $sidebar-text-indigo;
}

.indigo ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-indigo;
  }
}

.indigo ul.nav-menu > li.open > a,
.indigo ul.nav-menu > li > a:hover,
.indigo ul.nav-menu > li > a:focus {
  background-color: $sidebar-indigo;
  color: $app-primary-indigo;
}

.indigo ul.nav-menu li.menu > a:focus:before,
.indigo ul.nav-menu li.menu > a:hover:before {
  color: $app-primary-indigo;
}

.indigo ul.nav-menu li ul {
  background-color: $sidebar-indigo;
}

.indigo ul.nav-menu li.menu > a:before {
  color: $sidebar-text-indigo;
}

.indigo ul.nav-menu li.menu.open > a:before,
.indigo ul.nav-menu li.menu > a:focus:before,
.indigo ul.nav-menu li.menu li > a:hover:before {
  color: $app-primary-indigo;
}

.indigo ul.nav-menu li.menu .sub-menu li > a:before {
  color: $sidebar-text-indigo;
}

.indigo ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: $app-primary-indigo;
}

.indigo ul.nav-menu li.menu .sub-menu li > a:hover,
.indigo ul.nav-menu li.menu .sub-menu li > a:focus,
.indigo ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.indigo ul.nav-menu li.menu .sub-menu li > a:focus:before,
.indigo ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: $sidebar-indigo;
  color: $app-primary-indigo;
}

/*Header top Navbar Styles*/
.indigo .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a {
      color: $app-primary-indigo;
    }

    & a {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus {
      color: $app-primary-indigo;
    }
  }

  & li.nav-item.active > a {
    color: $app-primary-indigo;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a {
      color: $app-primary-indigo;
    }
  }

  & .nav-arrow {
    & > a:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus {
      &:before {
        color: $app-primary-indigo;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before {
      color: $app-primary-indigo;
    }
  }
}

.indigo .app-top-nav {
  background-color: $app-primary-indigo;

  & .navbar-nav {
    & li.nav-item > a {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a {
      color: $secondary-indigo;
    }
  }
}

//_app-module.scss
.indigo .module-nav {
  & li a {
    &.active {
      border-color: $app-primary-indigo;
    }
  }
}

//_calendar.scss
.indigo .rbc-event {
  background-color: $app-primary-indigo;
}

.indigo .rbc-event.rbc-selected {
  background-color: darken($app-primary-indigo, 10%);
}

.indigo .rbc-slot-selection {
  background-color: rgba($app-primary-indigo, 0.7);
}

.indigo .rbc-toolbar button:active,
.indigo .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-indigo, 0.9);
  border-color: $app-primary-indigo;
}

.indigo .rbc-toolbar button:active:hover,
.indigo .rbc-toolbar button.rbc-active:hover,
.indigo .rbc-toolbar button:active:focus,
.indigo .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-indigo, 0.9);
  border-color: $app-primary-indigo;
}

.indigo .rbc-toolbar button:focus {
  background-color: rgba($app-primary-indigo, 0.9);
  border-color: $app-primary-indigo;
}

.indigo .rbc-toolbar button:hover {
  background-color: rgba($app-primary-indigo, 0.9);
  border-color: $app-primary-indigo;
}

.indigo .rbc-day-slot .rbc-event {
  border-color: $app-primary-indigo;
}

//_chat.scss
.indigo .chat-sidenav-title {
  color: $app-primary-indigo;
}

.indigo .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-indigo, 45%);
  }
}

//_dashboard.scss
.indigo .contact-list {
  & i {
    color: $app-primary-indigo;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.indigo .Collapsible__trigger {
  background: $app-primary-indigo;
}

.indigo .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-indigo;
  }
}

//_login.scss

.indigo .login-content .form-control {
  &:focus {
    border-color: $app-primary-indigo;
  }
}

//_portfolio.scss
.indigo .filter-with-bg-color ul li {
  & a {
    border-color: $app-primary-indigo;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-indigo;
    }
  }
}

//_card.scss
.indigo .profile-intro {
  & .icon {
    color: $app-primary-indigo;
  }
}

.indigo .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-indigo;
  }
}

//_tables.scss
.indigo .actions {
  color: $secondary-indigo;
}

.indigo .table-hover tbody tr:hover {
  background-color: rgba($app-primary-indigo, 0.075);
}

//Border Color

.indigo .border-primary {
  border-color: $app-primary-indigo !important;
}

// login page content

.indigo .app-logo-content {
  background-color: $app-primary-indigo;
}

.indigo .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-indigo;
    color: $app-primary-indigo;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-indigo;
    }
  }
}

.indigo .btn-group,
.indigo .btn-group-vertical {
  > .jr-btn,
  > .btn-default {
    &.active {
      background-color: $app-primary-indigo;
      border-color: $app-primary-indigo;
    }

  }

  > .jr-flat-btn {
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $app-primary-indigo !important;
      border-color: transparent !important;

      &:hover,
      &:focus {
        background-color: rgba($app-primary-indigo, 0.12) !important;
        color: $app-primary-indigo !important;
      }
    }
  }
}

.indigo .nav-pill-sales {
  & .nav-link.active {
    background-color: $app-primary-indigo;
    border-color: $app-primary-indigo;
  }
}

.indigo .form-checkbox > input + .check,
.indigo .form-checkbox > input.sm + .check {
  color: $app-primary-indigo;
}


