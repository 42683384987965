
.gosms-time-picker {
  display: block;

  &> input {
    padding: 0.5rem 1rem;
    font-size: 17px;
    line-height: 1.5;
    border-radius: 0.3rem;
    height: inherit;
  }
}

.gosms-time-picker-popup {
  .rc-time-picker-panel-input-wrap{
    &> input {
      padding: 0.5rem 1rem;
      font-size: 17px;
      line-height: 1.5;
      border-radius: 0.3rem;
      height: inherit;
    }
  }

  .rc-time-picker-panel-select {
    padding-bottom: 20px;
  }
}
