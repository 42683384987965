.gosms_mentions {
  height: 70px;

  .gosms_mentions__control {
    height: 70px;

    .gosms_mentions__input,
    .gosms_mentions__highlighter {
      border: 1px solid #ced4da;
      color: #495057;
      padding: 0.5rem 1rem;
      font-size: 17px !important;
      line-height: 1.5;
      border-radius: 0.3rem;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
      height: 70px !important;
    }
  }

  .gosms_mentions__suggestions {
    font-size: 17px !important;
    margin-top: 17px !important;
    border: 1px solid #ced4da;

    .gosms_mentions__item {
      border: 1px solid #ced4da;
    }
  }
}
