.app-wrapper {
  padding-top: 10px;
}

ul.nav-menu li.menu.beta > a {
  color: $sidebar-beta-text-color;
}

ul.nav-menu > li.menu.beta.selected > a,
ul.nav-menu > li.menu.beta.open > a,
ul.nav-menu > li.menu.beta > a:hover,
ul.nav-menu > li.menu.beta > a:focus {
  color: $sidebar-beta-hover-color;
}

ul.nav-menu li.menu.beta > a:focus:before,
ul.nav-menu li.menu.beta > a:hover:before {
  color: $sidebar-beta-hover-color;
}