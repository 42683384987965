.cursor-pointer {
  cursor: pointer;
}

.padding-0 {
    padding: 0;
}

.height-300 {
  height: 300px !important;
}

.border-radius-0 {
    border-radius: 0;
}

.form-group {
    label {
        &.requerido {
            &:after {
                content: " *";
                color:red;
            }
        }
    }
}
