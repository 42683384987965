.module-box {
  max-width: 100%;
}

/***** Calendar *****/
.crm-event-danger {
  background-color: $danger !important;
  border-color: darken($danger, 10%) !important;
}

.crm-event-success {
  background-color: $success !important;
  border-color: darken($success, 10%) !important;
}
