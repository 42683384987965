/*Theme Pink Styles*/
$app-primary-pink: #e91e63 !default;
$secondary-pink: #00BFA5;

$sidebar-pink: $white !default;
$sidebar-text-pink: #868e96 !default;

//-Base-scss
.pink .right-arrow {
  color: $app-primary-pink;
  &:after {
    color: $app-primary-pink;
  }
}

//
// Alternate buttons
//
@each $color, $value in $theme-colors {
  .pink .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .pink a.btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .pink .btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-900);
    } @else {
      @include button-outline-variant($value, $sidebar-pink);
    }
  }
}

@each $color, $value in $theme-colors {
  .pink a.btn-outline-#{$color} {
    @if $color == "light" {
      @include button-outline-variant($value, $gray-900);
    } @else {
      @include button-outline-variant($value, $sidebar-pink);
    }
  }
}

.pink .btn-outline-primary,
.pink a.btn-outline-primary {
  color: $app-primary-pink;
  border-color: $app-primary-pink;

  &:focus,
  &:hover,
  &.disabled,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
    border-color: $app-primary-pink;
    background-color: $app-primary-pink;
  }
}

.pink .btn-outline-secondary,
.pink a.btn-outline-secondary {
  color: $secondary-pink;
  border-color: $secondary-pink;

  &:focus,
  &:hover,
  &.disabled,
  &:disabled,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: $white;
    border-color: $secondary-pink;
    background-color: $secondary-pink;
  }
}


//- Bootstrap file Style
.pink {
  & a,
  & a.text-primary {
    color: $app-primary-pink;
    &:focus,
    &:hover {
      color: darken($app-primary-pink, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-pink !important;
  }

  & .page-link {
    color: $app-primary-pink;
    &:focus,
    &:hover {
      color: $app-primary-pink;
    }
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-pink !important;
    color: $sidebar-pink !important;
  }


  & .btn-primary,
  & .jr-btn-primary,
  & a.btn-primary,
  & a.jr-btn-primary {
    background-color: $app-primary-pink;
    border-color: $app-primary-pink;
    color: $sidebar-pink;

    &:hover,
    &:focus,
    &:not([disabled]):not(.disabled):active {
      background-color: darken($app-primary-pink, 5%) !important;
      border-color: darken($app-primary-pink, 5%) !important;
      color: $sidebar-pink !important;
    }
  }


  & .bg-primary {
    &.lighten-4 {
      background-color: #f8bbd0 !important;
    }

    &.lighten-3 {
      background-color: #f48fb1 !important;
    }

    &.lighten-2 {
      background-color: #f06292 !important;
    }

    &.lighten-1 {
      background-color: #ec407a !important;
    }

    &.darken-1 {
      background-color: #d81b60 !important;
    }

    &.darken-2 {
      background-color: #c2185b !important;
    }

    &.darken-3 {
      background-color: #ad1457 !important;
    }

    &.darken-4 {
      background-color: #880e4f !important;
    }

    &.accent-1 {
      background-color: #ff80ab !important;
    }

    &.accent-2 {
      background-color: #ff4081 !important;
    }

    &.accent-3 {
      background-color: #f50057 !important;
    }

    &.accent-4 {
      background-color: #c51162 !important;
    }
  }
}

//Secondary
.pink {
  & a.text-secondary {
    color: $secondary-pink;
    &:focus,
    &:hover {
      color: darken($secondary-pink, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-pink !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-pink !important;
    color: $sidebar-pink !important;
  }

  & .btn-secondary {
    background-color: $secondary-pink;
    border-color: $secondary-pink;
    color: $sidebar-pink;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-pink, 5%) !important;
      border-color: darken($secondary-pink, 5%) !important;
      color: $sidebar-pink !important;
    }
  }
}

//flat-btn
.pink .jr-flat-btn {
  background-color: transparent !important;
  color: $body-color !important;
  &:hover,
  &:focus,
  &:active {
    background-color: map_get($gray, lighten-4) !important;
    color: $body-color !important;
  }
}

.pink .jr-flat-btn.jr-btn-primary {
  background-color: transparent !important;
  color: $app-primary-pink !important;
  border-color: transparent !important;

  &:hover,
  &:focus {
    background-color: rgba($app-primary-pink, 0.12) !important;
    color: $app-primary-pink !important;
  }
}

.pink .bg-primary {
  & .jr-flat-btn.jr-btn-primary {
    background-color: transparent !important;
    color: $white !important;
    border-color: transparent !important;

    &:hover,
    &:focus {
      background-color: rgba($white, 0.12) !important;
      color: $white !important;
    }
  }
}

.pink .jr-flat-btn.jr-btn-secondary {
  background-color: transparent !important;
  color: $secondary-pink !important;
  border-color: transparent !important;

  &:hover,
  &:focus {
    background-color: rgba($secondary-pink, 0.12) !important;
    color: $secondary-pink !important;
  }
}

.pink .bg-secondary {
  & .jr-flat-btn.jr-btn-secondary {
    background-color: transparent !important;
    color: $white !important;
    border-color: transparent !important;

    &:hover,
    &:focus {
      background-color: rgba($white, 0.12) !important;
      color: $white !important;
    }
  }
}

//_header.scss
.pink .app-main-header {

  & .search-dropdown input {
    @media screen and (max-width: 575px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.pink .color-theme-header {
  background-color: $app-primary-pink;

}

//_sidebar.scss
.pink .side-nav {
  background-color: $sidebar-pink !important;
  color: $sidebar-text-pink !important;
  border-right: solid 1px $gray-200 !important;

  & .user-profile {
    background-color: $gray-100;
    position: relative;
    z-index: 2;

    & .text-white {
      color: $app-primary-pink !important;
    }
  }

  & .customizer {
    border-bottom: solid 1px lighten($sidebar-text-pink, 35%);
  }

  & .user-detail {
    & .user-name {
      color: $app-primary-pink;
    }
  }
}

.pink ul.nav-menu li a {
  color: $sidebar-text-pink;
}

.pink ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-pink;
  }
}

.pink ul.nav-menu > li.open > a,
.pink ul.nav-menu > li > a:hover,
.pink ul.nav-menu > li > a:focus {
  background-color: $sidebar-pink;
  color: $app-primary-pink;
}

.pink ul.nav-menu li.menu > a:focus:before,
.pink ul.nav-menu li.menu > a:hover:before {
  color: $app-primary-pink;
}

.pink ul.nav-menu li ul {
  background-color: $sidebar-pink;
}

.pink ul.nav-menu li.menu > a:before {
  color: $sidebar-text-pink;
}

.pink ul.nav-menu li.menu.open > a:before,
.pink ul.nav-menu li.menu > a:focus:before,
.pink ul.nav-menu li.menu li > a:hover:before {
  color: $app-primary-pink;
}

.pink ul.nav-menu li.menu .sub-menu li > a:before {
  color: $sidebar-text-pink;
}

.pink ul.nav-menu li.menu .sub-menu li > a.active .nav-text {
  background-color: $app-primary-pink;
}

.pink ul.nav-menu li.menu .sub-menu li > a:hover,
.pink ul.nav-menu li.menu .sub-menu li > a:focus,
.pink ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.pink ul.nav-menu li.menu .sub-menu li > a:focus:before,
.pink ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: $sidebar-pink;
  color: $app-primary-pink;
}

/*Header top Navbar Styles*/
.pink .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a {
      color: $app-primary-pink;
    }

    & a {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus {
      color: $app-primary-pink;
    }
  }

  & li.nav-item.active > a {
    color: $app-primary-pink;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a {
      color: $app-primary-pink;
    }
  }

  & .nav-arrow {
    & > a:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus {
      &:before {
        color: $app-primary-pink;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before {
      color: $app-primary-pink;
    }
  }
}

.pink .app-top-nav {
  background-color: $app-primary-pink;

  & .navbar-nav {
    & li.nav-item > a {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a {
      color: $secondary-pink;
    }
  }
}

//_app-module.scss
.pink .module-nav {
  & li a {
    &.active {
      border-color: $app-primary-pink;
    }
  }
}

//_calendar.scss
.pink .rbc-event {
  background-color: $app-primary-pink;
}

.pink .rbc-event.rbc-selected {
  background-color: darken($app-primary-pink, 10%);
}

.pink .rbc-slot-selection {
  background-color: rgba($app-primary-pink, 0.7);
}

.pink .rbc-toolbar button:active,
.pink .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-pink, 0.9);
  border-color: $app-primary-pink;
}

.pink .rbc-toolbar button:active:hover,
.pink .rbc-toolbar button.rbc-active:hover,
.pink .rbc-toolbar button:active:focus,
.pink .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-pink, 0.9);
  border-color: $app-primary-pink;
}

.pink .rbc-toolbar button:focus {
  background-color: rgba($app-primary-pink, 0.9);
  border-color: $app-primary-pink;
}

.pink .rbc-toolbar button:hover {
  background-color: rgba($app-primary-pink, 0.9);
  border-color: $app-primary-pink;
}

.pink .rbc-day-slot .rbc-event {
  border-color: $app-primary-pink;
}

//_chat.scss
.pink .chat-sidenav-title {
  color: $app-primary-pink;
}

.pink .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-pink, 45%);
  }
}

//_dashboard.scss
.pink .contact-list {
  & i {
    color: $app-primary-pink;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.pink .Collapsible__trigger {
  background: $app-primary-pink;
}

.pink .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-pink;
  }
}

//_login.scss
.pink .login-content .form-control {
  &:focus {
    border-color: $app-primary-pink;
  }
}

//_portfolio.scss
.pink .filter-with-bg-color ul li {
  & a {
    border-color: $app-primary-pink;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-pink;
    }
  }
}

//_card.scss
.pink .profile-intro {
  & .icon {
    color: $app-primary-pink;
  }
}

.pink .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-pink;
  }
}

//_tables.scss
.pink .actions {
  color: $secondary-pink;
}

.pink .table-hover tbody tr:hover {
  background-color: rgba($app-primary-pink, 0.075);
}

//Border Color

.pink .border-primary {
  border-color: $app-primary-pink !important;
}

// login page content

.pink .app-logo-content {
  background-color: $app-primary-pink;
}

.pink .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-pink;
    color: $app-primary-pink;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-pink;
    }
  }
}

.pink .btn-group,
.pink .btn-group-vertical {
  > .jr-btn,
  > .btn-default {
    &.active {
      background-color: $app-primary-pink;
      border-color: $app-primary-pink;
    }
  }
  > .jr-flat-btn {
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $app-primary-pink !important;
      border-color: transparent !important;

      &:hover,
      &:focus {
        background-color: rgba($app-primary-pink, 0.12) !important;
        color: $app-primary-pink !important;
      }
    }
  }
}

.pink .nav-pill-sales {
  & .nav-link.active {
    background-color: $app-primary-pink;
    border-color: $app-primary-pink;
  }
}

.pink .form-checkbox > input + .check,
.pink .form-checkbox > input.sm + .check {
  color: $app-primary-pink;
}


