
.card {
  &.box-shadow {
    box-shadow: 5px 5px 20px $gray-300;

    &:hover {
      box-shadow: 5px 5px 20px $gray-500;
    }

    &.card-AB {
      border-left: 5px solid $warning;
    }

    &.card-PR {
      border-left: 5px solid $info;
    }

    &.card-GA {
      border-left: 5px solid $success;
    }

    &.card-CE {
      border-left: 5px solid $gray-700;
    }

    &.card-PE {
      border-left: 5px solid $danger;
    }
  }
}
